import { WizardState } from '@/features/Dashboards/types';
import { KpiLogWizardConfiguration } from '@/types';
import { KpiLogWizardData, KpiLogFileDescriptor } from './types';

export const createWizardData = ({
  data: { propertyIndexMap, baseData },
}: WizardState<KpiLogWizardConfiguration>): KpiLogWizardData => {
  const [, ...kpis] = baseData;
  const logFiles: KpiLogFileDescriptor[] = kpis.map((kpi) => ({
    source: kpi[propertyIndexMap['ingest.source']].toString(),
    testrunId: kpi[propertyIndexMap.id].toString(),
    logFilePath: kpi[propertyIndexMap['kpi.where']].toString(),
  }));
  return { logFiles };
};

import { useHttp } from "@/client/HttpContext";
import { AnnotationTemplateField, DefaultAnnotationTemplateField } from "@/types";
import { HttpClient } from "@/client/types";
import { useQuery, UseQueryResult } from "react-query";
import { useToaster, CoreToasterContext } from '@amzn/dots-core-ui';

const getTemplates = async (
  http: HttpClient,
  toaster: CoreToasterContext
): Promise<AnnotationTemplateField[]> => {
  const templateResponse = await http.get(`annotation/template`);
  if ( templateResponse.status.code !== 200 ) {
    toaster.openToast({
      title: 'Failed to fetch all templates',
      message: 'Failed to fetch all templates, returning array of just default template.',
      type: 'error'
    });
    return [
      DefaultAnnotationTemplateField
	  ];
	}
	return templateResponse.data.templates
};

export const useAnnotationTemplates = (): UseQueryResult<
  AnnotationTemplateField[],
  Error
> => {
  const { http } = useHttp();
  const toaster = useToaster();
	return useQuery({
    queryKey: 'annotationTemplates',
	  queryFn: () =>
	    getTemplates(
	      http,
	      toaster
	    ),
	});
};
import React, { useMemo } from 'react';
import { replaceJiraID } from '@/helpers';
import { MarkdownContent } from './MarkdownContent';

export type DashboardAnnotationContentStringProps = {
  value: string;
};

export const DashboardAnnotationContentString = ({
    value
}: DashboardAnnotationContentStringProps): JSX.Element => {
  const formatedNotes = useMemo(() => replaceJiraID(value), [value]);
  return (
    <MarkdownContent text={formatedNotes} />
  );
};

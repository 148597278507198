import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CorePageLoader } from '@amzn/dots-core-ui';
import { useDashboard as useDashboardApi } from '@/hooks';
import { Dashboard } from '@/types';
import { PageError } from '@/components';

export type DashboardContext = {
  dashboard: Dashboard;
};

export const DashboardContext = React.createContext<
  DashboardContext | undefined
>(undefined);

export type DashboardProviderProps = {
  dashboardName?: string;
  children: React.ReactNode;
};

export const DashboardProvider = ({
  dashboardName = undefined,
  children,
}: DashboardProviderProps): JSX.Element => {
  const { dashboardName: dashboardNameParam } = useParams<{
    dashboardName: string;
  }>();
  const query = useDashboardApi({
    dashboardId: dashboardName ?? dashboardNameParam,
  });
  const value = useMemo(
    () => (query.data ? { dashboard: query.data } : undefined),
    [query.data]
  );
  if (query.isLoading) {
    return <CorePageLoader />;
  }
  if (query.isError) {
    return (
      <PageError
        message="There was an error loading the dashboard"
        error={query.error}
      />
    );
  }
  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = (): DashboardContext => {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
};

export const withDashboard = <C extends React.ComponentType<P>, P>(
  component: C
) => ({
  dashboardName = undefined,
  ...props
}: // eslint-disable-next-line react/require-default-props
P & { dashboardName?: string }): JSX.Element => (
  <DashboardProvider dashboardName={dashboardName}>
    {React.createElement(component, props as P)}
  </DashboardProvider>
);

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useToaster } from '@amzn/dots-core-ui';
import {
  DashboardDetailUri,
  DashboardMonthlyReportsUri,
  DashboardsUri,
} from '@/features/routes';
import { useCreateDashboardReportRequest } from '@/hooks';
import { CorePage, CorePageTitle } from '@/components';
import { DashboardMonthylReportForm } from './components';
import { useDashboard, withDashboard } from './contexts';

export const DashboardMonthlyReportAddPage = withDashboard(
  (): JSX.Element => {
    const history = useHistory();
    const { dashboard } = useDashboard();
    const { openToast } = useToaster();
    const { mutate } = useCreateDashboardReportRequest();
    const onSubmit = useCallback(
      (reportRequest) => {
        mutate(reportRequest, {
          onSuccess: (data) => {
            openToast({
              title: 'Success!',
              message: `Successfully initiated a new report generation with ID: ${data.report_request_id}`,
              type: 'success',
            });
            history.push(DashboardMonthlyReportsUri(dashboard.name));
          },
          onError: () => {
            openToast({
              message: `Failed to initiate report generation.`,
              type: 'error',
            });
          },
        });
      },
      [history, mutate, dashboard.name, openToast]
    );
    return (
      <CorePage
        title="Add new"
        breadcrumbs={[
          { href: DashboardsUri, title: 'Dashboards' },
          { href: DashboardDetailUri(dashboard.name), title: dashboard.name },
          {
            href: DashboardMonthlyReportsUri(dashboard.name),
            title: 'Monthly Reports',
          },
        ]}
        header={<CorePageTitle title="Add a new Monthly Report" />}
      >
        <DashboardMonthylReportForm
          dashboardId={dashboard.id}
          dashboardName={dashboard.name}
          onSubmit={onSubmit}
          onCancel={() =>
            history.push(DashboardMonthlyReportsUri(dashboard.name))
          }
        />
      </CorePage>
    );
  }
);

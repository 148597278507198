import React, { useCallback } from 'react';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Row from '@amzn/meridian/row';
import AddTokens from '@amzn/meridian-tokens/base/icon/plus';
import ChevronUpTokens from '@amzn/meridian-tokens/base/icon/chevron-up-small';
import ChevronDownTokens from '@amzn/meridian-tokens/base/icon/chevron-down-small';
import TrashTokens from '@amzn/meridian-tokens/base/icon/trash';
import { CoreInput } from '@amzn/dots-core-ui';
import { getUniqueId } from '@/features/Dashboards/helpers';
import { SectionDimension } from './types';

export type DashboardLayoutSectionDimensionControlProps = {
  dimensions: SectionDimension[];
  type: 'text' | 'number';
  pattern: RegExp;
  validate: (value: string) => string;
  onChange: (value: SectionDimension[]) => void;
};

export const DashboardLayoutSectionDimensionControl = ({
  dimensions,
  type,
  pattern,
  validate,
  onChange,
}: DashboardLayoutSectionDimensionControlProps): JSX.Element => {
  const onAddClick = useCallback(
    () =>
      onChange(
        dimensions.concat({
          id: getUniqueId(),
          value: '',
        })
      ),
    [dimensions, onChange]
  );
  const onUpdate = useCallback(
    (index: number) => (value: string) => {
      const next = [...dimensions];
      const dim = next[index];
      next[index] = { ...dim, value };
      onChange(next);
    },
    [dimensions, onChange]
  );
  const swap = useCallback(
    (from: number, to: number) => {
      const next = [...dimensions];
      const temp = next[to];
      next[to] = next[from];
      next[from] = temp;
      onChange(next);
    },
    [dimensions, onChange]
  );
  const onRemove = useCallback(
    (index: number) => () => onChange(dimensions.filter((_, i) => i !== index)),
    [dimensions, onChange]
  );
  return (
    <Column>
      {dimensions.map(({ id, value }, i) => (
        <Row key={id} widths={['fill', 'fit', 'fit', 'fit']}>
          <CoreInput
            value={value}
            onChange={onUpdate(i)}
            errorMessage={validate(value)}
            pattern={pattern}
            type={type}
          />
          <Button onClick={() => swap(i, i - 1)} disabled={i === 0} type="icon">
            <Icon tokens={ChevronUpTokens}>Up</Icon>
          </Button>
          <Button
            onClick={() => swap(i, i + 1)}
            disabled={i === dimensions.length - 1}
            type="icon"
          >
            <Icon tokens={ChevronDownTokens}>Down</Icon>
          </Button>
          <Button onClick={onRemove(i)} type="icon">
            <Icon tokens={TrashTokens}>Remove</Icon>
          </Button>
        </Row>
      ))}
      <Button size="small" onClick={() => onAddClick()} type="tertiary">
        <Icon tokens={AddTokens}>Add</Icon>
      </Button>
    </Column>
  );
};

import React, { useMemo } from 'react';
import Card, { CardActionBar } from '@amzn/meridian/card';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import { CorePageLoader } from '@amzn/dots-core-ui';
import { JiraTagButton } from '@/components';
import { TagProperties, useJiras } from '@/hooks';
import { environment } from '@/environments';

export type KpiBucketJiraCardsProps = {
  kpiBucket: string;
  tagProperties?: TagProperties;
};

export const KpiBucketJiraCards = ({
  kpiBucket,
  tagProperties,
}: KpiBucketJiraCardsProps): JSX.Element => {
  const jql = useMemo(() => `bucket ~ '${kpiBucket}'`, [kpiBucket]);
  const { data: jiras, isLoading: isJirasLoading } = useJiras({ jql });
  if (isJirasLoading) {
    return <CorePageLoader />;
  }
  return (
    <Row widths="grid-4" wrap="down" alignmentVertical="top">
      {jiras &&
        jiras.map((jira) => (
          <Card
            key={jira.key}
            minWidth={275}
            href={`${environment.labcollabJiraInstanceUrl}/browse/${jira.key}`}
            onClick={(href): Window | null => window.open(href, '_blank')}
          >
            <Row>
              <Text type="b300">{jira.key}</Text>
              <img
                src={jira.status.iconUrl}
                alt={jira.status.name}
                width="20"
                height="20"
              />
              <img
                src={jira.priority.iconUrl}
                alt={jira.priority.name}
                width="20"
                height="20"
              />
            </Row>
            <Text type="h300" breakWord>
              {jira.summary}
            </Text>
            {tagProperties && (
              <CardActionBar widths="fit" alignmentHorizontal="center">
                <JiraTagButton jira={jira} tagProperties={tagProperties} />
              </CardActionBar>
            )}
          </Card>
        ))}
    </Row>
  );
};

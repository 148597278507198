/* eslint-disable camelcase */
import isValid from 'date-fns/isValid';
import {
  calculate,
  Operand,
  Operation,
  parseExpression,
} from '@/features/Dashboards/aggregation';
import { collator } from '@/helpers';
import { groupKpis } from '@/features/Dashboards/helpers';
import { WizardState } from '@/features/Dashboards/types';
import {
  BaseData,
  BaseDataPropertyIndexMap,
  KpiPropertyValue,
  LineChartWizardConfiguration,
} from '@/types';

export type LineChart = {
  key: string;
  title?: string;
  xAxisLabel: string;
  yAxisLabel: string;
  yAxisUnit: string;
  seriesLabels: string[];
  seriesData: Record<string, unknown>[];
};

export type WizardLineChartData = {
  charts: LineChart[];
};

const X_AXIS_DATA_KEY = 'name';

/**
 * Check if the value is a solidly valid string for date
 */
const isValidDate = (value: unknown): boolean => {
  // only accept string as date format
  if (typeof value !== 'string') {
    return false;
  }
  // date format length has to be at least 10, e.g. 2020-08-04
  if (value.length < 10) {
    return false;
  }
  return isValid(new Date(value));
};

const createChart = (
  baseData: BaseData,
  propertyIndexMap: BaseDataPropertyIndexMap,
  configuration: LineChartWizardConfiguration,
  lineOperation: Operation | Operand
): LineChart => {
  const lineKeys = new Set<string>();
  const { xAxis, yAxis, line } = configuration;
  const [baseDataHeader, ...kpis] = baseData;
  const xData = kpis.reduce((result, kpi) => {
    let xValue = kpi[propertyIndexMap[xAxis.data]].toString();
    if (!xValue) {
      return result;
    }
    if (isValidDate(xValue)) {
      xValue = new Date(xValue).toISOString().slice(0, 10);
    }
    let yValue;
    if (Object.prototype.hasOwnProperty.call(result, xValue)) {
      yValue = result[xValue];
    } else if (
      line.split_key &&
      Object.prototype.hasOwnProperty.call(propertyIndexMap, line.split_key)
    ) {
      yValue = {};
    } else {
      yValue = [baseDataHeader];
    }
    if (!Array.isArray(yValue)) {
      const keyValue =
        line.split_key && kpi[propertyIndexMap[line.split_key]].toString();
      if (keyValue) {
        const indexes = Object.prototype.hasOwnProperty.call(yValue, keyValue)
          ? yValue[keyValue]
          : [baseDataHeader];
        indexes.push(kpi);
        yValue[keyValue] = indexes;
      }
    } else {
      yValue.push(kpi);
    }
    // eslint-disable-next-line no-param-reassign
    result[xValue] = yValue;
    return result;
  }, {} as Record<string, KpiPropertyValue[][] | Record<string, KpiPropertyValue[][]>>);
  const dataPoints = Object.entries(xData)
    .map(([xValue, yValue]) => {
      const dataPoint: Record<string, string> = { [X_AXIS_DATA_KEY]: xValue };
      if (Array.isArray(yValue)) {
        lineOperation.reset();
        calculate(yValue, {}, lineOperation);
        dataPoint[yAxis.label] = lineOperation.value;
        lineKeys.add(yAxis.label);
      } else {
        Object.entries(yValue).forEach(([xxValue, yyValue]) => {
          lineOperation.reset();
          calculate(yyValue, {}, lineOperation);
          dataPoint[xxValue] = lineOperation.value;
          lineKeys.add(xxValue);
        });
      }
      return dataPoint;
    })
    .sort((a, b) => collator.compare(a[X_AXIS_DATA_KEY], b[X_AXIS_DATA_KEY]));
  return {
    key: '',
    xAxisLabel: X_AXIS_DATA_KEY,
    yAxisUnit: yAxis.unit,
    yAxisLabel: yAxis.label,
    seriesLabels: Array.from(lineKeys),
    seriesData: dataPoints,
  };
};

export const createWizardData = ({
  configuration,
  data: { propertyIndexMap, baseData },
}: WizardState<LineChartWizardConfiguration>): WizardLineChartData => {
  const { line, group_key } = configuration;
  const lineOperation = parseExpression(line.value);
  if (
    !group_key ||
    !Object.prototype.hasOwnProperty.call(propertyIndexMap, group_key)
  ) {
    return {
      charts: [
        createChart(baseData, propertyIndexMap, configuration, lineOperation),
      ],
    };
  }
  const [baseDataHeader] = baseData;
  const kpiGroups = groupKpis(propertyIndexMap, baseData, [group_key]);
  const charts = kpiGroups.map(({ baseDataIndices, groupedPropertyValues }) => {
    const groupBaseData: BaseData = [
      baseDataHeader,
      ...baseDataIndices.map((i) => baseData[i]),
    ];
    const chart = createChart(
      groupBaseData,
      propertyIndexMap,
      configuration,
      lineOperation
    );
    chart.title = groupedPropertyValues[group_key];
    return chart;
  });
  return { charts };
};

import React from 'react';
import Alert from '@amzn/meridian/alert';
import { DashboardBaseTableWizardForm } from './DashboardBaseTableWizardForm';
import { DashboardJiraTableWizardForm } from './DashboardJiraTableWizardForm';
import { DashboardKpiBaseTableWizardForm } from './DashboardKpiBaseTableWizardForm';
import { DashboardKpiLogWizardForm } from './DashboardKpiLogWizardForm';
import { DashboardKpiTableWizardForm } from './DashboardKpiTableWizardForm';
import { DashboardLineChartWizardForm } from './DashboardLineChartWizardForm';
import { DashboardPivotTableWizardForm } from './DashboardPivotTableWizardForm';
import { DashboardPivotTabWizardForm } from './DashboardPivotTabWizardForm';
import { DashboardWizardFormProps } from './types';

export const DashboardWizardForm = ({
  wizard,
  onCancel,
  onSubmit,
}: DashboardWizardFormProps): JSX.Element => {
  switch (wizard.type) {
    case 'basetable':
      return (
        <DashboardBaseTableWizardForm
          wizard={wizard}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      );
    case 'jiratable':
      return (
        <DashboardJiraTableWizardForm
          wizard={wizard}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      );
    case 'kpibasetable':
      return (
        <DashboardKpiBaseTableWizardForm
          wizard={wizard}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      );
    case 'kpilog':
      return (
        <DashboardKpiLogWizardForm
          wizard={wizard}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      );
    case 'kpitable':
      return (
        <DashboardKpiTableWizardForm
          wizard={wizard}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      );
    case 'linechart':
      return (
        <DashboardLineChartWizardForm
          wizard={wizard}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      );
    case 'pivottab':
      return (
        <DashboardPivotTabWizardForm
          wizard={wizard}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      );
    case 'pivottable':
      return (
        <DashboardPivotTableWizardForm
          wizard={wizard}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      );
    default:
      return (
        <Alert type="error" size="medium">
          Wizard type not valid
        </Alert>
      );
  }
};

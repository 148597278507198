import React from 'react';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Card, { CardHeader } from '@amzn/meridian/card';

const documents = [
  [
    {
      header: 'User Guide',
      content: 'Basic usage of Boomerang Dashboard',
      href:
        'https://quip-amazon.com/eZruAhb4Ztqm/Boomerang-Pivot-v2-User-Guide',
    },
    {
      header: 'Dashboard Guide',
      content: 'Visualize data as you like',
      href:
        'https://quip-amazon.com/abVgAvoKw6l1/Boomerang-Pivot-v2-Dashboard-Config-and-Customize-Guide',
    },
  ],
  [
    {
      header: 'Customer Translator',
      content: 'Translate data with your own translator',
      href:
        'https://quip-amazon.com/171XAo86evr1/Boomerang-Pivot-v2-Customer-Translator-Guide',
    },
    {
      header: 'Public API',
      content: 'Access your data through code from our API',
      href:
        'https://quip-amazon.com/3dNMAjPNpja9/Boomerang-Pivot-v2-Public-API-Guide',
    },
  ],
];

export const Documents = (): JSX.Element => {
  return (
    <>
      {documents &&
        documents.map((docRow) => (
          <Row
            key={JSON.stringify(docRow)}
            widths={docRow.length === 1 ? ['45%'] : ['45%', '45%']}
          >
            {docRow.map((doc) => (
              <Card key={doc.header} href={doc.href}>
                <CardHeader>
                  <Heading level={3}>{doc.header}</Heading>
                </CardHeader>
                <Text type="b300">{doc.content}</Text>
              </Card>
            ))}
          </Row>
        ))}
    </>
  );
};

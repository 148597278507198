/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { dictToURI, URIToDict } from '@/helpers';
import {
  DashboardExactTimeRange,
  DashboardFilterTimeRange,
  DashboardRelativeTimeRange,
} from '@/types';

export type Filter = {
  key: string;
  operator: string;
  value: string[];
};

export type DashboardQueryParameters = {
  tab?: string;
  allBuilds?: boolean;
  dashboard?: string;
  groupTab?: string;
  filterType?: string;
  filterValues?: string;
  numberOfBuilds?: string;
  importedDashboards?: string[];
  timeRange?: DashboardFilterTimeRange;
  filters?: Filter[];
};

export type DashboardQueryParameterResult = {
  params: DashboardQueryParameters;
  serializeUiQueryParameters: (params: DashboardQueryParameters) => string;
  createApiFilters: (params: DashboardQueryParameters) => Record<string, any>;
};

const mapQueryStringToDashboardQueryParameters = (
  queryString: string
): DashboardQueryParameters => {
  const query = URIToDict(queryString);
  const result = {} as DashboardQueryParameters;
  if ('tab' in query) {
    result.tab = query.tab as string;
  }
  if ('dashboard' in query) {
    result.dashboard = query.dashboard as string;
  }
  if ('groupTab' in query) {
    result.groupTab = query.groupTab as string;
  }
  if ('filterType' in query) {
    result.filterType = query.filterType as string;
  }
  if ('filterValues' in query) {
    result.filterValues = query.filterValues as string;
  }
  if ('numberOfBuilds' in query) {
    result.numberOfBuilds = query.numberOfBuilds as string;
  }
  if ('importedDashboards' in query) {
    result.importedDashboards = (Array.isArray(query.importedDashboards)
      ? query.importedDashboards
      : [query.importedDashboards]) as string[];
  }
  if ('allBuilds' in query) {
    result.allBuilds = query.allBuilds as boolean;
  }
  if ('start_time' in query && 'end_time' in query) {
    result.timeRange = [
      query.start_time,
      query.end_time,
    ] as DashboardExactTimeRange;
  }
  if ('time_range_unit' in query && 'time_range_value' in query) {
    result.timeRange = {
      unit: query.time_range_unit,
      number: query.time_range_value,
    } as DashboardRelativeTimeRange;
  }
  result.filters = Object.keys(query)
    .filter((key) => key.startsWith('include') || key.startsWith('exclude'))
    .map((key) => {
      const [operator, modifier, ...rest] = key.split('.');
      return {
        key: ['kats', ...rest].join('.'),
        operator: modifier === 'fuzzy' ? `${operator}_fuzzy` : operator,
        value: (Array.isArray(query[key])
          ? query[key]
          : [query[key]]) as string[],
      };
    });
  return result;
};

const flattenTimeRange = (
  timeRange?: DashboardFilterTimeRange
): Record<string, unknown> => {
  if (!timeRange) {
    return {};
  }
  const result = {} as Record<string, unknown>;
  if (Array.isArray(timeRange) && timeRange.length === 2) {
    const [start_time, end_time] = timeRange;
    result.start_time = start_time;
    result.end_time = end_time;
  } else if ('number' in timeRange && 'unit' in timeRange) {
    result.time_range_value = timeRange.number;
    result.time_range_unit = timeRange.unit;
  }
  return result;
};

const flattenFilters = (filters?: Filter[]): Record<string, unknown> => {
  if (!filters) {
    return {};
  }
  return filters.reduce((builder, filter) => {
    // the first entry in the filter's key will be 'kats' (for now...)
    const [, ...dataKeyParts] = filter.key.split('.');
    const operator = filter.operator.includes('_')
      ? `${filter.operator.split('_')[0]}.fuzzy`
      : `${filter.operator}.exact`;
    const payloadKey = [operator, ...dataKeyParts].join('.');
    builder[payloadKey] = filter.value;
    return builder;
  }, {} as Record<string, unknown>);
};

const createApiFilters = ({
  dashboard,
  groupTab,
  numberOfBuilds,
  filterType,
  filterValues,
  filters,
  timeRange,
  allBuilds,
  ...rest
}: DashboardQueryParameters): Record<string, any> => ({
  ...rest,
  dashboard_name: dashboard,
  group: groupTab,
  filter_type: filterType,
  filter_values: filterValues,
  fetch_all_builds: allBuilds,
  last_n_builds: numberOfBuilds,
  ...flattenTimeRange(timeRange),
  ...flattenFilters(filters),
});

export const serializeUiQueryParameters = ({
  filters,
  timeRange,
  ...rest
}: DashboardQueryParameters): string =>
  dictToURI({
    ...rest,
    ...flattenTimeRange(timeRange),
    ...flattenFilters(filters),
  });

export const useDashboardQueryParameters = (): DashboardQueryParameterResult => {
  const { search } = useLocation();
  const params = useMemo(
    () => mapQueryStringToDashboardQueryParameters(search),
    [search]
  );

  return {
    params,
    serializeUiQueryParameters,
    createApiFilters,
  };
};

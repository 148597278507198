import React from 'react';
import Tag from '@amzn/meridian/tag';
import { TranslatorType } from '@/features/Translators/types';

export type TranslatorTypeTagProps = { type: TranslatorType };

export const TranslatorTypeTag = ({
  type,
}: TranslatorTypeTagProps): JSX.Element => (
  <div>
    <Tag type={type === 'CT' ? 'neutral' : 'theme'}>{type}</Tag>
  </div>
);

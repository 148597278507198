import { isNullOrWhitespace } from '@amzn/dots-core-ui';
import { getUniqueId } from '@/features/Dashboards/helpers';
import { DashboardWizardConfiguration } from '@/types';
import { Action, LayoutParser, SectionParser, State } from './types';

export const wizardTypeLabels: Record<
  DashboardWizardConfiguration['type'],
  string
> = {
  basetable: 'Base Table',
  jiratable: 'Jira Table',
  kpibasetable: 'Kpi Base Table',
  kpilog: 'Kpi Log',
  kpitable: 'Kpi Table',
  linechart: 'Line Chart',
  pivottab: 'Pivot Tab',
  pivottable: 'Pivot Table',
};

const swap = <T>(collection: T[], from: number, to: number) => {
  const next = [...collection];
  const temp = next[to];
  next[to] = next[from];
  next[from] = temp;
  return next;
};

const update = <T extends { id: string }>(
  collection: T[],
  id: string,
  next: T
) => {
  const nextCollection = [...collection];
  const i = nextCollection.findIndex((x) => x.id === id);
  nextCollection[i] = next;
  return nextCollection;
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'layout-add':
      return {
        ...state,
        editingLayoutIndex: state.layouts.length,
        layouts: state.layouts.concat({
          id: getUniqueId(),
          name: '',
          sections: [],
        }),
      };
    case 'layout-move': {
      let nextEditingLayoutIndex = state.editingLayoutIndex;
      if (state.editingLayoutIndex === action.payload.fromIndex) {
        nextEditingLayoutIndex = action.payload.toIndex;
      }
      if (state.editingLayoutIndex === action.payload.toIndex) {
        nextEditingLayoutIndex = action.payload.fromIndex;
      }
      return {
        ...state,
        editingLayoutIndex: nextEditingLayoutIndex,
        layouts: swap(
          state.layouts,
          action.payload.fromIndex,
          action.payload.toIndex
        ),
        wizards: state.wizards.map((w) => {
          if (w.tabIndex === action.payload.fromIndex) {
            return { ...w, tabIndex: action.payload.toIndex };
          }
          if (w.tabIndex === action.payload.toIndex) {
            return { ...w, tabIndex: action.payload.fromIndex };
          }
          return w;
        }),
      };
    }
    case 'layout-remove': {
      let nextEditingLayoutIndex = state.editingLayoutIndex;
      if (state.editingLayoutIndex === action.payload.layoutIndex) {
        nextEditingLayoutIndex = undefined;
      }
      if (
        state.editingLayoutIndex !== undefined &&
        state.editingLayoutIndex > action.payload.layoutIndex
      ) {
        nextEditingLayoutIndex = state.editingLayoutIndex - 1;
      }
      return {
        ...state,
        editingLayoutIndex: nextEditingLayoutIndex,
        layouts: state.layouts.filter(
          (_, i) => i !== action.payload.layoutIndex
        ),
        wizards: state.wizards
          .map((wizard) => {
            if (wizard.tabIndex === action.payload.layoutIndex) {
              return undefined;
            }
            if (wizard.tabIndex > action.payload.layoutIndex) {
              return { ...wizard, tabIndex: wizard.tabIndex - 1 };
            }
            return wizard;
          })
          .filter((wizard) => wizard) as DashboardWizardConfiguration[],
      };
    }
    case 'layout-update':
      return {
        ...state,
        layouts: update(
          state.layouts,
          action.payload.layoutId,
          action.payload.layout
        ),
      };
    case 'layout-preview':
      return {
        ...state,
        editingLayoutIndex: action.payload.layoutIndex,
      };
    case 'section-add':
      return {
        ...state,
        layouts: state.layouts.map((l) => {
          if (l.id === action.payload.layoutId) {
            l.sections.push({
              id: getUniqueId(),
              heights: [{ id: getUniqueId(), value: 'fit' }],
              widths: [{ id: getUniqueId(), value: '12' }],
            });
          }
          return l;
        }),
      };
    case 'section-move':
      return {
        ...state,
        layouts: state.layouts.map((l, i) => {
          if (i === action.payload.layoutIndex) {
            return {
              ...l,
              sections: swap(
                l.sections,
                action.payload.fromIndex,
                action.payload.toIndex
              ),
            };
          }
          return l;
        }),
        wizards: state.wizards.map((w) => {
          if (
            w.tabIndex === action.payload.layoutIndex &&
            w.sectionIndex === action.payload.fromIndex
          ) {
            return { ...w, sectionIndex: action.payload.toIndex };
          }
          if (
            w.tabIndex === action.payload.layoutIndex &&
            w.sectionIndex === action.payload.toIndex
          ) {
            return { ...w, sectionIndex: action.payload.fromIndex };
          }
          return w;
        }),
      };
    case 'section-remove':
      return {
        ...state,
        layouts: state.layouts.map((l, i) => {
          if (i === action.payload.layoutIndex) {
            return {
              ...l,
              sections: l.sections.filter(
                (_, si) => si !== action.payload.sectionIndex
              ),
            };
          }
          return l;
        }),
        wizards: state.wizards.filter(
          (w) =>
            !(
              w.tabIndex === action.payload.layoutIndex &&
              w.sectionIndex === action.payload.sectionIndex
            )
        ),
      };
    case 'section-update':
      return {
        ...state,
        layouts: state.layouts.map((l) => {
          if (l.id === action.payload.layoutId) {
            return {
              ...l,
              sections: update(
                l.sections,
                action.payload.sectionId,
                action.payload.section
              ),
            };
          }
          return l;
        }),
      };
    case 'wizard-add':
      return {
        ...state,
        wizards: state.wizards.concat(action.payload),
      };
    case 'wizard-move':
      return {
        ...state,
        wizards: state.wizards.map((w) => {
          if (
            w.tabIndex === action.payload.layoutIndex &&
            w.sectionIndex === action.payload.sectionIndex &&
            w.position === action.payload.fromPosition
          ) {
            return { ...w, position: action.payload.toPosition };
          }
          if (
            w.tabIndex === action.payload.layoutIndex &&
            w.sectionIndex === action.payload.sectionIndex &&
            w.position === action.payload.toPosition
          ) {
            return { ...w, position: action.payload.fromPosition };
          }
          return w;
        }),
      };
    case 'wizard-remove':
      return {
        ...state,
        wizards: state.wizards.filter((w) => w.id !== action.payload.wizardId),
      };
    case 'wizard-update':
      return {
        ...state,
        wizards: update(
          state.wizards,
          action.payload.wizardId,
          action.payload.wizard
        ),
      };
    case 'section-editor-open':
      return {
        ...state,
        editingWizard: undefined,
        editingSection: {
          layoutId: action.payload.layoutId,
          section: action.payload.section,
        },
      };
    case 'section-editor-close':
      return { ...state, editingSection: undefined };
    case 'wizard-editor-open':
      return {
        ...state,
        editingSection: undefined,
        editingWizard: action.payload,
      };
    case 'wizard-editor-close':
      return { ...state, editingWizard: undefined };
    case 'submition-start':
      return {
        ...state,
        submitState: { status: 'submitting' },
      };
    case 'submition-end':
      return {
        ...state,
        submitState: { status: 'idle' },
      };
    case 'submition-failure':
      return {
        ...state,
        submitState: { status: 'error', error: action.payload },
      };
    default:
      throw new Error('bad action type');
  }
};

export const widthPattern = new RegExp('^([1-9]|1[0-2])$');

export const validateIndividualWidths = (value: string): string => {
  if (isNullOrWhitespace(value)) {
    return 'A width is required';
  }
  const valueAsNumber = Number(value);
  if (valueAsNumber > 12) {
    return 'Column widths must be less than or equal to 12';
  }
  if (valueAsNumber < 1) {
    return 'Column widths must be greater than or equal to 1';
  }
  return '';
};

export const heightPattern = new RegExp('^([1-9][0-9]?)$|^(f|fi|fit)$', 'i');

export const validateIndividualHeights = (value: string): string => {
  if (isNullOrWhitespace(value)) {
    return 'A height is required';
  }
  if (value === 'fit') {
    return '';
  }
  const valueAsNumber = Number(value);
  if (Number.isNaN(valueAsNumber)) {
    return 'Row heights must either be "fit" or between 1-99';
  }
  if (valueAsNumber > 99) {
    return 'Row heights must be less than or equal to 99';
  }
  if (valueAsNumber < 1) {
    return 'Row heights must be greater than or equal to 1';
  }
  return '';
};

export const sectionParser: SectionParser = {
  toFormModel: (sections) =>
    sections?.map(({ heights, widths }) => ({
      id: getUniqueId(),
      heights: heights?.map((value) => ({ id: getUniqueId(), value })) ?? [],
      widths: widths?.map((value) => ({ id: getUniqueId(), value })) ?? [],
    })) ?? [{ id: getUniqueId(), heights: [], widths: [] }],
  toConfigurationModel: (sections) =>
    sections.map(({ heights, widths }) => ({
      heights: heights.map(({ value }) => value),
      widths: widths.map(({ value }) => value),
    })),
};

export const layoutParser: LayoutParser = {
  toFormModel: (layouts) =>
    layouts?.map((layout) => ({
      id: getUniqueId(),
      name: layout.name,
      sections: sectionParser.toFormModel(layout.layout),
    })) ?? [{ id: getUniqueId(), name: '', sections: [] }],
  toConfigurationModel: (layouts) =>
    layouts.map(({ name, sections }) => ({
      name,
      layout: sectionParser.toConfigurationModel(sections),
    })),
};

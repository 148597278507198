import React, { useCallback, useContext, useMemo, useState } from 'react';
import { DashboardWizardConfiguration, KpiProperty } from '@/types';

/**
 * Key-value-pairs, matching a collection of KPI properties to values associated
 * with the cells selected by a user
 */
export type WizardSelection = Partial<Record<KpiProperty, string>>[];

/**
 * An individual wizard's selection state includes its own selection and the
 * selections from all of its parents
 */
export type WizardSelectionState = {
  /**
   * The Wizard's parent ID
   */
  parentId?: string;
  /**
   * The Wizard's current selection
   */
  selection: WizardSelection;
  /**
   * The Wizard's parent's selection, used to filter KPIs before rendering
   */
  parentSelection: WizardSelection[];
};

/**
 * Encapsulates all wizard selections merged with their parent
 *
 * A wizard's complete selection state is modeled as a 2D array. The first
 * dimension represents filters from each of the filters predecesors, the second
 * dimension represents the filters associated with each "generation"
 *
 * For a KPI to match a wizard's selection state, it must match at least one
 * term from each collection of filters.
 */
export type DashboardSelectionState = Record<string, WizardSelectionState>;

export type DashboardSelectionContext = {
  /**
   * The selection state of each wizard, wizard selection state includes parent
   * selections
   */
  selectionState: DashboardSelectionState;
  /**
   * Update a specific wizard's selection state
   */
  updateSelection: (wizardId: string, selection: WizardSelection) => void;
};

export const DashboardSelectionContext = React.createContext<
  DashboardSelectionContext | undefined
>(undefined);

export type DashboardSelectionProviderProps = {
  wizards: DashboardWizardConfiguration[];
  children: React.ReactNode;
};

/**
 * Build a map of wizard ID to immediate children of the wizard
 *
 * Example:
 *   input: [{id: A}, {id: B, parent: A}, {id: C, parent: B}, {id:D, parent: A}]
 *   output: { A: [B, C, D], B: [C], C: [], D: [] }
 */
const buildDependencyGraph = (
  wizards: DashboardWizardConfiguration[]
): Map<string, string[]> =>
  wizards.reduce((graph, w) => {
    if (!graph.has(w.id)) {
      graph.set(w.id, []);
    }
    if (w.parent) {
      const parent = graph.get(w.parent);
      graph.set(w.parent, (parent ?? []).concat(w.id));
    }
    return graph;
  }, new Map<string, string[]>());

/**
 * Merge a single selection with a collection of selections
 */
const mergeSelection = (
  selection: WizardSelection,
  parentSelection: WizardSelection[]
): WizardSelection[] =>
  [...parentSelection, selection].filter((s) => s.length !== 0);

/**
 * Create a function to update DashboardSelectionState, initiated from an update
 * to a single wizard's selection
 */
const updateStateFactory = (
  wizardId: string,
  selection: WizardSelection,
  dependencyGraph: Map<string, string[]>
) => (prev: DashboardSelectionState): DashboardSelectionState => {
  const wizardState = prev[wizardId];
  let parentSelection: WizardSelection[] = [];
  if (wizardState.parentId && wizardState.parentId in prev) {
    const parentState = prev[wizardState.parentId];
    parentSelection = mergeSelection(
      parentState.selection,
      parentState.parentSelection
    );
  }
  // the next state will be a copy of the previous, and an update to the current
  // wizard state, updating its own selection and its merged selection
  const next = {
    ...prev,
    [wizardId]: {
      ...prev[wizardId],
      selection,
    },
  };
  // our queue of children to update will need a new parent selection based on
  // the incoming change and the target wizard's parent selection
  const queue =
    dependencyGraph.get(wizardId)?.map((id) => ({
      id,
      parentSelection: mergeSelection(selection, parentSelection),
    })) ?? [];
  // we need to progagate the new selection to all of the current wizard's
  // children, we will accomplish this in a pre-order traveral of our dependency
  // graph
  while (queue.length) {
    const node = queue.shift();
    if (node) {
      // we will updated each child and then propigate its new selection to each
      // of its children
      next[node.id] = {
        ...next[node.id],
        parentSelection: node.parentSelection,
      };
      // push the current node's children with the updated parent selection to
      // our update queue
      (dependencyGraph.get(node.id) ?? []).forEach((id) =>
        queue.push({
          id,
          parentSelection: mergeSelection(
            next[node.id].selection,
            node.parentSelection
          ),
        })
      );
    }
  }
  return next;
};

export const DashboardSelectionProvider = ({
  wizards = [],
  children,
}: DashboardSelectionProviderProps): JSX.Element => {
  const [state, setState] = useState<DashboardSelectionState>(() =>
    Object.fromEntries(
      wizards.map(({ id, parent }) => [
        id,
        { parentId: parent, selection: [], parentSelection: [] },
      ])
    )
  );
  const dependencyGraph = useMemo(() => buildDependencyGraph(wizards), [
    wizards,
  ]);
  const value = useMemo<DashboardSelectionContext>(
    () => ({
      selectionState: state,
      updateSelection: (wizardId: string, selection: WizardSelection): void =>
        setState(updateStateFactory(wizardId, selection, dependencyGraph)),
    }),
    [dependencyGraph, state]
  );
  return (
    <DashboardSelectionContext.Provider value={value}>
      {children}
    </DashboardSelectionContext.Provider>
  );
};

export const useWizardSelection = (
  wizardId: string
): {
  selectionState: WizardSelectionState;
  updateSelection: (selection: WizardSelection) => void;
} => {
  const context = useContext(DashboardSelectionContext);
  if (context === undefined) {
    throw new Error(
      'useWizardSelection must be used within an DashboardSelectionProvider'
    );
  }
  const selectionState = useMemo(() => context.selectionState[wizardId], [
    context.selectionState,
    wizardId,
  ]);
  const updateSelection = useCallback(
    (selection: WizardSelection) =>
      context.updateSelection(wizardId, selection),
    [context, wizardId]
  );
  return { selectionState, updateSelection };
};

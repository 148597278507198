import React from 'react';
import Link from '@amzn/meridian/link';
import Heading from '@amzn/meridian/heading';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';
import { ControlledExpander } from '@/components';
import { useFlattenObject } from '@/hooks';
import { isValidUrl } from '@/features/Kpi/helpers';

export type KpiProperties = {
  kpi: Record<string, any>;
};

export const KpiProperties = ({ kpi }: KpiProperties): JSX.Element => {
  const flattenKPI = useFlattenObject(kpi);

  let testrun_url = kpi.data.testrun_url
  const isKatsTestrun = kpi.id != undefined && testrun_url.includes("https://kats.labcollab.net/testrun/")
  if (isKatsTestrun) {
    testrun_url = `https://scheduler.dots.lab126.a2z.com/scheduling/testruns/${kpi.id}/results`
  }

  return (
    <>
      {Object.keys(kpi).map((field) => (
        <ControlledExpander
          key={field}
          title={<Heading level={5}>{field.toUpperCase()}</Heading>}
        >
          <Table spacing="small" headerColumns={0}>
            {Object.entries(flattenKPI)
              .filter(([key]) => key.startsWith(field))
              .map(([key, value]) => (
                <TableRow key={key} highlightOnHover>
                  <TableCell width="30%">{`${key}`}</TableCell>
                  <TableCell>
                    {isValidUrl(value) ? (
                      <Link
                        href={key == "data.testrun_url" ?
                          testrun_url
                        : value}
                        target="_blank"
                        rel="noopener noreferrer"
                        type="primary"
                      >
                        {key == "data.testrun_url" ? testrun_url : value}
                      </Link>
                    ) : (
                      <>{value && <Text>{value}</Text>}</>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </Table>
        </ControlledExpander>
      ))}
    </>
  );
};

import React, { useEffect } from 'react';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Select, { SelectOption } from '@amzn/meridian/select';
import Toggle from '@amzn/meridian/toggle';
import closeTokens from '@amzn/meridian-tokens/base/icon/close';
import {
  useForm,
  isNullOrWhitespace,
  CoreFilterableSelect,
  CoreInput,
} from '@amzn/dots-core-ui';
import { TabOverride } from './types';

export type DashboardTabOverrideControlProps = {
  tabOverride?: Partial<TabOverride>;
  onChange: (value: TabOverride) => void;
  onRemove: () => void;
};

export const DashboardTabOverrideControl = ({
  tabOverride,
  onChange,
  onRemove,
}: DashboardTabOverrideControlProps): JSX.Element => {
  const { values, handlers, errors, states } = useForm({
    id: {
      initialValue: tabOverride?.id,
    },
    enabled: {
      initialValue: tabOverride?.enabled ?? true,
    },
    type: {
      initialValue: tabOverride?.type ?? 'device_family',
      validate: (value: string) =>
        isNullOrWhitespace(value) ? 'Tab type is required' : '',
    },
    groupName: {
      initialValue: tabOverride?.groupName ?? '',
      validate: (value: string) =>
        isNullOrWhitespace(value) ? 'Group name is required' : '',
    },
    value: {
      initialValue: tabOverride?.value ?? [],
      validate: (value: string[]) =>
        value.length === 0 ? 'A tab value is required' : '',
    },
  });
  useEffect(() => {
    onChange(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  return (
    <Row
      widths={['grid-10', 'grid-1', 'grid-1']}
      alignmentVertical="center"
      spacingInset="400"
      type="outline"
    >
      <Row widths={['grid-3', 'grid-3', 'grid-6']} alignmentVertical="top">
        <Select
          label="Type"
          value={values.type}
          onChange={handlers.type}
          disabled={!values.enabled}
        >
          <SelectOption label="Device Family" value="device_family" />
          <SelectOption label="Project Group" value="project_group" />
        </Select>
        <CoreInput
          label="Group name"
          value={values.groupName}
          onChange={handlers.groupName}
          errorMessage={errors.groupName}
          validationState={states.groupName}
          disabled={!values.enabled}
        />
        <CoreFilterableSelect
          label="Value(s)"
          canAddItems
          items={tabOverride?.value ?? []}
          value={values.value}
          onChange={handlers.value}
          errorMessage={errors.value}
          validationState={states.value}
          disabled={!values.enabled}
        />
      </Row>
      <Toggle checked={values.enabled} onChange={handlers.enabled} />
      <Button
        type="icon"
        size="small"
        onClick={onRemove}
        disabled={onRemove === undefined}
      >
        <Icon tokens={closeTokens}>delete</Icon>
      </Button>
    </Row>
  );
};

import React, { useCallback, useMemo } from 'react';
import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Text from '@amzn/meridian/text';
import Icon from '@amzn/meridian/icon';
import CaretUpTokens from '@amzn/meridian-tokens/base/icon/caret-up';
import CaretDownTokens from '@amzn/meridian-tokens/base/icon/caret-down';
import PencilTokens from '@amzn/meridian-tokens/base/icon/pencil';
import { useDashboardLayoutsFormState } from './DashboardLayoutsFormStateContext';
import { wizardTypeLabels } from './helpers';

export type DashboardLayoutSectionWizardTileProps = {
  layoutIndex: number;
  sectionIndex: number;
  position: number;
};

export const DashboardLayoutSectionWizardTile = ({
  layoutIndex,
  sectionIndex,
  position,
}: DashboardLayoutSectionWizardTileProps): JSX.Element => {
  const {
    dispatch,
    state: { layouts, wizards },
  } = useDashboardLayoutsFormState();
  const wizard = useMemo(
    () =>
      wizards.find(
        (w) =>
          w.tabIndex === layoutIndex &&
          w.sectionIndex === sectionIndex &&
          w.position === position
      ),
    [layoutIndex, position, sectionIndex, wizards]
  );
  const onEdit = useCallback(
    () =>
      dispatch({
        type: 'wizard-editor-open',
        payload: { wizardId: wizard?.id, layoutIndex, sectionIndex, position },
      }),
    [dispatch, layoutIndex, position, sectionIndex, wizard]
  );
  const onMoveDown =
    position !==
    layouts[layoutIndex].sections[sectionIndex].heights.length *
      layouts[layoutIndex].sections[sectionIndex].widths.length -
      1
      ? () =>
          dispatch({
            type: 'wizard-move',
            payload: {
              layoutIndex,
              sectionIndex,
              fromPosition: position,
              toPosition: position + 1,
            },
          })
      : undefined;
  const onMoveUp =
    position !== 0
      ? () =>
          dispatch({
            type: 'wizard-move',
            payload: {
              layoutIndex,
              sectionIndex,
              fromPosition: position,
              toPosition: position - 1,
            },
          })
      : undefined;
  return (
    <Row widths={['fill', 'fit']} type="fill" minHeight="128px">
      <Column alignmentHorizontal="center" alignmentVertical="center">
        {wizard ? (
          <Row>
            <Button type="tertiary" onClick={onEdit}>
              <Icon tokens={PencilTokens}>
                Edit {wizard.id}: {wizardTypeLabels[wizard.type]}
              </Icon>
            </Button>
            <Text>
              {wizard.id}: {wizardTypeLabels[wizard.type]}
            </Text>
          </Row>
        ) : (
          <Alert type="warning" size="medium">
            No wizard defined...
            <Button type="link" size="small" onClick={onEdit}>
              Pick one!
            </Button>
          </Alert>
        )}
      </Column>
      <Column alignmentVertical="top" spacingInset="300">
        <Button
          size="small"
          type="secondary"
          onClick={onMoveUp}
          disabled={onMoveUp === undefined}
        >
          <Icon tokens={CaretUpTokens}>Move wizard up in the order</Icon>
        </Button>
        <Button
          size="small"
          type="secondary"
          onClick={onMoveDown}
          disabled={onMoveDown === undefined}
        >
          <Icon tokens={CaretDownTokens}>Move wizard down in the order</Icon>
        </Button>
      </Column>
    </Row>
  );
};

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useHttp, HttpClient } from '@/client';
import { DashboardEmailReportConfiguration } from '@/types';

export type UpdateDashboardEmailConfigurationArgs = {
  reportConfigurationId: string;
};

export const updateDashboardEmailReportConfiguration = async (
  http: HttpClient,
  reportConfiguration: Partial<DashboardEmailReportConfiguration>
): Promise<DashboardEmailReportConfiguration> => {
  const { data, status } = await http.post(
    `dashboard/email_report/${reportConfiguration.report_id}`,
    reportConfiguration
  );
  if (status.code === 200) {
    return data;
  }
  throw new Error(data.message);
};

export const useDashboardEmailReportUpdate = (): UseMutationResult<
  Partial<DashboardEmailReportConfiguration>,
  Error,
  Partial<DashboardEmailReportConfiguration>
> => {
  const { http } = useHttp();
  const queryClient = useQueryClient();
  const { dashboardName: rawName } = useParams<{
    dashboardName: string;
    reportConfigurationId: string;
  }>();
  const dashboardName = useMemo(() => decodeURIComponent(rawName), [rawName]);
  return useMutation(
    (reportConfiguration: Partial<DashboardEmailReportConfiguration>) =>
      updateDashboardEmailReportConfiguration(http, reportConfiguration),
    {
      onSettled: () => {
        queryClient.invalidateQueries([
          'dashboardEmailReports',
          { dashboardName },
        ]);
      },
    }
  );
};

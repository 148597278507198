import React from 'react';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Select, { SelectOption } from '@amzn/meridian/select';
import { CoreInput } from '@amzn/dots-core-ui';
import { TestSourcePropertySelect } from '@/components';
import { useDashboard } from '@/features/Dashboards/contexts';
import { DashboardWizardBase } from '@/types';
import { useParentWizardOptions } from '../hooks';
import { DashboardWizardFormFieldLabel } from './DashboardWizardFormFieldLabel';
import {
  DashboardWizardBaseFormConfigurationHandlers,
  DashboardWizardBaseFormConfigurationValues,
} from './types';

export type DashboardWizardBaseControlProps<
  TValues extends DashboardWizardBaseFormConfigurationValues,
  THandlers extends DashboardWizardBaseFormConfigurationHandlers
> = {
  wizard: DashboardWizardBase;
  values: TValues;
  handlers: THandlers;
};

/**
 * A component that wraps controls for DashboardWizardBase fields
 */
export const DashboardWizardBaseControl = <
  TValues extends DashboardWizardBaseFormConfigurationValues,
  THandlers extends DashboardWizardBaseFormConfigurationHandlers
>({
  wizard,
  values,
  handlers,
}: DashboardWizardBaseControlProps<TValues, THandlers>): JSX.Element => {
  const { dashboard } = useDashboard();
  const options = useParentWizardOptions(wizard);
  return (
    <>
      <CoreInput
        label="Wizard Title"
        value={values.title ?? ''}
        onChange={handlers.title}
        constraintText="An optional field. Text shows as a heading above the wizard."
      />
      <Select
        label="Parent Wizard"
        value={values.parentWizardId ?? ''}
        onChange={handlers.parentWizardId}
        constraintText="An optional field. Changes in the parent wizard's cell selections will filter data available to this wizard."
      >
        {options.map(({ label, value, disabled }) => (
          <SelectOption
            key={value}
            label={label}
            value={value}
            disabled={disabled}
          />
        ))}
      </Select>
      <DashboardWizardFormFieldLabel
        label="KPI Filter"
        hint={
          <Column spacing="100">
            <Text>
              An optional field. Filter KPIs that feeds the wizard. Regular
              expressions are supported, such as &quot;3p.+&quot;.
            </Text>
          </Column>
        }
      >
        <Row widths="grid-6" alignmentVertical="top">
          <TestSourcePropertySelect
            label="Filter property"
            testSource={dashboard.testsources}
            value={values.kpiFilterKey ?? ''}
            onChange={handlers.kpiFilterKey}
          />
          <CoreInput
            label="Filter regular expression"
            value={values.kpiFilterValue ?? ''}
            onChange={handlers.kpiFilterValue}
          />
        </Row>
      </DashboardWizardFormFieldLabel>
    </>
  );
};

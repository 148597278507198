import React, { useMemo } from 'react';
import { format } from 'date-fns';

export type DashboardAnnotationContentDateProps = {
  value: string;
};

export const DashboardAnnotationContentDate = ({
    value
}: DashboardAnnotationContentDateProps): JSX.Element => {
    const formatedDate = useMemo(
        () => format(new Date(`${value}Z`), 'MMM d, HH:mm'),
        [value]
    );
  return (
    <p>{formatedDate}</p>
  );
};

import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { Annotation } from '@/types';
import { useHttp, HttpClient } from '@/client';
import { useDashboardQueryParameters } from '@/hooks';
import { useDashboard } from '../contexts';

const updateAnnotation = async (
  http: HttpClient,
  id: string,
  annotation: Partial<Annotation>
): Promise<Annotation> => {
  const { data, status } = await http.post(`annotation/${id}`, annotation);
  if (status.code === 200) {
    return data;
  }
  throw new Error(data.message);
};

export const useDashboardAnnotationUpdate = (): UseMutationResult<
  Annotation,
  Error,
  {
    id: string;
    annotation: Partial<Annotation>;
  },
  (() => void) | undefined
> => {
  const { http } = useHttp();
  const queryClient = useQueryClient();
  const { dashboard } = useDashboard();
  const { params } = useDashboardQueryParameters();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { tab, ...keyParams } = params;
  const queryKey = [
    'annotations',
    { dashboardId: dashboard.id, filters: keyParams },
  ];
  return useMutation(
    (payload: { id: string; annotation: Partial<Annotation> }) =>
      updateAnnotation(http, payload.id, payload.annotation),
    {
      onSuccess: (data) => {
        const previous = queryClient.getQueryData<Annotation[]>(queryKey);
        const next =
          previous?.filter((a) => a.annotation_id !== data.annotation_id) ?? [];
        queryClient.setQueryData(queryKey, [...next, data]);
      },
    }
  );
};

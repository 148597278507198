import React, { useEffect } from 'react';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import closeTokens from '@amzn/meridian-tokens/base/icon/close';
import Select, { SelectOption } from '@amzn/meridian/select';
import { useForm, isNullOrWhitespace, CoreInput } from '@amzn/dots-core-ui';
import { TestSourcePropertyValueSelect } from '@/components';
import { GoalGroup } from './types';
import { echoValue } from './helpers';

export type DashboardGoalGroupControlProps = {
  goalGroup?: Partial<GoalGroup>;
  testSource: string;
  onChange: (value: GoalGroup) => void;
  onRemove: () => void;
};

export const DashboardGoalGroupControl = ({
  goalGroup,
  testSource,
  onChange,
  onRemove,
}: DashboardGoalGroupControlProps): JSX.Element => {
  const { values, handlers, errors, states } = useForm({
    id: {
      initialValue: goalGroup?.id,
    },
    kpi: {
      initialValue: goalGroup?.kpi ?? '',
      validate: (value: string) =>
        isNullOrWhitespace(value) ? 'Kpi filter is required' : '',
    },
    display: {
      initialValue: goalGroup?.display ?? '',
      validate: (value: string) =>
        isNullOrWhitespace(value) ? 'Display type is required' : '',
    },
    deviceType: {
      initialValue: goalGroup?.deviceType ?? [],
    },
    upper: {
      initialValue: goalGroup?.upper ?? '',
    },
    lower: {
      initialValue: goalGroup?.lower ?? '',
    },
  });
  useEffect(() => {
    onChange(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  return (
    <Row
      widths={['grid-11', 'grid-1']}
      alignmentVertical="center"
      type="outline"
      spacingInset="400"
    >
      <Column>
        <Row widths="grid-6" alignmentVertical="top">
          <TestSourcePropertyValueSelect
            createItem={echoValue}
            label="Kpi Filter"
            propertyName="kpi.name"
            testSource={testSource}
            value={values.kpi}
            onChange={handlers.kpi}
            validationState={states.kpi}
            errorMessage={errors.kpi}
          />
          <Select
            label="Display Type"
            placeholder="Display Type"
            value={values.display}
            onChange={handlers.display}
            errorMessage={errors.display}
          >
            <SelectOption label="Total" value="TOTAL" />
            <SelectOption label="Percentage" value="PASS_RATE" />
            <SelectOption label="Avg Over Runs" value="AVG_OVER_RUNS" />
            <SelectOption label="Avg Per Run" value="AVG_PER_RUN" />
            <SelectOption label="Avg Per Hour" value="AVG_PER_HOUR" />
          </Select>
        </Row>
        <Row widths="grid-6" alignmentVertical="top">
          <TestSourcePropertyValueSelect
            label="Device Type"
            propertyName="data.device.type"
            testSource={testSource}
            value={values.deviceType}
            onChange={handlers.deviceType}
            validationState={states.deviceType}
            errorMessage={errors.deviceType}
          />
          <Row>
            <CoreInput
              label="Lower Bound"
              value={values.lower}
              onChange={handlers.lower}
              validationState={states.lower}
              errorMessage={errors.lower}
            />
            <CoreInput
              label="Upper Bound"
              value={values.upper}
              onChange={handlers.upper}
              validationState={states.upper}
              errorMessage={errors.upper}
            />
          </Row>
        </Row>
      </Column>
      <Button
        type="icon"
        size="small"
        onClick={onRemove}
        disabled={onRemove === undefined}
      >
        <Icon tokens={closeTokens}>delete</Icon>
      </Button>
    </Row>
  );
};

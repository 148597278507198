import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import editIconTokens from '@amzn/meridian-tokens/base/icon/pencil';
import { DashboardEmailReportConfiguration } from '@/types';
import { useDashboard } from '@/features/Dashboards/contexts';
import { DashboardEditEmailReportUri } from '@/features/routes';

export type DashboardEmailReportEditButtonProps = {
  report: DashboardEmailReportConfiguration;
};

export const DashboardEmailReportEditButton = ({
  report,
}: DashboardEmailReportEditButtonProps): JSX.Element => {
  const history = useHistory();
  const { dashboard } = useDashboard();
  return (
    <Button
      onClick={() =>
        history.push(
          DashboardEditEmailReportUri(dashboard.name, report.report_id)
        )
      }
      type="tertiary"
    >
      <Icon tokens={editIconTokens} />
    </Button>
  );
};

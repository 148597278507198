import React from 'react';
import Row from '@amzn/meridian/row';
import Card from '@amzn/meridian/card';
import Column from '@amzn/meridian/column';
import Button from '@amzn/meridian/button';
import Divider from '@amzn/meridian/divider';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import Tooltip from '@amzn/meridian/tooltip';
import Icon from '@amzn/meridian/icon';
import downloadTokens from '@amzn/meridian-tokens/base/icon/download-small';
import { Translator } from '@/features/Translators/types';
import { TranslatorEditButton } from './TranslatorEditButton';
import { TranslatorTypeTag } from './TranslatorTypeTag';

type TranslatorVersionCardProps = { translator: Translator };

const TranslatorVersionCard = ({
  translator,
}: TranslatorVersionCardProps): JSX.Element => {
  return (
    <Card spacingInset="none">
      <Row widths={['fill', 'fit']} alignmentVertical="top">
        <Column spacingInset="400 500" spacing="300">
          <Heading level={4}>{translator.version}</Heading>
          {translator.description ? (
            <Text type="b200">{translator.description}</Text>
          ) : (
            <Row />
          )}
        </Column>
        <Row spacingInset="400 500" widths={['fit', 'fill']}>
          <TranslatorEditButton
            name={translator.name}
            version={translator.version}
            type="icon"
            size="small"
          />
          <Tooltip
            title={`download ${translator.name}_${translator.version}.py`}
            position="bottom"
          >
            <Button href={translator.url} type="icon" size="small">
              <Icon tokens={downloadTokens} />
            </Button>
          </Tooltip>
        </Row>
      </Row>
      <Divider />
      <Column spacingInset="400 500">
        <Row wrap="down" widths="grid-6" alignmentVertical="top">
          <Text tag="div" alignment="start">
            <Text type="h100">Type</Text>
            <TranslatorTypeTag type={translator.type} />
          </Text>
          <Text tag="div" alignment="start">
            <Text type="h100">Source</Text>
            <Text type="b200">{translator.source}</Text>
          </Text>
        </Row>
        <Text tag="div" alignment="start">
          <Text type="h100">Owners</Text>
          <Text type="b200">{translator.owners.join(', ')}</Text>
        </Text>
      </Column>
    </Card>
  );
};

export type TranslatorCardsProps = { translators: Translator[] };

export const TranslatorCards = ({
  translators,
}: TranslatorCardsProps): JSX.Element => (
  <Row
    wrap="down"
    widths="grid-4"
    width="100%"
    alignmentVertical="stretch"
    maxWidth={1000}
  >
    {translators.map((translator) => (
      <TranslatorVersionCard
        key={`${translator.name}-${translator.version}`}
        translator={translator}
      />
    ))}
  </Row>
);

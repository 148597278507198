import React from 'react';
import { format } from 'date-fns';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Loader from '@amzn/meridian/loader';
import Alert from '@amzn/meridian/alert';
import Row from '@amzn/meridian/row';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import { useDashboardGetReportRequests } from '@/hooks';
import { DashboardReportRequest } from '@/types';
import { DashboardMonthlyReportViewButton } from './DashboardMonthlyReportViewButton';

type DetailCellContentProps = {
  reportRequestId: string;
  reportRequestStatus: string;
  dashboardName: string;
};

const DashboardMonthlyDetailCellContent = ({
  reportRequestId,
  reportRequestStatus,
  dashboardName,
}: DetailCellContentProps): JSX.Element => {
  if (reportRequestStatus === 'COMPLETED') {
    return (
      <DashboardMonthlyReportViewButton
        dashboardName={dashboardName}
        reportRequestId={reportRequestId}
      />
    );
  }
  if (reportRequestStatus === 'FAILED') {
    return <Text color="error">Failed</Text>;
  }
  return <Text color="secondary">Processing...</Text>;
};

export type DashboardMonthlyReportTableProps = {
  dashboardId: string;
  dashboardName: string;
};

export const DashboardMonthlyReportTable = ({
  dashboardId,
  dashboardName,
}: DashboardMonthlyReportTableProps): JSX.Element => {
  const {
    data: reportRequestsResponse,
    isLoading,
    isError,
    error,
  } = useDashboardGetReportRequests({
    dashboardId,
  });
  if (dashboardId == null || dashboardName == null) {
    return (
      <Alert size="small" type="error">
        Failed to fetch previous reports.
      </Alert>
    );
  }
  if (isLoading) {
    return (
      <Row
        alignmentHorizontal="center"
        alignmentVertical="center"
        height="300px"
      >
        <Loader size="medium" />
      </Row>
    );
  }

  if (isError && error) {
    return (
      <Alert size="small" type="error">
        {error?.message}
      </Alert>
    );
  }

  if (reportRequestsResponse == null) {
    return (
      <Alert size="small" type="error">
        Failed to get previous reports.
      </Alert>
    );
  }

  const reportRequests: DashboardReportRequest[] =
    reportRequestsResponse.report_requests;

  if (reportRequests == null || reportRequests.length === 0) {
    return <Text>No Reports found. Please generate a new report.</Text>;
  }
  return (
    <Column>
      <Row widths="fill">
        <Heading level={3}>Previous Reports</Heading>
        <Row alignmentHorizontal="right" alignmentVertical="bottom">
          <Heading level={6} color="secondary">
            (last 100 reports)
          </Heading>
        </Row>
      </Row>
      <Table headerRows={1} showDividers>
        <TableRow>
          <TableCell>Title</TableCell>
          <TableCell>Submitted On</TableCell>
          <TableCell>Submitted By</TableCell>
          <TableCell>Detail</TableCell>
        </TableRow>
        {reportRequests.map((reportRequest) => (
          <TableRow key={reportRequest.report_request_id}>
            <TableCell>{reportRequest.title}</TableCell>
            <TableCell>
              {format(reportRequest.created_on, 'yyyy-MM-dd hh:mm:ss')}
            </TableCell>
            <TableCell>{reportRequest.submitted_by}</TableCell>
            <TableCell>
              <DashboardMonthlyDetailCellContent
                reportRequestId={reportRequest.report_request_id}
                reportRequestStatus={reportRequest.report_request_status}
                dashboardName={dashboardName}
              />
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </Column>
  );
};

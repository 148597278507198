import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { nanoid } from 'nanoid';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Divider from '@amzn/meridian/divider';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Sheet from '@amzn/meridian/sheet';
import Select, { SelectOption } from '@amzn/meridian/select';
import CloseTokens from '@amzn/meridian-tokens/base/icon/close';
import { ErrorBoundaryFallback } from '@/components';
import { DashboardWizardConfiguration } from '@/types';
import { useDashboardLayoutsFormState } from './DashboardLayoutsFormStateContext';
import { DashboardWizardForm } from './DashboardWizardForm';
import { wizardTypeLabels } from './helpers';

export const DashboardLayoutSectionWizardSheet = (): JSX.Element => {
  const {
    dispatch,
    state: { wizards, editingWizard },
  } = useDashboardLayoutsFormState();
  const [formState, setFormState] = useState<
    DashboardWizardConfiguration | undefined
  >();
  const [wizardType, setWizardType] = useState<
    DashboardWizardConfiguration['type'] | undefined
  >();
  const onChangeWizardType = (nextType: any) => {
    setWizardType(nextType);
    setFormState((prev) => (prev ? { ...prev, type: nextType } : prev));
  };
  const isOpen = useMemo(() => editingWizard !== undefined, [editingWizard]);
  const onClose = useCallback(() => {
    dispatch({ type: 'wizard-editor-close' });
    setFormState(undefined);
    setWizardType(undefined);
  }, [dispatch]);
  const onConfirm = useCallback(
    (values: DashboardWizardConfiguration) => {
      if (editingWizard && formState) {
        if (!editingWizard.wizardId) {
          dispatch({
            type: 'wizard-add',
            payload: values,
          });
        } else {
          dispatch({
            type: 'wizard-update',
            payload: {
              wizardId: formState.id,
              wizard: values,
            },
          });
        }
      }
      onClose();
    },
    [dispatch, editingWizard, formState, onClose]
  );
  useEffect(() => {
    if (editingWizard) {
      if (!editingWizard.wizardId && !formState) {
        // the user is opening the form from a blank position
        setFormState({
          id: nanoid(8),
          tabIndex: editingWizard.layoutIndex,
          sectionIndex: editingWizard.sectionIndex,
          position: editingWizard.position,
          type: wizardType as any,
        });
      } else if (editingWizard.wizardId && !formState && !wizardType) {
        // the user is opening the form from a position with a wizard
        const wizard = wizards.find((w) => w.id === editingWizard.wizardId);
        setFormState(wizard);
        setWizardType(wizard?.type);
      }
    }
  }, [editingWizard, formState, wizardType, wizards]);
  return (
    <Sheet type="overlay" side="right" open={isOpen} onClose={onClose}>
      <Column width={680} spacingInset="400 200 none 200" spacing="400">
        <Row alignmentHorizontal="justify">
          <Text type="h300">
            Edit Wizard{formState ? `: ${formState.id}` : ''}
          </Text>
          <Button onClick={onClose} type="icon">
            <Icon tokens={CloseTokens}>
              Close wizard editor and return to page
            </Icon>
          </Button>
        </Row>
        <Select
          label="Wizard Type"
          value={wizardType}
          onChange={onChangeWizardType}
          error={!wizardType}
          errorMessage={!wizardType ? 'Wizard Type is required' : ''}
        >
          {Object.entries(wizardTypeLabels).map(([value, label]) => (
            <SelectOption key={value} label={label} value={value} />
          ))}
        </Select>
        <Divider />
        {formState && (
          <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
              <ErrorBoundaryFallback
                error={error}
                resetErrorBoundary={resetErrorBoundary}
                message="An error occured rendering the wizard's form"
              />
            )}
          >
            <DashboardWizardForm
              wizard={formState}
              onCancel={onClose}
              onSubmit={onConfirm}
            />
          </ErrorBoundary>
        )}
      </Column>
    </Sheet>
  );
};

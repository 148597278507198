import React from 'react';
import { TableCell, TableRow } from '@amzn/meridian/table';
import Row from '@amzn/meridian/row';
import {
  useDashboardTableState,
  useDashboardWizardTableData,
} from '@/features/Dashboards/hooks';
import { BaseTableWizardConfiguration } from '@/types';
import { DashboardSelectableTableCell } from '../../DashboardSelectableTableCell';
import { DashboardTableActionBar } from '../DashboardTableActionBar';
import { DashboardWizardTable } from '../DashboardWizardTable';
import { DashboardBaseTableActionsCellContent } from './DashboardBaseTableActionsCellContent';
import { DashboardBaseTableCellContent } from './DashboardBaseTableCellContent';
import { createWizardData } from './data';

export type DashboardBaseTableWizardProps = {
  configuration: BaseTableWizardConfiguration;
};

export const DashboardBaseTableWizard = ({
  configuration,
}: DashboardBaseTableWizardProps): JSX.Element => {
  const [tableState, tableStateDispatch] = useDashboardTableState();
  const { pagination, sortColumn, sortDirection } = tableState;
  const wizardData = useDashboardWizardTableData(
    configuration,
    tableState,
    createWizardData
  );
  return (
    <DashboardWizardTable
      spacing="small"
      showDividers
      headerRows={1}
      sortColumn={sortColumn.toString()}
      sortDirection={sortDirection}
      onSort={(payload) =>
        tableStateDispatch({
          type: 'update-sort-state',
          payload: {
            sortColumn: Number(payload.sortColumn),
            sortDirection: payload.sortDirection,
          },
        })
      }
    >
      <DashboardTableActionBar
        dataLength={wizardData.rows.length}
        tableState={tableState}
        tableStateDispatch={tableStateDispatch}
        wizardData={wizardData}
      />
      {wizardData.header.length > 0 && (
        <TableRow>
          {wizardData.header.map(({ content }, index) => (
            <TableCell key={content} sortColumn={index.toString()}>
              <Row minWidth="max-content">{content}</Row>
            </TableCell>
          ))}
          <TableCell>Actions</TableCell>
        </TableRow>
      )}
      {wizardData.rows
        .slice(pagination.startIndex, pagination.endIndex)
        .map((row) => (
          <TableRow key={row.key}>
            {row.cells.map((cell) => (
              <DashboardSelectableTableCell
                key={cell.key}
                selectionContext={[
                  {
                    unique_id: row.context.kpi('unique_id').toString(),
                    'ingest.source': row.context
                      .kpi('ingest.source')
                      .toString(),
                  },
                ]}
                wizardId={configuration.id}
              >
                <DashboardBaseTableCellContent
                  cell={cell}
                  rowContext={row.context}
                />
              </DashboardSelectableTableCell>
            ))}
            <DashboardSelectableTableCell
              selectionContext={[
                {
                  unique_id: row.context.kpi('unique_id').toString(),
                  'ingest.source': row.context.kpi('ingest.source').toString(),
                },
              ]}
              wizardId={configuration.id}
            >
              <DashboardBaseTableActionsCellContent rowContext={row.context} />
            </DashboardSelectableTableCell>
          </TableRow>
        ))}
    </DashboardWizardTable>
  );
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import plusIconTokens from '@amzn/meridian-tokens/base/icon/plus';
import { useDashboard } from '@/features/Dashboards/contexts';
import { DashboardAddEmailReportsUri } from '@/features/routes';

export const DashboardEmailReportAddButton = (): JSX.Element => {
  const history = useHistory();
  const { dashboard } = useDashboard();
  return (
    <Button
      onClick={() => history.push(DashboardAddEmailReportsUri(dashboard.name))}
    >
      <Icon tokens={plusIconTokens} />
      Add new
    </Button>
  );
};

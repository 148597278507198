import React from 'react';
import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import {
  ResponsiveContainer,
  LineChart as RLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from 'recharts';
import { randomColor } from '@/helpers';
import { LineChartWizardConfiguration } from '@/types';
import { useDashboardWizardData } from '@/features/Dashboards/hooks';
import { createWizardData, LineChart } from './data';

type LineChartProps = {
  chart: LineChart;
};

const ReLineChart = ({ chart }: LineChartProps) => (
  <ResponsiveContainer width="100%" height={357}>
    <RLineChart
      data={chart.seriesData}
      margin={{
        top: 20,
        right: 50,
        left: 25,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="4 4" stroke="#BBC0C1" />
      <XAxis
        dataKey={chart.xAxisLabel}
        padding={{ left: 30, right: 30 }}
        stroke="#6C7778"
      />
      <YAxis unit={chart.yAxisUnit} stroke="#6C7778" padding={{ bottom: 20 }}>
        <Label
          value={chart.yAxisLabel}
          angle={-90}
          position="insideLeft"
          offset={-2}
          fill="#6C7778"
        />
      </YAxis>
      <Tooltip />
      <Legend />
      {chart.seriesLabels.map((label, index) => (
        <Line
          type="monotone"
          key={label}
          dataKey={label}
          name={label}
          stroke={index === 0 ? '#0AABC4' : randomColor()}
          strokeWidth="2"
          connectNulls
        />
      ))}
    </RLineChart>
  </ResponsiveContainer>
);

export type DashboardLineChartWizardProps = {
  configuration: LineChartWizardConfiguration;
};

export const DashboardLineChartWizard = ({
  configuration,
}: DashboardLineChartWizardProps): JSX.Element => {
  const wizardData = useDashboardWizardData(configuration, createWizardData);
  if (wizardData === undefined) {
    return <span>no data</span>;
  }
  if (wizardData.charts.length === 1) {
    return <ReLineChart chart={wizardData.charts[0]} />;
  }
  const rows = Array.from(
    Array(Math.ceil(wizardData.charts.length / 2)).keys()
  );
  return (
    <Box width="100%" height="100%">
      {rows.map((row) => (
        <>
          <Row key={2 * row} width="100%" height="100%" widths={['grid-6']}>
            {2 * row < wizardData.charts.length && (
              <Text alignment="center">{wizardData.charts[2 * row].title}</Text>
            )}
            {2 * row + 1 < wizardData.charts.length && (
              <Text alignment="center">
                {wizardData.charts[2 * row + 1].title}
              </Text>
            )}
          </Row>
          <Row key={2 * row + 1} width="100%" height="100%" widths={['grid-6']}>
            {2 * row < wizardData.charts.length && (
              <ReLineChart chart={wizardData.charts[2 * row]} />
            )}
            {2 * row + 1 < wizardData.charts.length && (
              <ReLineChart chart={wizardData.charts[2 * row + 1]} />
            )}
          </Row>
        </>
      ))}
    </Box>
  );
};

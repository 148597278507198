import React, { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import Row from '@amzn/meridian/row';
import { useToaster } from '@amzn/dots-core-ui';
import { useHttp } from '@/client';
import { CorePage, CorePageTitle } from '@/components';
import { TranslatorDetailUri, TranslatorsUri } from '@/features/routes';
import { TranslatorEditForm } from './components';
import { Translator, TranslatorId } from './types';
import { translatorClient } from './client';
import { translatorKeys } from './queries';

export const TranslatorEditPage = (): JSX.Element => {
  const { http } = useHttp();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { openToast } = useToaster();
  const params = useParams<TranslatorId>();
  const { data: translator, isLoading, isError, error } = useQuery<
    Translator,
    Error
  >({
    queryKey: translatorKeys.all(params),
    queryFn: () => translatorClient.getTranslator(http, params),
  });
  const { mutateAsync } = useMutation(
    (next: Partial<Translator>) =>
      translatorClient.updateTranslator(http, { ...next, ...params }),
    {
      onSettled: () => {
        queryClient.invalidateQueries(translatorKeys.all(params));
      },
    }
  );
  const onSubmit = useCallback(
    async (next: Partial<Translator>) => {
      await mutateAsync(next);
      openToast({
        title: 'Success!',
        message: `${next?.name} has been updated.`,
        type: 'success',
      });
      history.push(TranslatorDetailUri(next?.name ?? ''));
    },
    [history, mutateAsync, openToast]
  );
  return (
    <CorePage
      title={`Edit v${params.version}`}
      breadcrumbs={[
        { href: TranslatorsUri, title: 'Translators' },
        {
          href: TranslatorDetailUri(params.name),
          title: `${params.name}`,
        },
      ]}
      isLoading={isLoading}
      isError={isError}
      error={error}
      header={
        <Row widths="fill">
          <CorePageTitle title={`Edit ${params.name} v${params.version}`} />
        </Row>
      }
    >
      {translator && (
        <TranslatorEditForm
          translator={translator}
          onCancel={() => history.goBack()}
          onSubmit={onSubmit}
        />
      )}
    </CorePage>
  );
};

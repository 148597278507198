import React from 'react';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import { ColumnDefinition } from '@amzn/dots-core-ui';
import { DashboardEmailReportConfiguration } from '@/types';
import { DashboardEmailReportEditButton } from './DashboardEmailReportEditButton';
import { DashboardEmailReportDeleteButton } from './DashboardEmailReportDeleteButton';
import { toLocalTime } from './helpers';

export const DashboardEmailReportColumnDefinitions: ColumnDefinition<
  DashboardEmailReportConfiguration
>[] = [
  {
    id: 'title',
    header: 'Report Title',
    item: (report: DashboardEmailReportConfiguration): string => report.title,
  },
  {
    id: 'recipient',
    header: 'Recipients',
    item: (report: DashboardEmailReportConfiguration): JSX.Element => (
      <Column spacing="small">
        {report.recipients.map((email) => (
          <Text key={email}>{email}</Text>
        ))}
      </Column>
    ),
  },
  {
    id: 'timeOfDay',
    header: 'Send Time',
    item: (report: DashboardEmailReportConfiguration): string =>
      toLocalTime(report.time_to_send),
  },
  {
    id: 'timeRangeValue',
    header: 'Days of Data',
    item: (report: DashboardEmailReportConfiguration): number =>
      report.report_params.data_range.time_range_value,
  },
  {
    id: 'actions',
    header: 'Actions',
    item: (report: DashboardEmailReportConfiguration): JSX.Element => (
      <Row>
        <DashboardEmailReportEditButton report={report} />
        <DashboardEmailReportDeleteButton report={report} />
      </Row>
    ),
  },
];

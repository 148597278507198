export const colorRuleRelationships = {
  equal: {
    label: 'is equal to',
    placeholder: '100',
  },
  'greater than': {
    label: 'is greater than',
    placeholder: '80',
  },
  'less than': {
    label: 'is less than',
    placeholder: '60',
  },
  between: {
    label: 'is between',
    placeholder: '80, 90',
  },
  else: {
    label: 'else',
    placeholder: 'Not required',
  },
};

import React from 'react';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import SearchField from '@amzn/meridian/search-field';
import Table, {
  TableActionBar,
  TableCell,
  TableRow,
} from '@amzn/meridian/table';
import Pagination from '@amzn/meridian/pagination';
import { useFilter } from '@amzn/dots-core-ui';
import { CorePage, CorePageTitle } from '@/components';
import { useDashboards } from '@/hooks';
import { DashboardAddButton, DashboardColumnDefinitions } from './components';
import { useDashboardTableState } from './hooks';
import { getNumberOfPages } from './helpers';

export const DashboardListPage = (): JSX.Element => {
  const [
    { pagination, searchQuery },
    tableStateDispatch,
  ] = useDashboardTableState({
    pagination: { currentPage: 1, perPage: 25, startIndex: 0, endIndex: 25 },
  });
  const { data, isLoading, isError, error } = useDashboards();
  const dashboards = data ?? [];
  const filteredData = useFilter(dashboards, searchQuery, (item) => item.name);
  filteredData.sort((a, b) => a.name.localeCompare(b.name));
  const numberOfPages = getNumberOfPages(
    filteredData.length,
    pagination.perPage
  );
  return (
    <CorePage
      title="Dashboards"
      isLoading={isLoading}
      isError={isError}
      error={error}
      header={
        <Row widths="fill">
          <CorePageTitle title="Dashboards" />
          <Row alignmentHorizontal="right">
            <DashboardAddButton />
          </Row>
        </Row>
      }
    >
      <Column width="100%" alignmentHorizontal="right" spacing="100">
        <Table
          spacing="small"
          showDividers
          headerRows={1}
          fixHeaderRows
          fixHeaderRowsFullWidth
        >
          <TableActionBar widths="fit">
            <SearchField
              value={searchQuery}
              placeholder="Search for..."
              inaccessibleAutoFocus
              searchButton={false}
              onChange={(value) =>
                tableStateDispatch({
                  type: 'update-search-query',
                  payload: value,
                })
              }
              onSubmit={(value) =>
                tableStateDispatch({
                  type: 'update-search-query',
                  payload: value,
                })
              }
            />
          </TableActionBar>
          <TableRow>
            {DashboardColumnDefinitions.map(({ id, header }) => (
              <TableCell key={id}>{header}</TableCell>
            ))}
          </TableRow>
          {filteredData
            .slice(pagination.startIndex, pagination.endIndex)
            .map((dashboard) => (
              <TableRow key={dashboard.id}>
                {DashboardColumnDefinitions.map(({ id, item }, index) => (
                  <TableCell key={id}>{item(dashboard, index)}</TableCell>
                ))}
              </TableRow>
            ))}
        </Table>
        <Column alignmentHorizontal="right">
          <Pagination
            currentPage={pagination.currentPage}
            numberOfPages={numberOfPages}
            onChange={(value) =>
              tableStateDispatch({
                type: 'update-pagination-current-page',
                payload: value,
              })
            }
          />
        </Column>
      </Column>
    </CorePage>
  );
};

import React, { useRef, useState, useCallback } from 'react';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import Link from '@amzn/meridian/link';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Badge from '@amzn/meridian/badge';
import Loader from '@amzn/meridian/loader';
import Popover from '@amzn/meridian/popover';
import { useJira } from '@/hooks';
import { environment } from '@/environments';

export type JiraStatusProps = {
  jiraID: string;
};

export const JiraStatus = ({ jiraID }: JiraStatusProps): JSX.Element => {
  const { data, status } = useJira(jiraID);
  const tagRef = useRef(null);
  const [open, setOpen] = useState(false);
  const onMouseOver = useCallback(() => setOpen(status !== 'loading'), [
    status,
  ]);
  const onMouseLeave = () => setOpen(false);

  return (
    <>
      <span
        ref={tagRef}
        onMouseLeave={onMouseLeave}
        onFocus={onMouseOver}
        onMouseOver={onMouseOver}
      >
        <Row spacing="small" tag="span">
          <Link
            href={`${environment.labcollabJiraInstanceUrl}/browse/${jiraID}`}
            target="_blank"
          >
            {jiraID}
          </Link>
          {status === 'error' && <Badge type="error" />}
          {status === 'loading' && <Loader size="small" />}
          {status === 'success' && data && (
            <>
              <img
                src={data.status.iconUrl}
                alt={data.status.name}
                width="20"
                height="20"
              />
              <img
                src={data.priority.iconUrl}
                alt={data.priority.name}
                width="20"
                height="20"
              />
            </>
          )}
        </Row>
        <Popover
          anchorNode={tagRef.current ?? undefined}
          open={open}
          onClose={onMouseLeave}
          position="bottom"
          maxWidth={350}
        >
          {status === 'error' && (
            <Text>An error occured retrieving the details for this JIRA</Text>
          )}
          {status === 'loading' && <Loader size="small" />}
          {status === 'success' && data && (
            <Column spacing="small">
              <Heading level={5}>{jiraID}</Heading>
              <Text>{data.summary}</Text>
              <Table headerColumns={1} spacing="small">
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>
                    <Row>
                      <img
                        src={data.status.iconUrl}
                        alt={data.status.name}
                        width="20"
                        height="20"
                      />
                      <Text>{data.status.name}</Text>
                    </Row>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Priority</TableCell>
                  <TableCell>
                    <Row>
                      <img
                        src={data.priority.iconUrl}
                        alt={data.priority.name}
                        width="20"
                        height="20"
                      />
                      <Text>{data.priority.name}</Text>
                    </Row>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Assignee</TableCell>
                  <TableCell>{data.assignee}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created</TableCell>
                  <TableCell>{data.created}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Updated</TableCell>
                  <TableCell>{data.updated}</TableCell>
                </TableRow>
              </Table>
            </Column>
          )}
        </Popover>
      </span>
    </>
  );
};

/* eslint-disable no-param-reassign */
import { isNullOrWhitespace, keys } from '@amzn/dots-core-ui';
import { KpiFilterResult } from '@/hooks';
import {
  Annotation,
  BaseData,
  BaseDataPropertyIndexMap,
  DashboardFilter,
  KpiProperty,
  KpiPropertyValue,
} from '@/types';
import { calculate } from '@/features/Dashboards/aggregation';
import { extractJiraID } from '@/helpers';
import {
  DashboardUiFilter,
  KpiGroup,
  PaginationOption,
  UserDefinedExpression,
} from './types';

export const groupByProperties: KpiProperty[] = [
  'data.device.type',
  'data.build.project',
  'data.build.version',
  'data.build.variant',
  'data.test_suite',
];

export const calculateExpressions = (
  format: string,
  expressions: UserDefinedExpression[],
  baseData: BaseData
): { content: string; calculatedData: Record<string, unknown> } => {
  let content = format;
  const global: Record<string, unknown> = {};
  expressions.forEach(({ variableName, expression }) => {
    if (!expression) {
      content = 'Bad data, check expression';
    } else {
      let opRootValue = 0;
      try {
        expression.reset();
        calculate(baseData, global, expression);
        opRootValue = expression.value;
      } catch (error) {
        if ((error as Error).message !== 'No data!') {
          console.error(error);
          content = 'Bad data, check expression';
          return;
        }
      }
      global[variableName] = opRootValue;
      content = content.replace(
        new RegExp(`${variableName}`, 'g'),
        opRootValue.toString()
      );
    }
  });
  return { content, calculatedData: global };
};

/**
 * Merge a dashboard's group and custom tab filters into a single collection
 */
export const getDashboardFilters = (
  groupTabs: DashboardFilter['group_tabs'],
  customTabs: DashboardFilter['custom_tabs']
): DashboardUiFilter[] => {
  const groupFilters =
    groupTabs?.map((value) => ({
      value,
    })) ?? [];
  const customFilters =
    customTabs?.flatMap(({ field, values }) =>
      values.map((value) => ({
        field,
        value,
      }))
    ) ?? [];
  const allFilters = [...customFilters, ...groupFilters];
  allFilters.sort((a, b) =>
    a.value.localeCompare(b.value, undefined, { numeric: true })
  );
  return allFilters;
};

/**
 * Get the number of pages needed to represent the size given a page size
 */
export const getNumberOfPages = (size: number, perPage: number): number => {
  const validPerPage = perPage < 1 ? 10 : perPage;
  if (size === 0) {
    return 0;
  }
  return Math.ceil((size - 1) / validPerPage);
};

/**
 * Get a collection of per page options based on the size
 */
export const getPerPageOptions = (size: number): PaginationOption[] => {
  const options = [{ label: 'All', value: size }];
  if (size === 0) {
    return options;
  }
  return [10, 20, 50]
    .map((pageSize) => ({ label: pageSize.toString(), value: pageSize }))
    .concat(options);
};

export const convertToMeridianGridValue = (
  type: 'height' | 'width',
  values: string[]
): string[] =>
  values.map((value) => {
    if (value === 'fit') {
      return value;
    }
    const intValue = parseInt(value, 10);
    if (Number.isNaN(intValue) || intValue <= 0) {
      return 'fit';
    }
    if (type === 'height') {
      return `${64 * intValue}px`;
    }
    return `grid-${Math.min(intValue, 12)}`;
  });

/**
 * Group KPIs in a base data collection by a collection of KPI properties
 */
export const groupKpis = (
  propertyIndexMap: BaseDataPropertyIndexMap,
  baseData: BaseData,
  propertyNames: KpiProperty[]
): KpiGroup[] => {
  const [, ...kpis] = baseData;
  // make sure the properties we are grouping by are available in the property
  // index map
  const availableProperties = propertyNames.filter(
    (name) => name in propertyIndexMap
  );
  // we can reduce the KPIs in basedata down to a mapping of a string key,
  // representing the key-value-pairs of the provided property names, to the
  // base data indicies of the KPIs in the group and record of each property's
  // value from the group
  const map = kpis.reduce((result, kpi, index) => {
    const { key, values } = availableProperties.reduce(
      (k, p) => {
        const value = kpi[propertyIndexMap[p]].toString();
        k.key = `${k.key}|${value}`;
        k.values[p] = value;
        return k;
      },
      { key: '', values: {} } as {
        key: string;
        values: KpiGroup['groupedPropertyValues'];
      }
    );
    const previous = result.get(key)?.baseDataIndices ?? [];
    previous.push(index + 1);
    result.set(key, {
      groupKey: key,
      groupedPropertyValues: values,
      baseDataIndices: previous,
    });
    return result;
  }, new Map<string, KpiGroup>());
  return Array.from(map.values());
};

export const extractUniqueKpiValues = (
  propertyNames: KpiProperty[],
  kpiFilterResult?: KpiFilterResult
): Record<KpiProperty, string[]> => {
  if (kpiFilterResult === undefined) {
    return propertyNames.reduce((result, propertyName) => {
      result[propertyName] = [];
      return result;
    }, {} as Record<KpiProperty, string[]>);
  }
  const { baseData, propertyIndexMap } = kpiFilterResult;
  const [, ...kpis] = baseData;
  const values = kpis.reduce((result, kpi) => {
    propertyNames.forEach((propertyName) => {
      if (!(propertyName in result)) {
        result[propertyName] = new Set();
      }
      const propertyValue = kpi[propertyIndexMap[propertyName]];
      if (Array.isArray(propertyValue)) {
        propertyValue.forEach((value) =>
          result[propertyName].add(value?.toString())
        );
      } else {
        result[propertyName].add(propertyValue?.toString());
      }
    });
    return result;
  }, {} as Record<KpiProperty, Set<string>>);
  return keys(values).reduce((result, key) => {
    result[key] = Array.from(values[key]).filter(
      (value) => !isNullOrWhitespace(value)
    );
    return result;
  }, {} as Record<KpiProperty, string[]>);
};

/**
 * Get all unique testrun and KPI ids for a basedata collection
 */
export const getIds = (
  baseData: BaseData,
  propertyIndexMap: BaseDataPropertyIndexMap
): {
  kpiIds: string[];
  testrunIds: string[];
} => {
  const [, ...kpis] = baseData;
  const { kpiIds, testrunIds } = kpis.reduce<{
    kpiIds: string[];
    testrunIds: Set<string>;
  }>(
    (result, kpi) => {
      result.kpiIds.push(kpi[propertyIndexMap.unique_id] as string);
      return {
        kpiIds: result.kpiIds,
        testrunIds: result.testrunIds.add(
          kpi[propertyIndexMap['data.testrun_id']] as string
        ),
      };
    },
    { kpiIds: [], testrunIds: new Set() }
  );
  return { kpiIds, testrunIds: Array.from(testrunIds) };
};

/**
 * Creates a string key which can be used to identify the group of properties
 * used to group data for goal computations
 */
export const createGoalGroupKey = (
  propertyValues: Partial<Record<KpiProperty, KpiPropertyValue>>
): string => {
  const goalGroupPrpoperties: KpiProperty[] = [
    'data.device.type',
    'data.build.project',
    'data.build.version',
    'data.build.variant',
    'data.test_suite',
  ];
  return goalGroupPrpoperties.reduce(
    (result, prop) => `${result}:${propertyValues[prop]}`,
    ''
  );
};

/**
 * A dirty solution for 'unique' ids for each test. Could be replaced with a
 * uuid function, but who has the time
 */
let counter = 0;
export const getUniqueId = (): string => {
  counter += 1;
  return counter.toString();
};

export const extractJiraIdsFromAnnotation = (
  annotation: Annotation
): string[] => {
  if (annotation.template) {
    return Object.values(annotation.template.content).flatMap(
      ({ type, value }) => {
        if (type === 'string' || type === 'jira') {
          return extractJiraID(value);
        }
        return [];
      }
    );
  }
  if (annotation.notes) {
    return extractJiraID(annotation.notes);
  }
  return [];
};

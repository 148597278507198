import React from 'react';
import Heading from '@amzn/meridian/heading';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';

export type DashboardFormRowProps = {
  label?: string;
  children: React.ReactNode;
};

export const DashboardFormRow = ({
  label,
  children,
}: DashboardFormRowProps): JSX.Element => {
  return (
    <Row widths={['grid-2', 500]} alignmentVertical="top">
      {label ? (
        <Heading level={1} type="b400">
          {label}
        </Heading>
      ) : (
        <div />
      )}
      <Column>{children}</Column>
    </Row>
  );
};

import React from 'react';
import Row from '@amzn/meridian/row';
import { DashboardDetailUri, DashboardsUri } from '@/features/routes';
import { CorePage, CorePageTitle } from '@/components';
import {
  DashboardMonthlyReportAddButton,
  DashboardMonthlyReportTable,
} from './components';
import { useDashboard, withDashboard } from './contexts';

export const DashboardMonthlyReportListPage = withDashboard(
  (): JSX.Element => {
    const { dashboard } = useDashboard();
    return (
      <CorePage
        title="Monthly Reports"
        breadcrumbs={[
          { href: DashboardsUri, title: 'Dashboards' },
          { href: DashboardDetailUri(dashboard.name), title: dashboard.name },
        ]}
        header={
          <Row widths="fill">
            <CorePageTitle title={`${dashboard.name} Monthly Reports`} />
            <Row alignmentHorizontal="right">
              <DashboardMonthlyReportAddButton dashboardName={dashboard.name} />
            </Row>
          </Row>
        }
      >
        <DashboardMonthlyReportTable
          dashboardId={dashboard.id}
          dashboardName={dashboard.name}
        />
      </CorePage>
    );
  }
);

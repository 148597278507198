import { useMemo } from 'react';
import { useDashboardAnnotations } from '@/features/Dashboards/hooks';
import { WizardTableCell, WizardTableData } from '@/features/Dashboards/types';
import { extractJiraIdsFromAnnotation } from '@/features/Dashboards/helpers';
import { useJiras } from '@/hooks';
import { Annotation, JiraTableWizardConfiguration, Jira } from '@/types';
import { environment } from '@/environments';

export const getJiraIdsFromAnnotations = (
  annotations?: Annotation[]
): string[] =>
  Array.from(
    new Set(
      (annotations ?? [])
        .filter((annotation) => !annotation.resolved)
        .flatMap(extractJiraIdsFromAnnotation)
    )
  );

export const useJiraTableJiras = (
  configuration: JiraTableWizardConfiguration
): { isLoading: boolean; jiras: Jira[] } => {
  const {
    data: annotations,
    isLoading: isAnnotationsLoading,
  } = useDashboardAnnotations();
  const jiraIDs = useMemo(() => getJiraIdsFromAnnotations(annotations), [
    annotations,
  ]);
  const { data: idResults, isLoading: isIdQueryLoading } = useJiras({
    ids: jiraIDs,
  });
  const { data: jqlResults, isLoading: isJqlQueryLoading } = useJiras({
    jql: configuration.jql ?? '',
  });
  const isLoading = useMemo(
    () => isIdQueryLoading || isJqlQueryLoading || isAnnotationsLoading,
    [isIdQueryLoading, isJqlQueryLoading, isAnnotationsLoading]
  );
  const jiras = useMemo(() => [...(idResults ?? []), ...(jqlResults ?? [])], [
    idResults,
    jqlResults,
  ]);
  return { isLoading, jiras };
};

export const getWizardData = (
  configuration: JiraTableWizardConfiguration,
  jiras: Jira[]
): WizardTableData => {
  const columnDefinitions = configuration.col.map(
    ({ name, data: jiraPropertyName }) => {
      return {
        columnName: name,
        compile: (jira: Jira): WizardTableCell => {
          const propertyValue = jira[jiraPropertyName];
          const result: WizardTableCell = {
            key: jiraPropertyName,
            content: '',
          };
          if (typeof propertyValue === 'string') {
            result.content = propertyValue;
            if (jiraPropertyName === 'key') {
              result.href = `${environment.labcollabJiraInstanceUrl}/browse/${propertyValue}`;
            }
          } else {
            result.content = propertyValue.name;
            result.href = propertyValue.iconUrl;
          }
          return result;
        },
      };
    }
  );
  const header = columnDefinitions.map(
    ({ columnName }): WizardTableCell => ({
      key: columnName,
      content: columnName,
    })
  );
  let idCounter = 0;
  const rows = jiras.map((jira) => ({
    key: jira.key,
    id: idCounter++,
    cells: columnDefinitions.map(({ compile }) => compile(jira)),
  }));
  return {
    header,
    rows,
  };
};

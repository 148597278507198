import React from 'react';
import { ColumnDefinition, CoreRouterLink } from '@amzn/dots-core-ui';
import { TranslatorDetailUri } from '@/features/routes';
import { Translator } from '@/features/Translators/types';
import { TranslatorTypeTag } from './TranslatorTypeTag';

export const TranslatorColumnDefinitions: ColumnDefinition<Translator>[] = [
  {
    id: 'name',
    header: 'Name',
    item: ({ name }: Translator): JSX.Element => (
      <CoreRouterLink href={TranslatorDetailUri(name)}>{name}</CoreRouterLink>
    ),
  },
  {
    id: 'version',
    header: 'Latest Version',
    item: ({ version }: Translator): string => version,
  },
  {
    id: 'type',
    header: 'Type',
    item: ({ type }: Translator): JSX.Element => (
      <TranslatorTypeTag type={type} />
    ),
  },
  {
    id: 'source',
    header: 'Test Source',
    item: ({ source }: Translator): string => source,
  },
];

import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useHttp, HttpClient } from '@/client';
import { TestrunFile } from '@/types';

export type TestrunFileQueryArgs = {
  source: string;
  testrunId: string;
  filePath: string;
};

const getTestrunFile = async (
  http: HttpClient,
  { source, testrunId, filePath }: TestrunFileQueryArgs
): Promise<TestrunFile> => {
  const { data, status } = await http.post('testrun/datafile', {
    data_source: source,
    testrun_id: testrunId,
    file_path: filePath,
  });
  if (status.code === 200) {
    return data;
  }
  throw new Error(data.message);
};

export const createTestrunFileQueryOptions = (
  http: HttpClient,
  args: TestrunFileQueryArgs
): UseQueryOptions<
  TestrunFile,
  Error,
  TestrunFile,
  (string | TestrunFileQueryArgs)[]
> => ({
  queryFn: () => getTestrunFile(http, args),
  queryKey: ['testrun-file', args],
});

export const useTestrunFile = (
  args: TestrunFileQueryArgs
): UseQueryResult<TestrunFile, Error> => {
  const { http } = useHttp();
  return useQuery(createTestrunFileQueryOptions(http, args));
};

import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SideMenu, {
  SideMenuLink,
  SideMenuSpacer,
} from '@amzn/meridian/side-menu';
import dashboardTokens from '@amzn/meridian-tokens/base/icon/dashboard';
import starTokens from '@amzn/meridian-tokens/base/icon/star';
import bulbTokens from '@amzn/meridian-tokens/base/icon/bulb';
import helpTokens from '@amzn/meridian-tokens/base/icon/help';
import { useUserProfile } from '@/hooks';
import {
  TranslatorsUri,
  DashboardAddUri,
  DashboardDetailUri,
  DashboardsUri,
  HelpCenterUri,
} from '@/features/routes';

export type AppNavigationProps = {
  isMenuOpen: boolean;
};

export const AppNavigation = ({
  isMenuOpen,
}: AppNavigationProps): JSX.Element => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { data: profile } = useUserProfile();
  const [isFavoriteSectionOpen, setIsFavoriteSectionOpen] = useState(true);
  return (
    <SideMenu open={isMenuOpen}>
      <SideMenuLink
        icon={starTokens}
        open={isFavoriteSectionOpen}
        onClick={() => setIsFavoriteSectionOpen(!isFavoriteSectionOpen)}
      >
        Favorites
        {profile?.dashboards.sort().map((dashboard) => {
          return (
            <SideMenuLink
              key={dashboard}
              href={DashboardDetailUri(dashboard)}
              selected={pathname === DashboardDetailUri(dashboard)}
              onClick={history.push}
            >
              {dashboard}
            </SideMenuLink>
          );
        })}
      </SideMenuLink>
      <SideMenuSpacer />
      <SideMenuLink
        href={DashboardsUri}
        icon={dashboardTokens}
        onClick={history.push}
        selected={pathname === DashboardsUri || pathname === DashboardAddUri}
      >
        Dashboards
      </SideMenuLink>
      <SideMenuLink
        href={TranslatorsUri}
        icon={bulbTokens}
        onClick={history.push}
        selected={pathname.includes(TranslatorsUri)}
      >
        Translators
      </SideMenuLink>
      <SideMenuSpacer />
      <SideMenuLink
        href={HelpCenterUri}
        icon={helpTokens}
        onClick={history.push}
        selected={pathname.includes(HelpCenterUri)}
      >
        Help Center
      </SideMenuLink>
    </SideMenu>
  );
};

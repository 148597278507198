import React, { useCallback, useMemo } from 'react';
import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Row from '@amzn/meridian/row';
import Heading from '@amzn/meridian/heading';
import PencilTokens from '@amzn/meridian-tokens/base/icon/pencil';
import ChevronUpTokens from '@amzn/meridian-tokens/base/icon/chevron-up-small';
import ChevronDownTokens from '@amzn/meridian-tokens/base/icon/chevron-down-small';
import TrashTokens from '@amzn/meridian-tokens/base/icon/trash';
import { convertToMeridianGridValue } from '@/features/Dashboards/helpers';
import { useDashboardLayoutsFormState } from './DashboardLayoutsFormStateContext';
import { Section } from './types';
import { DashboardLayoutSectionWizardTile } from './DashboardLayoutSectionWizardTile';

export type DashboardLayoutSectionControlProps = {
  layoutId: string;
  layoutIndex: number;
  sectionIndex: number;
  section: Section;
};

export const DashboardLayoutSectionControl = ({
  layoutId,
  layoutIndex,
  sectionIndex,
  section,
}: DashboardLayoutSectionControlProps): JSX.Element => {
  const {
    dispatch,
    state: { layouts },
  } = useDashboardLayoutsFormState();
  const { sections } = layouts[layoutIndex];
  const getPosition = useCallback(
    (columnIndex: number, rowIndex: number) =>
      rowIndex * section.widths.length + columnIndex,
    [section.widths.length]
  );
  const onEdit = () =>
    dispatch({
      type: 'section-editor-open',
      payload: { layoutId, section },
    });
  const onMoveDown =
    sectionIndex !== sections.length - 1
      ? () =>
          dispatch({
            type: 'section-move',
            payload: {
              layoutIndex,
              fromIndex: sectionIndex,
              toIndex: sectionIndex + 1,
            },
          })
      : undefined;
  const onMoveUp =
    sectionIndex !== 0
      ? () =>
          dispatch({
            type: 'section-move',
            payload: {
              layoutIndex,
              fromIndex: sectionIndex,
              toIndex: sectionIndex - 1,
            },
          })
      : undefined;
  const onRemove = () =>
    dispatch({
      type: 'section-remove',
      payload: { layoutIndex, sectionIndex },
    });
  const meridianHeights = useMemo(
    () =>
      convertToMeridianGridValue(
        'height',
        section.heights.map(({ value }) => value)
      ),
    [section.heights]
  );
  const meridianWidths = useMemo(
    () =>
      convertToMeridianGridValue(
        'width',
        section.widths.map(({ value }) => value)
      ),
    [section.widths]
  );
  return (
    <Column type="outline" spacingInset="300">
      <Row widths={['grid-9', 'grid-3']} alignmentVertical="center">
        <Heading level={5}>Section {sectionIndex + 1}</Heading>
        <Row alignmentHorizontal="right" spacing="100">
          <Button onClick={onEdit} type="icon">
            <Icon tokens={PencilTokens}>Edit</Icon>
          </Button>
          <Button
            onClick={onMoveUp}
            disabled={onMoveUp === undefined}
            type="icon"
          >
            <Icon tokens={ChevronUpTokens}>Up</Icon>
          </Button>
          <Button
            onClick={onMoveDown}
            disabled={onMoveDown === undefined}
            type="icon"
          >
            <Icon tokens={ChevronDownTokens}>Down</Icon>
          </Button>
          <Button onClick={onRemove} type="icon">
            <Icon tokens={TrashTokens}>Remove</Icon>
          </Button>
        </Row>
      </Row>
      <Column spacingInset="300">
        {meridianHeights.length === 0 || meridianWidths.length === 0 ? (
          <Alert type="warning" size="medium">
            This section&rsquo;s heights/widths are configured incorrectly...
            <Button type="link" size="small" onClick={onEdit}>
              Fix it!
            </Button>
          </Alert>
        ) : (
          <Row widths={meridianWidths}>
            {section.widths.map(({ id: columnId }, columnIndex) => (
              <Column key={columnId} width="100%" heights={meridianHeights}>
                {section.heights.map(({ id: rowId }, rowIndex) => (
                  <DashboardLayoutSectionWizardTile
                    key={rowId}
                    layoutIndex={layoutIndex}
                    sectionIndex={sectionIndex}
                    position={getPosition(columnIndex, rowIndex)}
                  />
                ))}
              </Column>
            ))}
          </Row>
        )}
      </Column>
    </Column>
  );
};

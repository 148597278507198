import React from 'react';
import { useParams } from 'react-router-dom';
import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import {
  CorePage,
  JiraCreateButton,
  JiraLinkButton,
  CorePageTitle,
  PageError,
} from '@/components';
import { useDashboardQueryParameters, useDashboard, useKpis } from '@/hooks';
import { DashboardProvider } from '@/features/Dashboards/contexts';
import { DashboardFiltersButton } from '@/features/Dashboards/components';
import {
  KpiBucketDashboardPicker,
  KpiBucketJiraCards,
  KpiBucketTestrunTable,
} from './components';
import {
  getIncorrectRequiredJiraFilters,
  getTagProperties,
  TagPropertiesResult,
} from './helpers';

export const KpiBucketDetailPage = (): JSX.Element => {
  const { id: kpiBucket } = useParams<{ id: string }>();
  const {
    params: { dashboard: dashboardId, filters, timeRange },
    createApiFilters,
  } = useDashboardQueryParameters();
  const {
    data: kpiFilterResult,
    error: kpiFilterResultError,
    isLoading: isKpiFilterResultLoading,
  } = useKpis({
    filter: createApiFilters({ filters, timeRange }),
  });
  const { data: dashboard, isLoading: isDashboardLoading } = useDashboard({
    dashboardId,
  });
  const missingRequiredFilterProperties = getIncorrectRequiredJiraFilters(
    filters
  );
  let getTagPropertiesResult: TagPropertiesResult = {
    latestKpi: undefined,
    tagProperties: undefined,
  };
  if (missingRequiredFilterProperties.length === 0) {
    getTagPropertiesResult = getTagProperties(
      kpiBucket,
      dashboard,
      kpiFilterResult
    );
  }
  const { tagProperties, latestKpi } = getTagPropertiesResult;
  return (
    <CorePage
      title={`Bucket: ${kpiBucket}`}
      isLoading={isKpiFilterResultLoading}
      header={
        <Row widths={['fill', 'fit']}>
          {latestKpi && (
            <CorePageTitle
              title={`${latestKpi('kpi.name')} in ${latestKpi(
                'kpi.kpi_summary'
              )}`}
            />
          )}
          {dashboard && (
            <Row alignmentHorizontal="right">
              <DashboardProvider dashboardName={dashboard.id}>
                <DashboardFiltersButton />
              </DashboardProvider>
            </Row>
          )}
        </Row>
      }
    >
      {kpiFilterResultError && (
        <PageError message="Failed to load Kpis" error={kpiFilterResultError} />
      )}
      {!kpiFilterResultError && (
        <>
          <Column>
            <Row widths={['fit', 'fill']} alignmentVertical="top">
              <Heading level={3}>Jiras</Heading>
              <Row widths="fit">
                {dashboard && latestKpi && (
                  <JiraCreateButton
                    kpi={latestKpi}
                    dashboardId={dashboard.id}
                    kpiId={latestKpi('unique_id') as string}
                    jiraProject={dashboard.jiraProject}
                    jiraComponents={dashboard.jiraComponents}
                    jiraLabels={dashboard.jiraLabels}
                    tagProperties={tagProperties}
                  />
                )}
                {tagProperties && (
                  <JiraLinkButton
                    linkProperties={{ ...tagProperties, kpiBucket }}
                  />
                )}
              </Row>
              {!dashboard && !isDashboardLoading && (
                <KpiBucketDashboardPicker />
              )}
            </Row>
            {missingRequiredFilterProperties.length > 0 && (
              <Alert type="warning">
                <Text>
                  The following required filter parameters are not for Jira
                  integration, please include a single value for the following
                  filters:
                </Text>
                {missingRequiredFilterProperties.map((prop) => (
                  <Text key={prop} type="h100">
                    {prop}
                  </Text>
                ))}
              </Alert>
            )}
            <KpiBucketJiraCards
              kpiBucket={kpiBucket}
              tagProperties={tagProperties}
            />
          </Column>
          {kpiFilterResult && (
            <KpiBucketTestrunTable
              kpiBucket={kpiBucket}
              kpiFilterResult={kpiFilterResult}
            />
          )}
        </>
      )}
    </CorePage>
  );
};

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useToaster } from '@amzn/dots-core-ui';
import { useDashboardEmailReportCreate } from '@/hooks';
import {
  DashboardDetailUri,
  DashboardEmailReportsUri,
  DashboardsUri,
} from '@/features/routes';
import { CorePageTitle, CorePage } from '@/components';
import { DashboardEmailReportForm } from './components';
import { useDashboard, withDashboard } from './contexts';

export const DashboardEmailReportAddPage = withDashboard(
  (): JSX.Element => {
    const history = useHistory();
    const { openToast } = useToaster();
    const { dashboard } = useDashboard();
    const { mutateAsync } = useDashboardEmailReportCreate();
    const onSubmit = useCallback(
      async (report) => {
        await mutateAsync(report);
        openToast({
          title: 'Success!',
          message: `${report?.name} has been added.`,
          type: 'success',
        });
        history.push(DashboardEmailReportsUri(dashboard.name));
      },
      [history, mutateAsync, dashboard.name, openToast]
    );
    return (
      <CorePage
        title="Add new"
        breadcrumbs={[
          { href: DashboardsUri, title: 'Dashboards' },
          { href: DashboardDetailUri(dashboard.name), title: dashboard.name },
          {
            href: DashboardEmailReportsUri(dashboard.name),
            title: 'Email Reports',
          },
        ]}
        header={<CorePageTitle title="Add a new Email Report" />}
      >
        <DashboardEmailReportForm
          onCancel={() =>
            history.push(DashboardEmailReportsUri(dashboard.name))
          }
          onSubmit={onSubmit}
        />
      </CorePage>
    );
  }
);

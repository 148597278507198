import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Sheet from '@amzn/meridian/sheet';
import CloseTokens from '@amzn/meridian-tokens/base/icon/close';
import { isNullOrWhitespace } from '@amzn/dots-core-ui';
import { DashboardFormSection } from '../DashboardFormSection';
import { DashboardLayoutSectionDimensionControl } from './DashboardLayoutSectionDimensionControl';
import { useDashboardLayoutsFormState } from './DashboardLayoutsFormStateContext';
import {
  validateIndividualWidths,
  validateIndividualHeights,
  widthPattern,
  heightPattern,
} from './helpers';
import { Section } from './types';

export const DashboardLayoutSectionSheet = (): JSX.Element => {
  const {
    dispatch,
    state: { editingSection },
  } = useDashboardLayoutsFormState();
  const [formState, setFormState] = useState<Section | undefined>();
  const isOpen = useMemo(() => editingSection !== undefined, [editingSection]);
  const onClose = useCallback(() => {
    dispatch({ type: 'section-editor-close' });
  }, [dispatch]);
  const onConfirm = useCallback(() => {
    if (formState && editingSection) {
      dispatch({
        type: 'section-update',
        payload: {
          layoutId: editingSection.layoutId,
          sectionId: editingSection.section.id,
          section: formState,
        },
      });
    }
    onClose();
  }, [dispatch, editingSection, formState, onClose]);
  const widthsErrorMessage = useMemo(() => {
    const widthsTotal =
      formState?.widths.reduce((sum, { value }) => sum + Number(value), 0) ?? 0;
    if (
      !formState ||
      !formState.widths ||
      widthsTotal > 12 ||
      widthsTotal < 1
    ) {
      return 'The sum of all widths must be between 0 and 13';
    }
    return '';
  }, [formState]);
  const heightsErrorMessage = useMemo(
    () =>
      !formState || formState.heights.length === 0
        ? 'At least one row is required'
        : '',
    [formState]
  );
  const areDimensionsValid = useMemo(
    () =>
      formState &&
      formState.heights &&
      formState.heights.length > 0 &&
      formState.heights.every(({ value }) =>
        isNullOrWhitespace(validateIndividualHeights(value))
      ) &&
      formState.widths &&
      formState.widths.length > 0 &&
      formState.widths.every(({ value }) =>
        isNullOrWhitespace(validateIndividualWidths(value))
      ),
    [formState]
  );
  useEffect(() => {
    setFormState(editingSection?.section);
  }, [editingSection]);
  return (
    <Sheet type="overlay" side="right" open={isOpen} onClose={onClose}>
      <Column width={320}>
        <Row alignmentHorizontal="justify" spacingInset="400 200 none 400">
          <Text type="h300">Edit Section</Text>
          <Button onClick={onClose} type="icon">
            <Icon tokens={CloseTokens}>
              Close section editor and return to page
            </Icon>
          </Button>
        </Row>
        {formState && (
          <>
            <Column spacingInset="400">
              <DashboardFormSection
                title="Columns"
                descriptionText="Columns use a 12 unit grid system. Columns can span multiple units."
              >
                {widthsErrorMessage && (
                  <Alert type="error" title="Error!" size="medium">
                    {widthsErrorMessage}
                  </Alert>
                )}
                <DashboardLayoutSectionDimensionControl
                  dimensions={formState.widths}
                  pattern={widthPattern}
                  validate={validateIndividualWidths}
                  type="number"
                  onChange={(widths) =>
                    setFormState((prev) => (prev ? { ...prev, widths } : prev))
                  }
                />
              </DashboardFormSection>
              <DashboardFormSection
                title="Rows"
                descriptionText="Rows heights are either 'fit' to resize based on the content or a numeric value in the range 1-99 to specify a static value in units of 64 pixels."
              >
                {heightsErrorMessage && (
                  <Alert type="error" title="Error!" size="medium">
                    {heightsErrorMessage}
                  </Alert>
                )}
                <DashboardLayoutSectionDimensionControl
                  dimensions={formState.heights}
                  pattern={heightPattern}
                  validate={validateIndividualHeights}
                  type="text"
                  onChange={(heights) =>
                    setFormState((prev) => (prev ? { ...prev, heights } : prev))
                  }
                />
              </DashboardFormSection>
            </Column>
            <Row
              alignmentHorizontal="right"
              alignmentVertical="center"
              widths="fit"
            >
              <Button type="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button disabled={!areDimensionsValid} onClick={onConfirm}>
                Confirm
              </Button>
            </Row>
          </>
        )}
      </Column>
    </Sheet>
  );
};

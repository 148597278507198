import { WizardState } from '@/features/Dashboards/types';
import { PivotTabWizardConfiguration } from '@/types';
import { PivotTabWizardData, PivotTabOption } from './types';

export const createWizardData = ({
  configuration,
  data: { propertyIndexMap, baseData },
}: WizardState<PivotTabWizardConfiguration>): PivotTabWizardData => {
  const { data, custom } = configuration;
  const [, ...kpis] = baseData;
  const options: PivotTabOption[] = [
    ...(custom?.map((c) => ({
      label: c.name,
      selectionContext: c.value.map((v) => ({ [c.key]: v })),
    })) ?? []),
    ...Array.from(
      new Map<string, PivotTabOption>(
        kpis.flatMap((kpi) => {
          var vals: [string, PivotTabOption][] = [];
          data.forEach((prop) => {
            let value: any = kpi[propertyIndexMap[prop]];
            if (typeof value != "object") {
              value = value.toString();
              vals.push([
                value,
                {
                  label: value,
                  selectionContext: [
                    {
                      [prop]: value,
                    },
                  ],
                },
              ]);
            } else {
              value.forEach((v: any) => {
                vals.push([
                  v,
                  {
                    label: v,
                    selectionContext: [
                      {
                        [prop]: v
                      }
                    ]
                  }
                ])
              })
            }
          });
          return vals;
        })
      ).values()
    ),
  ];
  options.sort((a, b) => a.label.localeCompare(b.label));
  return { options };
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  BarChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  BarChartProps,
  LineChartProps,
} from 'recharts';
import { WizardTableData, WizardTableRow } from '@/features/Dashboards/types';
import { PivotTableChartType, PivotTableWizardConfiguration } from '@/types';
import { createPivotTableChartData, PivotTableCell } from './data';

const Chart = ({
  type,
  children,
  ...props
}: (BarChartProps | LineChartProps) & {
  type: PivotTableChartType;
}) => {
  if (type === 'Bar Chart') {
    return <BarChart {...props}>{children}</BarChart>;
  }
  return <LineChart {...props}>{children}</LineChart>;
};

export type DashboardPivotTableChartProps = {
  configuration: PivotTableWizardConfiguration;
  wizardData: WizardTableData<PivotTableCell, WizardTableRow<PivotTableCell>>;
};

export const DashboardPivotTableChart = ({
  configuration,
  wizardData,
}: DashboardPivotTableChartProps): JSX.Element => {
  const { chart } = configuration;
  const chartData = createPivotTableChartData(configuration, wizardData);
  if (!chartData || !chart || !chart.type) {
    return <span>bad configuration</span>;
  }

  return (
    <ResponsiveContainer width="100%" height={357}>
      <Chart
        type={chart.type}
        data={chartData.data}
        margin={{
          top: 20,
          right: 50,
          left: 25,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="4 4" stroke="#BBC0C1" />
        <XAxis
          dataKey={chartData.xAxisDataKey}
          padding={{ left: 30, right: 30 }}
          stroke="#6C7778"
        />
        <YAxis
          unit={chartData.yAxisUnit}
          stroke="#6C7778"
          padding={{ bottom: 20 }}
        >
          <Label
            value={chartData.yAxisLabel}
            angle={-90}
            position="insideLeft"
            offset={-2}
            fill="#6C7778"
          />
        </YAxis>
        <Tooltip />
        {chartData.lines.map(({ color, dataKey }) => {
          if (configuration.chart?.type === 'Bar Chart') {
            return (
              <Bar
                key={dataKey}
                dataKey={dataKey}
                name={dataKey}
                fill={color}
              />
            );
          }
          return (
            <Line
              key={dataKey}
              type="monotone"
              dataKey={dataKey}
              name={dataKey}
              stroke={color}
              strokeWidth="2"
              connectNulls
            />
          );
        })}
      </Chart>
    </ResponsiveContainer>
  );
};

import React from 'react';
import { useParams } from 'react-router-dom';
import Heading from '@amzn/meridian/heading';
import { CorePageTitle, CorePage, KpiLogFile } from '@/components';
import { useKpi } from '@/hooks';
import { KpiProperties } from './components';

export const KpiDetailPage = (): JSX.Element => {
  const { source, id } = useParams<{ source: string; id: string }>();
  const { data, isLoading, isError, error } = useKpi({ source, kpiId: id });
  return (
    <CorePage
      title={`Kpi: ${id}`}
      isLoading={isLoading}
      isError={isError}
      error={error}
      header={<CorePageTitle title="Kpi Detail" />}
    >
      {data && <KpiProperties kpi={data} />}
      <Heading level={3}>Logs</Heading>
      <KpiLogFile
        source={data?.ingest?.source}
        testrunId={data?.id}
        logFilePath={data?.kpi?.where}
      />
    </CorePage>
  );
};

import React, { useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Tag from '@amzn/meridian/tag';
import Tooltip from '@amzn/meridian/tooltip';
import { CoreAsyncButton, useToaster } from '@amzn/dots-core-ui';
import { useDashboardAnnotationUpdate } from '@/features/Dashboards/hooks';
import { Annotation } from '@/types';
import { useUserProfile } from '@/hooks';
import { DashboardAnnotationLegacyContent } from './DashboardAnnotationLegacyContent';
import { DashboardAnnotationContent } from './DashboardAnnotationContent'

export type DashboardAnnotationTileProps = {
  annotation: Annotation;
  onEdit: (annotation: Annotation) => void;
  isEditing: boolean;
};

export const DashboardAnnotationTile = ({
  annotation,
  onEdit,
  isEditing,
}: DashboardAnnotationTileProps): JSX.Element => {
  const { mutateAsync } = useDashboardAnnotationUpdate();
  const { openToast } = useToaster();
  const { data: userProfile } = useUserProfile();
  const updater = userProfile?.user_id ?? '';
  const onStatusChange = useCallback(async (): Promise<void> => {
    await mutateAsync({
      id: annotation.annotation_id,
      annotation: { updater, resolved: !annotation.resolved },
    });
    openToast({
      title: 'Success!',
      message: 'The annotation has been updated.',
      type: 'success',
    });
  }, [
    mutateAsync,
    annotation.annotation_id,
    annotation.resolved,
    updater,
    openToast,
  ]);
  const formatedLastUpdated = useMemo(
    () => format(new Date(`${annotation.update_time}Z`), 'MMM d, HH:mm'),
    [annotation.update_time]
  );
  return (
    <Column
      type="outline"
      spacingInset="300"
      spacing="100"
      heights={['fill', 'fit', 'fit']}
      height="fit"
      minWidth={420}
    >
      {annotation.notes !== undefined && (
        <DashboardAnnotationLegacyContent content={annotation.notes} />
      )}
      {annotation.template !== undefined &&(
        <DashboardAnnotationContent content={annotation.template.content} />
      )}
      <Row />
      <Row spacing="none" widths={['fit', 'fill', 'fit']}>
        <Tooltip position="bottom" title={`@${annotation.updater}`}>
          <Text type="b200">
            {' Last updated by '}
            {<a href={'http://phonetool.amazon.com/users/'+annotation.updater} target="_blank"> {annotation.updater}</a>}
            {' on '+ formatedLastUpdated}
          </Text>
        </Tooltip>
        <Column />
        <Row widths="fit" spacing="200">
          {isEditing && <Tag type="theme">Editing</Tag>}
          {!isEditing && (
            <Button type="link" size="small" onClick={() => onEdit(annotation)}>
              Edit
            </Button>
          )}
          <Text>·</Text>
          <CoreAsyncButton type="link" size="small" onClick={onStatusChange}>
            {annotation.resolved ? 'Open' : 'Resolve'}
          </CoreAsyncButton>
        </Row>
      </Row>
    </Column>
  );
};

import { useMutation, UseMutationResult } from 'react-query';
import { useHttp, HttpClient } from '@/client';
import { Dashboard, UserProfile } from '@/types';
import { useUserProfile } from './useUserProfile';

const cloneDashboard = async (
  http: HttpClient,
  dashboardName: Dashboard['id'],
  userId?: UserProfile['user_id']
): Promise<Dashboard> => {
  const params = { name: dashboardName };
  const dashboardResult = await http.get('dashboard', params);
  if (dashboardResult.status.code !== 200) {
    throw new Error(`Failed to clone dashboard ${dashboardName}`);
  }
  const dashboard = dashboardResult.data;
  const { data, status } = await http.post('dashboard', {
    ...dashboard,
    name: `${dashboard.name} copy ${Date.now()}`,
    owners: userId ? dashboard.owners.concat(userId) : dashboard.owners,
  });
  if (status.code === 200) {
    return data;
  }
  throw new Error(`Failed to clone dashboard ${dashboardName}`);
};

export const useDashboardClone = (): UseMutationResult<
  Dashboard,
  unknown,
  string,
  unknown
> => {
  const { http } = useHttp();
  const { data: userProfile } = useUserProfile();
  return useMutation((dashboardId: Dashboard['id']) =>
    cloneDashboard(http, dashboardId, userProfile?.user_id)
  );
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import Icon from '@amzn/meridian/icon';
import Tooltip from '@amzn/meridian/tooltip';
import copyIconTokens from '@amzn/meridian-tokens/base/icon/copy';
import { CoreAsyncButton, useToaster } from '@amzn/dots-core-ui';
import { DashboardDetailUri } from '@/features/routes';
import { useDashboardClone } from '@/hooks';
import { DashboardButtonProps } from './types';

export const DashboardCloneButton = ({
  id,
  name,
  type = 'tertiary',
}: DashboardButtonProps): JSX.Element => {
  const history = useHistory();
  const { openToast } = useToaster();
  const { mutateAsync, isLoading } = useDashboardClone();
  const onClick = async () => {
    try {
      const newDashboard = await mutateAsync(id);
      openToast({
        message: `Successfully cloned ${name}!`,
        type: 'success',
      });
      history.push(DashboardDetailUri(newDashboard.name));
    } catch (error) {
      openToast({
        message: `Failed to clone ${name}, please try again later`,
        type: 'error',
      });
    }
  };
  return (
    <Tooltip title="create a new copy" position="bottom">
      <CoreAsyncButton
        label="clone dashboard"
        isLoading={isLoading}
        onClick={onClick}
        type={type}
        size="small"
      >
        <Icon tokens={copyIconTokens} />
        {type !== 'icon' && <span>Clone</span>}
      </CoreAsyncButton>
    </Tooltip>
  );
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  CoreFilterableSelect,
  CoreFilterableSelectProps,
  ValidationProps,
} from '@amzn/dots-core-ui';
import { useQuery } from 'react-query';
import { useHttp } from '../client/HttpContext';

export type TestSourcePropertySelectProps = { testSource: string } & Pick<
  ValidationProps<CoreFilterableSelectProps>,
  'label' | 'value' | 'onChange' | 'errorMessage' | 'validationState'
>;

export const TestSourcePropertySelect = ({
  testSource,
  ...props
}: TestSourcePropertySelectProps): JSX.Element => {
  const { http } = useHttp();
  const { data: kpiProperties, isLoading: isLoadingKpiProperties } = useQuery({
    queryKey: ['testSourceProps', { testSource }],
    queryFn: async () => {
      const data = await http.get(`testsource/${testSource}/fields`);
      if (data.status.code === 200) {
        return data.data.map((item: { label: string }) => item.label);
      }
      return [];
    },
  });
  return (
    <CoreFilterableSelect
      {...props}
      placeholder="Filter property"
      items={kpiProperties ?? []}
      disabled={isLoadingKpiProperties}
    />
  );
};

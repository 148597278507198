import { useReducer } from 'react';
import { TableState, TableStateUpdateAction } from '../types';

const getIndices = (currentPage: number, perPage: number) => ({
  startIndex: (currentPage - 1) * perPage,
  endIndex: currentPage * perPage,
});

export const initialTableState: TableState = {
  searchQuery: '',
  pagination: { currentPage: 1, perPage: 10, startIndex: 0, endIndex: 10 },
  sortColumn: 0,
  sortDirection: 'ascending',
};

export const tableStateReducer = (
  state: TableState,
  action: TableStateUpdateAction
): TableState => {
  switch (action.type) {
    case 'update-pagination-current-page':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...getIndices(action.payload, state.pagination.perPage),
          currentPage: action.payload,
        },
      };
    case 'update-pagination-per-page':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...getIndices(1, action.payload),
          perPage: action.payload,
          currentPage: 1,
        },
      };
    case 'update-search-query':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...getIndices(1, state.pagination.perPage),
          currentPage: 1,
        },
        searchQuery: action.payload,
      };
    case 'update-sort-state':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const useDashboardTableState = (
  initialState?: Partial<TableState>
): [TableState, React.Dispatch<TableStateUpdateAction>] => {
  const result = useReducer(tableStateReducer, {
    ...initialTableState,
    ...initialState,
  });
  return result;
};

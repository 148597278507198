/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

/**
 * Flatten a deep object into a one level object with it’s path as key
 * @param  {object} the object to be flattened
 * @return {object} the resulting flat object
 */
const flattenObject = (
  object: Record<string, any>,
  separator = '.'
): Record<string, any> => {
  const flatten = (objectBit: Record<string, any>, path = ''): any =>
    // concat everything into one level
    [].concat(
      // iterate over object
      ...Object.keys(objectBit).map((key) =>
        // check if there is a nested object
        objectBit[key] !== null && typeof objectBit[key] === 'object'
          ? // call itself if there is
            flatten(objectBit[key], `${path}${path ? separator : ''}${key}`)
          : // append object with it’s path as key
            {
              [`${path}${path ? separator : ''}${key}`]: objectBit[key],
            }
      )
    );
  return Object.assign(
    {},
    // spread the result into our return object
    ...flatten(object)
  );
};

export const useFlattenObject = (
  object: Record<string, any>
): Record<string, any> => {
  return useMemo(() => flattenObject(object), [object]);
};

import { useMemo } from 'react';
import { keys } from '@amzn/dots-core-ui';
import {
  BaseDataPropertyIndexMap,
  DashboardWizardBase,
  KpiPropertyValue,
} from '@/types';
import { useWizardSelection, WizardSelection, useDashboard } from '../contexts';
import { WizardState } from '../types';
import { useDashboardGlobalData } from './useDashboardGlobalData';
import { useDashboardKpis } from './useDashboardKpis';

/**
 * Create a function that can determine if a KPI matches the configured KPI
 * filter, performs regular expression matching against a specific KPI property
 */
const isKpiInWizardKpiFilterFactory = (
  propertyIndexMap: BaseDataPropertyIndexMap,
  wizardConfiguration: DashboardWizardBase
) => {
  const kpiFilter = wizardConfiguration.kpi;
  if (!kpiFilter) {
    return () => true;
  }
  const regExp = new RegExp(kpiFilter.value);
  return (kpi: KpiPropertyValue[]) =>
    regExp.test(kpi[propertyIndexMap[kpiFilter.key]].toString());
};

/**
 * Create a function that determines if a KPI matches the current selection
 * filters
 */
export const isKpiInSelectionFactory = (
  propertyIndexMap: BaseDataPropertyIndexMap,
  wizardSelections: WizardSelection[]
) => (kpi: KpiPropertyValue[]): boolean =>
  wizardSelections.every(
    (wizardSelection) =>
      wizardSelection.length === 0 ||
      wizardSelection.some((selection) =>
        keys(selection).every(
          (filterTerm) => {
            if (typeof kpi[propertyIndexMap[filterTerm]] == "object") {
              let s: any = kpi[propertyIndexMap[filterTerm]];
              return s.some((select:any) => selection[filterTerm] == select);
            }
            return selection[filterTerm] === kpi[propertyIndexMap[filterTerm]];
          }
        )
      )
  );

// filters are "registered" as closures and applied to all raw KPIs
const getFilters = (
  propertyIndexMap: BaseDataPropertyIndexMap,
  wizardConfiguration: DashboardWizardBase,
  wizardSelections: WizardSelection[]
) => [
  // filter based on the Wizard's configuration
  isKpiInWizardKpiFilterFactory(propertyIndexMap, wizardConfiguration),
  isKpiInSelectionFactory(propertyIndexMap, wizardSelections),
];

export const useDashboardWizardData = <
  TConfiguration extends DashboardWizardBase,
  TResult
>(
  configuration: TConfiguration,
  createWizardData: (state: WizardState<TConfiguration>) => TResult
): TResult | undefined => {
  const { data } = useDashboardKpis();
  const { dashboard } = useDashboard();
  const {
    selectionState: { parentSelection },
  } = useWizardSelection(configuration.id);
  const globalCalculatedData = useDashboardGlobalData();
  const result = useMemo(() => {
    if (data === undefined) {
      return undefined;
    }
    const [baseDataHeader, ...allKpis] = data.baseData;
    const filters = getFilters(
      data.propertyIndexMap,
      configuration,
      parentSelection
    );
    const filteredKpis = allKpis.filter((kpi) => filters.every((f) => f(kpi)));
    return createWizardData({
      configuration,
      data: { ...data, baseData: [baseDataHeader, ...filteredKpis] },
      dashboard,
      globalCalculatedData,
    });
  }, [
    configuration,
    createWizardData,
    dashboard,
    data,
    globalCalculatedData,
    parentSelection,
  ]);
  return result;
};

import React, { useMemo } from 'react';
import {
  CoreInput,
  CoreTextarea,
  isNullOrWhitespace,
  useForm,
} from '@amzn/dots-core-ui';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import InputGroup from '@amzn/meridian/input-group';
import Input from '@amzn/meridian/input';
import Select, { SelectOption } from '@amzn/meridian/select';
import Alert from '@amzn/meridian/alert';
import AddTokens from '@amzn/meridian-tokens/base/icon/plus';
import TrashTokens from '@amzn/meridian-tokens/base/icon/trash';
import { TestSourcePropertySelect } from '@/components';
import { useDashboard } from '@/features/Dashboards/contexts';
import { getUniqueId } from '@/features/Dashboards/helpers';
import { WithId } from '@/features/Dashboards/types';
import { PivotTableWizardConfiguration } from '@/types';
import { DashboardWizardBaseControl } from './DashboardWizardBaseControl';
import {
  cellColorTabParser,
  onWizardConfirmFactory,
  getWizardBaseFormConfiguration,
  kpiDimensionParser,
  userDefinedVariableParser,
} from './helpers';
import {
  CellColor,
  DashboardWizardFormProps,
  KpiDimension,
  UserDefinedVariable,
} from './types';
import { DashboardWizardFormFieldLabel } from './DashboardWizardFormFieldLabel';
import { meridianColors } from './constants';

export const DashboardPivotTableWizardForm = ({
  wizard,
  onCancel,
  onSubmit,
}: DashboardWizardFormProps<PivotTableWizardConfiguration>): JSX.Element => {
  const { dashboard } = useDashboard();
  const { hasErrors: hasFormErrors, handlers, values, errors } = useForm({
    ...getWizardBaseFormConfiguration(wizard),
    cellColorType: {
      initialValue: wizard.cell?.color_type ?? 'grid',
      validate: (value: string) =>
        isNullOrWhitespace(value) ? 'Cell Color Type is required' : '',
    },
    cellFormat: {
      initialValue: wizard.cell?.format ?? '',
      validate: (value: string) =>
        isNullOrWhitespace(value) ? 'Cell Format is required' : '',
    },
    cellColorRules: {
      initialValue: cellColorTabParser.toFormModel(wizard.cell?.color),
      validate: (rules: CellColor[]) =>
        rules.some(
          (rule) =>
            isNullOrWhitespace(rule.color) ||
            isNullOrWhitespace(rule.data) ||
            isNullOrWhitespace(rule.relationship) ||
            isNullOrWhitespace(rule.value)
        )
          ? 'All fields are required'
          : '',
    },
    rows: {
      initialValue: kpiDimensionParser.toFormModel(wizard.row),
      validate: (value: KpiDimension[]) => {
        if (value.length === 0) {
          return 'At least one attribute must be defined';
        }
        if (
          value.some(
            (v) => isNullOrWhitespace(v.name) || isNullOrWhitespace(v.data)
          )
        ) {
          return 'Name and KPI Property must be defined for all rows';
        }
        return '';
      },
    },
    columns: {
      initialValue: kpiDimensionParser.toFormModel(wizard.col),
      validate: (value: KpiDimension[]) => {
        if (
          value.some(
            (v) => isNullOrWhitespace(v.name) || isNullOrWhitespace(v.data)
          )
        ) {
          return 'Name and KPI Property must be defined for all columns';
        }
        return '';
      },
    },
    variables: {
      initialValue: userDefinedVariableParser.toFormModel(wizard.cell?.data),
      validate: (value: UserDefinedVariable[]) => {
        if (
          value.some(
            (v) =>
              isNullOrWhitespace(v.name) || isNullOrWhitespace(v.expression)
          )
        ) {
          return 'Name and Expression must be defined for all variables';
        }
        return '';
      },
    },
    mode: { initialValue: wizard.chart?.show ?? 'Table Only' },
    chartType: { initialValue: wizard.chart?.type ?? 'Line Chart' },
    chartSeries: { initialValue: wizard.chart?.data ?? '' },
    xAxis: { initialValue: wizard.chart?.xAxis ?? 'Table Row' },
    yAxisLabel: { initialValue: wizard.chart?.yAxis?.label ?? '' },
    yAxisUnit: { initialValue: wizard.chart?.yAxis?.unit ?? '' },
  });
  const shouldValidateChartConfiguration = useMemo(
    () => values.mode === 'Chart Only' || values.mode === 'Table and Chart',
    [values.mode]
  );
  const hasErrors = useMemo(
    () =>
      hasFormErrors ||
      (shouldValidateChartConfiguration &&
        (isNullOrWhitespace(values.chartSeries) ||
          isNullOrWhitespace(values.chartType) ||
          isNullOrWhitespace(values.xAxis))),
    [
      hasFormErrors,
      shouldValidateChartConfiguration,
      values.chartSeries,
      values.chartType,
      values.xAxis,
    ]
  );
  const onCollectionChange = (
    targetId: string,
    field: 'rows' | 'cellColorRules' | 'columns' | 'variables',
    next:
      | Partial<CellColor>
      | Partial<UserDefinedVariable>
      | Partial<KpiDimension>
  ) =>
    handlers[field](
      values[field].map((item: WithId<unknown>) => {
        if (item.id === targetId) {
          return { ...item, ...next };
        }
        return item;
      })
    );
  const onCollectionRemove = (
    targetId: string,
    field: 'rows' | 'cellColorRules' | 'columns' | 'variables'
  ) =>
    handlers[field](
      values[field].filter((item: WithId<unknown>) => item.id !== targetId)
    );
  const onCollectionAdd = (
    field: 'rows' | 'cellColorRules' | 'columns' | 'variables'
  ) => () =>
    handlers[field](
      values[field].concat({ id: getUniqueId(), name: '', format: '' })
    );
  const onConfirm = () =>
    onSubmit({
      ...onWizardConfirmFactory(wizard, values),
      type: wizard.type,
      col: kpiDimensionParser.toConfigurationModel(values.columns),
      row: kpiDimensionParser.toConfigurationModel(values.rows),
      cell: {
        color_type: values.cellColorType,
        color: values.cellColorRules,
        format: values.cellFormat,
        data: userDefinedVariableParser.toConfigurationModel(values.variables),
      },
      chart: {
        show: values.mode,
        type: values.chartType,
        data: values.chartSeries,
        xAxis: values.xAxis,
        yAxis:
          values.yAxisLabel || values.yAxisUnit
            ? {
                label: values.yAxisLabel,
                unit: values.yAxisUnit,
              }
            : undefined,
      },
    });
    const betweenInputValid = (input: string) => {
      const values = input.split(',').map((item) => item.trim());
      if (values.length !== 2) {
        return 'Must be <number>,<number> format';
      }
      const leftEnd = Number(values[0]);
      const rightEnd = Number(values[1]);

      if (Number.isNaN(leftEnd) || Number.isNaN(rightEnd)) {
        return 'Must be <number>,<number> format';
      }
      if (rightEnd <= leftEnd) {
        return 'Right number must be larger than left number';
      }

      return '';
    }
  return (
    <Column>
      <DashboardWizardBaseControl
        wizard={wizard}
        values={values}
        handlers={handlers}
      />
      <DashboardWizardFormFieldLabel
        label="Table Rows"
        hint={
          <Column spacing="100">
            <Text>KPI properties are used to group KPIs into table rows.</Text>
            <Text>
              A table&apos;s rows can be multi-dimensional, i.e. group KPIs by
              more than one property.
            </Text>
          </Column>
        }
      >
        {values.rows.map(({ id, name, data }: KpiDimension) => (
          <Row key={id} widths={['fill', 'fit']}>
            <Row widths="grid-6" alignmentVertical="top">
              <CoreInput
                label="Name"
                value={name}
                onChange={(value) =>
                  onCollectionChange(id, 'rows', { name: value })
                }
                errorMessage={
                  isNullOrWhitespace(name) ? 'Name is required' : ''
                }
              />
              <TestSourcePropertySelect
                label="KPI Property"
                testSource={dashboard.testsources}
                value={data}
                onChange={(value: any) =>
                  onCollectionChange(id, 'rows', { data: value })
                }
                errorMessage={
                  isNullOrWhitespace(data) ? 'KPI Property is required' : ''
                }
              />
            </Row>
            <Button
              type="secondary"
              onClick={() => onCollectionRemove(id, 'rows')}
              disabled={values.rows.length === 1}
            >
              <Icon tokens={TrashTokens}>Remove this row</Icon>
            </Button>
          </Row>
        ))}
        <Button type="tertiary" onClick={onCollectionAdd('rows')}>
          <Icon tokens={AddTokens}>Add a new row</Icon>
        </Button>
      </DashboardWizardFormFieldLabel>
      <DashboardWizardFormFieldLabel
        label="Table Columns"
        hint={
          <Column spacing="100">
            <Text>
              KPI properties are used to group KPIs into table columns.
            </Text>
            <Text>
              A table&apos;s columns can be multi-dimensional, i.e. group KPIs
              by more than one property.
            </Text>
          </Column>
        }
      >
        {values.columns.map(({ id, name, data }: KpiDimension) => (
          <Row key={id} widths={['fill', 'fit']}>
            <Row widths="grid-6" alignmentVertical="top">
              <CoreInput
                label="Name"
                value={name}
                onChange={(value) =>
                  onCollectionChange(id, 'columns', { name: value })
                }
                errorMessage={
                  isNullOrWhitespace(name) ? 'Name is required' : ''
                }
              />
              <TestSourcePropertySelect
                label="KPI Property"
                testSource={dashboard.testsources}
                value={data}
                onChange={(value: any) =>
                  onCollectionChange(id, 'columns', { data: value })
                }
                errorMessage={
                  isNullOrWhitespace(data) ? 'KPI Property is required' : ''
                }
              />
            </Row>
            <Button
              type="secondary"
              onClick={() => onCollectionRemove(id, 'columns')}
              disabled={values.columns.length === 1}
            >
              <Icon tokens={TrashTokens}>Remove this row</Icon>
            </Button>
          </Row>
        ))}
        <Button type="tertiary" onClick={onCollectionAdd('columns')}>
          <Icon tokens={AddTokens}>Add a new row</Icon>
        </Button>
      </DashboardWizardFormFieldLabel>
      <DashboardWizardFormFieldLabel
        label="Table Cell"
        hint={
          <Column spacing="100">
            <Text>Specify the template used for each cell.</Text>
            <Text>The format can refer to User Defined Variables.</Text>
          </Column>
        }
      >
        <CoreTextarea
          label="Format"
          value={values.cellFormat}
          onChange={handlers.cellFormat}
          errorMessage={errors.cellFormat}
        />
      </DashboardWizardFormFieldLabel>
      <DashboardWizardFormFieldLabel
        label="Table Color Type"
        hint={
          <Column spacing="100">
            <Text>
              Specify how a cell should be colored when a cell&apos;s User
              Defined Variable matches a rule
            </Text>
            <Text>
              Colors can apply to either the cell text or the cell background
              color.
            </Text>
          </Column>
        }
      >
        <Select
          label="Type"
          value={values.cellColorType}
          onChange={handlers.cellColorType}
          errorMessage={errors.cellColorType}
        >
          <SelectOption label="Cell text color" value="text" />
          <SelectOption label="Cell background color" value="grid" />
        </Select>
      </DashboardWizardFormFieldLabel>
      <DashboardWizardFormFieldLabel
        label="Table Color Rules"
        hint={
          <Column spacing="100">
            <Text>
              Define the rules for cell coloring. Rules are based on User
              Defined Variables.
            </Text>
          </Column>
        }
      >
        {values.cellColorRules.map(
          ({ id, data, relationship, value: threshold, color }: CellColor) => (
            <Row key={id} widths={['fill', 'fit']}>
              <Row widths="grid-3" alignmentVertical="top">
                <Select
                  label="Variable"
                  value={data}
                  onChange={(value) =>
                    onCollectionChange(id, 'cellColorRules', { data: value })
                  }
                  errorMessage={
                    isNullOrWhitespace(data) ? 'Variable is required' : ''
                  }
                >
                  {values.variables.length === 0 && (
                    <SelectOption
                      label="No User Defined Variables found"
                      value="!_"
                      disabled
                    />
                  )}
                  {values.variables.map(
                    ({ id: vId, name }: UserDefinedVariable) => (
                      <SelectOption key={vId} label={name} value={name} />
                    )
                  )}
                </Select>
                <Select
                  label="Relationship"
                  value={relationship}
                  onChange={(value) =>
                    onCollectionChange(id, 'cellColorRules', {
                      relationship: value,
                    })
                  }
                  errorMessage={
                    isNullOrWhitespace(relationship)
                      ? 'Relationship is required'
                      : ''
                  }
                >
                  <SelectOption label="equal" value="equal" />
                  <SelectOption label="greater than" value="greater than" />
                  <SelectOption label="less than" value="less than" />
                  <SelectOption label="between" value="between" />
                  <SelectOption label="else" value="else" />
                </Select>
                <CoreInput
                  label="Threshold"
                  value={threshold}
                  type={relationship == "between" ? "text" : "number"}
                  onChange={(value) =>
                    onCollectionChange(id, 'cellColorRules', { value })
                  }
                  errorMessage={
                    isNullOrWhitespace(threshold) ? 'Threshold is required' :
                    relationship == "between"  ? betweenInputValid(threshold) : ''
                  }
                />
                <Select
                  label="Color"
                  value={color}
                  onChange={(value) =>
                    onCollectionChange(id, 'cellColorRules', { color: value })
                  }
                  errorMessage={
                    isNullOrWhitespace(color) ? 'Color is required' : ''
                  }
                >
                  {meridianColors.map((meridianColor) => (
                    <SelectOption
                      key={meridianColor}
                      label={meridianColor}
                      value={meridianColor}
                    >
                      {() => (
                        <div
                          style={{
                            backgroundColor: `${meridianColor}`,
                            width: '64px',
                            height: '16px',
                          }}
                        />
                      )}
                    </SelectOption>
                  ))}
                </Select>
              </Row>
              <Button
                type="secondary"
                onClick={() => onCollectionRemove(id, 'cellColorRules')}
              >
                <Icon tokens={TrashTokens}>Remove this color rule</Icon>
              </Button>
            </Row>
          )
        )}
        <Button type="tertiary" onClick={onCollectionAdd('cellColorRules')}>
          <Icon tokens={AddTokens}>Add a new coloring rule</Icon>
        </Button>
      </DashboardWizardFormFieldLabel>
      <DashboardWizardFormFieldLabel
        label="Chart Configuration"
        hint={
          <Column spacing="100">
            <Text>
              Add an optional chart below the Pivot Table using a User Defined
              Variable as a series.
            </Text>
          </Column>
        }
      >
        <Row widths="grid-6" alignmentVertical="top">
          <Select label="Mode" value={values.mode} onChange={handlers.mode}>
            <SelectOption label="Table Only" value="Table Only" />
            <SelectOption label="Chart Only" value="Chart Only" />
            <SelectOption label="Table and Chart" value="Table and Chart" />
          </Select>
          <Select
            label="Chart Type"
            value={values.chartType}
            onChange={handlers.chartType}
            error={
              shouldValidateChartConfiguration &&
              isNullOrWhitespace(values.chartType)
            }
            errorMessage={
              shouldValidateChartConfiguration &&
              isNullOrWhitespace(values.chartType)
                ? 'Chart Type is required'
                : ''
            }
          >
            <SelectOption label="Line Chart" value="Line Chart" />
            <SelectOption label="Bar Chart" value="Bar Chart" />
          </Select>
        </Row>
        <Row widths="grid-6" alignmentVertical="top">
          <Select
            label="Chart Series"
            value={values.chartSeries}
            onChange={handlers.chartSeries}
            error={
              shouldValidateChartConfiguration &&
              isNullOrWhitespace(values.chartSeries)
            }
            errorMessage={
              shouldValidateChartConfiguration &&
              isNullOrWhitespace(values.chartSeries)
                ? 'Chart Series is required'
                : ''
            }
          >
            {values.variables.length === 0 && (
              <SelectOption
                label="No User Defined Variables found"
                value="!_"
                disabled
              />
            )}
            {values.variables.map(({ id: vId, name }: UserDefinedVariable) => (
              <SelectOption key={vId} label={name} value={name} />
            ))}
          </Select>
          <Select
            label="X Axis"
            value={values.xAxis}
            onChange={handlers.xAxis}
            error={
              shouldValidateChartConfiguration &&
              isNullOrWhitespace(values.xAxis)
            }
            errorMessage={
              shouldValidateChartConfiguration &&
              isNullOrWhitespace(values.xAxis)
                ? 'X Axis is required'
                : ''
            }
          >
            <SelectOption label="Table Row" value="Table Row" />
            <SelectOption label="Table Column" value="Table Column" />
          </Select>
        </Row>
        <Row widths="grid-6" alignmentVertical="top">
          <CoreInput
            label="Y Axis Label"
            value={values.yAxisLabel}
            onChange={handlers.yAxisLabel}
          />
          <CoreInput
            label="Y Axis Unit"
            value={values.yAxisUnit}
            onChange={handlers.yAxisUnit}
          />
        </Row>
      </DashboardWizardFormFieldLabel>
      <DashboardWizardFormFieldLabel
        label="User Defined Variables"
        hint={
          <Column spacing="100">
            <Text>
              Define the name and expression for variables to be used above.
            </Text>
          </Column>
        }
      >
        <Column>
          {errors.variables.length !== 0 && (
            <Alert type="error" size="small">
              {errors.variables}
            </Alert>
          )}
          {values.variables.map(
            ({ id, name, expression }: UserDefinedVariable) => (
              <Column key={id}>
                <Row widths={['fill', 'fit']}>
                  <InputGroup
                    value={[name, expression]}
                    onChange={([nextName, nextExpression]) =>
                      onCollectionChange(id, 'variables', {
                        name: nextName,
                        expression: nextExpression,
                      })
                    }
                    direction="column"
                  >
                    <Input label="Name" error={isNullOrWhitespace(name)} />
                    <Input
                      label="Expression"
                      error={isNullOrWhitespace(expression)}
                    />
                  </InputGroup>
                  <Button
                    type="secondary"
                    onClick={() => onCollectionRemove(id, 'variables')}
                  >
                    <Icon tokens={TrashTokens}>Remove this variable</Icon>
                  </Button>
                </Row>
                {(isNullOrWhitespace(name) ||
                  isNullOrWhitespace(expression)) && (
                  <Alert type="error" size="small">
                    Name and Expression must be defined
                  </Alert>
                )}
              </Column>
            )
          )}
        </Column>
        <Button type="tertiary" onClick={onCollectionAdd('variables')}>
          <Icon tokens={AddTokens}>Add a new variable</Icon>
        </Button>
      </DashboardWizardFormFieldLabel>
      <Row alignmentHorizontal="right" alignmentVertical="center" widths="fit">
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={hasErrors} onClick={onConfirm}>
          Confirm
        </Button>
      </Row>
    </Column>
  );
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import viewTokens from '@amzn/meridian-tokens/base/icon/view';
import { DashboardMonthlyReportDetailUri } from '@/features/routes';

export type DashboardMonthlyReportViewButtonProps = {
  dashboardName: string;
  reportRequestId: string;
};

export const DashboardMonthlyReportViewButton = ({
  dashboardName,
  reportRequestId,
}: DashboardMonthlyReportViewButtonProps): JSX.Element => {
  const history = useHistory();
  return (
    <Button
      size="small"
      type="secondary"
      onClick={() =>
        history.push(
          DashboardMonthlyReportDetailUri(dashboardName, reportRequestId)
        )
      }
    >
      <Icon tokens={viewTokens}>Report</Icon>
      View
    </Button>
  );
};

import {
  QueryClient,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { isNullOrWhitespace } from '@amzn/dots-core-ui';
import { useHttp, HttpClient } from '@/client';
import { Jira } from '@/types';

export type GetJirasArgs =
  | {
      jql: string;
      fields?: string;
    }
  | {
      ids: string[];
      fields?: string;
    };

async function getJiras(
  http: HttpClient,
  args: GetJirasArgs,
  queryClient: QueryClient
): Promise<Jira[]> {
  // ignore empty requests
  if (
    ('jql' in args && isNullOrWhitespace(args.jql)) ||
    ('ids' in args && args.ids.length === 0)
  ) {
    return [];
  }
  // if we are querying for IDs, we might have them in the cache. Return cached
  // Jiras if they are all available
  if ('ids' in args) {
    const cachedJiras = args.ids
      .map((id) => queryClient.getQueryData<Jira>(['jira', { id }]))
      .filter((maybeJira) => maybeJira) as Jira[];
    if (cachedJiras.length === args.ids.length) {
      return cachedJiras;
    }
  }
  const { data, status } = await http.post('jira', args);
  if (status.code === 200) {
    return data.filter((jira: Jira) => jira?.key);
  }
  throw new Error(data.message);
}

export function useJira(jiraId: string): UseQueryResult<Jira, Error> {
  const { http } = useHttp();
  const queryClient = useQueryClient();
  return useQuery(['jira', { id: jiraId }], async () => {
    const result = await getJiras(http, { ids: [jiraId] }, queryClient);
    if (result.length) {
      return result[0];
    }
    throw new Error(`Jira ${jiraId} could not be found!`);
  });
}

export function useJiras(args: GetJirasArgs): UseQueryResult<Jira[], Error> {
  const { http } = useHttp();
  const queryClient = useQueryClient();
  return useQuery({
    queryFn: () => getJiras(http, args, queryClient),
    queryKey: ['jiras', args],
    onSuccess: (response: Jira[]) => {
      response.forEach((jira) =>
        queryClient.setQueryData(['jira', { id: jira.key }], jira)
      );
    },
  });
}

import React, { useEffect, useMemo } from 'react';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import closeTokens from '@amzn/meridian-tokens/base/icon/close';
import {
  useForm,
  isNullOrWhitespace,
  CoreFilterableSelect,
} from '@amzn/dots-core-ui';
import { TranslatorFilter } from './types';

export type DashboardTranslatorFilterControlProps = {
  filter?: Partial<TranslatorFilter>;
  translators?: Record<string, string[]>;
  isLoading: boolean;
  onChange: (value: TranslatorFilter) => void;
  onRemove: () => void;
};

export const DashboardTranslatorFilterControl = ({
  filter,
  translators,
  isLoading,
  onChange,
  onRemove,
}: DashboardTranslatorFilterControlProps): JSX.Element => {
  const { values, handlers, errors, states } = useForm({
    id: { initialValue: filter?.id },
    key: {
      initialValue: filter?.key ?? '',
      validate: (value: string) =>
        isNullOrWhitespace(value) ? 'Translator name is required' : '',
    },
    value: {
      initialValue: filter?.value ?? '',
      validate: (value: string) =>
        isNullOrWhitespace(value) ? 'Translator version is required' : '',
    },
  });
  const translatorNames = useMemo(
    () => (translators ? Object.keys(translators).sort() : []),
    [translators]
  );
  const translatorVersions = useMemo(
    () => (translators && values.key ? translators[values.key].sort() : []),
    [translators, values.key]
  );
  useEffect(() => {
    onChange(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  return (
    <Row widths={['grid-7', 'grid-4', 'grid-1']} alignmentVertical="center">
      <CoreFilterableSelect
        label="Translator Name"
        items={translatorNames}
        value={values.key}
        onChange={handlers.key}
        errorMessage={errors.key}
        validationState={states.key}
        disabled={isLoading}
      />
      <CoreFilterableSelect
        label="Translator Version"
        items={translatorVersions}
        value={values.value}
        onChange={handlers.value}
        errorMessage={errors.value}
        validationState={states.value}
        disabled={isLoading}
      />
      <Button type="icon" size="small" onClick={onRemove}>
        <Icon tokens={closeTokens}>delete</Icon>
      </Button>
    </Row>
  );
};

import React, { useEffect } from 'react';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import closeTokens from '@amzn/meridian-tokens/base/icon/close';
import { useForm, isNullOrWhitespace } from '@amzn/dots-core-ui';
import {
  TestSourcePropertySelect,
  TestSourcePropertyValueSelect,
} from '@/components';
import { TestrunRangeFilter } from './types';
import { echoValue } from './helpers';

export type DashboardTestrunFilterControlProps = {
  testrunRangeFilter?: Partial<TestrunRangeFilter>;
  testSource: string;
  onChange: (value: TestrunRangeFilter) => void;
  onRemove?: () => void;
};

export const DashboardTestrunFilterControl = ({
  testrunRangeFilter,
  testSource,
  onChange,
  onRemove,
}: DashboardTestrunFilterControlProps): JSX.Element => {
  const { values, handlers, errors, states } = useForm({
    id: {
      initialValue: testrunRangeFilter?.id,
    },
    testSource: {
      initialValue: testSource,
    },
    propertyName: {
      initialValue: testrunRangeFilter?.propertyName ?? '',
      validate: (value: string) =>
        isNullOrWhitespace(value) ? 'Property name is required' : '',
    },
    filterType: {
      initialValue: testrunRangeFilter?.filterType ?? 'include',
      validate: (value: string) =>
        isNullOrWhitespace(value) ? 'Filter type is required' : '',
    },
    value: {
      initialValue: testrunRangeFilter?.value ?? [],
      validate: (value: string[]) =>
        value.length === 0 ? 'A filter value is required' : '',
    },
  });
  useEffect(() => {
    onChange(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  return (
    <Row
      widths={['grid-11', 'grid-1']}
      alignmentVertical="center"
      type="outline"
      spacingInset="400"
    >
      <Row widths={['grid-4', 'grid-8']} alignmentVertical="top">
        <Column>
          <TestSourcePropertySelect
            label="Filter Property"
            testSource={testSource}
            value={values.propertyName}
            onChange={handlers.propertyName}
            errorMessage={errors.propertyName}
            validationState={states.propertyName}
          />
          <Select
            label="Filter type"
            value={values.filterType}
            onChange={handlers.filterType}
            errorMessage={errors.filterType}
          >
            <SelectOption label="Include (Exact)" value="include" />
            <SelectOption label="Exclude (Exact)" value="exclude" />
            <SelectOption label="Include (Fuzzy)" value="include_fuzzy" />
            <SelectOption label="Exclude (Fuzzy)" value="exclude_fuzzy" />
          </Select>
        </Column>
        <TestSourcePropertyValueSelect
          label="Filter Value(s)"
          createItem={echoValue}
          testSource={testSource}
          propertyName={values.propertyName}
          value={values.value}
          onChange={handlers.value}
          errorMessage={errors.value}
          validationState={states.value}
        />
      </Row>
      <Button
        type="icon"
        size="small"
        onClick={onRemove}
        disabled={onRemove === undefined}
      >
        <Icon tokens={closeTokens}>delete</Icon>
      </Button>
    </Row>
  );
};

import { useHttp } from "@/client/HttpContext";
import { AnnotationTemplateField, DefaultAnnotationTemplateField, DefaultAnnotationTemplateId } from "@/types";
import { HttpClient } from "@/client/types";
import { useQuery, UseQueryResult } from "react-query";
import { useToaster, CoreToasterContext } from '@amzn/dots-core-ui';

const getTemplate = async (
  http: HttpClient,
  toaster: CoreToasterContext,
  templateId?: string,
): Promise<AnnotationTemplateField> => {
  if ( templateId === undefined ) {
    toaster.openToast({
      title: 'No Template defined',
	    message: 'No template defined fetching default annotation template.',
	    type: 'warning'
	  });
	  const templateResponse = await http.get(`annotation/template/${DefaultAnnotationTemplateId}`);
	  if ( templateResponse.status.code !== 200 ) {
	    toaster.openToast({
	      title: 'Fetch Template Failed',
	      message: 'Failed call to fetch default template. Returning hardcoded version.',
	      type: 'error'
	    });
	    return DefaultAnnotationTemplateField;
	  }
	  return templateResponse.data;
	} else {
	  const templateResponse = await http.get(`annotation/template/${templateId}`);
      if ( templateResponse.status.code !== 200 ) {
	    toaster.openToast({
	      title: 'Fetch Template Failed',
	      message: 'Failed call to fetch template. Returning hardcoded default template',
	      type: 'error'
	    });
	    return DefaultAnnotationTemplateField;
	  }
	  return templateResponse.data;
	}
};

export const useAnnotationTemplate = (templateId?: string): UseQueryResult<
  AnnotationTemplateField,
  Error
> => {
  const { http } = useHttp();
  const toaster = useToaster();
  return useQuery({
    queryKey: [
      'annotationTemplate',
      { templateId },
    ],
	  queryFn: () =>
	    getTemplate(
	      http,
	      toaster,
	      templateId,
	    ),
	  });
};

import React from 'react';
import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Select, { SelectOption } from '@amzn/meridian/select';
import {
  CoreFilterableSelect,
  CoreFormFooter,
  CoreInput,
  CoreTextarea,
  useForm,
} from '@amzn/dots-core-ui';
import { Translator } from '@/features/Translators/types';

export type TranslatorEditFormProps = {
  translator: Translator;
  onCancel: () => void;
  onSubmit: (translator: Partial<Translator>) => Promise<void> | void;
};

export const TranslatorEditForm = ({
  translator,
  onCancel,
  onSubmit,
}: TranslatorEditFormProps): JSX.Element => {
  const {
    values,
    states,
    errors,
    handlers,
    submitState,
    handleSubmit,
    hasErrors,
    isSubmitting,
  } = useForm({
    description: {
      initialValue: translator.description ?? '',
    },
    source: {
      initialValue: translator.source ?? 'KATS',
    },
    owners: {
      initialValue: translator.owners ?? [],
      validate: (value: string[]) =>
        !Array.isArray(value) || value.length === 0
          ? 'At least one owner is required'
          : '',
    },
    type: {
      initialValue: translator.type ?? 'CT',
    },
  });
  return (
    <form
      onSubmit={handleSubmit((vs) => {
        return onSubmit({ ...translator, ...vs });
      })}
    >
      <Column spacing="500" maxWidth="100%" width={650} alignmentVertical="top">
        {submitState.status === 'error' && (
          <Alert type="error" title="Error!" size="large">
            {submitState.error?.message}
          </Alert>
        )}
        <CoreInput
          label="Name"
          value={translator.name}
          constraintText="Name is specified by the Translator's NAME class attribute"
          disabled
        />
        <CoreInput
          label="Version"
          value={translator.version}
          constraintText="Version is specified by the Translator's VERSION class attribute"
          disabled
        />
        <Select
          label="Type"
          value={values.type}
          onChange={handlers.type}
          errorMessage={errors.type}
          constraintText="Type can only be changed by the DOTS team"
          disabled
        >
          <SelectOption label="System Translator" value="ST" />
          <SelectOption label="Custom Translator" value="CT" />
        </Select>
        <Select
          label="Test Source"
          value={values.source}
          onChange={handlers.source}
          errorMessage={errors.source}
          disabled
        >
          <SelectOption label={translator.source} value={translator.source} />
        </Select>
        <CoreTextarea
          label="Description"
          value={values.description}
          onChange={handlers.description}
          errorMessage={errors.description}
          validationState={states.description}
        />
        <CoreFilterableSelect
          canAddItems
          label="Owners"
          items={translator.owners ?? []}
          value={values.owners}
          onChange={handlers.owners}
          errorMessage={errors.owners}
          validationState={states.owners}
        />
        <CoreFormFooter
          isConfirmDisabled={hasErrors || isSubmitting}
          isLoading={isSubmitting}
          onCancelClick={onCancel}
        />
      </Column>
    </form>
  );
};

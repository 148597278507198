import React, { useMemo } from 'react';
import { css } from 'emotion';
import { TableRow } from '@amzn/meridian/table';
import Row from '@amzn/meridian/row';
import {
  useDashboardTableState,
  useDashboardWizardTableData,
} from '@/features/Dashboards/hooks';
import { KpiTableWizardConfiguration } from '@/types';
import { DashboardSelectableTableCell } from '../../DashboardSelectableTableCell';
import { DashboardTableActionBar } from '../DashboardTableActionBar';
import { DashboardWizardTable } from '../DashboardWizardTable';
import { createWizardData } from './data';

export type DashboardKpiTableWizardProps = {
  configuration: KpiTableWizardConfiguration;
};

export const DashboardKpiTableWizard = ({
  configuration,
}: DashboardKpiTableWizardProps): JSX.Element => {
  const headerRowCount = useMemo(
    () => (configuration.display === 'Data item in rows' ? 1 : undefined),
    [configuration.display]
  );
  const headerColumnCount = useMemo(
    () => (configuration.display === 'Data item in rows' ? undefined : 1),
    [configuration.display]
  );
  const [tableState, tableStateDispatch] = useDashboardTableState();
  const { pagination, sortColumn, sortDirection } = tableState;
  const wizardData = useDashboardWizardTableData(
    configuration,
    tableState,
    createWizardData
  );
  const getCellContentStyle = (backgroundColor?: string) =>
    backgroundColor ? css({ color: '#ffffff' }) : undefined;
  return (
    <DashboardWizardTable
      spacing="small"
      showDividers
      headerRows={headerRowCount}
      headerColumns={headerColumnCount}
      sortColumn={sortColumn.toString()}
      sortDirection={sortDirection}
      onSort={(payload) =>
        tableStateDispatch({
          type: 'update-sort-state',
          payload: {
            sortColumn: Number(payload.sortColumn),
            sortDirection: payload.sortDirection,
          },
        })
      }
    >
      <DashboardTableActionBar
        dataLength={wizardData.rows.length}
        tableState={tableState}
        tableStateDispatch={tableStateDispatch}
        wizardData={wizardData}
      />
      {wizardData.header.length > 0 && (
        <TableRow>
          {wizardData.header.map(({ content, selectionContext }, index) => (
            <DashboardSelectableTableCell
              key={content}
              selectionContext={selectionContext}
              sortColumn={index.toString()}
              wizardId={configuration.id}
            >
              <Row minWidth="max-content">{content}</Row>
            </DashboardSelectableTableCell>
          ))}
        </TableRow>
      )}
      {wizardData.rows
        .slice(pagination.startIndex, pagination.endIndex)
        .map((row) => {
          return (
            <TableRow key={row.key}>
              {row.cells.map(
                ({ key, content, backgroundColor, selectionContext }) => (
                  <DashboardSelectableTableCell
                    key={key}
                    selectionContext={selectionContext}
                    backgroundColor={backgroundColor}
                    wizardId={configuration.id}
                  >
                    <Row minWidth="max-content">
                      <span className={getCellContentStyle(backgroundColor)}>
                        {content}
                      </span>
                    </Row>
                  </DashboardSelectableTableCell>
                )
              )}
            </TableRow>
          );
        })}
    </DashboardWizardTable>
  );
};

import React from 'react';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';

const terms = [
  {
    term: 'Dashboard',
    explanation:
      'Dashboard is a base unit in Boomerang, where users can pick test data and show them in table/graph.',
  },
  {
    term: 'Customer Translator',
    explanation:
      'Customer Translator aims to provide users with the functionality to design their own exclusive translators to retrieve/parse/ingest/view the data from original test resource log as a customized KPI rather than default KPIs provided by Boomerang.',
  },
];

export const Glossary = (): JSX.Element => {
  return (
    <Table showDividers={false} headerColumns={1} spacing="small">
      {terms &&
        terms.map(({ term, explanation }) => (
          <TableRow key={term}>
            <TableCell alignmentVertical="top" width="240px">
              {term}
            </TableCell>
            <TableCell>{explanation}</TableCell>
          </TableRow>
        ))}
    </Table>
  );
};

import React from 'react';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Link from '@amzn/meridian/link';
import { environment } from '@/environments';

export const ContactUs = (): JSX.Element => {
  return (
    <Row>
      <Text tag="ul">
        <Text tag="li">
          <Link
            href="https://w.amazon.com/bin/view/Devices/Tools/Onboarding/Boomerang#HContact"
            target="_blank"
          >
            Sign up
          </Link>{' '}
          for DOTS office hours.
        </Text>
        <Text tag="li">
          If you find a bug or have new feature request, please:
        </Text>
        <Text tag="ul">
          <Text tag="li">
            <Link
              href={`${environment.labcollabJiraInstanceUrl}/secure/CreateIssueDetails!init.jspa?pid=39803&priority=3&assignee=bireshwa&issuetype=1&components=109484`}
              target="_blank"
            >
              File a bug jira
            </Link>
          </Text>
          <Text tag="li">
            <Link
              href={`${environment.labcollabJiraInstanceUrl}/secure/CreateIssueDetails!init.jspa?pid=39803&priority=3&assignee=bireshwa&issuetype=13&components=109484`}
              target="_blank"
            >
              File a feature request
            </Link>
          </Text>
        </Text>
        <Text tag="li">Community forums:</Text>
        <Text tag="ul">
          <Text tag="li">
            Join our Slack channel{' '}
            <Link
              href="https://amzn-devices.slack.com/archives/C01RTNL16RF"
              target="_blank"
            >
              #dots-boomerang-discus
            </Link>
          </Text>
          <Text tag="li">
            Ask a question on sage with{' '}
            <Link
              href="https://sage.amazon.com/tags/pivot-boomerang"
              target="_blank"
            >
              <b>pivot-boomerang</b>
            </Link>{' '}
            tag.
          </Text>
        </Text>
        <Text tag="li">
          E-mail{' '}
          <Link href="mailto:coreos-tools@amazon.com">
            coreos-tools@amazon.com
          </Link>{' '}
          for more information.
        </Text>
      </Text>
    </Row>
  );
};

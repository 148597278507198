/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import { useQuery } from 'react-query';
import Column from '@amzn/meridian/column';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';
import { useHttp } from '@/client';
import { getUniqueId } from '@/features/Dashboards/helpers';
import { DashboardFilterAutoTranstlator } from '@/types';
import { DashboardFormSection } from '../DashboardFormSection';
import { DashboardFiltersFormControlProps, TranslatorFilter } from './types';
import { DashboardTranslatorFilterControl } from './DashboardTranslatorFilterControl';

export const DashboardTranslatorFilterCollectionControl = ({
  value,
  onChange,
}: DashboardFiltersFormControlProps<
  Partial<TranslatorFilter>[]
>): JSX.Element => {
  const { http } = useHttp();
  const { data: translators, isLoading } = useQuery({
    queryFn: async () => {
      const data = await http.get('ct');
      if (data.status.code === 200) {
        const currentTranslators = data.data.reduce(
          (
            result: Record<string, string[]>,
            current: { name: string; version: string }
          ) => {
            if (!Object.prototype.hasOwnProperty.call(result, current.name)) {
              // eslint-disable-next-line no-param-reassign
              result[current.name] = [];
            }
            result[current.name].push(current.version);
            return result;
          },
          { katsDefaultTrans: ['0.0'] } as Record<string, string[]>
        );
        value.forEach((translator) => {
          if (
            translator?.key &&
            !Object.prototype.hasOwnProperty.call(
              currentTranslators,
              translator.key
            )
          ) {
            currentTranslators[translator.key] = [translator.value];
          } else if (
            translator?.key &&
            translator?.value &&
            currentTranslators[translator.key].indexOf(translator.value) === -1
          ) {
            currentTranslators[translator.key].push(translator.value);
          }
        });
        return currentTranslators;
      }
      return {};
    },
    queryKey: ['translators'],
  });
  const onAddClick = useCallback(
    () => onChange(value.concat({ id: getUniqueId() })),
    [onChange, value]
  );
  const onUpdate = useCallback(
    (index: number) => (filter: DashboardFilterAutoTranstlator) => {
      const next = [...value];
      next[index] = filter;
      onChange(next);
    },
    [onChange, value]
  );
  const onRemove = useCallback(
    (index: number) => () => onChange(value.filter((_, i) => i !== index)),
    [onChange, value]
  );
  return (
    <DashboardFormSection
      title="Translator Filters"
      descriptionText="Filter data by specific translator name and version"
    >
      <Column>
        {value.map((filter, i) => (
          <DashboardTranslatorFilterControl
            key={filter.id}
            filter={filter}
            translators={translators}
            isLoading={isLoading}
            onChange={onUpdate(i)}
            onRemove={onRemove(i)}
          />
        ))}
      </Column>
      <Button type="primary" onClick={onAddClick}>
        <Icon tokens={plusTokens}>Add a new translator</Icon>
      </Button>
    </DashboardFormSection>
  );
};

import React, { useCallback, useContext, useMemo, useReducer } from 'react';
import { isPromise, useSafeDispatch } from '@amzn/dots-core-ui';
import { Dashboard } from '@/types';
import { layoutParser, reducer } from './helpers';
import { Action, State } from './types';

export type DashboardLayoutsFormStateContext = {
  state: State;
  dispatch: React.Dispatch<Action>;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
};

export const DashboardLayoutsFormStateContext = React.createContext<
  DashboardLayoutsFormStateContext | undefined
>(undefined);

export type DashboardLayoutsFormStateProviderProps = {
  dashboard?: Dashboard;
  onSubmit: (dashboard: Partial<Dashboard>) => Promise<void> | void;
  children: React.ReactNode;
};

export const DashboardLayoutsFormStateProvider = ({
  dashboard,
  onSubmit,
  children,
}: DashboardLayoutsFormStateProviderProps): JSX.Element => {
  const layouts = layoutParser.toFormModel(dashboard?.layouts);
  const [state, badDispatch] = useReducer(reducer, {
    editingLayoutIndex: dashboard?.layouts?.length !== 0 ? 0 : undefined,
    layouts,
    wizards: dashboard?.wizards ?? [],
    submitState: { status: 'idle' },
  });
  const dispatch = useSafeDispatch(badDispatch);
  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
      try {
        event.preventDefault();
        dispatch({ type: 'submition-start' });
        const maybePromise = onSubmit({
          ...dashboard,
          layouts: layoutParser.toConfigurationModel(state.layouts),
          wizards: state.wizards,
        });
        if (isPromise(maybePromise)) {
          maybePromise
            .then(() => dispatch({ type: 'submition-end' }))
            .catch((error) =>
              dispatch({ type: 'submition-failure', payload: error })
            );
        } else {
          dispatch({ type: 'submition-end' });
        }
      } catch (error) {
        dispatch({ type: 'submition-failure', payload: error as Error });
      }
    },
    [dashboard, dispatch, onSubmit, state.layouts, state.wizards]
  );
  const value = useMemo(
    () => ({
      state,
      dispatch,
      handleSubmit,
    }),
    [dispatch, handleSubmit, state]
  );
  return (
    <DashboardLayoutsFormStateContext.Provider value={value}>
      {children}
    </DashboardLayoutsFormStateContext.Provider>
  );
};

export const useDashboardLayoutsFormState = (): DashboardLayoutsFormStateContext => {
  const context = useContext(DashboardLayoutsFormStateContext);
  if (context === undefined) {
    throw new Error(
      'useDashboardLayoutsFormState must be used within a DashboardLayoutsFormStateProvider'
    );
  }
  return context;
};

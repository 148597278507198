import React from 'react';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Tooltip from '@amzn/meridian/tooltip';
import ChevronUpTokens from '@amzn/meridian-tokens/base/icon/chevron-up-small';
import ChevronDownTokens from '@amzn/meridian-tokens/base/icon/chevron-down-small';
import ViewTokens from '@amzn/meridian-tokens/base/icon/view';
import TrashTokens from '@amzn/meridian-tokens/base/icon/trash';
import { CoreInput, isNullOrWhitespace } from '@amzn/dots-core-ui';
import { useDashboardLayoutsFormState } from './DashboardLayoutsFormStateContext';
import { Layout } from './types';

export type DashboardLayoutControlProps = {
  layoutIndex: number;
  layout: Layout;
};

export const DashboardLayoutControl = ({
  layoutIndex,
  layout,
}: DashboardLayoutControlProps): JSX.Element => {
  const {
    dispatch,
    state: { layouts, editingLayoutIndex },
  } = useDashboardLayoutsFormState();
  const onPreview = () =>
    dispatch({
      type: 'layout-preview',
      payload: { layoutIndex },
    });
  const onChange = (value: string) =>
    dispatch({
      type: 'layout-update',
      payload: {
        layoutId: layout.id,
        layout: { ...layout, name: value },
      },
    });
  const onMoveDown =
    layoutIndex !== layouts.length - 1
      ? () =>
          dispatch({
            type: 'layout-move',
            payload: { fromIndex: layoutIndex, toIndex: layoutIndex + 1 },
          })
      : undefined;
  const onMoveUp =
    layoutIndex !== 0
      ? () =>
          dispatch({
            type: 'layout-move',
            payload: { fromIndex: layoutIndex, toIndex: layoutIndex - 1 },
          })
      : undefined;
  const onRemove = () =>
    dispatch({
      type: 'layout-remove',
      payload: { layoutIndex },
    });
  return (
    <Row widths={['fit', 'fill', 'fit']} alignmentVertical="center">
      <Tooltip position="bottom" title="Preview this tab">
        <Button
          onClick={onPreview}
          type={layoutIndex === editingLayoutIndex ? 'primary' : 'icon'}
        >
          <Icon tokens={ViewTokens}>Preview the tab</Icon>
        </Button>
      </Tooltip>
      <CoreInput
        label="Tab name"
        value={layout.name}
        onChange={onChange}
        errorMessage={
          isNullOrWhitespace(layout.name) ? 'Tab name is required' : ''
        }
      />
      <Row alignmentHorizontal="right" spacing="100">
        <Button
          onClick={onMoveUp}
          disabled={onMoveUp === undefined}
          type="icon"
        >
          <Icon tokens={ChevronUpTokens}>Up</Icon>
        </Button>
        <Button
          onClick={onMoveDown}
          disabled={onMoveDown === undefined}
          type="icon"
        >
          <Icon tokens={ChevronDownTokens}>Down</Icon>
        </Button>
        <Button onClick={onRemove} type="icon">
          <Icon tokens={TrashTokens}>Remove</Icon>
        </Button>
      </Row>
    </Row>
  );
};

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Expander from '@amzn/meridian/expander';
import { ExpanderProps } from '@amzn/meridian/expander/expander';

export const ControlledExpander = ({
  openDefault = true,
  ...props
}: Omit<ExpanderProps, 'open' | 'onChange'> & {
  openDefault?: boolean;
}): JSX.Element => {
  const [open, setOpen] = useState(openDefault);
  return <Expander open={open} onChange={setOpen} {...props} />;
};

export default ControlledExpander;

import React, { useCallback, useMemo } from 'react';
import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Sheet from '@amzn/meridian/sheet';
import { ErrorBoundary } from 'react-error-boundary';
import { CorePageLoader } from '@amzn/dots-core-ui';
import { ErrorBoundaryFallback, PageError } from '@/components';
import { useDashboard } from '@/features/Dashboards/contexts/DashboardContext';
import { convertToMeridianGridValue } from '@/features/Dashboards/helpers';
import { DashboardSelectionProvider } from '@/features/Dashboards/contexts';
import { useDashboardQueryParameters } from '@/hooks';
import { useDashboardKpis } from '@/features/Dashboards/hooks';
import {
  DashboardAnnotationView,
  useDashboardAnnotationViewContext,
} from './DashboardAnnotations';
import { DashboardWizard } from './DashboardWizard';
import { DashboardExactTimeRange } from '@/types';

export const DashboardWizardLayout = (): JSX.Element => {
  const {
    params: { tab, timeRange},
  } = useDashboardQueryParameters();
  const { isViewOpen, closeView } = useDashboardAnnotationViewContext();
  const { isLoading, error, data } = useDashboardKpis();
  const {
    dashboard: { filters, layouts, wizards },
  } = useDashboard();
  const layoutIndex = useMemo(
    () => layouts.findIndex((l) => l.name === tab) ?? 0,
    [layouts, tab]
  ); 

  const archivedDataAlert = (chilrden:JSX.Element): JSX.Element => {
    const filterParamExists = timeRange != undefined;
    const exactRangeExists = filterParamExists && Array.isArray(timeRange);
    let exactRangeArchived = false;
    if (exactRangeExists) {
      const exactRange = timeRange as DashboardExactTimeRange;
      exactRangeArchived = exactRange[0].substring(0, 4) != "2022" && exactRange[0].substring(0, 4) != "2023"
    }
    return <div>
      <div style={{padding: "10px"}}>
        {(exactRangeExists && exactRangeArchived)
        || 
        (!filterParamExists
        && (Array.isArray(filters.data_range.time.range) 
        && filters.data_range.time.range[0].substring(0, 4) != "2023"
        && filters.data_range.time.range[0].substring(0, 4) != "2024")) ?
        <Alert type="error">
        <p>
            Not all data is visible because the date range filter begins before January 1st, 2023.
            To learn more about our archival policy please see 
            <a href="https://wiki.labcollab.net/confluence/display/DOTS/Boomerang+Archival+Policy"> Boomerang Archival Policy</a>
          </p>
        </Alert>
        :<div/>}
      </div>
      {chilrden}
    </div>
  }
  const getWizardConfiguration = useCallback(
    (sectionIndex: number, position: number) =>
      wizards.find(
        (w) =>
          w.tabIndex === layoutIndex &&
          w.sectionIndex === sectionIndex &&
          w.position === position
      ),
    [layoutIndex, wizards]
  );
  if (isLoading || layoutIndex === -1) {
    return archivedDataAlert(<CorePageLoader />);
  }
  if (data && data.baseData.length < 2) {
    return archivedDataAlert(
      <Alert type="warning" size="xlarge">
        No data found using the current filters
      </Alert>
    );
  }
  if (error) {
    return <PageError message="Failed to load Kpis" error={error} />;
  }
  return archivedDataAlert(
    <ErrorBoundary
      fallbackRender={({ error: boundaryError, resetErrorBoundary }) => (
        <ErrorBoundaryFallback
          error={boundaryError}
          resetErrorBoundary={resetErrorBoundary}
          message="An error occured rendering the current tab"
        />
      )}
    >
      <DashboardSelectionProvider wizards={wizards}>
        <Column alignmentHorizontal="center" width="100%">
          {layouts[layoutIndex].layout.map(
            ({ heights, widths }, sectionIndex) => {
              const correctedHeights = convertToMeridianGridValue(
                'height',
                heights
              );
              const correctedWidths = convertToMeridianGridValue(
                'width',
                widths
              );
              return (
                <Column
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${sectionIndex}`}
                  spacing="400"
                  width="100%"
                >
                  {correctedHeights &&
                    correctedHeights.map((height, rowIndex) => (
                      <Row
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${sectionIndex}-${rowIndex}-${height}`}
                        alignmentVertical="top"
                        height={height}
                        widths={correctedWidths}
                        width="100%"
                      >
                        {correctedWidths &&
                          correctedWidths.map((_, columnIndex) => {
                            const configuration = getWizardConfiguration(
                              sectionIndex,
                              rowIndex * widths.length + columnIndex
                            );
                            return (
                              configuration && (
                                <DashboardWizard
                                  key={configuration.id}
                                  configuration={configuration}
                                />
                              )
                            );
                          })}
                      </Row>
                    ))}
                </Column>
              );
            }
          )}
          <Sheet
            type="overlay"
            side="right"
            open={isViewOpen}
            onClose={closeView}
          >
            <DashboardAnnotationView />
          </Sheet>
        </Column>
      </DashboardSelectionProvider>
    </ErrorBoundary>
  );
};

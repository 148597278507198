import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useHttp, HttpClient } from '@/client';
import { Jira } from '@/types';

export type TagJiraArgs = {
  jira: string;
  buildVersion: string;
  buildProject: string;
  deviceType: string;
  kpiBucketLink: string;
  kpiName: string;
  goal: string;
  taggedValue: string;
  testsuiteName: string;
  testrunLinks: string[];
};

export type TagProperties = Omit<TagJiraArgs, 'jira'>;

export const tagJira = async (
  http: HttpClient,
  args: TagJiraArgs
): Promise<Jira> => {
  const { data, status } = await http.post('jira/tag', args);
  if (status.code === 200) {
    return data;
  }
  throw new Error(data.message);
};

export const useJiraTag = (): UseMutationResult<Jira, Error, TagJiraArgs> => {
  const { http } = useHttp();
  const queryClient = useQueryClient();
  return useMutation((args: TagJiraArgs) => tagJira(http, args), {
    onSuccess: () => queryClient.invalidateQueries(['jiras']),
  });
};

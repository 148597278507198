/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  CoreFilterableSelect,
  CoreFilterableSelectProps,
  ValidationProps,
} from '@amzn/dots-core-ui';
import { useDashboards } from '@/hooks';

export type DashboardSelectProps = Pick<
  ValidationProps<CoreFilterableSelectProps>,
  'value' | 'onChange' | 'errorMessage' | 'validationState'
>;

export const DashboardSelect = ({
  value: propsValue,
  onChange: propsOnChange,
  ...props
}: DashboardSelectProps): JSX.Element => {
  const { data, isLoading } = useDashboards();
  const dashboards = data ?? [];
  const value = Array.isArray(propsValue)
    ? propsValue
        .map((v) => dashboards.find((d) => d.id === v)?.name)
        .filter((v): v is string => v !== undefined)
    : dashboards.find((d) => d.id === propsValue)?.name ?? '';
  const onChange = (nextValue: string | string[]) =>
    Array.isArray(nextValue)
      ? propsOnChange(
          nextValue
            .map((name) => dashboards.find((d) => d.name === name)?.id)
            .filter((v): v is string => v !== undefined)
        )
      : propsOnChange(dashboards.find((d) => d.name === nextValue)?.id ?? '');
  return (
    <CoreFilterableSelect
      {...props}
      value={value}
      onChange={onChange}
      items={dashboards?.map((d) => d.name) ?? []}
      placeholder="Search for a dashboard..."
      disabled={isLoading}
    />
  );
};

import React from 'react';
import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';

export type ErrorBoundaryFallbackProps = {
  message: string;
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
};

export const ErrorBoundaryFallback = ({
  message,
  error,
}: ErrorBoundaryFallbackProps): JSX.Element => (
  <Column alignmentHorizontal="center" alignmentVertical="center">
    <Alert type="error" size="large">
      {message}: {error?.message}
    </Alert>
  </Column>
);

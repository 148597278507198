import { useMemo } from 'react';
import { collator } from '@/helpers';
import { DashboardWizardBase } from '@/types';
import {
  WizardState,
  WizardTableData,
  TableState,
  WizardTableRow,
  WizardTableCell,
} from '../types';
import { useDashboardWizardData } from './useDashboardWizardData';

export const useDashboardFilteredWizardData = <
  TCell extends WizardTableCell = WizardTableCell,
  TRow extends WizardTableRow<TCell> = WizardTableRow<TCell>
>(
  wizardData: WizardTableData<TCell, TRow>,
  { searchQuery, sortColumn, sortDirection }: TableState
): TRow[] => {
  const filteredData = useMemo(() => {
    let { rows } = wizardData;
    rows = searchQuery
      ? rows.filter((row) =>
          row.cells.some((cell) => cell.content.includes(searchQuery))
        )
      : rows;
    if (wizardData.header.length > 0) {
      rows.sort((a, b) => {
        const result = collator.compare(
          a.cells[sortColumn].content,
          b.cells[sortColumn].content
        );
        return sortDirection === 'ascending' ? result : result * -1;
      });
    }

    return rows;
  }, [searchQuery, sortColumn, sortDirection, wizardData]);
  return filteredData;
};

export const useDashboardWizardTableData = <
  TConfiguration extends DashboardWizardBase,
  TCell extends WizardTableCell = WizardTableCell,
  TRow extends WizardTableRow<TCell> = WizardTableRow<TCell>
>(
  configuration: TConfiguration,
  tableState: TableState,
  createWizardData: (
    state: WizardState<TConfiguration>
  ) => WizardTableData<TCell, TRow>
): WizardTableData<TCell, TRow> => {
  const wizardData = useDashboardWizardData(configuration, createWizardData);
  const result =
    wizardData === undefined ? { header: [], rows: [] } : wizardData;
  const rows = useDashboardFilteredWizardData(result, tableState);
  return { header: result.header, rows };
};

import { useQuery, UseQueryResult } from 'react-query';
import { useHttp, HttpClient } from '@/client';
import { UserProfile } from '@/types';

const getUserProfile = async (http: HttpClient): Promise<UserProfile> => {
  const { data, status } = await http.get('user');
  if (status.code === 200) {
    return data;
  }
  throw new Error(data.message);
};

export const useUserProfile = (): UseQueryResult<UserProfile, Error> => {
  const { http } = useHttp();
  return useQuery({
    queryFn: () => getUserProfile(http),
    queryKey: ['user'],
  });
};

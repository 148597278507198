import React from 'react';
import Text from '@amzn/meridian/text';
import Theme from '@amzn/meridian/theme';
import Icon from '@amzn/meridian/icon';
import Tooltip from '@amzn/meridian/tooltip';
import trashIconTokens from '@amzn/meridian-tokens/base/icon/trash';
import orangeLightTokens from '@amzn/meridian-tokens/theme/orange-light';
import { CoreAsyncButton, useToaster } from '@amzn/dots-core-ui';
import { useDashboardDelete } from '@/hooks';
import { DashboardButtonProps } from './types';

export const DashboardDeleteButton = ({
  id,
  name,
  type,
}: DashboardButtonProps): JSX.Element => {
  const { openToast } = useToaster();
  const { mutateAsync, isLoading } = useDashboardDelete();
  const onClick = async () => {
    try {
      await mutateAsync(id);
      openToast({
        message: `Successfully removed ${name}!`,
        type: 'success',
      });
    } catch (error) {
      openToast({
        message: `Failed to remove ${name}, please try again later`,
        type: 'error',
      });
    }
  };
  return (
    <Theme tokens={orangeLightTokens}>
      <Tooltip title="remove" position="bottom">
        <CoreAsyncButton
          label="remove dashboard"
          isLoading={isLoading}
          onClick={onClick}
          size="small"
          type={type}
          confirm={{
            title: `Remove ${name}`,
            children: (
              <Text>
                Are you sure you want to remove <strong>{name}</strong>?
              </Text>
            ),
          }}
        >
          <Icon tokens={trashIconTokens} />
          {type !== 'icon' && <span>Remove</span>}
        </CoreAsyncButton>
      </Tooltip>
    </Theme>
  );
};

export const toUtcTime = (rawTimeToSend: string): number => {
  const currentOffset = new Date().getTimezoneOffset();
  const hourToSend = Number(rawTimeToSend.split(':')[0]);
  let utcTimeToSend = hourToSend + Math.floor(currentOffset / 60);
  if (utcTimeToSend > 23) {
    utcTimeToSend -= 24;
  } else if (utcTimeToSend < 0) {
    utcTimeToSend += 24;
  }
  return utcTimeToSend;
};

export const toLocalTime = (hourToSend: number): string => {
  const currentOffset = new Date().getTimezoneOffset();
  let localTimeToSend = hourToSend - Math.floor(currentOffset / 60);
  if (localTimeToSend > 23) {
    localTimeToSend -= 24;
  } else if (localTimeToSend < 0) {
    localTimeToSend += 24;
  }
  if (localTimeToSend < 10) {
    return `0${localTimeToSend}:00`;
  }
  return `${localTimeToSend}:00`;
};

import { useQuery, UseQueryResult } from 'react-query';
import { HttpClient, useHttp } from '@/client';
import { MonthlyTestCount } from '@/types';

export type GetReportArgs = {
  reportRequestId: string;
};

const getDashboardReport = async (
  http: HttpClient,
  reportRequestId: string
): Promise<MonthlyTestCount[]> => {
  const params = { report_request_id: reportRequestId };
  const { data, status } = await http.get(`report/${reportRequestId}`, params);
  if (status.code === 200) {
    return data;
  }
  throw new Error(data.message);
};

export const useGetDashboardReport = ({
  reportRequestId,
}: GetReportArgs): UseQueryResult<MonthlyTestCount[], Error> => {
  const { http } = useHttp();
  return useQuery<MonthlyTestCount[], Error>({
    queryFn: () => getDashboardReport(http, reportRequestId),
    queryKey: ['dashboardReportGet', { reportRequestId }],
  });
};

import { HttpClient } from '@/client';
import { keys } from '@amzn/dots-core-ui';
import {
  Translator,
  TranslatorException,
  TranslatorExceptionFilter,
  TranslatorFileDescrptor,
  TranslatorFilters,
  TranslatorGetTestResultRequest,
  TranslatorGetTestResultResponse,
  TranslatorId,
  TranslatorTestRequest,
  TranslatorTestResponse,
  TranslatorUploadResult,
} from './types';

export const translatorClient = {
  getTranslators: async (
    http: HttpClient,
    filter?: TranslatorFilters
  ): Promise<Translator[]> => {
    const { data, status } = await http.get('ct', filter);
    if (status.code === 200) {
      return data;
    }
    throw new Error('Failed to get all Translators');
  },
  getTranslator: async (
    http: HttpClient,
    id: TranslatorId
  ): Promise<Translator> => {
    const { data, status } = await http.get('ct', { ...id, latest: true });
    if (status.code === 200 && Array.isArray(data) && data.length > 0) {
      return data[0];
    }
    throw new Error(`Failed to get translator ${id.name}: ${id.version}`);
  },
  updateTranslator: async (
    http: HttpClient,
    translator: Partial<Translator>
  ): Promise<Partial<Translator>> => {
    const { data, status } = await http.post('ct', translator);
    if (status.code === 200 && data.result) {
      return translator;
    }
    throw new Error('Failed to update translator');
  },
  uploadTranslator: async (
    http: HttpClient,
    file: File
  ): Promise<TranslatorUploadResult> => {
    const formData = new FormData();
    formData.append(file.name, file);
    const { data, status } = await http.post(
      'ct/ctv',
      formData,
      'application/py'
    );
    if (status.code === 200) {
      return {
        result: data.ct_meta,
        errors: [...data.log_err, ...data.sty_err, ...data.unit_test],
      };
    }
    throw new Error(`Failed to verify ${file.name}`);
  },
  commitTranslator: async (
    http: HttpClient,
    fileDescriptor: TranslatorFileDescrptor
  ): Promise<void> => {
    const { data, status } = await http.put('ct', fileDescriptor);
    if (status.code === 200 || status.code === 204) {
      return data;
    }
    throw new Error(
      `Failed to upload ${fileDescriptor.name} v${fileDescriptor.version}`
    );
  },
  getTranslatorExceptions: async (
    http: HttpClient,
    filter: TranslatorExceptionFilter
  ): Promise<TranslatorException[]> => {
    const { data, status } = await http.get('ct/exception', filter);
    if (status.code === 200) {
      return data;
    }
    throw new Error(
      `Failed to get exceptions for ${filter.name} v${filter.version} in testrun ID ${filter.id}`
    );
  },
  testTanslator: async (
    http: HttpClient,
    request: TranslatorTestRequest
  ): Promise<TranslatorTestResponse> => {
    const { data, status } = await http.post('ct/test', request);
    if (status.code === 200) {
      const pendingTestrunIds = keys(data.query_codes);
      return request.ids.map((testrunId) => {
        const isTestPending = pendingTestrunIds.includes(testrunId);
        return {
          testrunId,
          status: isTestPending ? 'pending' : 'rejected',
          queryCode: isTestPending ? data.query_codes[testrunId] : '',
        };
      });
    }
    throw new Error(`Failed test ${request.name} v${request.version}`);
  },
  getTranslatorTestResult: async (
    http: HttpClient,
    request: TranslatorGetTestResultRequest
  ): Promise<TranslatorGetTestResultResponse> => {
    const { data, status } = await http.post('ct/test/result', request);
    if (status.code === 200) {
      return data;
    }
    throw new Error(
      `Failed to get test results for ${request.name} v${request.version}`
    );
  },
};

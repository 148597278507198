/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button/button';
import { useDashboardQueryParameters } from '@/hooks';
import { useDashboardFilters } from '@/features/Dashboards/hooks';

export const DashboardFilters = (): JSX.Element => {
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    params: { filterType, filterValues, groupTab, ...otherParams },
    serializeUiQueryParameters,
  } = useDashboardQueryParameters();
  const {
    areFiltersProvided,
    areFiltersRequired,
    filters,
    isFilterActive,
    isValidFilterAvailable,
  } = useDashboardFilters();
  useEffect(() => {
    if (
      areFiltersRequired &&
      (!areFiltersProvided || !isValidFilterAvailable)
    ) {
      const [filter] = filters;
      const nextParams =
        'field' in filter
          ? {
              filterType: filter.field,
              filterValues: filter.value,
            }
          : { groupTab: filter.value };
      history.replace({
        pathname,
        search: `?${serializeUiQueryParameters({
          ...otherParams,
          ...nextParams,
        })}`,
      });
    }
  }, [
    filterType,
    filterValues,
    filters,
    areFiltersProvided,
    areFiltersRequired,
    groupTab,
    history,
    otherParams,
    pathname,
    serializeUiQueryParameters,
    isValidFilterAvailable,
  ]);
  return (
    <Row spacing="300" wrap="down">
      {filters.map((filter) => (
        <Button
          key={filter.value}
          type={isFilterActive(filter) ? 'primary' : 'secondary'}
          data-test-isselected={isFilterActive(filter)}
          onClick={() => {
            const nextParams =
              'field' in filter
                ? {
                    filterType: filter.field,
                    filterValues: filter.value,
                  }
                : { groupTab: filter.value };
            history.push({
              pathname,
              search: `?${serializeUiQueryParameters({
                ...otherParams,
                ...nextParams,
              })}`,
            });
          }}
        >
          {filter.value}
        </Button>
      ))}
    </Row>
  );
};

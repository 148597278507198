export const jiraFields = [
  { name: 'Jira ID', data: 'key' },
  { name: 'Created', data: 'created' },
  { name: 'Last Activity', data: 'updated' },
  { name: 'Priority', data: 'priority' },
  { name: 'Status', data: 'status' },
  { name: 'Assignee', data: 'assignee' },
  { name: 'Summary', data: 'summary' },
];

export const meridianColors = [
  '#232F3E',
  '#FFFFFF',
  '#F9FAFA',
  '#F0F1F2',
  '#E7E9E9',
  '#BBC0C1',
  '#949C9C',
  '#6C7778',
  '#485050',
  '#252829',
  '#0B0C0C',
  '#ECEDED',
  '#ABB0B5',
  '#5B5F5F',
  '#323838',
  '#E5F6FB',
  '#B9E8F7',
  '#7CCEE9',
  '#47C2EC',
  '#00A8E1',
  '#077398',
  '#055D7B',
  '#03384B',
  '#01222D',
  '#C1DEE8',
  '#90C5D7',
  '#86A4AF',
  '#204B5A',
  '#24586A',
  '#14181D',
  '#FFF3F0',
  '#FFDAD1',
  '#FFA28A',
  '#FF714D',
  '#FF6138',
  '#CB330B',
  '#932306',
  '#4F1203',
  '#310B02',
  '#F5CCC2',
  '#EFAD9C',
  '#BE968C',
  '#99351B',
  '#8A3823',
  '#55190A',
  '#E6F9FB',
  '#B5EBF3',
  '#6ED9EA',
  '#2DD4ED',
  '#0AABC4',
  '#067586',
  '#045C69',
  '#023138',
  '#01171B',
  '#C0DFE5',
  '#8AC6CF',
  '#8AA6AD',
  '#164D55',
  '#185862',
  '#13181C',
  '#DCF3A0',
  '#C3EB5E',
  '#ADE422',
  '#98C91E',
  '#F9D5E2',
  '#FF7494',
  '#CC0C39',
  '#AD0A30',
  '#FEF2B8',
  '#FCDE4D',
  '#FAD000',
  '#DCB700',
  '#FFBBD3',
  '#FF7DAB',
  '#FF5A94',
  '#E31F64',
  '#E9C6E4',
  '#E989DA',
  '#EB64D7',
  '#8C297D',
  '#BDE8E4',
  '#8AE2D9',
  '#36C2B4',
  '#12857A',
  '#FFD094',
  '#FFB352',
  '#FF8F00',
  '#DA7F0B',
];

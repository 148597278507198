import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { DashboardEmailReportConfiguration } from '@/types';
import { useHttp, HttpClient } from '@/client';
import { useDashboard } from './useDashboard';

export const createDashboardEmailReportConfiguration = async (
  http: HttpClient,
  reportConfiguration: DashboardEmailReportConfiguration
): Promise<DashboardEmailReportConfiguration> => {
  const { data, status } = await http.post(
    `dashboard/email_report`,
    reportConfiguration
  );
  if (status.code === 200) {
    return data;
  }
  throw new Error(data.message);
};

export const useDashboardEmailReportCreate = (): UseMutationResult<
  DashboardEmailReportConfiguration,
  Error,
  DashboardEmailReportConfiguration
> => {
  const { http } = useHttp();
  const queryClient = useQueryClient();
  const { dashboardName: rawName } = useParams<{
    dashboardName: string;
  }>();
  const dashboardName = useMemo(() => decodeURIComponent(rawName), [rawName]);
  const dashboardResult = useDashboard({
    dashboardId: dashboardName,
  });
  return useMutation(
    (reportConfiguration: DashboardEmailReportConfiguration) =>
      createDashboardEmailReportConfiguration(http, {
        ...reportConfiguration,
        dashboard_id: dashboardResult.data?.id ?? '',
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries([
          'dashboardEmailReports',
          { dashboardName },
        ]);
      },
    }
  );
};

import { useQuery, UseQueryResult } from 'react-query';
import { keys } from '@amzn/dots-core-ui';
import { useHttp, HttpClient } from '@/client';
import { Dashboard } from '@/types';

export type DashboardListItem = Pick<
  Dashboard,
  'id' | 'description' | 'owners' | 'jiraProject' | 'name'
>;

const getAllDashboards = async (
  http: HttpClient
): Promise<DashboardListItem[]> => {
  const { data, status } = await http.get('dashboards');
  if (status.code === 200) {
    return (
      keys(data)
        // who knows why but there is a fake dashboard that should always be
        // filtered out
        .filter((id) => id !== 'unique_id')
        .map((id) => ({
          id,
          name: data[id].name,
          owners: data[id].owners,
          jiraProject: data[id].jira_project,
          description: data[id].description,
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }
  throw new Error('Failed to get all dashboards');
};

export const useDashboards = (): UseQueryResult<DashboardListItem[], Error> => {
  const { http } = useHttp();
  return useQuery({
    queryKey: ['all-dashboards'],
    queryFn: () => getAllDashboards(http),
  });
};

import { parseExpression } from '@/features/Dashboards/aggregation';
import { findMatchingGoal } from '@/features/Dashboards/goals';
import { calculateExpressions, groupKpis } from '@/features/Dashboards/helpers';
import {
  GlobalCalculatedData,
  KpiGroup,
  WizardState,
  WizardTableCell,
  WizardTableData,
  WizardTableRow,
} from '@/features/Dashboards/types';
import { WizardSelection } from '@/features/Dashboards/contexts';
import {
  BaseData,
  BaseDataPropertyIndexMap,
  Dashboard,
  KpiTableWizardConfiguration,
} from '@/types';

export interface KpiTableCell extends WizardTableCell {
  selectionContext: WizardSelection;
}

type ColumnDefinitionCompileParameters = {
  kpiGroup: KpiGroup;
  groupBaseData: BaseData;
};

type ColumnDefinition = {
  compile: (params: ColumnDefinitionCompileParameters) => KpiTableCell;
  name: string;
};

const kpiColumnCompileFactory = (
  dashboard: Dashboard,
  propertyIndexMap: BaseDataPropertyIndexMap,
  globalCalculatedData: GlobalCalculatedData,
  kpiName: string
) => ({
  groupBaseData,
  kpiGroup,
}: ColumnDefinitionCompileParameters): KpiTableCell => {
  const [header, ...kpis] = groupBaseData;
  const filteredKpis = kpis.filter(
    (kpi) => kpi[propertyIndexMap['kpi.name']].toString() === kpiName
  );
  const kpiBaseData: BaseData = [header, ...filteredKpis];
  // the JIRA cells require testrun and KPI Ids
  // const { kpiIds, testrunIds } = getIds(kpiBaseData, propertyIndexMap);
  // the result and goal columns rely on computing a goal against the
  // KPI group current
  const { goal, value, color } = findMatchingGoal(
    dashboard.filters?.goal_groups ?? [],
    propertyIndexMap,
    kpiBaseData,
    // Reducing by 1 because first row is the header
    { ...globalCalculatedData, _kpiCount: kpiBaseData.length - 1 }
  );
  return {
    key: `${kpiName}-${kpiGroup.groupKey}`,
    content: value ?? goal,
    backgroundColor: color,
    selectionContext: [
      { ...kpiGroup.groupedPropertyValues, 'kpi.name': kpiName },
    ],
  };
};

const getColumnDefinitions = (
  dashboard: Dashboard,
  configuration: KpiTableWizardConfiguration,
  propertyIndexMap: BaseDataPropertyIndexMap,
  globalCalculatedData: GlobalCalculatedData,
  kpiNames: string[]
): ColumnDefinition[] => {
  const {
    attrs: userDefinedProperties,
    extras: userDefinedColumns,
    data: userDefinedVariables,
  } = configuration;
  // we should avoid parsing these expressions more than once, so we will close
  // over the parsed expressions here
  const userDefinedExpressions = userDefinedVariables.map(
    ({ name, expression }) => ({
      variableName: name,
      expression: parseExpression(expression),
    })
  );
  return [
    ...userDefinedProperties.map(
      ({ name, data }): ColumnDefinition => ({
        name,
        compile: ({ kpiGroup }): KpiTableCell => ({
          key: `${name}-${kpiGroup.groupKey}`,
          content: kpiGroup.groupedPropertyValues[data],
          selectionContext: [kpiGroup.groupedPropertyValues],
        }),
      })
    ),
    ...userDefinedColumns.map(
      ({ name, format }): ColumnDefinition => ({
        name,
        compile: ({ groupBaseData, kpiGroup }) => {
          const { content } = calculateExpressions(
            format,
            userDefinedExpressions,
            groupBaseData
          );
          return {
            key: `${name}-${kpiGroup.groupKey}`,
            content,
            selectionContext: [kpiGroup.groupedPropertyValues],
          };
        },
      })
    ),
    ...kpiNames.map((kpiName) => ({
      name: kpiName,
      compile: kpiColumnCompileFactory(
        dashboard,
        propertyIndexMap,
        globalCalculatedData,
        kpiName
      ),
    })),
  ];
};

/**
 * Create a selector function that produces a single page of data to be rendered
 * by a base table wizard
 */
export const createWizardData = ({
  configuration,
  data: { baseData, propertyIndexMap },
  dashboard,
  globalCalculatedData,
}: WizardState<KpiTableWizardConfiguration>): WizardTableData<KpiTableCell> => {
  const groupKeys = configuration.attrs.map(
    ({ data: propertyName }) => propertyName
  );
  const [baseDataHeader, ...kpis] = baseData;
  // our rows are based on groups of KPIs, lets create those groups now
  const kpiGroups = groupKpis(propertyIndexMap, baseData, groupKeys);
  const kpiNames = Array.from(
    kpis.reduce(
      (set, kpi) => set.add(kpi[propertyIndexMap['kpi.name']].toString()),
      new Set<string>()
    )
  ).sort();
  const columnDefinitions = getColumnDefinitions(
    dashboard,
    configuration,
    propertyIndexMap,
    globalCalculatedData,
    kpiNames
  );
  const rows = kpiGroups.map(
    (kpiGroup): WizardTableRow<KpiTableCell> => {
      // lets get a basedata structure that represents the current group
      const groupBaseData: BaseData = [
        baseDataHeader,
        ...kpiGroup.baseDataIndices.map((i) => baseData[i]),
      ];
      const cells = columnDefinitions.map(({ compile }) =>
        compile({ kpiGroup, groupBaseData })
      );
      return { key: kpiGroup.groupKey, cells };
    }
  );
  const header = columnDefinitions.map(
    ({ name }): KpiTableCell => ({
      key: name,
      content: name,
      selectionContext: [],
    })
  );
  if (configuration.display === 'Data item in columns') {
    const reflectedRows = columnDefinitions.map(
      ({ name }, i): WizardTableRow<KpiTableCell> => {
        const cells: KpiTableCell[] = [
          { key: name, content: name, selectionContext: [] },
          ...rows.map(
            (row): KpiTableCell => ({
              ...row.cells[i],
            })
          ),
        ];
        return { key: name, cells };
      }
    );
    return {
      header: [],
      rows: reflectedRows,
    };
  }
  return {
    header,
    rows,
  };
};

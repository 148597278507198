import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { CoreToasterProvider } from '@amzn/dots-core-ui';
import { KpiDetailPage, KpiBucketDetailPage } from './Kpi';
import {
  DashboardCreatePage,
  DashboardEditFiltersPage,
  DashboardEditLayoutsPage,
  DashboardEmailReportAddPage,
  DashboardEmailReportEditPage,
  DashboardEmailReportListPage,
  DashboardListPage,
  DashboardMonthlyReportAddPage,
  DashboardMonthlyReportListPage,
  DashboardMonthlyReportPage,
  DashboardPage,
} from './Dashboards';
import { HelpCenterPage } from './HelpCenter';
import { Home } from './Home';
import {
  TranslatorDetailPage,
  TranslatorEditPage,
  TranslatorsListPage,
  TranslatorUploadPage,
} from './Translators';

export type AppRouterProps = {
  isRootApp: boolean;
};

export const AppRouter = ({ isRootApp }: AppRouterProps): JSX.Element => {
  return (
    <CoreToasterProvider>
      <Switch>
        <Route path="/results/dashboard/add">
          <DashboardCreatePage />
        </Route>
        <Route path="/results/dashboard/:dashboardName/edit/filters">
          <DashboardEditFiltersPage />
        </Route>
        <Route path="/results/dashboard/:dashboardName/edit/layouts">
          <DashboardEditLayoutsPage />
        </Route>
        <Route path="/results/dashboard/:dashboardName/email-reports/add">
          <DashboardEmailReportAddPage />
        </Route>
        <Route path="/results/dashboard/:dashboardName/email-reports/:reportConfigurationId/edit">
          <DashboardEmailReportEditPage />
        </Route>
        <Route path="/results/dashboard/:dashboardName/email-reports">
          <DashboardEmailReportListPage />
        </Route>
        <Route path="/results/dashboard/:dashboardName/monthly-reports/add">
          <DashboardMonthlyReportAddPage />
        </Route>
        <Route path="/results/dashboard/:dashboardName/monthly-reports/:reportRequestId">
          <DashboardMonthlyReportPage />
        </Route>
        <Route path="/results/dashboard/:dashboardName/monthly-reports">
          <DashboardMonthlyReportListPage />
        </Route>
        <Route path="/results/dashboard/:dashboardName">
          <DashboardPage />
        </Route>
        <Route path="/results/dashboard">
          <DashboardListPage />
        </Route>
        <Route path="/results/translator/upload">
          <TranslatorUploadPage />
        </Route>
        <Route path="/results/translator/:name/:version/edit">
          <TranslatorEditPage />
        </Route>
        <Route path="/results/translator/:name">
          <TranslatorDetailPage />
        </Route>
        <Route path="/results/translator">
          <TranslatorsListPage />
        </Route>
        <Route path="/results/help">
          <HelpCenterPage />
        </Route>
        <Route path="/results/kpi/detail/:source/:id">
          <KpiDetailPage />
        </Route>
        <Route path="/results/kpi/bucket/:id">
          <KpiBucketDetailPage />
        </Route>
        <Route path="/results">
          <Home />
        </Route>
        {isRootApp && <Redirect to="/results" />}
      </Switch>
    </CoreToasterProvider>
  );
};

import React, { useCallback } from 'react';
import { css } from 'emotion';
import Link from '@amzn/meridian/link';
import { TableCell, TableRow } from '@amzn/meridian/table';
import Row from '@amzn/meridian/row';
import { useDashboardQueryParameters } from '@/hooks';
import {
  useDashboardTableState,
  useDashboardWizardTableData,
} from '@/features/Dashboards/hooks';
import { KpiBaseTableWizardConfiguration } from '@/types';
import { DashboardSelectableTableCell } from '../../DashboardSelectableTableCell';
import { DashboardTableActionBar } from '../DashboardTableActionBar';
import { DashboardWizardTable } from '../DashboardWizardTable';
import { createWizardData } from './data';
import { DashboardKpiBaseTableJiraCell } from './DashboardKpiBaseTableJiraCell';

export type DashboardKpiBaseTableWizardProps = {
  configuration: KpiBaseTableWizardConfiguration;
};

export const DashboardKpiBaseTableWizard = ({
  configuration,
}: DashboardKpiBaseTableWizardProps): JSX.Element => {
  const [tableState, tableStateDispatch] = useDashboardTableState();
  const { pagination, sortColumn, sortDirection } = tableState;
  const wizardData = useDashboardWizardTableData(
    configuration,
    tableState,
    createWizardData
  );
  const {
    params: { timeRange },
    serializeUiQueryParameters,
  } = useDashboardQueryParameters();
  const getHref = useCallback(
    (href?: string) =>
      timeRange ? `${href}&${serializeUiQueryParameters({ timeRange })}` : href,
    [serializeUiQueryParameters, timeRange]
  );
  const getCellContentStyle = (backgroundColor?: string) =>
    backgroundColor ? css({ color: '#ffffff' }) : undefined;
  return (
    <DashboardWizardTable
      spacing="small"
      showDividers
      headerRows={1}
      sortColumn={sortColumn.toString()}
      sortDirection={sortDirection}
      onSort={(payload) =>
        tableStateDispatch({
          type: 'update-sort-state',
          payload: {
            sortColumn: Number(payload.sortColumn),
            sortDirection: payload.sortDirection,
          },
        })
      }
    >
      <DashboardTableActionBar
        dataLength={wizardData.rows.length}
        tableState={tableState}
        tableStateDispatch={tableStateDispatch}
        wizardData={wizardData}
      />
      {wizardData.header.length > 0 && (
        <TableRow>
          {wizardData.header.map(({ content }, index) => (
            <DashboardSelectableTableCell
              key={content}
              sortColumn={index.toString()}
              selectionContext={[]}
              wizardId={configuration.id}
            >
              <Row minWidth="max-content">{content}</Row>
            </DashboardSelectableTableCell>
          ))}
          <TableCell>JIRA</TableCell>
        </TableRow>
      )}
      {wizardData.rows
        .slice(pagination.startIndex, pagination.endIndex)
        .map((row) => (
          <TableRow key={row.cells[0].content}>
            {row.cells.map(
              ({ key, content, backgroundColor, href, selectionContext }) => (
                <DashboardSelectableTableCell
                  key={key}
                  backgroundColor={backgroundColor}
                  selectionContext={selectionContext}
                  wizardId={configuration.id}
                >
                  <Row minWidth="max-content">
                    {key === 'SUMMARY' ? (
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        type="secondary"
                        href={getHref(href)}
                      >
                        {content}
                      </Link>
                    ) : (
                      <span className={getCellContentStyle(backgroundColor)}>
                        {content}
                      </span>
                    )}
                  </Row>
                </DashboardSelectableTableCell>
              )
            )}
            <DashboardKpiBaseTableJiraCell
              kpiBucket={row.kpiBucket}
              kpiIds={row.kpiIds}
              testrunIds={row.testrunIds}
            />
          </TableRow>
        ))}
    </DashboardWizardTable>
  );
};

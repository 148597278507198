import { UserManagerSettings } from 'oidc-client';
import {
  lassoProductionConfiguration,
  lassoStagingConfiguration,
} from '@amzn/dots-core-ui';

export interface Environment {
  hosts: string[];
  boomerangApiUrl: string;
  boomerangJiraApiUrl: string;
  labcollabJiraInstanceUrl: string;
  userManagerSettings: UserManagerSettings;
}

const environments: Environment[] = [
  {
    // Beta
    hosts: [
      // developer desktops
      'localhost',
      'listg.aka.corp.amazon.com',
      'smahaja.aka.corp.amazon.com',
      'braunera.aka.corp.amazon.com',
      'aleardel.aka.corp.amazon.com',
      'saurade.aka.corp.amazon.com',
      'sauradeep.aka.corp.amazon.com',
      'yangaar.aka.corp.amazon.com',
      // deployed environments
      'tools-beta.dots.lab126.a2z.com',
      'beta.boomerang.tdev.lab126.a2z.com',
    ],
    boomerangApiUrl:
      'https://api.middleware.beta.boomerang.tdev.lab126.a2z.com',
    boomerangJiraApiUrl:
      'https://jira.middleware.beta.boomerang.tdev.lab126.a2z.com',
    labcollabJiraInstanceUrl: 'https://issues-staging.labcollab.net',
    userManagerSettings: lassoStagingConfiguration,
  },
  {
    // Gamma
    hosts: ['gamma.boomerang.tdev.lab126.a2z.com'],
    boomerangApiUrl:
      'https://api.middleware.gamma.boomerang.tdev.lab126.a2z.com',
    boomerangJiraApiUrl:
      'https://jira.middleware.gamma.boomerang.tdev.lab126.a2z.com',
    labcollabJiraInstanceUrl: 'https://issues.labcollab.net',
    userManagerSettings: lassoStagingConfiguration,
  },
  {
    // Prod
    hosts: ['tools.dots.lab126.a2z.com', 'boomerang.tdev.lab126.a2z.com'],
    boomerangApiUrl: 'https://api.middleware.boomerang.tdev.lab126.a2z.com',
    boomerangJiraApiUrl:
      'https://jira.middleware.boomerang.tdev.lab126.a2z.com',
    labcollabJiraInstanceUrl: 'https://issues.labcollab.net',
    userManagerSettings: lassoProductionConfiguration,
  },
];

const match = environments.filter(
  (config) => config.hosts.indexOf(window.location.hostname) > -1
);
if (match.length === 0) {
  throw new Error(`${window.location.hostname} is not configured`);
}

export const [environment] = match;

import {
  TranslatorExceptionFilter,
  TranslatorFilters,
  TranslatorGetTestResultRequest,
} from './types';

export const translatorKeys = {
  all: (filter: TranslatorFilters = { latest: true }): unknown[] => [
    'translators',
    filter,
  ],
  allExceptions: (filter: TranslatorExceptionFilter): unknown[] => [
    'translatorExceptions',
    filter,
  ],
  testResults: (filter: TranslatorGetTestResultRequest): unknown[] => [
    'translatorTestResults',
    filter,
  ],
};

import React, { useMemo } from 'react';
import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Loader from '@amzn/meridian/loader';
import { TableCell } from '@amzn/meridian/table';
import { JiraStatus } from '@/components';
import { useDashboardWizardTableCellAnnotations } from '@/features/Dashboards/hooks';
import { extractJiraIdsFromAnnotation } from '@/features/Dashboards/helpers';
import { useJiras } from '@/hooks';

export type DashboardKpiBaseTableJiraCellProps = {
  kpiIds: string[];
  kpiBucket: string;
  testrunIds: string[];
};

export const DashboardKpiBaseTableJiraCell = ({
  kpiIds,
  kpiBucket,
  testrunIds,
}: DashboardKpiBaseTableJiraCellProps): JSX.Element => {
  const { data: bucketJiras, isLoading, isError, error } = useJiras({
    jql: `bucket ~ '${kpiBucket}'`,
  });
  const { annotations } = useDashboardWizardTableCellAnnotations({
    type: 'kpi_annotation',
    kpiIds,
    testrunIds,
  });
  const jiraIds = useMemo(() => {
    const bucketJirasKeys = bucketJiras?.map((jira) => jira.key) ?? [];
    const validAnnotations = Array.from(
      new Set(
        annotations
          .filter(
            (annotation) =>
              // check if the annotation is a KPI annotation
              ((annotation.kpi_id && kpiIds.includes(annotation.kpi_id)) ||
                // check if the annotation is a Testrun annotation
                (annotation.testrun_id &&
                  testrunIds.includes(annotation.testrun_id))) &&
              !annotation.resolved
          )
          .flatMap(extractJiraIdsFromAnnotation)
      )
    );
    return bucketJirasKeys.concat(validAnnotations);
  }, [bucketJiras, annotations, kpiIds, testrunIds]);
  return (
    <TableCell>
      <Column>
        {isLoading && <Loader size="small" type="circular" />}
        {isError && (
          <Alert size="small" type="error">
            {error?.message}
          </Alert>
        )}
        {jiraIds?.map((jiraId) => (
          <Row key={jiraId} alignmentVertical="center" minWidth="max-content">
            <JiraStatus jiraID={jiraId} />
          </Row>
        ))}
      </Column>
    </TableCell>
  );
};

import React, { useCallback, useMemo } from 'react';
import { CoreAsyncButton, useToaster } from '@amzn/dots-core-ui';
import { Jira } from '@/types';
import { TagProperties, useJiraTag } from '@/hooks';

export type JiraTagButtonProps = {
  jira: Jira;
  tagProperties: TagProperties;
};

export const JiraTagButton = ({
  jira,
  tagProperties,
}: JiraTagButtonProps): JSX.Element => {
  const { openToast } = useToaster();
  const { mutateAsync } = useJiraTag();
  const { testsuiteName, buildProject, buildVersion } = tagProperties;
  const isTagged = useMemo(
    () =>
      jira.labels.includes(`${testsuiteName}-${buildProject}-${buildVersion}`),
    [jira.labels, buildProject, buildVersion, testsuiteName]
  );
  const onClick = useCallback(async () => {
    await mutateAsync(
      { jira: jira.key, ...tagProperties },
      {
        onError: (error: Error) =>
          openToast({
            message: `Failed to Tag Jira ${jira}: ${error}`,
            type: 'error',
          }),
      }
    );
    openToast({
      message: `Successfully tagged ${jira.key}!`,
      type: 'success',
    });
  }, [jira, mutateAsync, openToast, tagProperties]);
  return (
    <CoreAsyncButton
      type="tertiary"
      size="small"
      disabled={isTagged}
      onClick={onClick}
    >
      Tag
    </CoreAsyncButton>
  );
};

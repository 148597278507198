import { useEffect, useMemo, useState } from 'react';

export type PaginationState<T> = {
  currentPage: number;
  numberOfPages: number;
  changePage: (nextPage: number) => void;
  data: T[];
};

export const usePagination = <T>(
  data: T[],
  defaults = { currentPage: 1, pageSize: 10 }
): PaginationState<T> => {
  const [{ currentPage, pageSize }, setState] = useState(defaults);
  const numberOfPages = useMemo(() => Math.ceil(data.length / pageSize), [
    data.length,
    pageSize,
  ]);
  const slice = useMemo(
    () => data.slice((currentPage - 1) * pageSize, currentPage * pageSize),
    [currentPage, data, pageSize]
  );
  const changePage = (nextPage: number): void =>
    setState((prev) => ({ ...prev, currentPage: nextPage }));
  // go back to the first page when the underlying data changes
  useEffect(() => {
    changePage(1);
  }, [data]);
  return { currentPage, numberOfPages, changePage, data: slice };
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import { getUniqueId } from '@/features/Dashboards/helpers';
import { ModelParser } from '@/features/Dashboards/types';
import {
  BaseTableWizardConfigurationColumnConfiguration,
  DashboardWizardBase,
  DashboardWizardConfiguration,
  JiraTableWizardConfigurationColumnConfiguration,
  KpiDimensionConfiguration,
  KpiProperty,
  PivotTableCellColorConfiguration,
  PivotTabWizardConfigurationCustomTabConfiguration,
  UserDefinedColumnConfiguration,
  UserDefinedVariableConfiguration,
} from '@/types';
import { isNullOrWhitespace } from '@amzn/dots-core-ui';
import {
  BaseTableColumn,
  CellColor,
  CustomTab,
  DashboardWizardBaseFormConfiguration,
  DashboardWizardBaseFormConfigurationValues,
  JiraTableColumn,
  KpiDimension,
  UserDefinedColumn,
  UserDefinedVariable,
} from './types';

export const validateCustomTabName = (
  nameCandidate: string,
  tabs: CustomTab[]
): string => {
  if (isNullOrWhitespace(nameCandidate)) {
    return 'Name is required';
  }
  if (tabs.filter(({ name }) => name === nameCandidate).length === 2) {
    return `${nameCandidate} is already being used`;
  }
  return '';
};

export const echoValue = (value: string): string => value;

export const getWizardBaseFormConfiguration = (
  wizard: DashboardWizardConfiguration
): DashboardWizardBaseFormConfiguration => ({
  title: { initialValue: wizard.title },
  parentWizardId: { initialValue: wizard.parent },
  kpiFilterKey: { initialValue: wizard.kpi?.key },
  kpiFilterValue: { initialValue: wizard.kpi?.value },
});

export const onWizardConfirmFactory = <
  TValues extends DashboardWizardBaseFormConfigurationValues
>(
  wizard: DashboardWizardBase,
  values: TValues
): DashboardWizardBase => ({
  id: wizard.id,
  tabIndex: wizard.tabIndex,
  sectionIndex: wizard.sectionIndex,
  position: wizard.position,
  title: values.title,
  parent: values.parentWizardId,
  kpi:
    values.kpiFilterKey && values.kpiFilterValue
      ? { key: values.kpiFilterKey, value: values.kpiFilterValue }
      : undefined,
});

export const baseTableColumnWizardParser: ModelParser<
  BaseTableWizardConfigurationColumnConfiguration[],
  BaseTableColumn[]
> = {
  toConfigurationModel: (model) => model?.map(({ id, ...item }) => item) ?? [],
  toFormModel: (model) =>
    model?.map(
      ({ name, format, href }: BaseTableWizardConfigurationColumnConfiguration) => ({
        id: getUniqueId(),
        name,
        format: Array.isArray(format) ? format[0] : format,
        href
      })
    ) ?? [
      {
        id: getUniqueId(),
        name: '',
        format: '',
      },
    ],
};

export const jiraTableColumnParser: ModelParser<
  JiraTableWizardConfigurationColumnConfiguration[],
  JiraTableColumn[]
> = {
  toConfigurationModel: (model) => model?.map(({ id, ...item }) => item) ?? [],
  toFormModel: (model) =>
    model?.map((item) => ({
      id: getUniqueId(),
      ...item,
    })) ?? [],
};

export const userDefinedVariableParser: ModelParser<
  UserDefinedVariableConfiguration[],
  UserDefinedVariable[]
> = {
  toConfigurationModel: (model) => model?.map(({ id, ...item }) => item) ?? [],
  toFormModel: (model) =>
    model?.map((item) => ({
      id: getUniqueId(),
      ...item,
    })) ?? [],
};

export const userDefinedColumnParser: ModelParser<
  UserDefinedColumnConfiguration[],
  UserDefinedColumn[]
> = {
  toConfigurationModel: (model) => model?.map(({ id, ...item }) => item) ?? [],
  toFormModel: (model) =>
    model?.map((item) => ({
      id: getUniqueId(),
      ...item,
    })) ?? [],
};

export const kpiDimensionParser: ModelParser<
  KpiDimensionConfiguration[],
  KpiDimension[]
> = {
  toConfigurationModel: (model) => model?.map(({ id, ...item }) => item) ?? [],
  toFormModel: (model) =>
    model?.map((item) => ({
      id: getUniqueId(),
      ...item,
    })) ?? [
      {
        id: getUniqueId(),
        name: '',
        data: '' as KpiProperty,
      },
    ],
};

export const customTabParser: ModelParser<
  PivotTabWizardConfigurationCustomTabConfiguration[],
  CustomTab[]
> = {
  toConfigurationModel: (model) => model?.map(({ id, ...item }) => item) ?? [],
  toFormModel: (model) =>
    model?.map((item) => ({
      id: getUniqueId(),
      ...item,
    })) ?? [],
};

export const cellColorTabParser: ModelParser<
  PivotTableCellColorConfiguration[],
  CellColor[]
> = {
  toConfigurationModel: (model) => model?.map(({ id, ...item }) => item) ?? [],
  toFormModel: (model) =>
    model?.map((item) => ({
      id: getUniqueId(),
      ...item,
    })) ?? [],
};

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { css } from 'emotion';
import { useQuery } from 'react-query';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import Tag from '@amzn/meridian/tag';
import Loader from '@amzn/meridian/loader';
import { useHttp } from '@/client';
import { ControlledExpander } from '@/components';
import { TranslatorTestResponse } from '@/features/Translators/types';
import { translatorClient } from '@/features/Translators/client';
import { translatorKeys } from '../queries';

const overflowStyle = css({
  overflowX: 'scroll',
});

const maxRefetches = 10;
const refetchInterval = 3000;

type TranslatorLocalTestResultProps = {
  testrunId: string;
  result: string;
  initialStatus: 'rejected' | 'pending';
  isResolved: boolean;
  isFetchInProgress: boolean;
};

const TranslatorLocalTestResult = ({
  testrunId,
  initialStatus,
  result,
  isFetchInProgress,
  isResolved,
}: TranslatorLocalTestResultProps): JSX.Element => {
  const isRejected =
    initialStatus === 'rejected' || (!isResolved && !isFetchInProgress);
  const isPending =
    initialStatus === 'pending' && isFetchInProgress && !isResolved && !result;
  return (
    <ControlledExpander title={testrunId} type="box">
      <Row>
        <Text>Status: </Text>
        {isRejected && <Tag type="error">Failed</Tag>}
        {isResolved && <Tag type="success">Success</Tag>}
        {isPending && <Loader size="small" />}
      </Row>
      {isResolved && (
        <>
          <Text>Result:</Text>
          <Text tag="pre" className={overflowStyle}>
            {JSON.stringify(JSON.parse(result), undefined, 2)}
          </Text>
        </>
      )}
    </ControlledExpander>
  );
};

export type TranslatorLocalTestResultsProps = {
  name: string;
  version: string;
  source: string;
  tests: TranslatorTestResponse;
};

export const TranslatorLocalTestResults = ({
  name,
  version,
  source,
  tests,
}: TranslatorLocalTestResultsProps): JSX.Element => {
  const { http } = useHttp();
  const refreshCountRef = useRef(0);
  const [resolvedQueryCodes, setResolvedQueryCodes] = useState<string[]>([]);
  const pendingQueryCodes = useMemo(
    () => tests.filter(({ status }) => status === 'pending'),
    [tests]
  );
  const testResultFilter = useMemo(
    () => ({
      name,
      version,
      source,
      query_codes: pendingQueryCodes.map(({ queryCode }) => queryCode),
    }),
    [name, pendingQueryCodes, source, version]
  );
  const isFetchInProgress =
    resolvedQueryCodes.length !== pendingQueryCodes.length &&
    refreshCountRef.current < maxRefetches;
  const { data } = useQuery({
    queryKey: translatorKeys.testResults(testResultFilter),
    queryFn: () =>
      translatorClient.getTranslatorTestResult(http, testResultFilter),
    onSuccess: (result) => {
      setResolvedQueryCodes(Object.keys(result));
    },
    onSettled: () => {
      refreshCountRef.current += 1;
    },
    enabled: pendingQueryCodes.length > 0,
    refetchInterval: isFetchInProgress ? refetchInterval : undefined,
  });
  useEffect(() => {
    refreshCountRef.current = 0;
  }, [testResultFilter]);
  return (
    <Column>
      <Column spacing="300">
        <Heading level={4}>Local Verification Results</Heading>
        {tests.length === 0 && (
          <Text type="b200" color="secondary">
            Run a test with Destination: LOCAL to see results
          </Text>
        )}
      </Column>
      {tests.map(({ queryCode, testrunId, status }) => (
        <TranslatorLocalTestResult
          key={testrunId}
          testrunId={testrunId}
          initialStatus={status}
          isResolved={resolvedQueryCodes.includes(queryCode)}
          isFetchInProgress={isFetchInProgress}
          result={data ? data[queryCode] : ''}
        />
      ))}
    </Column>
  );
};

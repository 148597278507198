import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Link from '@amzn/meridian/link';
import FileInput, { FileDetails } from '@amzn/meridian/file-input';
import { CoreFormFooter, useToaster } from '@amzn/dots-core-ui';
import { CorePage, CorePageTitle } from '@/components';
import { useHttp } from '@/client';
import { TranslatorDetailUri, TranslatorsUri } from '@/features/routes';
import { useUserProfile } from '@/hooks';
import { TranslatorUploadResult } from './types';
import { translatorClient } from './client';

export const TranslatorUploadPage = (): JSX.Element => {
  const history = useHistory();
  const { http } = useHttp();
  const { openToast } = useToaster();
  const { data: userProfile } = useUserProfile();
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [verificationResult, setVerificationResult] = useState<
    TranslatorUploadResult | undefined
  >();
  const {
    error: commitError,
    isLoading: isCommiting,
    mutateAsync: commit,
  } = useMutation<void, Error>(
    () =>
      translatorClient.commitTranslator(http, {
        ...verificationResult?.result,
        creator: userProfile?.user_id,
      }),
    {
      onSuccess: () => {
        openToast({
          title: 'Success!',
          message: `${verificationResult?.result.name} has been uploaded.`,
          type: 'success',
        });
        history.push(
          TranslatorDetailUri(verificationResult?.result.name ?? '')
        );
      },
    }
  );
  const {
    error: uploadError,
    isLoading: isUploading,
    mutateAsync: upload,
  } = useMutation<TranslatorUploadResult, Error, File>(
    (file: File) => translatorClient.uploadTranslator(http, file),
    {
      onMutate: () => {
        setVerificationResult(undefined);
      },
      onSuccess: (result) => {
        setVerificationResult(result);
      },
      onError: (error) => {
        openToast({
          title: 'Error!',
          message: error.message,
          type: 'error',
        });
      },
    }
  );
  return (
    <CorePage
      title="Upload"
      breadcrumbs={[{ href: TranslatorsUri, title: 'Translators' }]}
      header={
        <Row widths="fill">
          <CorePageTitle title="Upload a Translator" />
        </Row>
      }
    >
      <form
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          commit();
        }}
      >
        <Column
          spacing="500"
          maxWidth="100%"
          width={650}
          alignmentVertical="top"
        >
          {commitError && (
            <Alert type="error" title="Error!" size="large">
              {commitError?.message}
            </Alert>
          )}
          <Column spacing="400">
            <Heading level={4}>Translator File</Heading>
            <Text type="b200" color="secondary">
              For more guidance please refer to{' '}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://quip-amazon.com/eZruAhb4Ztqm/Boomerang-Pivot-v2-User-Guide#FPT9CAm2SeU"
              >
                Customer Translator Code Example
              </Link>
            </Text>
            <FileInput
              accept=".py"
              type="single"
              showDropZone
              uploadButtonLabel="Select file"
              onFileAttached={([incomingFile]: File[]) => {
                setSelectedFile(incomingFile);
                upload(incomingFile);
              }}
            />
            {selectedFile && (
              <Row width={340}>
                <FileDetails
                  file={selectedFile}
                  onClickRemoveFile={() => {
                    setSelectedFile(undefined);
                    setVerificationResult(undefined);
                  }}
                  uploadComplete={!isUploading}
                  error={
                    uploadError !== null ||
                    (verificationResult && verificationResult.errors.length > 0)
                  }
                  uploadCompleteLabel="Translator Verification complete"
                  errorMessage="Translator Verification failed"
                />
              </Row>
            )}
          </Column>
          {verificationResult && (
            <Column spacing="400">
              <Heading level={4}>Verification Results</Heading>
              <Column spacing="300">
                {verificationResult.result && (
                  <>
                    <Alert size="small" type="success">
                      Translator Name: {verificationResult.result.name}
                    </Alert>
                    <Alert size="small" type="success">
                      Translator Name: {verificationResult.result.version}
                    </Alert>
                    <Alert size="small" type="success">
                      Translator Verfication Passed
                    </Alert>
                  </>
                )}
                {verificationResult.errors.map((error) => (
                  <Alert key={error} size="small" type="error">
                    {error}
                  </Alert>
                ))}
              </Column>
            </Column>
          )}
          <CoreFormFooter
            isLoading={isCommiting}
            isConfirmDisabled={
              selectedFile === undefined ||
              verificationResult === undefined ||
              verificationResult.errors.length > 0
            }
            onCancelClick={() => history.goBack()}
          />
        </Column>
      </form>
    </CorePage>
  );
};

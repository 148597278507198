import React from 'react';
import { format, addMonths, startOfMonth, endOfMonth } from 'date-fns';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import { useForm, CoreTextarea, CoreFormFooter } from '@amzn/dots-core-ui';
import { DashboardReportRequest } from '@/types';

export type DashboardMonthlyReportFormProps = {
  dashboardId: string;
  dashboardName: string;
  onCancel: () => void;
  onSubmit: (report: Partial<DashboardReportRequest>) => Promise<void> | void;
};

export const DashboardMonthylReportForm = ({
  dashboardId,
  dashboardName,
  onSubmit,
  onCancel,
}: DashboardMonthlyReportFormProps): JSX.Element => {
  const now = new Date();
  const {
    values,
    errors,
    handlers,
    hasErrors,
    handleSubmit,
    isSubmitting,
  } = useForm({
    title: {
      initialValue: '',
      validate: (value): string =>
        value && value.length > 100
          ? 'Title can be maximum of 100 characters'
          : '',
    },
    dashboard_id: {
      initialValue: dashboardId,
    },
    dashboard_name: {
      initialValue: dashboardName,
    },
    start_date: {
      initialValue: format(startOfMonth(addMonths(now, -3)), 'yyyy-MM-dd'),
    },
    end_date: {
      initialValue: format(endOfMonth(now), 'yyyy-MM-dd'),
    },
  });

  return (
    <form
      onSubmit={handleSubmit((vs) => {
        return onSubmit(vs);
      })}
    >
      <Column width={500}>
        <Heading level={3}>Generate a new report</Heading>
        <CoreTextarea
          onChange={handlers.title}
          value={values.title}
          errorMessage={errors.title}
          label="Enter title - optional"
        />
        <Text alignment="left" type="b100">
          Use the title field to identify a particular report. If not specified
          we will generate a title based on the date range of the report.
        </Text>
        <CoreFormFooter
          isConfirmDisabled={hasErrors || isSubmitting}
          isLoading={isSubmitting}
          onCancelClick={onCancel}
        />
      </Column>
    </form>
  );
};

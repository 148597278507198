import React from 'react';
import { css } from 'emotion';
import { CorePageLoader } from '@amzn/dots-core-ui';
import Alert from '@amzn/meridian/alert';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import downloadToken from '@amzn/meridian-tokens/base/icon/download-large';
import { useTestrunFile } from '@/hooks';
import { PageError } from './PageError';

const style = css({
  whiteSpace: 'pre-wrap',
  fontFamily: 'monospace',
});

export type KpiLogFileProps = {
  source: string;
  testrunId: string;
  logFilePath: string;
};

export const KpiLogFile = ({
  source,
  testrunId,
  logFilePath,
}: KpiLogFileProps): JSX.Element => {
  const { data, error, isLoading, isError } = useTestrunFile({
    source,
    testrunId,
    filePath: logFilePath,
  });

  if (!logFilePath) {
    return <Alert>KPI {testrunId} is not associated with a file</Alert>;
  }
  if (isLoading) {
    return <CorePageLoader />;
  }
  if (isError && error) {
    return <PageError error={error} />;
  }
  return (
    <Column type="fill" spacing="500" spacingInset="500">
      <Row>
        {data && data?.url && (
          <Button type="icon" href={data.url} download={logFilePath}>
            <Icon tokens={downloadToken}>download</Icon>
          </Button>
        )}
        <Heading level={5}>{logFilePath}</Heading>
      </Row>
      <Text tag="pre" className={style}>
        {data?.content}
      </Text>
    </Column>
  );
};

import React, { useState } from 'react';
import Row from '@amzn/meridian/row';
import DateRangePicker from '@amzn/meridian/date-range-picker';
import RadioButton from '@amzn/meridian/radio-button';
import Column from '@amzn/meridian/column';
import {
  CoreFilterableSelect,
  CoreInput,
  isNullOrWhitespace,
  useForm,
} from '@amzn/dots-core-ui';
import { DashboardExactTimeRange } from '@/types';
import { DashboardFormRow } from '../DashboardFormRow';
import { DashboardFormSection } from '../DashboardFormSection';
import {
  BuildMode,
  DashboardFiltersFormControlProps,
  DataRangeFilter,
  RelativeTimeRangeLabel,
  TimeMode,
} from './types';
import { defaultExactTimeRange, relativeRanges } from './helpers';

//Calculates how many milliseconds in given time
//Currently calculates milliseconds in 2 years
const oldestDateFilter = 1000/*milliseconds*/ * 60/*seconds*/ * 60/*minutes*/ * 24/*hours*/ * 365/*days*/ * 1 /*years*/;

export const DashboardDataRangeControl = ({
  value,
  onChange,
}: DashboardFiltersFormControlProps<DataRangeFilter>): JSX.Element => {
  const { states, values, handlers, errors } = useForm({
    numberOfBuilds: {
      initialValue: value.numberOfBuilds,
      validate: (v: unknown) =>
        isNullOrWhitespace(v) ? 'Number of builds is required' : '',
    },
    timeRange: {
      initialValue: value.timeRange,
      validate: (v: RelativeTimeRangeLabel | DashboardExactTimeRange) =>
        (Array.isArray(v) && (v.length !== 2 || v.some(isNullOrWhitespace))) ||
        isNullOrWhitespace(v)
          ? 'Time range is required'
          : '',
    },
  });
  const [{ buildMode, timeMode }, setState] = useState<{
    buildMode: BuildMode;
    timeMode: TimeMode;
  }>({
    buildMode: value.allBuilds ? 'all' : 'some',
    timeMode: Array.isArray(value.timeRange) ? 'exact' : 'relative',
  });
  const setTimeMode = (nextValue: TimeMode) => {
    if (nextValue === 'relative') {
      const nextTimeRange =
        typeof value.timeRange === 'string' ? value.timeRange : 'Last 2 weeks';
      onChange({ ...value, timeRange: nextTimeRange });
      handlers.timeRange(nextTimeRange);
    }
    if (nextValue === 'exact') {
      const nextTimeRange = Array.isArray(value.timeRange)
        ? value.timeRange
        : defaultExactTimeRange;
      onChange({ ...value, timeRange: nextTimeRange });
      handlers.timeRange(nextTimeRange);
    }
    setState((prev) => ({ ...prev, timeMode: nextValue }));
  };
  const setTimeRange = (nextValue: any) => {
    handlers.timeRange(nextValue);
    onChange({ ...value, timeRange: nextValue });
  };
  const setBuildMode = (nextValue: BuildMode) => {
    if (nextValue === 'all') {
      handlers.numberOfBuilds('all');
      onChange({ ...value, allBuilds: true });
    }
    if (nextValue === 'some') {
      handlers.numberOfBuilds(value.numberOfBuilds);
      onChange({
        ...value,
        allBuilds: false,
        numberOfBuilds: value.numberOfBuilds,
      });
    }
    setState((prev) => ({ ...prev, buildMode: nextValue }));
  };
  const setNumberOfBuilds = (nextValue: string) => {
    onChange({ ...value, numberOfBuilds: nextValue });
    handlers.numberOfBuilds(nextValue);
  };
  return (
    <DashboardFormSection title="Data Range">
      <Column spacing="500">
        <DashboardFormRow label="Time">
          <Row alignmentVertical="center">
            <RadioButton
              checked={timeMode === 'relative'}
              onChange={setTimeMode}
              name="Relative"
              value="relative"
            >
              Relative
            </RadioButton>
            <RadioButton
              checked={timeMode === 'exact'}
              onChange={setTimeMode}
              name="Exact"
              value="exact"
            >
              Exact
            </RadioButton>
          </Row>
          {timeMode === 'relative' && (
            <CoreFilterableSelect
              value={values.timeRange}
              onChange={setTimeRange}
              validationState={states.timeRange}
              errorMessage={errors.timeRange}
              items={Object.keys(relativeRanges)}
            />
          )}
          {timeMode === 'exact' && (
            <DateRangePicker
              startPlaceholder="Start date"
              endPlaceholder="End date"
              monthsInView={1}
              value={values.timeRange}
              onChange={setTimeRange}
              errorMessage={errors.timeRange}
              disabledDates={(date: any) => {
                //checks delta of current date and given date, and disables it if it larger than oldestDateFilter
                const dateCheck = Date.parse(date);
                const currentDate = (new Date).setHours(0,0,0,0);
                return (currentDate - dateCheck > oldestDateFilter);
              }}
              startError={!isNullOrWhitespace(errors.timeRange)}
              endError={!isNullOrWhitespace(errors.timeRange)}
            />
          )}
        </DashboardFormRow>
        <DashboardFormRow label="Build">
          <Row alignmentVertical="center">
            <RadioButton
              checked={buildMode === 'all'}
              onChange={setBuildMode}
              name="All-Builds"
              value="all"
            >
              All Builds
            </RadioButton>
            <RadioButton
              checked={buildMode === 'some'}
              onChange={setBuildMode}
              name="Last-N-Builds"
              value="some"
            >
              Last N Builds
            </RadioButton>
          </Row>
          {buildMode === 'some' && (
            <CoreInput
              value={values.numberOfBuilds}
              onChange={setNumberOfBuilds}
              errorMessage={errors.numberOfBuilds}
              validationState={states.numberOfBuilds}
              type="number"
              placeholder="N"
              prefix="Last"
              suffix="Builds"
            />
          )}
        </DashboardFormRow>
      </Column>
    </DashboardFormSection>
  );
};

import { calculate, parseExpression } from '@/features/Dashboards/aggregation';
import { findMatchingGoal } from '@/features/Dashboards/goals';
import { groupByProperties } from '@/features/Dashboards/helpers';
import {
  Filter,
  KpiFilterResult,
  mapBaseDataPageToFlatKpi,
  TagProperties,
} from '@/hooks';
import { Dashboard, FlatKpi } from '@/types';

export type TagPropertiesResult = {
  latestKpi?: FlatKpi;
  tagProperties?: TagProperties;
};

const totalDurationPerHour = parseExpression(
  'divide(sum(max(group(input, data.testrun_id, data.test_runtime))),3600)'
);

export const getTagProperties = (
  kpiBucket: string,
  dashboard?: Dashboard,
  kpiFilterResult?: KpiFilterResult
): TagPropertiesResult => {
  const defaultResult = { latestKpi: undefined, tagProperties: undefined };
  if (!dashboard || !kpiFilterResult) {
    return defaultResult;
  }
  const [kpiHeader, ...kpis] = kpiFilterResult.baseData;
  const kpiBucketBaseData = kpis.filter(
    (kpi) => kpi[kpiFilterResult.propertyIndexMap['kpi.bucket']] === kpiBucket
  );
  if (kpiBucketBaseData.length < 1) {
    return defaultResult;
  }
  const latestKpi = mapBaseDataPageToFlatKpi([
    kpiHeader,
    kpiBucketBaseData[0],
  ])[0];

  totalDurationPerHour.reset();
  calculate(kpiFilterResult.baseData, {}, totalDurationPerHour);
  const globalData = {
    _kpiCount: kpiBucketBaseData.length,
    _durationPerHour: totalDurationPerHour.value,
  };

  const goal = findMatchingGoal(
    dashboard?.filters?.goal_groups ?? [],
    kpiFilterResult.propertyIndexMap,
    [kpiHeader, ...kpiBucketBaseData],
    globalData
  );
  return {
    latestKpi,
    tagProperties: {
      buildVersion: latestKpi('data.build.version') as string,
      buildProject: latestKpi('data.build.project') as string,
      deviceType: latestKpi('data.device.type') as string,
      kpiBucketLink: window.location.href,
      kpiName: latestKpi('kpi.name') as string,
      taggedValue: goal.value,
      goal: goal.goal,
      testsuiteName: latestKpi('data.test_suite') as string,
      testrunLinks: Array.from(
        new Set(
          kpis?.map(
            (kpi) =>
              `https://tools.dots.lab126.a2z.com/scheduling/testruns/${
                kpi[kpiFilterResult.propertyIndexMap['data.testrun_id']]
              }`
          )
        )
      ),
    },
  };
};

export const getIncorrectRequiredJiraFilters = (filters?: Filter[]): string[] =>
  filters !== undefined
    ? groupByProperties.filter(
        (requiredProp) =>
          filters.find(
            (filter) =>
              filter.operator === 'include' &&
              filter.value.length === 1 &&
              filter.key.includes(requiredProp)
          ) === undefined
      )
    : groupByProperties;

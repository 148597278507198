import { useQuery, UseQueryResult } from 'react-query';
import { HttpClient, useHttp } from '@/client';
import { GetReportRequestsResponse } from '@/types';

export type GetDashboardReportRequestsArgs = {
  dashboardId: string;
};

const getDashboardReportRequests = async (
  http: HttpClient,
  dashboardId: string
): Promise<GetReportRequestsResponse> => {
  if (dashboardId === undefined) {
    return <GetReportRequestsResponse>{};
  }

  const params = { dashboard_id: dashboardId };
  const { data, status } = await http.get(`report/requests`, params);
  if (status.code === 200) {
    return data;
  }
  throw new Error(data.message);
};

export const useDashboardGetReportRequests = ({
  dashboardId,
}: GetDashboardReportRequestsArgs): UseQueryResult<
  GetReportRequestsResponse,
  Error
> => {
  const { http } = useHttp();
  return useQuery<GetReportRequestsResponse, Error>({
    queryFn: () => getDashboardReportRequests(http, dashboardId),
    queryKey: ['dashboardReports', { dashboardId }],
  });
};

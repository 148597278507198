/* eslint-disable camelcase */
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { Annotation } from '@/types';
import { useHttp, HttpClient } from '@/client';
import { useUserProfile } from '@/hooks';
import {
  useDashboardQueryParameters,
  DashboardQueryParameters,
} from './useDashboardQueryParameters';

const createAnnotation = async (
  http: HttpClient,
  dashboardId: string,
  userId: string,
  queryParams: DashboardQueryParameters,
  annotation: Partial<Annotation>
): Promise<Annotation> => {
  console.log({annotation});
  const payload: Partial<Annotation> = { ...annotation, updater: userId };
  if (annotation.type === 'kpi_annotation') {
    payload.source = 'KATS';
  }
  if (annotation.type === 'wizard_annotation') {
    const { groupTab, filterType, filterValues } = queryParams;
    payload.dashboard_id = dashboardId;
    payload.dashboard_additions = JSON.stringify({
      groupTab,
      filterType,
      filterValues,
    });
  }
  const { data, status } = await http.post('annotation', payload);
  if (status.code === 200) {
    return data;
  }
  throw new Error(data.message);
};

export const useDashboardAnnotationCreate = (
  dashboardId: string
): UseMutationResult<Annotation, Error, Partial<Annotation>, () => void> => {
  const { http } = useHttp();
  const queryClient = useQueryClient();
  const { params } = useDashboardQueryParameters();
  const { data: userProfile } = useUserProfile();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { tab, ...keyParams } = params;
  const queryKey = ['annotations', { dashboardId, filters: keyParams }];
  return useMutation(
    (annotation: Partial<Annotation>) =>
      createAnnotation(
        http,
        dashboardId,
        userProfile?.user_id ?? '',
        params,
        annotation
      ),
    {
      onSuccess: (data) => {
        const previous = queryClient.getQueryData<Annotation[]>(queryKey);
        const next =
          previous?.filter((a) => a.annotation_id !== data.annotation_id) ?? [];
        queryClient.setQueryData(queryKey, [...next, data]);
      },
    }
  );
};

import { Action, State, PivotTabOption } from './types';

export const allOption: PivotTabOption = { label: 'All', selectionContext: [] };

export const initialState: State = {
  selectedOption: allOption,
  showAll: false,
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'update-selected-option':
      return { ...state, selectedOption: action.payload };
    case 'update-show-all':
      return { ...state, showAll: action.payload };
    default:
      return state;
  }
};

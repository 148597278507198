/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import Column from '@amzn/meridian/column';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';
import { getUniqueId } from '@/features/Dashboards/helpers';
import { DashboardFormSection } from '../DashboardFormSection';
import { TabOverride, DashboardFiltersFormControlProps } from './types';
import { DashboardTabOverrideControl } from './DashboardTabOverrideControl';

export const DashboardTabOverrideCollectionControl = ({
  value,
  onChange,
}: DashboardFiltersFormControlProps<Partial<TabOverride>[]>): JSX.Element => {
  const onAddClick = useCallback(
    () => onChange(value.concat({ id: getUniqueId() })),
    [onChange, value]
  );
  const onUpdate = useCallback(
    (index: number) => (tabOverride: TabOverride) => {
      const next = [...value];
      next[index] = tabOverride;
      onChange(next);
    },
    [onChange, value]
  );
  const onRemove = useCallback(
    (index: number) => () => onChange(value.filter((_, i) => i !== index)),
    [onChange, value]
  );
  return (
    <DashboardFormSection
      title="Local Group Tab Overrides"
      descriptionText="Define tabs to filter data based on custom Project Groups or Device Families"
      descriptionLink="https://quip-amazon.com/eZruAhb4Ztqm/Boomerang-Pivot-v2-User-Guide#FPT9CAMlYzA"
    >
      <Column spacing="500">
        {value.map((tabOverride, i) => (
          <DashboardTabOverrideControl
            key={tabOverride.id}
            tabOverride={tabOverride}
            onChange={onUpdate(i)}
            onRemove={onRemove(i)}
          />
        ))}
      </Column>
      <Button type="primary" onClick={onAddClick}>
        <Icon tokens={plusTokens}>Add a new custom tab</Icon>
      </Button>
    </DashboardFormSection>
  );
};

import React, { useState, useMemo, useCallback } from 'react';
import Column from '@amzn/meridian/column';
import { CorePageLoader, useToaster } from '@amzn/dots-core-ui';
import { useDashboardAnnotationCreate } from '@/hooks';
import {
  useDashboardAnnotationUpdate,
  useDashboardWizardTableCellAnnotations,
} from '@/features/Dashboards/hooks';
import { useDashboard } from '@/features/Dashboards/contexts';
import { Annotation } from '@/types';
import { DashboardAnnotationTile } from './DashboardAnnotationTile';
import { DashboardAnnotationForm } from './DashboardAnnotationForm';
import {
  AnnotationResolutionFilter,
  AnnotationViewHeader,
} from './DashboardAnnotationViewHeader';
import { useDashboardAnnotationViewContext } from './DashboardAnnotationViewContext';
import { useAnnotationTemplate } from '@/hooks'

export const DashboardAnnotationView = (): JSX.Element => {
  const { dashboard } = useDashboard();
  const { context, closeView } = useDashboardAnnotationViewContext();
  const { annotations } = useDashboardWizardTableCellAnnotations(context);
  const {
    mutateAsync: createAnnotationMutation,
  } = useDashboardAnnotationCreate(dashboard.id);
  const {
    mutateAsync: updateAnnotationMutation,
  } = useDashboardAnnotationUpdate();
  const { openToast } = useToaster();

  const [query, setQuery] = useState('');
  const [resolutionFilter, setResolutionFilter] = useState<
    AnnotationResolutionFilter
  >('open');
  const [selectedAnnotation, setSelectedAnnotation] = useState<
    Partial<Annotation>
  >({});
  const resetAnnotationForm = useCallback(() => {
    setSelectedAnnotation({});
    closeView();
  }, [closeView]);

  const filteredAnnotations = useMemo(
    () =>
      annotations.filter(
        (annotation) =>
          (resolutionFilter === 'open') !== annotation.resolved && ((annotation.template &&
          Object.entries(annotation.template?.content).some(([, value]) => value.value.toLowerCase().includes(query.toLowerCase()))) ||
          annotation.notes?.toLowerCase().includes(query.toLowerCase()))
      ),
    [annotations, query, resolutionFilter]
  );
  const sortedFilteredAnnotations = filteredAnnotations.sort((a, b) => 
    new Date(`${b.update_time}Z`).getTime() - new Date(`${a.update_time}Z`).getTime() );

  const { kpiIds, location, type, wizardId } = context;
  const onSubmitAnnotationForm = useCallback(
    async (annotation: Partial<Annotation>): Promise<void> => {
      let verb = 'created';
      if (annotation.annotation_id) {
        verb = 'updated';
        await updateAnnotationMutation({
          id: annotation.annotation_id,
          annotation: { template: annotation.template },
        });
      } else {
        verb = 'created';
        const payload: Partial<Annotation> = { type, template: annotation.template };
        if (type === 'kpi_annotation') {
          payload.source = 'KATS';
          payload.kpi_id = kpiIds.length ? kpiIds[0] : '';
        }
        if (type === 'wizard_annotation') {
          payload.location = location;
          payload.wizard_id = wizardId;
        }
        await createAnnotationMutation(payload);
      }
      resetAnnotationForm();
      openToast({
        title: 'Success!',
        message: `The annotation has been ${verb}.`,
        type: 'success',
      });
    },
    [
      resetAnnotationForm,
      openToast,
      updateAnnotationMutation,
      type,
      createAnnotationMutation,
      kpiIds,
      location,
      wizardId,
    ]
  );
  const onEditAnnotation = (annotation: Annotation) =>
    setSelectedAnnotation(annotation);
  const templateId = selectedAnnotation && selectedAnnotation.template ? selectedAnnotation.template.templateId : dashboard.template?.template_id;
  const { data: template, isLoading: isTemplateLoading } = useAnnotationTemplate(templateId);

  return (
    <Column spacingInset="300" overflowX="hidden" overflowY="hidden">
      <AnnotationViewHeader
        query={query}
        resolutionFilter={resolutionFilter}
        onQueryChange={setQuery}
        onResolutionFilterChange={setResolutionFilter}
      />
      { template && <DashboardAnnotationForm
        annotation={selectedAnnotation}
        onSubmit={onSubmitAnnotationForm}
        onCancel={resetAnnotationForm}
        template={template}
      /> }
      { isTemplateLoading && <CorePageLoader /> }
      {sortedFilteredAnnotations.map((annotation) => (
        <DashboardAnnotationTile
          key={annotation.annotation_id}
          annotation={annotation}
          isEditing={
            annotation.annotation_id === selectedAnnotation?.annotation_id
          }
          onEdit={onEditAnnotation}
        />
      ))}
    </Column>
  );
};

/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react';
import { css } from 'emotion';
import { TableCell } from '@amzn/meridian/table';
import { TableCellProps } from '@amzn/meridian/table/table-cell';
import { keys } from '@amzn/dots-core-ui';
import { shadeColor } from '@/helpers';
import {
  useWizardSelection,
  WizardSelection,
} from '@/features/Dashboards/contexts';

const selectableElementStyles = css({
  cursor: 'pointer',
});

const isMultiSelecting = (event: React.MouseEvent<HTMLElement>) =>
  (navigator.userAgent.toLowerCase().includes('win') && event.ctrlKey) ||
  event.metaKey;

export type DashboardSelectableTableCellProps = TableCellProps & {
  selectionContext: WizardSelection;
  wizardId: string;
};

export const DashboardSelectableTableCell = ({
  children,
  backgroundColor: propBackgroundColor,
  selectionContext,
  wizardId,
  ...tableCellProps
}: DashboardSelectableTableCellProps): JSX.Element => {
  const { selectionState, updateSelection } = useWizardSelection(wizardId);
  const isSelected = useMemo(
    () =>
      selectionContext.some((localSelection) =>
        selectionState.selection.some((stateSelection) =>
          keys(stateSelection).every(
            (key) => localSelection[key] === stateSelection[key]
          )
        )
      ),
    [selectionContext, selectionState.selection]
  );
  const onKeyPress = useCallback(() => updateSelection(selectionContext), [
    selectionContext,
    updateSelection,
  ]);
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!selectionContext.length) {
        // this cell represents a 'select all' cell
        updateSelection(selectionContext);
      }
      const [localSelection] = selectionContext;
      if (isMultiSelecting(event)) {
        // try to remove the current cell's selection context, we are trying to
        // determine if the user is trying to remove the current cell from the
        // selection
        const filteredSelection = selectionState.selection.filter(
          (stateSelection) => {
            const localKeys = keys(localSelection);
            const stateKeys = keys(stateSelection);
            // a rough estimate of 'object equality' here, but these are simple
            // key-value pairs so it should be okay
            return (
              localKeys.length !== stateKeys.length ||
              keys(localSelection).some(
                (key) => stateSelection[key] !== localSelection[key]
              )
            );
          }
        );
        if (selectionState.selection.length > filteredSelection.length) {
          // if selection exists, remove it
          updateSelection(filteredSelection);
        } else {
          // otherwise add selection
          updateSelection([...selectionContext, ...selectionState.selection]);
        }
      } else {
        // no ctrl/meta key is being pressed so we can just update the current
        // selection with the cell's selection context
        updateSelection(selectionContext);
      }
    },
    [selectionContext, selectionState.selection, updateSelection]
  );
  const backgroundColor = useMemo(() => {
    if (isSelected) {
      if (propBackgroundColor) {
        return shadeColor(propBackgroundColor, -25);
      }
      return '#f2f3f4';
    }
    return propBackgroundColor;
  }, [isSelected, propBackgroundColor]);
  return (
    <TableCell backgroundColor={backgroundColor} {...tableCellProps}>
      <div
        role="button"
        tabIndex={0}
        onClick={onClick}
        onKeyPress={onKeyPress}
        className={selectableElementStyles}
      >
        {children}
      </div>
    </TableCell>
  );
};

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { useHttp, HttpClient } from '@/client';
import { DashboardEmailReportConfiguration } from '@/types';
import { useDashboard } from './useDashboard';

const getDashboardEmailReport = async (
  http: HttpClient,
  reportConfigurationId: string,
  dashboardId?: string
): Promise<DashboardEmailReportConfiguration> => {
  const { data, status } = await http.get(
    `dashboard/email_report/${reportConfigurationId}?dashboard_id=${dashboardId}`
  );
  if (status.code === 200) {
    return data;
  }
  throw new Error(data.message);
};

export const useDashboardEmailReport = (): UseQueryResult<
  DashboardEmailReportConfiguration,
  Error
> => {
  const { http } = useHttp();
  const queryClient = useQueryClient();
  const { dashboardName: rawName, reportConfigurationId } = useParams<{
    dashboardName: string;
    reportConfigurationId: string;
  }>();
  const dashboardName = useMemo(() => decodeURIComponent(rawName), [rawName]);
  const dashboardResult = useDashboard({
    dashboardId: dashboardName,
  });
  const reportResult = useQuery<DashboardEmailReportConfiguration, Error>({
    queryKey: [
      'dashboardEmailReports',
      {
        dashboardName,
        reportConfigurationId,
      },
    ],
    queryFn: () =>
      getDashboardEmailReport(
        http,
        reportConfigurationId,
        dashboardResult.data?.id
      ),
    onSuccess: (data) =>
      queryClient.setQueryData(
        [
          'dashboardEmailReports',
          { dashboardName, reportConfigurationId: data.report_id },
        ],
        data
      ),
    enabled: dashboardResult.data !== undefined,
  });
  if (dashboardResult.data) {
    return reportResult;
  }
  return { ...dashboardResult, data: undefined, refetch: reportResult.refetch };
};

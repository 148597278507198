import React, { useRef, useState, useCallback } from 'react';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Link from '@amzn/meridian/link';
import Icon from '@amzn/meridian/icon';
import Button from '@amzn/meridian/button';
import helpTokens from '@amzn/meridian-tokens/base/icon/help';
import Popover from '@amzn/meridian/popover';
import SearchField from '@amzn/meridian/search-field';
import ButtonGroup, { ButtonOption } from '@amzn/meridian/button-group';

export type AnnotationResolutionFilter = 'open' | 'resolved';

export type AnnotationViewHeaderProps = {
  query: string;
  resolutionFilter: AnnotationResolutionFilter;
  onQueryChange: (value: string) => void;
  onResolutionFilterChange: (value: AnnotationResolutionFilter) => void;
};

export const AnnotationViewHeader = ({
  query,
  onQueryChange,
  resolutionFilter,
  onResolutionFilterChange,
}: AnnotationViewHeaderProps): JSX.Element => {
  const buttonRef = useRef();
  const [open, setOpen] = useState(false);
  const onClickButton = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);

  return (
    <Row widths={['fit', 'fit', 'fill', 'fit', 'fit']}>
      <Text type="b500">Annotations</Text>
      <Button type="icon" ref={buttonRef} onClick={onClickButton}>
        <Icon tokens={helpTokens} />
      </Button>
      <Column />
      <SearchField
        size="small"
        value={query}
        onChange={onQueryChange}
        placeholder="Search annotations..."
        onSubmit={onQueryChange}
      />
      <ButtonGroup
        size="small"
        value={resolutionFilter}
        onChange={onResolutionFilterChange}
      >
        <ButtonOption value="open">Open</ButtonOption>
        <ButtonOption value="resolved">Resolved</ButtonOption>
      </ButtonGroup>
      <Popover
        anchorNode={buttonRef.current}
        open={open}
        onClose={onClose}
        position="bottom"
      >
        <Column spacing="none">
          <Row spacingInset="none" spacing="none">
            <Text type="h100">Tip:</Text>
            <Text>
              &nbsp; Type the name of a Jira issue in all caps (like
              &quot;PIV-123&quot;) to automatically create a reference to that
              bug.
            </Text>
          </Row>
          <Row spacingInset="none" spacing="none">
            <Text type="h100">Tip:</Text>
            <Text>&nbsp; We support standard markdown.&nbsp;</Text>
            <Link
              href="https://gist.github.com/jonschlinkert/5854601"
              target="_blank"
              rel="noopener noreferrer"
            >
              Markdown Cheat Sheet
            </Link>
          </Row>
        </Column>
      </Popover>
    </Row>
  );
};

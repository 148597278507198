import React from 'react';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Alert from '@amzn/meridian/alert';

export type DashboardErrorProps = {
  action?: React.ReactNode;
  error?: Error;
  message?: string;
};

export const PageError = ({
  action,
  error,
  message,
}: DashboardErrorProps): JSX.Element => {
  return (
    <Column alignmentVertical="center" width="100%" height="60%">
      <Alert type="warning" size="xlarge">
        {message && (
          <Heading level={3} alignment="center">
            {message}
          </Heading>
        )}
        {error && <Text alignment="center">{error.message}</Text>}
      </Alert>
      {action && (
        <Row alignmentHorizontal="center" width="100%">
          {action}
        </Row>
      )}
    </Column>
  );
};

import React from 'react';
import { isNullOrWhitespace, useForm } from '@amzn/dots-core-ui';
import { getUniqueId } from '@/features/Dashboards/helpers';
import { JiraTableWizardConfiguration } from '@/types';
import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Input from '@amzn/meridian/input';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Toggle from '@amzn/meridian/toggle';
import Select, { SelectOption } from '@amzn/meridian/select';
import Textarea from '@amzn/meridian/textarea';
import AddTokens from '@amzn/meridian-tokens/base/icon/plus';
import TrashTokens from '@amzn/meridian-tokens/base/icon/trash';
import { DashboardWizardFormFieldLabel } from './DashboardWizardFormFieldLabel';
import { DashboardWizardBaseControl } from './DashboardWizardBaseControl';
import { jiraFields } from './constants';
import {
  onWizardConfirmFactory,
  getWizardBaseFormConfiguration,
  jiraTableColumnParser,
} from './helpers';
import { DashboardWizardFormProps, JiraTableColumn } from './types';

export const DashboardJiraTableWizardForm = ({
  wizard,
  onCancel,
  onSubmit,
}: DashboardWizardFormProps<JiraTableWizardConfiguration>): JSX.Element => {
  const { hasErrors, handlers, values, errors } = useForm({
    ...getWizardBaseFormConfiguration(wizard),
    show: { initialValue: wizard.show ?? false },
    jql: { initialValue: wizard.jql ?? '' },
    columns: {
      initialValue: jiraTableColumnParser.toFormModel(wizard.col),
      validate: (value: JiraTableColumn[]) => {
        if (value.length === 0) {
          return 'At least one column must be defined';
        }
        if (
          value.some(
            (v) => isNullOrWhitespace(v.name) || isNullOrWhitespace(v.data)
          )
        ) {
          return 'Name and Field must be defined for all columns';
        }
        return '';
      },
    },
  });
  const onChangeColumn = (itemId: string, next: Partial<JiraTableColumn>) =>
    handlers.columns(
      values.columns.map((column: JiraTableColumn) => {
        if (column.id === itemId) {
          return { ...column, ...next };
        }
        return column;
      })
    );
  const onRemoveColumn = (itemId: string) =>
    handlers.columns(
      values.columns.filter(({ id }: JiraTableColumn) => id !== itemId)
    );
  const onAddColumn = () =>
    handlers.columns(
      values.columns.concat({ id: getUniqueId(), name: '', data: '' })
    );
  const onConfirm = () =>
    onSubmit({
      ...onWizardConfirmFactory(wizard, values),
      type: wizard.type,
      show: values.show,
      jql: values.jql,
      col: jiraTableColumnParser.toConfigurationModel(values.columns),
    });
  return (
    <Column>
      <DashboardWizardBaseControl
        wizard={wizard}
        values={values}
        handlers={handlers}
      />
      <DashboardWizardFormFieldLabel label="Show Table by Default">
        <Toggle checked={values.show} onChange={handlers.show} />
      </DashboardWizardFormFieldLabel>
      <DashboardWizardFormFieldLabel
        label="JQL Query"
        hint={
          <Column spacing="100">
            <Text>Define a JQL statement to query Jiras for this table.</Text>
            <Text>
              The JQL is executed against the Jira Project specified in the
              Dashboard&apos;s Filter configuration.
            </Text>
            <Text type="h100">
              Note: Boomerang must have access to the Jira project. Reach out to
              the DOTS team for support.
            </Text>
          </Column>
        }
      >
        <Textarea
          placeholder="JQL Statement..."
          value={values.jql}
          onChange={handlers.jql}
          rows={3}
        />
      </DashboardWizardFormFieldLabel>
      <DashboardWizardFormFieldLabel
        label="Table Columns"
        hint={
          <Column spacing="100">
            <Text>Define the name and Jira property for each column.</Text>
          </Column>
        }
      >
        <Column>
          {errors.columns.length !== 0 && (
            <Alert type="error" size="small">
              {errors.columns}
            </Alert>
          )}
          {values.columns.map(({ id, name, data }: JiraTableColumn) => (
            <Column key={id}>
              <Row widths={['fill', 'fit']}>
                <Row widths="grid-6">
                  <Input
                    label="Name"
                    value={name}
                    onChange={(value) => onChangeColumn(id, { name: value })}
                    error={isNullOrWhitespace(name)}
                  />
                  <Select
                    label="Field"
                    value={data}
                    onChange={(value) => onChangeColumn(id, { data: value })}
                    error={isNullOrWhitespace(data)}
                  >
                    {jiraFields.map((field) => (
                      <SelectOption
                        key={field.data}
                        label={field.name}
                        value={field.data}
                      />
                    ))}
                  </Select>
                </Row>
                <Button
                  type="secondary"
                  onClick={() => onRemoveColumn(id)}
                  disabled={values.columns.length === 1}
                >
                  <Icon tokens={TrashTokens}>Remove this column</Icon>
                </Button>
              </Row>
              {(isNullOrWhitespace(name) || isNullOrWhitespace(data)) && (
                <Alert type="error" size="small">
                  Name and Field must be defined
                </Alert>
              )}
            </Column>
          ))}
        </Column>
      </DashboardWizardFormFieldLabel>
      <Button type="tertiary" onClick={onAddColumn}>
        <Icon tokens={AddTokens}>Add a new column</Icon>
      </Button>
      <Row alignmentHorizontal="right" alignmentVertical="center" widths="fit">
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={hasErrors} onClick={onConfirm}>
          Confirm
        </Button>
      </Row>
    </Column>
  );
};

import React from 'react';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import { TestSourcePropertySelect } from '@/components';
import { useDashboard } from '@/features/Dashboards/contexts';
import { LineChartWizardConfiguration } from '@/types';
import {
  CoreInput,
  CoreTextarea,
  isNullOrWhitespace,
  useForm,
} from '@amzn/dots-core-ui';
import { DashboardWizardBaseControl } from './DashboardWizardBaseControl';
import { DashboardWizardFormFieldLabel } from './DashboardWizardFormFieldLabel';
import {
  onWizardConfirmFactory,
  getWizardBaseFormConfiguration,
} from './helpers';
import { DashboardWizardFormProps } from './types';

export const DashboardLineChartWizardForm = ({
  wizard,
  onCancel,
  onSubmit,
}: DashboardWizardFormProps<LineChartWizardConfiguration>): JSX.Element => {
  const { dashboard } = useDashboard();
  const { hasErrors, handlers, values, errors } = useForm({
    ...getWizardBaseFormConfiguration(wizard),
    groupKey: {
      initialValue: wizard.group_key ?? '',
    },
    xAxisProperty: {
      initialValue: wizard.xAxis?.data ?? '',
      validate: (value) =>
        isNullOrWhitespace(value) ? 'X Axis is required' : '',
    },
    yAxisUnit: {
      initialValue: wizard.yAxis?.unit ?? '',
      validate: (value) =>
        isNullOrWhitespace(value) ? 'Y Axis Unit is required' : '',
    },
    yAxisLabel: {
      initialValue: wizard.yAxis?.label ?? '',
      validate: (value) =>
        isNullOrWhitespace(value) ? 'Y Axis Label is required' : '',
    },
    lineExpression: {
      initialValue: wizard.line?.value ?? '',
      validate: (value) =>
        isNullOrWhitespace(value) ? 'Line Expression is required' : '',
    },
    lineSplitKey: {
      initialValue: wizard.line?.split_key ?? '',
    },
  });
  const onConfirm = () =>
    onSubmit({
      ...onWizardConfirmFactory(wizard, values),
      type: wizard.type,
      group_key: values.groupKey,
      xAxis: {
        data: values.xAxisProperty,
      },
      yAxis: {
        label: values.yAxisLabel,
        unit: values.yAxisUnit,
      },
      line: {
        value: values.lineExpression,
        split_key: values.lineSplitKey,
      },
    });
  return (
    <Column>
      <DashboardWizardBaseControl
        wizard={wizard}
        values={values}
        handlers={handlers}
      />
      <DashboardWizardFormFieldLabel label="X Axis">
        <TestSourcePropertySelect
          label="KPI Property"
          testSource={dashboard.testsources}
          value={values.xAxisProperty}
          onChange={handlers.xAxisProperty}
          errorMessage={errors.xAxisProperty}
        />
      </DashboardWizardFormFieldLabel>
      <DashboardWizardFormFieldLabel label="Y Axis">
        <Row widths="grid-6">
          <CoreInput
            label="Label"
            value={values.yAxisLabel}
            onChange={handlers.yAxisLabel}
            errorMessage={errors.yAxisLabel}
          />
          <CoreInput
            label="Unit"
            value={values.yAxisUnit}
            onChange={handlers.yAxisUnit}
            errorMessage={errors.yAxisUnit}
          />
        </Row>
      </DashboardWizardFormFieldLabel>
      <DashboardWizardFormFieldLabel
        label="Lines"
        hint={
          <Column spacing="100">
            <Text>
              Calculate Y axis values for each line series based on an
              expression.
            </Text>
            <Text>
              Generate multiple series by splitting the data based on a split
              key.
            </Text>
          </Column>
        }
      >
        <CoreTextarea
          label="Expression"
          value={values.lineExpression}
          onChange={handlers.lineExpression}
          errorMessage={errors.lineExpression}
        />
        <TestSourcePropertySelect
          label="Split Key"
          testSource={dashboard.testsources}
          value={values.lineSplitKey}
          onChange={handlers.lineSplitKey}
          errorMessage={errors.lineSplitKey}
        />
      </DashboardWizardFormFieldLabel>
      <DashboardWizardFormFieldLabel
        label="Chart Group"
        hint={
          <Column>
            <Text>
              Generate multiple charts by splitting the data based on a group
              key.
            </Text>
          </Column>
        }
      >
        <TestSourcePropertySelect
          label="Group Key"
          testSource={dashboard.testsources}
          value={values.groupKey}
          onChange={handlers.groupKey}
          errorMessage={errors.groupKey}
        />
      </DashboardWizardFormFieldLabel>
      <Row alignmentHorizontal="right" alignmentVertical="center" widths="fit">
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={hasErrors} onClick={onConfirm}>
          Confirm
        </Button>
      </Row>
    </Column>
  );
};

/* eslint-disable no-underscore-dangle */
import {
  BaseData,
  BaseDataPropertyIndexMap,
  DashboardFilterGoalGroup,
  KpiProperty,
  KpiPropertyValue,
} from '@/types';
import { parseExpression, calculate } from './aggregation';
import { groupByProperties } from './helpers';
import { GlobalCalculatedData } from './types';

export type MatchedGoal = {
  goal: string;
  value: string;
  color?: string;
};

export const goalMetColor = '#12857A';

export const goalNotMetColor = '#CC0C39';

export const goalDisplayTypeExpressions: Record<
  string,
  {
    label: string;
    expression: string;
    unit?: string;
  }
> = {
  TOTAL: {
    label: 'Total',
    expression: 'sum(extract(input, kpi.value))',
  },
  PASS_RATE: {
    label: 'Percentage',
    unit: '%',
    expression:
      'percentage(count(extract(filter(input, kpi.value, is, PASS), kpi.value)), count(extract(input, kpi.value)))',
  },
  AVG_OVER_RUNS: {
    label: 'Avg Over Runs',
    unit: ' / runs',
    expression: 'average(sum(group(input, id, kpi.value)))',
  },
  AVG_PER_RUN: {
    label: 'Avg Per Run',
    unit: ' / run',
    expression: 'average(average(group(input, id, kpi.value)))',
  },
  AVG_PER_HOUR: {
    label: 'Avg Per Hour',
    unit: ' / hr',
    expression: 'divide(_kpiCount, max(_durationPerHour, 1.0))',
  },
};

/**
 * Creates a KEY which can be used to identify the group of properties used to
 * slice the data.
 */
export const createGroupByPropertiesKey = (
  propertyValues: Partial<Record<KpiProperty, KpiPropertyValue>>
): string =>
  groupByProperties.reduce(
    (result, prop) => `${result}:${propertyValues[prop]}`,
    ''
  );

/**
 * Determine the color of a value based on a goal
 */
const getGoalColor = (
  value: number,
  goal: DashboardFilterGoalGroup
): string | undefined => {
  if (!goal.upper && !goal.lower) {
    return undefined;
  }
  if (
    (goal.upper && value > Number(goal.upper)) ||
    (goal.lower && value < Number(goal.lower))
  ) {
    return goalNotMetColor;
  }
  return goalMetColor;
};

/**
 * Create a string representation of a goal's bounds
 */
const goalToString = (goal: DashboardFilterGoalGroup) => {
  if (goal.lower && goal.upper) {
    return `${goal.lower} ≤ x ≤ ${goal.upper}`;
  }
  if (goal.lower) {
    return `${goal.lower} ≤ x`;
  }
  if (goal.upper) {
    return `x ≤ ${goal.upper}`;
  }
  return '';
};

/**
 * Detemine if the goal matches the KPI by name
 * Goal is a match if goal.kpi name is a wildcard, KPI name equals goal.kpi,
 * KPI name contains goal.kpi or goal.kpi is a regex match
 */
const doesGoalMatchKpiName = (
  goal: DashboardFilterGoalGroup,
  kpiName: string
) => {
  if (!goal.kpi) {
    return false;
  }
  if (
    goal.kpi === '*' ||
    goal.kpi === kpiName ||
    kpiName.indexOf(goal.kpi) !== -1
  ) {
    return true;
  }
  try {
    // RegExp constructor could throw if goal.kpi is not a valid regex
    return new RegExp(goal.kpi).test(kpiName);
  } catch (e) {
    return false;
  }
};

/**
 * Determine if the goal matches the KPI by device type
 * Goal is a match if the goal does not have a device type or if the goal's
 * device types contain the KPI device type
 */
const doesGoalMatchDeviceType = (
  goal: DashboardFilterGoalGroup,
  kpiDeviceType: string
) =>
  !goal.deviceType ||
  goal.deviceType.length === 0 ||
  (goal.deviceType && goal.deviceType.includes(kpiDeviceType));

/**
 * Find a matching goal for the target collection of KPIs
 */
export const findMatchingGoal = (
  goalGroups: DashboardFilterGoalGroup[],
  propertyIndexMap: BaseDataPropertyIndexMap,
  goalBaseData: BaseData,
  globalData: GlobalCalculatedData
): MatchedGoal => {
  const result: MatchedGoal = { goal: '', value: '', color: undefined };

  if (goalBaseData.length <= 1) {
    result.value = 'No Data';
    return result;
  }

  const kpiName = goalBaseData[1][propertyIndexMap['kpi.name']] as string;
  const kpiDeviceType = goalBaseData[1][
    propertyIndexMap['data.device.type']
  ] as string;
  const groupedPropertiesMap: Partial<Record<
    KpiProperty,
    KpiPropertyValue
  >> = {};
  // Creating a map of properties and their values so that a key can be
  // generated to fetch total duration for the group from global data.
  groupByProperties.forEach((property) => {
    groupedPropertiesMap[property] =
      goalBaseData[1][propertyIndexMap[property]];
  });
  // This global data might not be available for all pages. For example, its not
  // available for KPI Bucket detail page.
  let { _durationPerHour } = globalData;
  if (globalData._globalGroupPropertyData !== undefined) {
    const groupPropertyKey = createGroupByPropertiesKey(groupedPropertiesMap);
    const totalDurationForGroup =
      globalData._globalGroupPropertyData[groupPropertyKey];
    _durationPerHour = totalDurationForGroup;
  }
  // lets sort the goals, ordering all non-wildcard goals first, making the more
  // specific goal come first in our matched list later on
  goalGroups.sort((a, b) => {
    if (a.kpi === '*') {
      return 1;
    }
    if (b.kpi === '*') {
      return -1;
    }
    return 0;
  });
  const matchingGoals = goalGroups.filter(
    (goal) =>
      doesGoalMatchKpiName(goal, kpiName) &&
      doesGoalMatchDeviceType(goal, kpiDeviceType)
  );

  if (matchingGoals.length === 0) {
    result.goal = 'No Goal';
    return result;
  }

  const validGoals = matchingGoals.filter(
    (goal) => goal.display && goal.display in goalDisplayTypeExpressions
  );

  if (validGoals.length === 0) {
    result.goal = 'Goal not supported';
    return result;
  }

  // because we ordered the goals above, we can take the first goal in our
  // matched list and assume that it is the most specific goal we could find
  const [goal] = validGoals;
  if (!(goal.display in goalDisplayTypeExpressions)) {
    result.goal = 'Goal not supported';
    return result;
  }

  const goalExpression = goalDisplayTypeExpressions[goal.display];
  let goalExpressionRoot;
  try {
    goalExpressionRoot = parseExpression(goalExpression.expression);
  } catch (error) {
    console.error(error);
    result.value = 'Bad Goal Expression';
    return result;
  }

  try {
    goalExpressionRoot.reset();
    calculate(
      goalBaseData,
      { ...globalData, _durationPerHour },
      goalExpressionRoot
    );
  } catch (error: any) {
    if (error.message === 'No data!') {
      goalExpressionRoot.value = 0;
    } else {
      console.error(error);
      result.value = 'Bad Data';
      return result;
    }
  }

  const expressionResult = Number(goalExpressionRoot.value);
  const valueWithUnit = goalExpression.unit
    ? `${expressionResult}${goalExpression.unit}`
    : `${expressionResult}`;

  result.value = `${valueWithUnit} (${goalBaseData.length - 1})`;
  result.color = getGoalColor(expressionResult, goal);
  result.goal = goalToString(goal);

  return result;
};

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { useHttp, HttpClient } from '@/client';
import { DashboardEmailReportConfiguration } from '@/types';
import { useDashboard } from './useDashboard';

const getDashboardEmailReports = async (
  http: HttpClient,
  dashboardId?: string
): Promise<DashboardEmailReportConfiguration[]> => {
  const { data, status } = await http.get(
    `dashboard/email_report?dashboard_id=${dashboardId}`
  );
  if (status.code === 200) {
    return data.configs;
  }
  throw new Error(data.message);
};

export const useDashboardEmailReports = (): UseQueryResult<
  DashboardEmailReportConfiguration[],
  Error
> => {
  const { http } = useHttp();
  const queryClient = useQueryClient();
  const { dashboardName: rawName } = useParams<{
    dashboardName: string;
  }>();
  const dashboardName = useMemo(() => decodeURIComponent(rawName), [rawName]);
  const dashboardResult = useDashboard({
    dashboardId: dashboardName,
  });
  const reportResult = useQuery<DashboardEmailReportConfiguration[], Error>({
    queryKey: ['dashboardEmailReports', { dashboardName }],
    queryFn: () => getDashboardEmailReports(http, dashboardResult.data?.id),
    onSuccess: (data) =>
      data.forEach((report) =>
        queryClient.setQueryData(
          [
            'dashboardEmailReports',
            { dashboardName, reportId: report.report_id },
          ],
          report
        )
      ),
    enabled: dashboardResult.data !== undefined,
  });
  if (dashboardResult.data) {
    return reportResult;
  }
  return { ...dashboardResult, data: undefined, refetch: reportResult.refetch };
};

import React from 'react';
import Tooltip from '@amzn/meridian/tooltip';
import Icon from '@amzn/meridian/icon';
import starTokens from '@amzn/meridian-tokens/base/icon/star';
import { useUpdateUserDashboardFavoriteStatus, useUserProfile } from '@/hooks';
import { CoreAsyncButton, useToaster } from '@amzn/dots-core-ui';
import { DashboardButtonProps } from './types';

export const DashboardFavoriteButton = ({
  id,
  name,
}: Omit<DashboardButtonProps, 'type'>): JSX.Element => {
  const { data: profile } = useUserProfile();
  const { openToast } = useToaster();
  const { mutateAsync, isLoading } = useUpdateUserDashboardFavoriteStatus();
  const isDashboardFavorite = profile?.dashboards.includes(name) ?? false;
  const hintText = isDashboardFavorite
    ? 'remove from favorites'
    : 'add to favorites';
  const onClick = async () => {
    try {
      await mutateAsync({
        dashboardId: id,
        isFavorite: !isDashboardFavorite,
      });
      openToast({
        message: `Successfully ${
          isDashboardFavorite ? 'removed' : 'added'
        } ${name} as a favorite!`,
        type: 'success',
      });
    } catch (error) {
      openToast({
        message: `Failed to ${isDashboardFavorite ? 'remove' : 'add'}
        ${name} as a favorite, please try again later`,
        type: 'error',
      });
    }
  };
  return (
    <Tooltip position="bottom" title={hintText}>
      <CoreAsyncButton
        isLoading={isLoading || !profile}
        onClick={onClick}
        size="small"
        type={isDashboardFavorite ? 'primary' : 'icon'}
        label={hintText}
      >
        <Icon tokens={starTokens}>{hintText}</Icon>
      </CoreAsyncButton>
    </Tooltip>
  );
};

import React, { useMemo, useState } from 'react';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Expander from '@amzn/meridian/expander';
import Text from '@amzn/meridian/text';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import { CorePageLoader } from '@amzn/dots-core-ui';
import {
  useDashboardFilteredWizardData,
  useDashboardTableState,
} from '@/features/Dashboards/hooks';
import { JiraTableWizardConfiguration } from '@/types';
import { DashboardTableActionBar } from '../DashboardTableActionBar';
import { getWizardData, useJiraTableJiras } from './data';

export type DashboardJiraTableWizardProps = {
  configuration: JiraTableWizardConfiguration;
};

export const DashboardJiraTableWizard = ({
  configuration,
}: DashboardJiraTableWizardProps): JSX.Element => {
  const [tableState, tableStateDispatch] = useDashboardTableState();
  const [state, setState] = useState({
    isOpen: configuration.show,
  });
  const { pagination, sortColumn, sortDirection } = tableState;
  const { jiras, isLoading } = useJiraTableJiras(configuration);
  const wizardData = useMemo(() => getWizardData(configuration, jiras), [
    configuration,
    jiras,
  ]);
  const filteredData = useDashboardFilteredWizardData(wizardData, tableState);
  return (
    <Expander
      open={state.isOpen}
      onChange={() => setState((prev) => ({ ...prev, isOpen: !prev.isOpen }))}
      title="Show Jira Table"
      type="inline"
    >
      {isLoading && <CorePageLoader />}
      {!isLoading && (
        <Table
          spacing="small"
          showDividers
          headerRows={1}
          sortColumn={sortColumn.toString()}
          sortDirection={sortDirection}
          onSort={(payload) =>
            tableStateDispatch({
              type: 'update-sort-state',
              payload: {
                sortColumn: Number(payload.sortColumn),
                sortDirection: payload.sortDirection,
              },
            })
          }
        >
          <DashboardTableActionBar
            dataLength={filteredData.length}
            tableState={tableState}
            tableStateDispatch={tableStateDispatch}
          />
          <TableRow>
            {configuration.col.map((cell, index) => (
              <TableCell key={cell.name} sortColumn={index.toString()}>
                {cell.name}
              </TableCell>
            ))}
          </TableRow>
          {filteredData
            .slice(pagination.startIndex, pagination.endIndex)
            .map((row) => (
              <TableRow key={row.id}>
                {row.cells.map(({ key, href, content }) => (
                  <TableCell key={key}>
                    {key === 'key' && (
                      <Row minWidth="max-content">
                        <Link
                          type="secondary"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={href}
                        >
                          {content}
                        </Link>
                      </Row>
                    )}
                    {(key === 'priority' || key === 'status') && (
                      <Row alignmentVertical="center">
                        <Text>{content}</Text>
                        <img src={href} alt={content} width="20" height="20" />
                      </Row>
                    )}
                    {key !== 'key' &&
                      key !== 'priority' &&
                      key !== 'status' && <Text>{content}</Text>}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </Table>
      )}
    </Expander>
  );
};

import React, { useMemo } from 'react';
import { css } from 'emotion';
import Column from '@amzn/meridian/column';
import { replaceJiraID } from '@/helpers';
import { MarkdownContent } from './MarkdownContent';

const style = css({
  whiteSpace: 'pre-wrap',
});

export type DashboardAnnotationLegacyContentProps = {
  content: string;
};

export const DashboardAnnotationLegacyContent = ({
  content,
}: DashboardAnnotationLegacyContentProps): JSX.Element => {
  const formatedNotes = useMemo(() => replaceJiraID(content), [content]);
  return (
    <Column overflowY="auto" width="fill" className={style}>
      <MarkdownContent text={formatedNotes} />
    </Column>
  );
};

import React from 'react';
import { useForm } from '@amzn/dots-core-ui';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import { KpiLogWizardConfiguration } from '@/types';
import { DashboardWizardBaseControl } from './DashboardWizardBaseControl';
import {
  onWizardConfirmFactory,
  getWizardBaseFormConfiguration,
} from './helpers';
import { DashboardWizardFormProps } from './types';

export const DashboardKpiLogWizardForm = ({
  wizard,
  onCancel,
  onSubmit,
}: DashboardWizardFormProps<KpiLogWizardConfiguration>): JSX.Element => {
  const { hasErrors, handlers, values } = useForm({
    ...getWizardBaseFormConfiguration(wizard),
  });
  const onConfirm = () =>
    onSubmit({
      ...onWizardConfirmFactory(wizard, values),
      type: wizard.type,
    });
  return (
    <Column>
      <DashboardWizardBaseControl
        wizard={wizard}
        values={values}
        handlers={handlers}
      />
      <Row alignmentHorizontal="right" alignmentVertical="center" widths="fit">
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={hasErrors} onClick={onConfirm}>
          Confirm
        </Button>
      </Row>
    </Column>
  );
};

import React from 'react';
import Text from "@amzn/meridian/text"
import { css } from 'emotion';
import { AnnotationTemplateContent, AnnotationTemplateContentField } from '@/types';
import { keys } from '@amzn/dots-core-ui';
import { DashboardAnnotationContentString } from './DashboardAnnotationContentString';
import { DashboardAnnotationContentDate } from './DashboardAnnotationContentDate';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';

const style = css({
  whiteSpace: 'pre-wrap',
});

const Content = ({content: {type, value}}: {content: AnnotationTemplateContentField}) => {
  switch(type) {
    case 'string':
      return ( <DashboardAnnotationContentString value={value} /> );
    case 'choice':
      return ( <DashboardAnnotationContentString value={value} /> );
    case 'date':
      return ( <DashboardAnnotationContentDate value={value} /> );
    case 'jira':
      return ( <DashboardAnnotationContentString value={value} /> );
    }
}

export type DashboardAnnotationContentProps = {
  content: AnnotationTemplateContent;
};

export const DashboardAnnotationContent = ({
  content,
}: DashboardAnnotationContentProps): JSX.Element => {

  return <>
    {keys(content).map( (contentKey) => 
      <Row widths={["fit", "fit"]} key={contentKey} >
        <Column ><Text>{contentKey}:</Text></Column>
        <Column className={style} >
          <Content content={content[contentKey]} />
        </Column>
      </Row>)
    }
  </>;
};
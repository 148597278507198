import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import Row from '@amzn/meridian/row';
import { useToaster } from '@amzn/dots-core-ui';
import { useHttp } from '@/client';
import { translatorClient } from '@/features/Translators/client';
import {
  TranslatorTestRequest,
  TranslatorTestResponse,
} from '@/features/Translators/types';
import { TranslatorTestForm } from './TranslatorTestForm';
import { TranslatorLocalTestResults } from './TranslatorLocalTestResults';

export type TranslatorTesterProps = {
  name: string;
  versions: string[];
  source: string;
};

export const TranslatorTester = ({
  name,
  versions,
  source,
}: TranslatorTesterProps): JSX.Element => {
  const { http } = useHttp();
  const { openToast } = useToaster();
  const [selectedVersion, setSelectedVersion] = useState('');
  const [tests, setTests] = useState<TranslatorTestResponse>([]);
  const { mutateAsync } = useMutation<
    TranslatorTestResponse,
    Error,
    TranslatorTestRequest
  >(
    (payload: TranslatorTestRequest) =>
      translatorClient.testTanslator(http, payload),
    {
      onMutate: () => {
        setTests([]);
      },
      onSuccess: (data, variables) => {
        openToast({
          title: 'Success!',
          message: `${data.length} ${variables.destination} test(s) were started for ${name}`,
          type: 'success',
        });
      },
      onError: (error) => {
        openToast({
          title: 'Error!',
          message: `Failed to start the test for ${name}: ${error.message}`,
          type: 'error',
        });
      },
    }
  );
  const onSubmit = useCallback(
    async (request: TranslatorTestRequest) => {
      setSelectedVersion(request.version);
      const testResponse = await mutateAsync(request);
      if (request.destination === 'LOCAL') {
        setTests(testResponse);
      } else {
        setTests([]);
      }
    },
    [mutateAsync]
  );
  return (
    <Row widths={['grid-3', 'grid-9']} alignmentVertical="top">
      <TranslatorTestForm
        name={name}
        versions={versions}
        source={source}
        onSubmit={onSubmit}
      />
      <TranslatorLocalTestResults
        name={name}
        version={selectedVersion}
        source={source}
        tests={tests}
      />
    </Row>
  );
};

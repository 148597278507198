/* eslint-disable camelcase */
import { useQuery, UseQueryResult } from 'react-query';
import { useHttp, HttpClient } from '@/client';
import {
  BaseData,
  BaseDataPropertyIndexMap,
  FlatKpi,
  KpiFilter,
  KpiProperty,
  KpiPropertyValue,
} from '@/types';

export type KpiFilterResult = {
  baseData: BaseData;
  propertyIndexMap: BaseDataPropertyIndexMap;
};

export type KpiQueryOptions<T> = {
  getAllPages: boolean;
  enabled: boolean;
  select?: (data: KpiFilterResult) => T;
};

export type GetKpisArgs<T> = {
  filter: KpiFilter;
  importedDashboards?: string[];
  options?: KpiQueryOptions<T>;
};

/**
 * Combine multiple pages from 'kpi/filter' into one, ordering each kpi row
 * according to a superset of each pages' header row
 */
export const normalizeBaseDataPages = (pages: unknown[][][]): unknown[][] => {
  // filter "empty" results, i.e. results that only contain [['total', 'pass']]
  const validPages = pages.filter((r) => r && r.length > 1);
  // not all result sets have the same header schema so we need to create a
  // super set of headers which we will use to build a sparse matrix of all
  // results
  const headerSuperSet = Array.from(new Set(validPages.flatMap((r) => r[0])));
  // now that we have a single list of headers, we need to reorder kpis that
  // have that have a value for a specific header or inject a default value
  const resultsSuperSet = validPages.flatMap((result) => {
    const [currentHeader, ...currentKpis] = result;
    const getHeadingIndex = (h: unknown) => currentHeader.indexOf(h);
    return currentKpis.map((kpi) => {
      return headerSuperSet.map((h) => {
        const headerIndex = getHeadingIndex(h);
        // default value of empty string when this kpi does not contain a
        // a value for the current header
        return headerIndex !== -1 ? kpi[headerIndex] : '';
      });
    });
  });
  return [headerSuperSet, ...resultsSuperSet];
};

export const mapBaseDataPageToFlatKpi = (page: BaseData): FlatKpi[] => {
  const [header, ...kpiValues] = page;
  const indexOf = (prop: KpiProperty): number => header.indexOf(prop);
  const kpis = kpiValues.map(
    (kpi: KpiPropertyValue[]) => (prop: KpiProperty): KpiPropertyValue => {
      const index = indexOf(prop);
      return index === -1 ? '' : kpi[index];
    }
  );
  return kpis;
};

export const getKpiFilterPages = async (
  http: HttpClient,
  query: KpiFilter
): Promise<[][][]> => {
  const { data, status } = await http.get('kpi/filter', query);
  // request failed, just return an empty array
  if (status.code !== 200 || !data?.data || data?.num_page < 1) {
    return [];
  }
  if (data.num_page > 50) {
    throw new Error(
      'The requested KPIs are more than 150000, please update the current filters to reduce the number of KPIs.'
    );
  }
  const pages = [data.data];
  const query_id = [data.query_id];
  const pageRequests = Array(data.num_page - 1)
    .fill(undefined)
    .map((_, i) => http.get('kpi/filter', { ...query, page: i + 2, query_id: query_id }));
  // parallelize the requests for the remaining pages
  const pageResponses = await Promise.all(pageRequests);
  pages.push(
    ...pageResponses
      // eslint-disable-next-line no-prototype-builtins
      .filter((r) => r.status.code === 200 && r.data.hasOwnProperty('data'))
      .map((r) => r.data.data)
  );
  return pages;
};

export const getKpisPayload = async (
  http: HttpClient,
  query: KpiFilter,
  importedDashboards: string[]
): Promise<KpiFilterResult> => {
  let pages = [];
  if (
    'dashboard_name' in query &&
    typeof query.dashboard_name === 'string' &&
    importedDashboards.length
  ) {
    const callerDashboardId = query.dashboard_name;
    const responses = await Promise.all(
      importedDashboards.map((dashboard_name) =>
        getKpiFilterPages(http, { ...query, dashboard_name, callerDashboardId })
      )
    );
    pages = responses.flatMap((r) => r);
  } else {
    pages = await getKpiFilterPages(http, query);
  }
  const baseData = (normalizeBaseDataPages(pages) as unknown) as BaseData;
  const propertyIndexMap = baseData[0].reduce((previous, current, index) => {
    // eslint-disable-next-line no-param-reassign
    previous[current] = index;
    return previous;
  }, {} as BaseDataPropertyIndexMap);
  return {
    baseData: (baseData as unknown) as BaseData,
    propertyIndexMap,
    // kpis: mapBaseDataPageToFlatKpi(baseData),
  };
};

export const useKpis = <T = KpiFilterResult>({
  filter,
  importedDashboards = [],
  options = {
    getAllPages: true,
    enabled: true,
    select: undefined,
  },
}: GetKpisArgs<T>): UseQueryResult<T, Error> => {
  const { http } = useHttp();
  return useQuery({
    queryFn: () => getKpisPayload(http, filter, importedDashboards),
    queryKey: ['kpi', { ...filter, importedDashboards }],
    enabled: options.enabled,
    select: options.select,
    structuralSharing: false,
  });
};

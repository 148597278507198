import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useHttp, HttpClient } from '@/client';
import { UserProfile } from '@/types';

export type UserDashboardFavoriteStatusPayload = {
  dashboardId: string;
  isFavorite: boolean;
};

const updateUserDashboardFavoriteStatus = async (
  http: HttpClient,
  dashboardId: string,
  isFavorite: boolean
): Promise<UserProfile> => {
  const { data, status } = await http.patch('user/dashboards', {
    dashboard_id: dashboardId,
    favorite: isFavorite.toString(),
  });
  if (status.code === 200) {
    return data;
  }
  throw new Error("Failed to update the user's profile");
};

export const useUpdateUserDashboardFavoriteStatus = (): UseMutationResult<
  UserProfile,
  unknown,
  UserDashboardFavoriteStatusPayload,
  {
    previousUser: UserProfile;
    nextUser: UserProfile;
  }
> => {
  const queryClient = useQueryClient();
  const { http } = useHttp();
  return useMutation(
    ({ dashboardId, isFavorite }: UserDashboardFavoriteStatusPayload) =>
      updateUserDashboardFavoriteStatus(http, dashboardId, isFavorite),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['user']);
      },
    }
  );
};

import React from 'react';
import { DashboardSelect } from '@/components';
import { DashboardFormRow } from '../DashboardFormRow';
import { DashboardFormSection } from '../DashboardFormSection';
import { DashboardFiltersFormControlProps } from './types';

export const DashboardImportedDashboardsControl = ({
  value,
  onChange,
  errorMessage,
  validationState,
}: DashboardFiltersFormControlProps<string[]>): JSX.Element => {
  return (
    <DashboardFormSection
      title="Imported Dashboards"
      descriptionText="Import other dashboard filters"
      descriptionLink="https://quip-amazon.com/eZruAhb4Ztqm"
    >
      <DashboardFormRow label="Dashboards">
        <DashboardSelect
          value={value}
          onChange={onChange as (v: string | string[]) => void}
          errorMessage={errorMessage}
          validationState={validationState}
        />
      </DashboardFormRow>
    </DashboardFormSection>
  );
};

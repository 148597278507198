import { useMutation, UseMutationResult } from 'react-query';
import { useHttp, HttpClient } from '@/client';
import { Dashboard } from '@/types';

const deleteDashboard = async (
  http: HttpClient,
  dashboardId: Dashboard['id']
): Promise<void> => {
  const { data, status } = await http.delete('dashboard', dashboardId as any);
  if (status.code === 200) {
    return data;
  }
  throw new Error(`Failed to delete dashboard ${dashboardId}`);
};

export const useDashboardDelete = (): UseMutationResult<
  void,
  unknown,
  string,
  unknown
> => {
  const { http } = useHttp();
  return useMutation((dashboardId: Dashboard['id']) =>
    deleteDashboard(http, dashboardId)
  );
};

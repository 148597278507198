/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import Column from '@amzn/meridian/column';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';
import { getUniqueId } from '@/features/Dashboards/helpers';
import { DashboardFormSection } from '../DashboardFormSection';
import { DashboardTestrunFilterControl } from './DashboardTestrunFilterControl';
import { DashboardFiltersFormControlProps, TestrunRangeFilter } from './types';

export type DashboardTestrunFilterCollectionControlProps = DashboardFiltersFormControlProps<
  Partial<TestrunRangeFilter>[]
> & {
  canBeEmpty?: boolean;
};

export const DashboardTestrunFilterCollectionControl = ({
  testSource,
  value,
  onChange,
  canBeEmpty = false,
}: DashboardTestrunFilterCollectionControlProps): JSX.Element => {
  const onAddClick = useCallback(
    () => onChange(value.concat({ id: getUniqueId() })),
    [onChange, value]
  );
  const onUpdate = useCallback(
    (index: number) => (filter: TestrunRangeFilter) => {
      const next = [...value];
      next[index] = filter;
      onChange(next);
    },
    [onChange, value]
  );
  const onRemove = useCallback(
    (index: number) =>
      index === 0 && value.length < 2 && !canBeEmpty
        ? undefined
        : () => onChange(value.filter((_, i) => i !== index)),
    [canBeEmpty, onChange, value]
  );
  return (
    <DashboardFormSection
      title="Filters"
      descriptionText="Define filters based on propety names, filter type and property value(s)"
      descriptionLink="https://quip-amazon.com/eZruAhb4Ztqm/Boomerang-Pivot-v2-User-Guide#FPT9CAMlYzA"
    >
      <Column spacing="400">
        {value.map((filter, i) => (
          <DashboardTestrunFilterControl
            key={filter.id}
            testSource={testSource}
            testrunRangeFilter={filter}
            onChange={onUpdate(i)}
            onRemove={onRemove(i)}
          />
        ))}
      </Column>
      <Button type="primary" onClick={onAddClick}>
        <Icon tokens={plusTokens}>Add a new filter</Icon>
      </Button>
    </DashboardFormSection>
  );
};

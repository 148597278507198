import React, { useMemo, useReducer } from 'react';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';
import { useWizardSelection } from '@/features/Dashboards/contexts';
import { useDashboardWizardData } from '@/features/Dashboards/hooks';
import { PivotTabWizardConfiguration } from '@/types';
import { createWizardData } from './data';
import { initialState, allOption, reducer } from './state';

export type DashboardPivotTabWizardProps = {
  configuration: PivotTabWizardConfiguration;
};

export const DashboardPivotTabWizard = ({
  configuration,
}: DashboardPivotTabWizardProps): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const wizardData = useDashboardWizardData(configuration, createWizardData);
  const { updateSelection } = useWizardSelection(configuration.id);
  const endIndex = useMemo(() => (state.showAll ? undefined : 10), [
    state.showAll,
  ]);
  if (wizardData === undefined) {
    return <span>no data</span>;
  }
  return (
    <Row width="100%" spacing="200" wrap="down">
      {[allOption, ...wizardData.options.slice(0, endIndex)].map((option) => (
        <Button
          size="small"
          key={option.label}
          onClick={() => {
            dispatch({ type: 'update-selected-option', payload: option });
            updateSelection(option.selectionContext);
          }}
          type={
            state.selectedOption.label === option.label
              ? 'primary'
              : 'secondary'
          }
        >
          {option.label}
        </Button>
      ))}
      {wizardData.options.length > 10 && (
        <Button
          size="small"
          type="secondary"
          onClick={() => {
            dispatch({
              type: 'update-show-all',
              payload: !state.showAll,
            });
          }}
        >
          {state.showAll ? 'Show less' : 'Show all'}
        </Button>
      )}
    </Row>
  );
};

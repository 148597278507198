import { DashboardLayout, DashboardWizardConfiguration } from '@/types';

export const defaultDashboardLayouts: DashboardLayout[] = [
  {
    name: 'Historical Summary',
    layout: [
      {
        widths: ['12'],
        heights: ['fit', 'fit', 'fit', 'fit', 'fit', 'fit'],
      },
    ],
  },
  {
    layout: [
      {
        widths: ['12'],
        heights: ['fit', 'fit', 'fit', 'fit'],
      },
    ],
    name: 'Testcase Explorer',
  },
  {
    layout: [
      {
        widths: ['12'],
        heights: ['fit', 'fit', 'fit', 'fit', 'fit', 'fit'],
      },
    ],
    name: 'Testrun Summary',
  },
];

export const defaultDashboardWizards: DashboardWizardConfiguration[] = [
  {
    id: '0-0-0',
    type: 'pivottab',
    tabIndex: 0,
    sectionIndex: 0,
    position: 0,
    data: ['data.build.project'],
  },
  {
    id: '0-0-1',
    tabIndex: 0,
    sectionIndex: 0,
    position: 1,
    col: [
      {
        name: 'Jira ID',
        data: 'key',
      },
      {
        name: 'Created',
        data: 'created',
      },
      {
        name: 'Last Activity',
        data: 'updated',
      },
      {
        name: 'Priority',
        data: 'priority',
      },
      {
        name: 'Status',
        data: 'status',
      },
      {
        name: 'Assignee',
        data: 'assignee',
      },
      {
        name: 'Summary',
        data: 'summary',
      },
    ],
    show: false,
    type: 'jiratable',
  },
  {
    id: '0-0-4',
    tabIndex: 0,
    sectionIndex: 0,
    position: 4,
    parent: '0-0-0',
    type: 'linechart',
    xAxis: {
      data: 'data.build.version',
    },
    yAxis: {
      unit: '%',
      label: 'Pass rate',
    },
    line: {
      value:
        'percentage(sum(max(group(input, data.testrun_id, data.result.pass))), sum( sum(max(group(input, data.testrun_id, data.result.pass))), sum(max(group(input, data.testrun_id, data.result.fail))), sum(max(group(input, data.testrun_id, data.result.skip))) ))',
    },
  },
  {
    id: '0-0-3',
    type: 'pivottable',
    tabIndex: 0,
    sectionIndex: 0,
    position: 3,
    parent: '0-0-2',
    row: [
      {
        data: 'data.test_suite',
        name: 'Testsuite',
      },
    ],
    col: [
      {
        data: 'data.device.type',
        name: 'Device',
      },
    ],
    cell: {
      data: [
        {
          name: 'num_pass',
          expression:
            'sum(max(group(input, data.testrun_id, data.result.pass)))',
        },
        {
          name: 'num_total',
          expression:
            'sum( sum(max(group(input, data.testrun_id, data.result.pass))), sum(max(group(input, data.testrun_id, data.result.fail))), sum(max(group(input, data.testrun_id, data.result.skip))) )',
        },
        {
          name: 'passrate',
          expression: 'percentage(num_pass, num_total)',
        },
      ],
      format: 'passrate% (num_pass/num_total)',
      color_type: 'grid',
      color: [
        {
          data: 'passrate',
          relationship: 'equal',
          value: '100',
          color: '#12857A',
        },
        {
          data: 'passrate',
          relationship: 'less than',
          value: '100',
          color: '#CC0C39',
        },
      ],
    },
    kpi: {
      key: 'kpi.name',
      value: '',
    },
  },
  {
    id: '0-0-2',
    type: 'pivottable',
    tabIndex: 0,
    sectionIndex: 0,
    position: 2,
    parent: '0-0-0',
    row: [
      {
        data: 'data.device.type',
        name: 'Device',
      },
    ],
    col: [
      {
        data: 'data.build.version',
        name: 'Build',
      },
    ],
    cell: {
      data: [
        {
          name: 'num_pass',
          expression:
            'sum(max(group(input, data.testrun_id, data.result.pass)))',
        },
        {
          name: 'num_total',
          expression:
            'sum( sum(max(group(input, data.testrun_id, data.result.pass))), sum(max(group(input, data.testrun_id, data.result.fail))), sum(max(group(input, data.testrun_id, data.result.skip))) )',
        },
        {
          name: 'passrate',
          expression: 'percentage(num_pass, num_total)',
        },
      ],
      format: 'passrate% (num_pass/num_total)',
      color_type: 'grid',
      color: [
        {
          data: 'passrate',
          relationship: 'equal',
          value: '100',
          color: '#12857A',
        },
        {
          data: 'passrate',
          relationship: 'less than',
          value: '100',
          color: '#CC0C39',
        },
      ],
    },
  },
  {
    id: '0-0-5',
    type: 'basetable',
    tabIndex: 0,
    sectionIndex: 0,
    position: 5,
    parent: '0-0-3',
    col: [
      {
        name: 'Source',
        format: 'ingest.source',
      },
      {
        name: 'ID',
        format: 'id',
        href: 'data.testrun_url',
      },
      {
        name: 'Testrun Start Time',
        format: 'data.start_time',
      },
      {
        name: 'Build/Variant',
        format: [
          'data.build.version data.build.variant',
          'data.metadata.version',
        ],
      },
      {
        name: 'Device Pools',
        format: 'data.device.pool',
      },
      {
        name: 'Device',
        format: [
          'data.device.type: data.device.dsn',
          'data.metadata.device: data.metadata.dsn',
        ],
      },
      {
        name: 'Status',
        format: 'data.status',
      },
      {
        name: 'KPI detail',
        format: 'kpi.name : kpi.value kpi.unit',
      },
    ],
    kpi: {
      key: 'kpi.name',
      value: 'Test .+',
    },
  },
  {
    id: '1-0-0',
    type: 'pivottab',
    tabIndex: 1,
    sectionIndex: 0,
    position: 0,
    data: ['data.build.project'],
  },
  {
    id: '1-0-2',
    type: 'pivottable',
    tabIndex: 1,
    sectionIndex: 0,
    position: 1,
    parent: '1-0-0',
    row: [
      {
        data: 'data.device.type',
        name: 'Device',
      },
    ],
    col: [
      {
        data: 'data.build.version',
        name: 'Build',
      },
    ],
    cell: {
      data: [
        {
          name: 'num_pass',
          expression:
            'sum(max(group(input, data.testrun_id, data.result.pass)))',
        },
        {
          name: 'num_total',
          expression:
            'sum( sum(max(group(input, data.testrun_id, data.result.pass))), sum(max(group(input, data.testrun_id, data.result.fail))), sum(max(group(input, data.testrun_id, data.result.skip))) )',
        },
        {
          name: 'passrate',
          expression: 'percentage(num_pass, num_total)',
        },
      ],
      format: 'passrate% (num_pass/num_total)',
      color_type: 'grid',
      color: [
        {
          data: 'passrate',
          relationship: 'equal',
          value: '100',
          color: '#12857A',
        },
        {
          data: 'passrate',
          relationship: 'less than',
          value: '100',
          color: '#CC0C39',
        },
      ],
    },
  },
  {
    id: '1-0-3',
    type: 'pivottable',
    tabIndex: 1,
    sectionIndex: 0,
    position: 2,
    parent: '1-0-2',
    row: [
      {
        data: 'kpi.bucket',
        name: 'Testcase',
      },
    ],
    col: [
      {
        data: 'data.device.type',
        name: 'Device',
      },
    ],
    cell: {
      data: [
        {
          name: 'num_pass',
          expression:
            'count(extract(filter(input, kpi.value, is, PASS), kpi.value))',
        },
        {
          name: 'num_fail',
          expression:
            'count(extract(filter(input, kpi.value, is, FAIL), kpi.value))',
        },
        {
          name: 'num_skip',
          expression:
            'count(extract(filter(input, kpi.value, is, SKIP), kpi.value))',
        },
        {
          name: 'num_total',
          expression: 'sum(num_pass, num_fail, num_skip)',
        },
        {
          name: 'passrate',
          expression: 'percentage(num_pass, num_total)',
        },
      ],
      format: 'passrate% (num_pass/num_total)',
      color_type: 'grid',
      color: [
        {
          data: 'passrate',
          relationship: 'equal',
          value: '100',
          color: '#12857A',
        },
        {
          data: 'passrate',
          relationship: 'less than',
          value: '100',
          color: '#CC0C39',
        },
      ],
    },
    kpi: {
      key: 'kpi.bucket',
      value: 'Pass Rate*',
    },
  },
  {
    id: '1-0-4',
    type: 'basetable',
    tabIndex: 1,
    sectionIndex: 0,
    position: 3,
    parent: '1-0-3',
    col: [
      {
        name: 'ID',
        format: 'id',
        href: 'data.testrun_url',
      },
      {
        name: 'Testrun Start Time',
        format: 'data.start_time',
      },
      {
        name: 'Build/Variant',
        format: [
          'data.build.version data.build.variant',
          'data.metadata.version',
        ],
      },
      {
        name: 'Device Pools',
        format: 'data.device.pool',
      },
      {
        name: 'Device',
        format: [
          'data.device.type: data.device.dsn',
          'data.metadata.device: data.metadata.dsn',
        ],
      },
      {
        name: 'Status',
        format: 'data.status',
      },
      {
        name: 'KPI detail',
        format: 'kpi.name : kpi.value kpi.unit',
      },
    ],
    kpi: {
      key: 'kpi.name',
      value: 'Pass Rate',
    },
  },
  {
    id: '2-0-0',
    type: 'pivottab',
    tabIndex: 2,
    sectionIndex: 0,
    position: 0,
    data: ['data.build.project'],
  },
  {
    id: '2-0-1',
    tabIndex: 2,
    sectionIndex: 0,
    position: 1,
    type: 'kpitable',
    attrs: [
      {
        name: 'BUILD',
        data: 'data.build.version',
      },
      {
        name: 'VARIANT',
        data: 'data.build.variant',
      },
      {
        name: 'DEVICE',
        data: 'data.device.type',
      },
    ],
    extras: [
      {
        name: 'TESTRUNS',
        format: 'completed_count | error_count',
      },
    ],
    data: [
      {
        name: 'completed_count',
        expression:
          'count(max(group(filter(input, data.status, is, completed), data.testrun_id, data.testrun_id)))',
      },
      {
        name: 'error_count',
        expression:
          'count(max(group(filter(input, data.status, isnot, completed), data.testrun_id, data.testrun_id)))',
      },
    ],
    display: 'Data item in rows',
    parent: '2-0-0',
    kpi: {
      key: 'kpi.name',
      value: '^(?!Test .+$)',
    },
  },
  {
    id: '2-0-2',
    tabIndex: 2,
    sectionIndex: 0,
    position: 2,
    display: 'Data item in columns',
    attrs: [
      {
        name: 'TESTSUITE',
        data: 'data.test_suite',
      },
      {
        name: 'BUILD',
        data: 'data.build.version',
      },
      {
        name: 'VARIANT',
        data: 'data.build.variant',
      },
      {
        name: 'DEVICE',
        data: 'data.device.type',
      },
    ],
    extras: [
      {
        name: 'TESTRUNS',
        format: 'completed_count | error_count',
      },
    ],
    data: [
      {
        name: 'completed_count',
        expression:
          'count(max(group(filter(input, data.status, is, completed), data.testrun_id, data.testrun_id)))',
      },
      {
        name: 'error_count',
        expression:
          'count(max(group(filter(input, data.status, isnot, completed), data.testrun_id, data.testrun_id)))',
      },
    ],
    type: 'kpitable',
    parent: '2-0-1',
    kpi: {
      key: 'kpi.name',
      value: '^(?!Test .+$)',
    },
  },
  {
    id: '2-0-3',
    tabIndex: 2,
    sectionIndex: 0,
    position: 3,
    extras: [
      {
        name: 'Testsuite',
        format: 'testsuites',
      },
      {
        name: 'Device',
        format: 'devices',
      },
    ],
    data: [
      {
        name: 'testsuites',
        expression: 'getFirst(extract(input, data.test_suite))',
      },
      {
        name: 'devices',
        expression: 'getFirst(extract(input, data.device.type))',
      },
    ],
    type: 'kpibasetable',
    parent: '2-0-2',
    kpi: {
      key: 'kpi.name',
      value: '^(?!Test .+$)',
    },
  },
  {
    id: '2-0-4',
    tabIndex: 2,
    sectionIndex: 0,
    position: 4,
    col: [
      {
        name: 'ID',
        format: 'id',
        href: 'data.testrun_url',
      },
      {
        name: 'KPI detail',
        format: 'kpi.name : kpi.value kpi.unit',
      },
      {
        name: 'KPI Bucket',
        format: ['kpi.bucket'],
      },
    ],
    type: 'basetable',
    parent: '2-0-3',
    kpi: {
      key: 'kpi.name',
      value: '^(?!Test .+$)',
    },
  },
  {
    id: '2-0-5',
    tabIndex: 2,
    sectionIndex: 0,
    position: 5,
    type: 'kpilog',
    parent: '2-0-4',
    kpi: {
      key: 'kpi.name',
      value: '^(?!Test .+$)',
    },
  },
];

export const depreciatedGroups = [
  "tablet-builds",
  "ldap-tablet-builds"
];

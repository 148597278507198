import React from 'react';
import Column from '@amzn/meridian/column';
import Pagination from '@amzn/meridian/pagination';
import SearchField from '@amzn/meridian/search-field';
import Select, { SelectOption } from '@amzn/meridian/select';
import { TableActionBar } from '@amzn/meridian/table';
import {
  TableState,
  TableStateUpdateAction,
  WizardTableData,
} from '@/features/Dashboards/types';
import {
  getNumberOfPages,
  getPerPageOptions,
} from '@/features/Dashboards/helpers';
import { DashboardExportButton } from '../DashboardButtons';

export type DashboardTableActionBarProps = {
  dataLength: number;
  tableState: TableState;
  tableStateDispatch: (value: TableStateUpdateAction) => void;
  //wizardData exists if the table is exportable to CSV
  wizardData?: WizardTableData;
};

export const DashboardTableActionBar = ({
  dataLength,
  tableState,
  tableStateDispatch,
  wizardData,
}: DashboardTableActionBarProps): JSX.Element => {
  const numberOfPages = getNumberOfPages(
    dataLength,
    tableState.pagination.perPage
  );
  const perPageOptions = getPerPageOptions(dataLength);
  return (
    <TableActionBar widths={['fit', 'fill', 'fit', 'fit']}>
      <SearchField
        value={tableState.searchQuery}
        placeholder="Search for..."
        searchButton={false}
        onChange={(value) =>
          tableStateDispatch({ type: 'update-search-query', payload: value })
        }
        onSubmit={(value) =>
          tableStateDispatch({ type: 'update-search-query', payload: value })
        }
      />
      <Column />
      <Pagination
        currentPage={tableState.pagination.currentPage}
        numberOfPages={numberOfPages}
        onChange={(value) =>
          tableStateDispatch({
            type: 'update-pagination-current-page',
            payload: value,
          })
        }
      />
      <Select
        placeholder="Per page"
        suffix="Per Page"
        value={tableState.pagination.perPage}
        onChange={(value) =>
          tableStateDispatch({
            type: 'update-pagination-per-page',
            payload: value,
          })
        }
      >
        {perPageOptions.map(({ label, value }) => (
          <SelectOption key={label} label={label} value={value} />
        ))}
      </Select>
      {wizardData && wizardData.rows.length > 0 ? <DashboardExportButton header={wizardData.header} rows={wizardData.rows}/> : null}
    </TableActionBar>
  );
};

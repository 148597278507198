import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Tooltip from '@amzn/meridian/tooltip';
import uploadTokens from '@amzn/meridian-tokens/base/icon/upload-large';
import { TranslatorsUploadUri } from '@/features/routes';

export const TranslatorUploadButton = (): JSX.Element => {
  const history = useHistory();
  return (
    <Tooltip position="bottom" title="Upload a new translator">
      <Button label="Upload" onClick={() => history.push(TranslatorsUploadUri)}>
        <Icon tokens={uploadTokens}>Upload a new translator</Icon>
        Upload
      </Button>
    </Tooltip>
  );
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import Tooltip from '@amzn/meridian/tooltip';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import addTokens from '@amzn/meridian-tokens/base/icon/plus';
import { DashboardAddMonthlyReportsUri } from '@/features/routes';

export type DashboardMonthlyReportAddButtonProps = {
  dashboardName: string;
};

export const DashboardMonthlyReportAddButton = ({
  dashboardName,
}: DashboardMonthlyReportAddButtonProps): JSX.Element => {
  const history = useHistory();
  return (
    <Tooltip position="bottom" title="Generate a new monthly report">
      <Button
        onClick={() =>
          history.push(DashboardAddMonthlyReportsUri(dashboardName))
        }
      >
        <Icon tokens={addTokens}>Report</Icon>
        Generate
      </Button>
    </Tooltip>
  );
};

import {
  WizardTableData,
  WizardState,
  WizardTableRow,
  WizardTableCell,
  AnnotationContext,
} from '@/features/Dashboards/types';
import { KpiFilterResult } from '@/hooks';
import {
  BaseTableWizardConfiguration,
  FlatKpi,
  KpiProperty,
  KpiPropertyValue,
} from '@/types';

export type BaseTableRowContext = {
  kpi: FlatKpi;
};

export interface BaseTableRow extends WizardTableRow {
  context: BaseTableRowContext;
}

const kpiProperties = [
  'kpi.name',
  'kpi.value',
  'kpi.kpi_summary',
  'kpi.unit',
  'kpi.bucket',
  'kpi.where',
  'id',
  'data.status',
  'data.tms_tags',
  'data.testrun_id',
  'data.testrun_url',
  'data.test_runtime',
  'data.test_suite',
  'data.result.pass',
  'data.result.fail',
  'data.result.skip',
  'data.total_runtime',
  'data.device.type',
  'data.device.dsn',
  'data.device.hw_config',
  'data.device.pool',
  'data.start_time',
  'data.build.version',
  'data.build.variant',
  'data.build.project',
  'data.build.type',
  'data.build.time',
  'ingest.source',
  'ingest.type',
  'ingest.start_time',
  'ingest.duration',
  'translator.name',
  'translator.version',
  'unique_id',
  'dashboard_name',
  'total',
  'pass_rate',
];

const propRegExp = new RegExp(
  ['(', kpiProperties.join('|'), ')'].join(''),
  'g'
);

export const getAnnotationContext = (
  rowContext: BaseTableRowContext
): AnnotationContext => ({
  type: 'kpi_annotation',
  testrunIds: [rowContext.kpi('data.testrun_id').toString()],
  kpiIds: [rowContext.kpi('unique_id').toString()],
});

/**
 * Create closures that can create a column cell based on a KPI
 *
 * Columns can be configured with multiple formats
 * we always pick the first one, no clue how to handle more than one...
 * the format acts as a template so we should find all of the strings that
 * are a valid property name and prepare to replace those with actual values
 * in each row
 */
const getColumnDefinitions = (
  configuration: BaseTableWizardConfiguration,
  propertyIndexMap: KpiFilterResult['propertyIndexMap']
) =>
  configuration.col.map(({ format, ...rest }) => {
    const selectedFormat = Array.isArray(format) ? format[0] : format;
    const compileSource = `return ["${selectedFormat.replace(
      propRegExp,
      '",k[m["$1"]],"'
    )}"].join("")`;
    // eslint-disable-next-line no-new-func
    const compile = new Function('k', 'm', compileSource);
    return {
      ...rest,
      format,
      compile: (kpi: KpiPropertyValue[]): string =>
        compile(kpi, propertyIndexMap),
    };
  });

/**
 * Create a selector function that produces a single page of data to be rendered
 * by a base table wizard
 */
export const createWizardData = ({
  configuration,
  data: { propertyIndexMap, baseData },
}: WizardState<BaseTableWizardConfiguration>): WizardTableData<
  WizardTableCell,
  BaseTableRow
> => {
  const [, ...kpis] = baseData;
  // create column definitions, an array of ojects that can create the cell
  // content for a column given a KPI
  const columnDefinitions = getColumnDefinitions(
    configuration,
    propertyIndexMap
  );
  const rows = kpis.map(
    (kpi): BaseTableRow => ({
      key: kpi[propertyIndexMap.unique_id].toString(),
      cells: columnDefinitions.map(({ format, name, compile, href }) => ({
        key: name,
        content: compile(kpi),
        href: href
          || (typeof format == "string" && (format.trim() === "id" || format.trim() === "data.testrun_url"))
          ? "https://tools.dots.lab126.a2z.com/scheduling/testruns/" + kpi[propertyIndexMap['data.testrun_id']].toString() : undefined,
      })),
      context: {
        kpi: (property: KpiProperty) => kpi[propertyIndexMap[property]],
      },
    })
  );
  return {
    header: columnDefinitions.map(({ name }) => ({
      key: name,
      content: name,
    })),
    rows,
  };
};

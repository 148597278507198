import React, { useMemo, useRef, useState } from 'react';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Menu, { MenuItem } from '@amzn/meridian/menu';
import Row from '@amzn/meridian/row';
import exportTokens from '@amzn/meridian-tokens/base/icon/export-large';
import { JiraCreateButton } from '@/components';
import { useDashboard } from '@/features/Dashboards/contexts';
import { AnnotationContext } from '@/features/Dashboards/types';
import { KpiBucketDetailUri, KpiDetailUri } from '@/features/routes';
import {
  DashboardAnnotationCountBadge,
  DashboardAnnotationPopover,
} from '../DashboardAnnotations';
import { BaseTableRowContext } from './data';
import { serializeUiQueryParameters } from '@/hooks';
import { groupByProperties } from '@/features/Dashboards/helpers';

export type DashboardBaseTableActionsCellContentProps = {
  rowContext: BaseTableRowContext;
};

export const DashboardBaseTableActionsCellContent = ({
  rowContext: { kpi },
}: DashboardBaseTableActionsCellContentProps): JSX.Element => {
  const buttonRef = useRef();
  const popoverRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { dashboard } = useDashboard();
  const bucketDetailFilters = useMemo(
    () =>
      serializeUiQueryParameters({
        filters: groupByProperties.map((property) => ({
          key: `kats.${property}`,
          operator: 'include',
          value: [kpi(property).toString()],
        })),
      }),
    [kpi]
  );
  const annotationContext: AnnotationContext = {
    type: 'kpi_annotation',
    testrunIds: [kpi('data.testrun_id').toString()],
    kpiIds: [kpi('unique_id').toString()],
  };
  return (
    <Row>
      <Button
        ref={buttonRef}
        onClick={() => setIsOpen(true)}
        type="tertiary"
        size="small"
      >
        Detail
      </Button>
      <Menu
        anchorNode={buttonRef.current}
        open={isOpen}
        position="bottom"
        onClose={() => setIsOpen(false)}
      >
        <MenuItem
          target="_blank"
          rel="noopener noreferrer"
          href={KpiDetailUri(
            kpi('unique_id').toString(),
            kpi('ingest.source').toString()
          )}
        >
          <Row spacing="small">
            <Icon tokens={exportTokens} />
            <span>KPI Detail</span>
          </Row>
        </MenuItem>
        {kpi('kpi.bucket') ? <MenuItem
          target="_blank"
          rel="noopener noreferrer"
          href={`${KpiBucketDetailUri(
            kpi('kpi.bucket').toString()
          )}?dashboard=${dashboard.id}&${bucketDetailFilters}`}
        >
          <Row spacing="small">
            <Icon tokens={exportTokens} />
            <span>Bucket Detail</span>
          </Row>
        </MenuItem> :
        null
          }
      </Menu>
      <JiraCreateButton
        kpiId={kpi('unique_id').toString()}
        dashboardId={dashboard.id}
        kpi={kpi}
        jiraComponents={dashboard.jiraComponents ?? ''}
        jiraLabels={dashboard.jiraLabels ?? ''}
        jiraProject={dashboard.jiraProject ?? ''}
      />
      <div ref={popoverRef}>
        <DashboardAnnotationCountBadge context={annotationContext} />
      </div>
      <DashboardAnnotationPopover
        anchorNode={popoverRef}
        context={annotationContext}
      />
    </Row>
  );
};

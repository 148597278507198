import React from 'react';
import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Toggle from '@amzn/meridian/toggle';
import {
  CoreTimeSelect,
  CoreFilterableSelect,
  CoreFormFooter,
  CoreInput,
  isNullOrWhitespace,
  useForm,
} from '@amzn/dots-core-ui';
import { DashboardEmailReportConfiguration } from '@/types';
import { toUtcTime, toLocalTime } from './helpers';

export type DashboardEmailReportFormProps = {
  report?: DashboardEmailReportConfiguration;
  onCancel: () => void;
  onSubmit: (
    report: Partial<DashboardEmailReportConfiguration>
  ) => Promise<void> | void;
};

export const DashboardEmailReportForm = ({
  report,
  onCancel,
  onSubmit,
}: DashboardEmailReportFormProps): JSX.Element => {
  const {
    values,
    states,
    errors,
    handlers,
    hasErrors,
    handleSubmit,
    isSubmitting,
    submitState,
  } = useForm({
    title: {
      initialValue: report?.title ?? '',
      validate: (value: string): string =>
        isNullOrWhitespace(value) ? 'Report Title is required' : '',
    },
    recipients: {
      initialValue: report?.recipients ?? [],
      validate: (value: string[]): string =>
        value.length === 0 ? 'At least one recipient is required' : '',
    },
    timeToSend: {
      initialValue: report?.time_to_send
        ? `${toLocalTime(report.time_to_send)}:00`
        : '',
      validate: (value: string): string =>
        isNullOrWhitespace(value) ? 'Send Time is required' : '',
    },
    timeRangeUnit: {
      initialValue:
        report?.report_params?.data_range?.time_range_unit ?? 'days',
    },
    timeRangeValue: {
      initialValue: report?.report_params?.data_range?.time_range_value ?? 1,
      validate: (value: number): string => {
        if (isNullOrWhitespace(value)) {
          return 'Days of Data is required';
        }
        if (Number(value) > 7) {
          return 'Days of Data must be less than or equal to 7';
        }
        return '';
      },
    },
    dashboard_id: {
      initialValue: report?.dashboard_id,
    },
    report_id: {
      initialValue: report?.report_id,
    },
    status: {
      initialValue: report?.status ?? 'ENABLED',
    },
  });
  return (
    <form
      onSubmit={handleSubmit((vs) => {
        const utcTimeToSend = toUtcTime(vs.timeToSend);
        return onSubmit({
          title: vs.title,
          time_to_send: utcTimeToSend,
          recipients: vs.recipients,
          report_params: {
            data_range: {
              time_range_unit: vs.timeRangeUnit,
              time_range_value: vs.timeRangeValue,
            },
          },
          status: vs.status,
          dashboard_id: vs.dashboard_id,
          report_id: vs.report_id,
        });
      })}
    >
      <Column spacingInset="400" spacing="500" maxWidth="100%" width={650}>
        {submitState.status === 'error' && (
          <Alert type="error" title="Error!" size="large">
            {submitState.error?.message}
          </Alert>
        )}
        <CoreInput
          label="Report Title"
          placeholder="Report Title"
          value={values.title}
          onChange={handlers.title}
          errorMessage={errors.title}
          validationState={states.title}
        />
        <CoreFilterableSelect
          canAddItems
          label="Recipients"
          placeholder="Recipients"
          items={report?.recipients ?? []}
          value={values.recipients}
          onChange={handlers.recipients}
          errorMessage={errors.recipients}
          validationState={states.recipients}
        />
        <Row widths="fill" alignmentVertical="top">
          <CoreTimeSelect
            label="Send Time"
            placeholder="Send Time"
            format={{ hourCycle: 'h23', hour: '2-digit', minute: '2-digit' }}
            value={values.timeToSend}
            onChange={handlers.timeToSend}
            errorMessage={errors.timeToSend}
            validationState={states.timeToSend}
          />
          <CoreInput
            label="Days of Data"
            placeholder="Days of Data"
            type="number"
            value={values.timeRangeValue}
            onChange={handlers.timeRangeValue}
            errorMessage={errors.timeRangeValue}
            validationState={states.timeRangeValue}
          />
        </Row>
        <Row alignmentVertical="center">
          <Text type="h100">Enabled</Text>
          <Toggle
            checked={values.status === 'ENABLED'}
            onChange={(value: boolean) => {
              const status = value ? 'ENABLED' : 'DISABLED';
              handlers.status(status);
            }}
          />
        </Row>
        <CoreFormFooter
          isConfirmDisabled={hasErrors || isSubmitting}
          isLoading={isSubmitting}
          onCancelClick={onCancel}
        />
      </Column>
    </form>
  );
};

export const RootUri = '/results';

export const DashboardsUri = `${RootUri}/dashboard`;
export const DashboardAddUri = `${DashboardsUri}/add`;
export const DashboardDetailUri = (dashboardName: string): string =>
  `${DashboardsUri}/${dashboardName}`;
export const DashboardEditFiltersUri = (dashboardName: string): string =>
  `${DashboardDetailUri(dashboardName)}/edit/filters`;
export const DashboardEditLayoutsUri = (dashboardName: string): string =>
  `${DashboardDetailUri(dashboardName)}/edit/layouts`;

export const DashboardEmailReportsUri = (dashboardName: string): string =>
  `${DashboardDetailUri(dashboardName)}/email-reports`;
export const DashboardAddEmailReportsUri = (dashboardName: string): string =>
  `${DashboardEmailReportsUri(dashboardName)}/add`;
export const DashboardEditEmailReportUri = (
  dashboardName: string,
  emailReportConfigurationId: string
): string =>
  `${DashboardEmailReportsUri(
    dashboardName
  )}/${emailReportConfigurationId}/edit`;

export const DashboardMonthlyReportsUri = (dashboardName: string): string =>
  `${DashboardDetailUri(dashboardName)}/monthly-reports`;
export const DashboardAddMonthlyReportsUri = (dashboardName: string): string =>
  `${DashboardMonthlyReportsUri(dashboardName)}/add`;
export const DashboardMonthlyReportDetailUri = (
  dashboardName: string,
  monthlyReportConfigurationId: string
): string =>
  `${DashboardMonthlyReportsUri(
    dashboardName
  )}/${monthlyReportConfigurationId}`;

export const TranslatorsUri = `${RootUri}/translator`;
export const TranslatorsUploadUri = `${TranslatorsUri}/upload`;
export const TranslatorDetailUri = (translatorName: string): string =>
  `${TranslatorsUri}/${translatorName}`;
export const TranslatorEditUri = (
  translatorName: string,
  translatorVersion: string
): string => `${TranslatorDetailUri(translatorName)}/${translatorVersion}/edit`;

export const HelpCenterUri = `${RootUri}/help`;

export const KpiDetailUri = (kpiId: string, kpiSource: string): string =>
  `${RootUri}/kpi/detail/${kpiSource}/${kpiId}`;

export const KpiBucketDetailUri = (bucketId: string): string =>
  `${RootUri}/kpi/bucket/${bucketId}`;

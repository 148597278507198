/* eslint-disable max-classes-per-file */
import { compareValues } from '@/helpers';

function InvalidExpressionError(expression) {
  throw new Error(`Invalid expression '${expression}'`);
}

function InvalidCharacterError(expression) {
  throw new Error(`Invalid character in expression '${expression}'`);
}

function UnsupportedOperatorError(operator) {
  throw new Error(`Unsupported operator '${operator}'`);
}

function UnsupportedDataDimensionError(message) {
  throw new Error(message);
}

function InvalidOperationParamError(param) {
  throw new Error(
    `Unsupported param '${JSON.stringify(
      param
    )}' Expect instance of Operand/Operation.`
  );
}

function InvalidKeyError(message) {
  throw new Error(message);
}

function UnsupportedConditionError(condition) {
  throw new Error(`Unsupported condition '${condition}'`);
}

function FunctionArgumentsMissingError(functionName, argumentName) {
  throw new Error(`Missing '${argumentName}' for function ${functionName}`);
}

function NoDataError() {
  throw new Error('No data!');
}

/**
 * Check if the data is 2D array
 * @param {array} data
 * @return {boolean}
 */
const is2DArray = (data) => {
  return Array.isArray(data) && data.length > 0 && Array.isArray(data[0]);
};

/**
 * Get the dimension of data
 * @param {array} data
 * @return {number} dimension of data
 */
const getDataDimension = (data) => {
  if (!Array.isArray(data)) {
    return 0;
  }
  if (data.length === 0 || !Array.isArray(data[0])) {
    return 1;
  }
  if (data[0].length === 0 || !Array.isArray(data[0][0])) {
    return 2;
  }
  throw UnsupportedDataDimensionError('Data dimension is at most 2');
};

/**
 * Filter input data by defined condition
 * @param {array} data is 2D array with first row as key names
 * @param {string} filterKey column name to filter by.
 * @param {string} condition is one of ["is", "isnot"]
 * @param  {array} values filter values
 *
 * @returns filtered data with same format as input data
 *
 * @throws InvalidKeyException filter key is not in the data
 * @throws FunctionArgumentsMissingException argument(s) missing
 * @throws UnsupportedConditionException no data left after filtering
 */
const filter = (data, filterKey, condition, ...values) => {
  const filterKeyIndex = data[0].indexOf(filterKey);

  if (!filterKey || filterKeyIndex < 0) {
    throw InvalidKeyError(`Invalid filter key '${filterKey}'`);
  }

  if (!values || values.length === 0) {
    throw FunctionArgumentsMissingError('filter', 'filter value');
  }

  const decodedValues = values.map((value) => decodeURIComponent(value));

  let result;
  if (!condition) {
    throw UnsupportedConditionError(condition);
  } else if (condition.toLowerCase() === 'is') {
    result = data.filter(
      (row, index) => index === 0 || decodedValues.includes(row[filterKeyIndex])
    );
  } else if (condition.toLowerCase() === 'isnot') {
    result = data.filter(
      (row, index) =>
        index === 0 || !decodedValues.includes(row[filterKeyIndex])
    );
  } else {
    throw UnsupportedConditionError(condition);
  }

  // nothing left
  if (result && result.length === 1) {
    throw NoDataError();
  }
  return result;
};

/**
 * Sort input data by specified sort key and direction
 * @param {array} data is 2D array with first row as key names
 * @param {string} sortKey
 * @param {string} sortDirection
 */
const sort = (data, sortKey, sortDirection = 'descending') => {
  if (!is2DArray(data)) {
    throw InvalidKeyError('Key missing for 2D data');
  }

  const index = data[0].indexOf(sortKey);

  if (index === -1) {
    throw InvalidKeyError(`Invalid sort key '${sortKey}'`);
  }

  const sortedData = data
    .slice(1)
    .sort((x, y) => compareValues(sortDirection)(x[index], y[index]));

  return [data[0], ...sortedData];
};

/**
 * Extract data column by key
 * @param {array} data is 2D array with first row as key names
 * @param {string} key is column name to be extracted
 *
 * e.g. data [["name", "age"], ["Tom", 24], ["Jerry", "25"]]
 *      key "age"
 *      =>
 *      [24, 25]
 */
const extract = (data, key) => {
  if (!is2DArray(data)) {
    throw InvalidKeyError('Key missing for 2D data');
  }

  const index = data[0].indexOf(key);

  if (index === -1) {
    throw InvalidKeyError(`Invalid key '${key}'`);
  }

  const result = data.map((d) => d[index]);
  result.shift();
  return result;
};

/**
 * Group data by group key and extract by value key
 * @param {array} data is 2D array with first row as key names
 * @param {string} groupKey column name to group
 * @param {string} valueKey column name to extract
 *
 * e.g. array [["id", "kpi", "value"],
 *             [ 1,   "Pass Rate",  100],
 *             [ 1,   "Java Crash", "xxx"],
 *             [ 2,   "Pass Rate",  80],
 *             [ 2,   "Java Crash", "yyy"]]
 *      groupKey "kpi"
 *      valueKey "value"
 *      =>
 *      [[100, 80], ["xxx", "yyy"]]
 */
const group = (data, groupKey, valueKey) => {
  const groupItems = {};

  const groupKeyIndex = data[0].indexOf(groupKey);
  if (!groupKey || groupKeyIndex < 0) {
    throw InvalidKeyError(`Invalid group key '${groupKey}'`);
  }

  const valueKeyIndex = data[0].indexOf(valueKey);
  if (!valueKey || valueKeyIndex < 0) {
    throw InvalidKeyError(`Invalid value key '${valueKey}'`);
  }

  for (let i = 1; i < data.length; i += 1) {
    const groupKeyValue = data[i][groupKeyIndex];
    if (!Object.prototype.hasOwnProperty.call(groupItems, groupKeyValue)) {
      groupItems[groupKeyValue] = [];
    }
    groupItems[groupKeyValue].push(i);
  }

  Object.entries(groupItems).forEach(([key, items]) => {
    const dataItems = items.map((itemIndex) => data[itemIndex]);
    groupItems[key] = dataItems.map((item) => item[valueKeyIndex]);
  });

  return Object.values(groupItems);
};

const sumReducer = (accumulator, currentValue) =>
  accumulator + Number(currentValue);
const maxReducer = (accumulator, currentValue) =>
  Math.max(accumulator, Number(currentValue));
const minReducer = (accumulator, currentValue) =>
  Math.min(accumulator, Number(currentValue));
const averageReducer = (accumulator, currentValue, _, array) =>
  accumulator + Number(currentValue) / array.length;

/**
 * Sum function
 * @param  {...any} args
 * 1. If args length is 1, the arg can be a plain number, 1D or 2D array.
 *    This function will sum the argument by one dimension.
 *    e.g. 2 => 2
 *    e.g. [1,2] => 3
 *    e.g. [[1,2], [3,4,5]] => [3, 12]
 * 2. If the args length is more than 1, the args should be a list of plain
 *    numbers.
 *    This function will sum them up and return final result.
 *    e.g. 2,3,4 => 9
 */
const sum = (...args) => {
  if (args.length === 0) {
    return 0;
  }

  if (args.length === 1) {
    const data = args[0];
    const dim = getDataDimension(data);

    if (dim === 0) {
      return Number(data);
    }
    if (dim === 1) {
      return data.reduce(sumReducer, 0);
    }
    if (dim === 2) {
      return data.map((row) => row.reduce(sumReducer, 0));
    }
    return 0;
  }

  args.forEach((arg) => {
    if (getDataDimension(arg) !== 0) {
      throw UnsupportedDataDimensionError(
        'Expect one dimemsion data for max function when number of arguments is more than 1'
      );
    }
  });
  return args.reduce(sumReducer, 0);
};

/**
 * Max function
 * @param  {...any} args
 * 1. If args length is 1, the arg can be a plain number, 1D or 2D array.
 *    This function will find the maximum in the argument by one dimension.
 *    e.g. 2 => 2
 *    e.g. [1,2] => 2
 *    e.g. [[1,2], [3,4,5]] => [2, 5]
 * 2. If the args length is more than 1, the args should be a list of plain
 *    numbers.
 *    This function will find the maximum among them and return final result.
 *    e.g. 2,3,4 => 4
 */
const max = (...args) => {
  if (args.length === 0) {
    return 0;
  }

  if (args.length === 1) {
    const data = args[0];
    const dim = getDataDimension(data);

    if (dim === 0) {
      return Number(data);
    }
    if (dim === 1) {
      return data.reduce(maxReducer, Number.MIN_SAFE_INTEGER);
    }
    if (dim === 2) {
      return data.map((row) => row.reduce(maxReducer, Number.MIN_SAFE_INTEGER));
    }
    return 0;
  }

  args.forEach((arg) => {
    if (getDataDimension(arg) !== 0) {
      throw UnsupportedDataDimensionError(
        'Expect one dimemsion data for max function when number of arguments is more than 1'
      );
    }
  });
  return args.reduce(maxReducer, Number.MIN_SAFE_INTEGER);
};

/**
 * Min function
 * @param  {...any} args
 * 1. If args length is 1, the arg can be a plain number, 1D or 2D array.
 *    This function will find the minimum in the argument by one dimension.
 *    e.g. 2 => 2
 *    e.g. [1,2] => 1
 *    e.g. [[1,2], [3,4,5]] => [1, 3]
 * 2. If the args length is more than 1, the args should be a list of plain
 *    numbers.
 *    This function will find the minimum among them and return final result.
 *    e.g. 2,3,4 => 2
 */
const min = (...args) => {
  if (args.length === 0) {
    return 0;
  }

  if (args.length === 1) {
    const data = args[0];
    const dim = getDataDimension(data);

    if (dim === 0) {
      return Number(data);
    }
    if (dim === 1) {
      return data.reduce(minReducer, Number.MAX_SAFE_INTEGER);
    }
    if (dim === 2) {
      return data.map((row) => row.reduce(minReducer, Number.MAX_SAFE_INTEGER));
    }
    return 0;
  }

  args.forEach((arg) => {
    if (getDataDimension(arg) !== 0) {
      throw UnsupportedDataDimensionError(
        'Expect one dimemsion data for min function when number of arguments is more than 1'
      );
    }
  });
  return args.reduce(minReducer, Number.MAX_SAFE_INTEGER);
};

/*
 * Average function
 * @param {number|string} args
 */
const average = (...args) => {
  if (args.length === 0) {
    return 0;
  }

  if (args.length === 1) {
    const data = args[0];
    const dim = getDataDimension(data);

    if (dim === 0) {
      return Number(data);
    }
    if (dim === 1) {
      return data.reduce(averageReducer, 0).toFixed(2);
    }
    if (dim === 2) {
      return data.map((row) => row.reduce(averageReducer, 0).toFixed(2));
    }
    return 0;
  }

  args.forEach((arg) => {
    if (getDataDimension(arg) !== 0) {
      throw UnsupportedDataDimensionError(
        'Expect one dimemsion data for average function when number of arguments is more than 1'
      );
    }
  });
  return args.reduce(averageReducer, 0).toFixed(2);
};

/**
 * Percentage function calculates a percetage: 100 * numertor / denominator
 * @param {number|string} numerator
 * @param {number|string} denominator
 * @param {integer} decimals to keep
 */
const percentage = (numerator, denominator, decimals = 1) => {
  let percent = (100 * Number(numerator)) / Number(denominator);
  percent = Number.isNaN(percent) || !Number.isFinite(percent) ? 0 : percent;
  const digits =
    decimals && Number.isInteger(decimals) && decimals >= 0 ? decimals : 1;
  return percent.toFixed(digits);
};

/**
 * Count the length of arguments
 * @param  {...any} args
 * 1. If args length is 1, the arg can be a plain number or array.
 *    This function will find the length or the arg.
 *    e.g. 2 => 1
 *    e.g. [1,2,3] => 3
 *    e.g. [[1,2], [3,4,5]] => 2
 * 2. If the args length is more than 1, the args should be a list of plain
 *    numbers.
 *    This function will return the length of the args
 *    e.g. 2,3,4 => 3
 */
const count = (...args) => {
  if (args.length === 1) {
    const data = args[0];
    const dim = getDataDimension(data);

    if (dim === 0) {
      return 1;
    }
    if (dim === 1) {
      return data.length;
    }
    if (dim === 2) {
      return data.map((d) => d.length);
    }
    return data.length;
  }

  return args.length;
};

/**
 * Scale one arg with the other as scale factor
 * @param  {...any} args
 *
 * 1. If two args are plain number, this function will return their
 *    multiplication
 *    e.g. scale(2,3) => 6.0
 * 2. If one arg is plain number and another one is array, this function will
 *    return respective multiplication
 *    e.g. scale(100, [4,5,6]) => [400, 500, 600]
 */
const scale = (...args) => {
  let result;

  if (args.length === 1) {
    [result] = args;
  } else if (args.length === 2) {
    const dim0 = getDataDimension(args[0]);
    const dim1 = getDataDimension(args[1]);

    if (dim0 > 1 || dim1 > 1) {
      throw UnsupportedDataDimensionError(
        'Scaled array should be at most 1 dimension'
      );
    }
    if (dim0 === 1 && dim1 === 1) {
      if (args[0].length !== args[1].length) {
        throw UnsupportedDataDimensionError(
          'Input arrays should have same dimension when scaling'
        );
      }
      result = args[0].map((v, i) => v * args[1][i]);
    } else if (dim0 === 0 && dim1 === 0) {
      result = args[0] * args[1];
    } else if (dim0 === 0) {
      result = args[1].map((e) => e * args[0]);
    } else if (dim1 === 0) {
      result = args[0].map((e) => e * args[1]);
    }
  } else {
    throw UnsupportedDataDimensionError(
      'Two args are needed for scale function'
    );
  }

  const resultDim = getDataDimension(result);
  if (resultDim === 0) {
    return result.toFixed(1);
  }
  if (resultDim === 1) {
    return result.map((r) => r.toFixed(1));
  }
  return result;
};

/**
 * Divide one arg with the other as divisor
 * @param  {...any} args
 *
 * 1. If two args are plain number, this function will return their
 *    multiplication
 *    e.g. divide(6,2) => 3.0
 * 2. If one arg is plain number and another one is array, this function will
 *    return respective multiplication
 *    e.g. scale([40,50,60], 10) => [4, 5, 6]
 */
const divide = (...args) => {
  let result;

  if (args.length === 1) {
    [result] = args;
  } else if (args.length === 2) {
    const dim0 = getDataDimension(args[0]);
    const dim1 = getDataDimension(args[1]);

    if (dim0 > 1 || dim1 > 1) {
      throw UnsupportedDataDimensionError(
        'Scaled array should be at most 1 dimension'
      );
    }
    if (dim0 === 1 && dim1 === 1) {
      if (args[0].length !== args[1].length) {
        throw UnsupportedDataDimensionError(
          'Input arrays should have same dimension when scaling'
        );
      }
      result = args[0].map((v, i) => v / args[1][i]);
    } else if (dim0 === 0 && dim1 === 0) {
      result = args[0] / args[1];
    } else if (dim0 === 0) {
      result = args[1].map((e) => args[0] / e);
    } else if (dim1 === 0) {
      result = args[0].map((e) => e / args[1]);
    }
  } else {
    throw UnsupportedDataDimensionError(
      'Two args are needed for scale function'
    );
  }

  const resultDim = getDataDimension(result);
  if (resultDim === 0) {
    return result.toFixed(2);
  }
  if (resultDim === 1) {
    return result.map((r) => r.toFixed(2));
  }
  return result;
};

/**
 * Get first element in arguments
 * @param  {...any} args
 */
const getFirst = (...args) => {
  if (args.length === 0) {
    return '';
  }
  if (args.length === 1) {
    const data = args[0];
    const dim = getDataDimension(data);

    if (dim === 0) {
      return data;
    }
    if (dim === 1) {
      return data[0];
    }
    if (dim === 2) {
      return data.map((d) => d[0]);
    }
    return data[0];
  }

  return args[0];
};

/**
 * Calculate 90th percentiles of given data
 * @param  {...any} args first argument must be an 1d array
 */
const tp90 = (...args) => {
  if (args.length === 0) {
    return '';
  }

  if (args.length === 1) {
    const data = args[0];
    const dim = getDataDimension(data);

    if (dim === 0) {
      return data;
    }
    const targetIndex = Math.ceil(data.length * 0.9);
    return data.sort(compareValues())[targetIndex - 1];
  }

  throw UnsupportedDataDimensionError('tp90 only accepts one argument 1D');
};

/**
 * Calculate the standard deviation of a 1D array
 * @param {...any} args first argument must be a 1D array
 */
const stdDev = (...args) => {
  let data = args;
  if (args.length === 0) {
    return '';
  }
  if (args.length === 1) {
    [data] = args;
    const dim = getDataDimension(data);
    if (dim !== 1) {
      throw UnsupportedDataDimensionError(
        'stdDev only accepts one argument 1D'
      );
    }
  }
  const n = data.length;
  const mean = data.reduce(sumReducer, 0) / n;
  return Math.sqrt(
    data.map((x) => (Number(x) - mean) ** 2).reduce(sumReducer, 0) / n
  ).toFixed(2);
};

// use this recursive function with a parse funciton
const parseObjectProperties = (obj, parse) => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      parseObjectProperties(value, parse);
    } else if (Object.prototype.hasOwnProperty.call(obj, key)) {
      parse(value);
    }
  });
};

/**
 * Get the first elements of input data with a key
 * @param {array} data
 * @param {string} resultKey what data should be returned for result of each KPI
 * @param {string} sortKey how to sort the data for first element?
 * @param {string} sortDirection what's the direction for sorting?
 * @param  {...string} args what keys are used to group the input data?
 */
const getFirstWithOrderByGroup = (
  data,
  resultKey,
  sortKey,
  sortDirection,
  ...args
) => {
  if (!is2DArray(data)) {
    throw InvalidKeyError('Key missing for 2D data');
  }

  const resultKeyIndex = data[0].indexOf(resultKey);
  const sortKeyIndex = data[0].indexOf(sortKey);

  if (resultKeyIndex === -1) {
    throw InvalidKeyError(`Invalid result key '${resultKey}'`);
  }
  if (sortKeyIndex === -1) {
    throw InvalidKeyError(`Invalid result key '${sortKey}'`);
  }
  if (sortDirection !== 'ascending' && sortDirection !== 'descending') {
    throw InvalidKeyError(`Invalid sort direction key '${sortDirection}'`);
  }

  const groupKeys = [];
  const groupKeyIndice = [];

  args.forEach((arg) => {
    const argIndex = data[0].indexOf(arg);
    if (arg && arg !== '' && argIndex !== -1) {
      groupKeys.push(arg);
      groupKeyIndice.push(argIndex);
    } else {
      throw InvalidKeyError(`Invalid group key '${arg}'`);
    }
  });

  if (groupKeys.length === 0) {
    return getFirst(extract(sort(data, sortKey, sortDirection), resultKey));
  }

  const groups = {};
  const winnerKey = 'WINNER';
  for (let i = 1; i < data.length; i += 1) {
    const row = data[i];
    let curr = groups;
    groupKeys.forEach((_, index) => {
      const key = row[groupKeyIndice[index]];
      if (!Object.prototype.hasOwnProperty.call(curr, key)) {
        curr[key] = {};
      }
      curr = curr[key];
    });

    if (!Object.prototype.hasOwnProperty.call(curr, winnerKey)) {
      curr[winnerKey] = row;
    } else {
      const previousWinner = curr[winnerKey];
      if (row[sortKeyIndex] > previousWinner[sortKeyIndex]) {
        curr[winnerKey] = row;
      }
    }
  }

  const result = [];
  parseObjectProperties(groups, (row) => result.push(row[resultKeyIndex]));

  return result;
};

/**
 * Base Class for Operation/Operand
 */
class OpBase {
  // operation/operand's result value
  value = null;

  // reset value
  reset() {
    this.value = null;
  }
}

/**
 * Operand Class
 */
export class Operand extends OpBase {
  static LEGAL_OPERAND = /^[A-Za-z0-9._%]+$/;

  // operand name
  name = undefined;

  constructor(name) {
    super();
    // check if operand name is valid
    if (!name.match(Operand.LEGAL_OPERAND)) {
      throw InvalidCharacterError(name);
    }
    this.name = name;
  }
}

/**
 * Operation Class
 */
export class Operation extends OpBase {
  static LEGAL_OPERATOR = /^(filter|group|extract|sort|sum|max|min|percentage|average|getFirst|count|scale|divide|tp90|stdDev|getFirstWithOrderByGroup)$/;

  // operation type
  operator = undefined;

  // operation params, Operation/Operand instance
  params = [];

  constructor(operator) {
    super();
    // check if operation is valid
    if (!operator.match(Operation.LEGAL_OPERATOR)) {
      throw UnsupportedOperatorError(operator);
    }
    this.operator = operator;
  }

  // add param to current operation
  addParam(param) {
    if (!(param instanceof Operand) && !(param instanceof Operation)) {
      throw InvalidOperationParamError(param);
    }
    this.params.push(param);
  }

  // reset value of current operation
  reset() {
    this.value = null;
    this.params.forEach((param) => param.reset());
  }
}

const SUPPORTED_OPERATORS = {
  filter,
  extract,
  group,
  sort,
  sum,
  max,
  min,
  percentage,
  average,
  getFirst,
  count,
  scale,
  divide,
  tp90,
  stdDev,
  getFirstWithOrderByGroup,
};

/**
 * Execute operation
 * @param {Operation} operation
 */
const execute = (operation) => {
  const params = operation.params.map((param) => param.value);

  if (operation.operator in SUPPORTED_OPERATORS) {
    return SUPPORTED_OPERATORS[operation.operator](...params);
  }
  return null;
};

/**
 * Parse aggregation expression helper by recursion
 * @param {string} expression with whitespace removed
 * @return {Operation|Operand}
 */
const parseExpressionHelper = (expression) => {
  const firstLeftParenthesisIndex = expression.indexOf('(');
  const lastRightParenthesisIndex = expression.lastIndexOf(')');

  // Return Operand instance if there is no left parenthesis of expression
  if (firstLeftParenthesisIndex === -1) {
    return new Operand(expression);
  }

  // Throw exception if there is left parenthesis but no right parenthesis
  if (lastRightParenthesisIndex === -1) {
    throw InvalidExpressionError(expression);
  }

  // extract first operator from expression
  const operator = expression.slice(0, firstLeftParenthesisIndex);

  // generate root Operation
  const root = new Operation(operator);

  // extract parameter string of root operation
  const paramsExpr = expression.slice(
    firstLeftParenthesisIndex + 1,
    lastRightParenthesisIndex
  );

  let level = 0;
  let current = '';

  for (let i = 0; i < paramsExpr.length; i += 1) {
    const char = paramsExpr.charAt(i);
    // increase level by one if encountered left parenthesis
    if (char === '(') {
      level += 1;
    }
    // decrease level by one if encountered right parenthesis
    if (char === ')') {
      level -= 1;
    }
    // throw exception if expression is invalid
    if (level < 0) {
      throw InvalidExpressionError(paramsExpr.slice(0, i + 1));
    }
    // parse parameter and add to root operation's params list
    if (char === ',' && level === 0) {
      root.addParam(parseExpressionHelper(current));
      current = '';
    } else {
      current += char;
    }
  }

  if (level === 0) {
    root.addParam(parseExpressionHelper(current));
  } else {
    throw InvalidExpressionError(paramsExpr);
  }

  return root;
};

/**
 * Parse aggregation expression
 * @param {string} expression
 * @return {Operation|Operand}
 */
export const parseExpression = (expression) => {
  // remove spaces from expression
  return parseExpressionHelper(expression.replace(/\s+/g, ''));
};

/**
 * Calculate operation/operand
 * @param {any} input is input data to be aggregated
 * @param {object} global is global data that are needed in calculation
 * @param {Operation|Operand} op operation/operand to be calculated
 *
 * The `value` attribute of op will be filled after calculation
 */
export const calculate = (input, global, op) => {
  if (op.value !== null) {
    return;
  }

  if (op instanceof Operand) {
    if (op.name === 'input') {
      op.value = input;
    } else if (Object.prototype.hasOwnProperty.call(global, op.name)) {
      op.value = global[op.name];
    } else if (!Number.isNaN(Number(op.name))) {
      op.value = Number(op.name);
    } else {
      op.value = op.name;
    }
    return;
  }

  op.params.forEach((param) => {
    if (param.value === null) {
      calculate(input, global, param);
    }
  });
  op.value = execute(op);
};

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useToaster } from '@amzn/dots-core-ui';
import { Dashboard } from '@/types';
import { CorePageTitle, CorePage } from '@/components';
import { DashboardDetailUri, DashboardsUri } from '@/features/routes';
import { DashboardLayoutsForm } from './components';
import { useDashboard, withDashboard } from './contexts';
import { useDashboardUpdate } from './hooks';

export const DashboardEditLayoutsPage = withDashboard(
  (): JSX.Element => {
    const history = useHistory();
    const { openToast } = useToaster();
    const { dashboard } = useDashboard();
    const { mutateAsync } = useDashboardUpdate();
    const onSubmit = useCallback(
      async (nextDashboard: Partial<Dashboard>) => {
        await mutateAsync(nextDashboard);
        openToast({
          title: 'Success!',
          message: `${nextDashboard?.name} has been updated.`,
          type: 'success',
        });
        history.push(DashboardDetailUri(nextDashboard?.name ?? ''));
      },
      [history, mutateAsync, openToast]
    );
    return (
      <CorePage
        title="Edit Layouts"
        breadcrumbs={[
          { href: DashboardsUri, title: 'Dashboards' },
          { href: DashboardDetailUri(dashboard.name), title: dashboard.name },
        ]}
        header={<CorePageTitle title={`Edit ${dashboard.name} Layouts`} />}
      >
        <DashboardLayoutsForm
          dashboard={dashboard}
          onSubmit={onSubmit}
          onCancel={() => history.goBack()}
        />
      </CorePage>
    );
  }
);

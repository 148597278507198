import React, { useRef, useState } from 'react';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Popover from '@amzn/meridian/popover';
import Icon from '@amzn/meridian/icon';
import InfoKnockoutTokens from '@amzn/meridian-tokens/base/icon/info-knockout';
import Button from '@amzn/meridian/button';

export type DashboardWizardFormFieldLabelProps = {
  label: string;
  hint?: React.ReactNode;
  children: React.ReactNode;
};

export const DashboardWizardFormFieldLabel = ({
  label,
  hint,
  children,
}: DashboardWizardFormFieldLabelProps): JSX.Element => {
  const iconRef = useRef();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const onHover = () => setIsPopoverOpen(true);
  const onClose = () => setIsPopoverOpen(false);
  return (
    <Column spacing="300">
      <Row spacing="100">
        <Text type="h100">{label}</Text>
        {hint && (
          <>
            <Button
              type="icon"
              size="small"
              ref={iconRef}
              onMouseEnter={onHover}
              onMouseLeave={onClose}
            >
              <Icon tokens={InfoKnockoutTokens}>Hover for hint text</Icon>
            </Button>
            <Popover
              anchorNode={iconRef.current}
              open={isPopoverOpen}
              onClose={onClose}
              position="bottom"
            >
              {hint}
            </Popover>
          </>
        )}
      </Row>
      {children}
    </Column>
  );
};

/* eslint-disable camelcase */
import React, { useState, useCallback, useMemo } from 'react';
import { CoreAsyncButton, useToaster } from '@amzn/dots-core-ui';
import Column from '@amzn/meridian/column';
import Textarea from '@amzn/meridian/textarea';
import Icon from '@amzn/meridian/icon';
import addTokens from '@amzn/meridian-tokens/base/icon/plus';
import {
  CreateJiraArgs,
  TagJiraArgs,
  useJiraCreate,
  useJiraTag,
  useUserProfile,
  useDashboardAnnotationCreate,
} from '@/hooks';
import { FlatKpi } from '@/types';

const parseCommaSeparatedValue = (value: string) =>
  value
    .split(',')
    .map((s) => s.trim())
    .filter((s) => s !== '');

const getInitialJiraState = (
  kpi: FlatKpi | undefined,
  jiraProject: string,
  jiraComponents: string,
  jiraLabels: string,
  kpiId: string
): CreateJiraArgs => {
  if (!kpi) {
    return {
      where: '',
      testrunId: '',
      source: '',
      summary: '',
      kpiBucket: '',
      labels: '',
      description: '',
      jiraProject,
      components: jiraComponents,
      referenceUrl: window.location.href,
    };
  }
  const where = kpi('kpi.where') as string;
  const testrunId = kpi('data.testrun_id') as string;
  const source = kpi('ingest.source') as string;
  const kpiBucket = kpi('kpi.bucket') as string;
  const kpiName = kpi('kpi.name') as string;
  const kpiSummary = kpi('kpi.kpi_summary') as string;
  const buildProject = kpi('data.build.project') as string;
  const buildType = kpi('data.build.type') as string;
  const buildVersion = kpi('data.build.version') as string;
  const buildVariant = kpi('data.build.variant') as string;
  const deviceType = kpi('data.device.type') as string;
  const devicePool = kpi('data.device.pool') as string;
  const deviceDsn = kpi('data.device.dsn') as string;
  const summary = kpiSummary
    ? `${kpiName} in ${kpiSummary}`
    : `Issue for Build with info ${buildProject} ${buildType} ${buildVersion} ${buildVariant} on Device with Info ${deviceType} ${devicePool} ${deviceDsn}`;
  const labels = [
    buildProject,
    buildType,
    buildVersion,
    buildVariant,
    deviceType,
    devicePool,
    deviceDsn,
  ]
    .concat(parseCommaSeparatedValue(jiraLabels))
    .join();
  const description = `KPI Detail Page: https://${window.location.host}/results/kpi/detail/${source}/${kpiId} \n\n`;
  return {
    where,
    testrunId,
    source,
    summary,
    kpiBucket,
    jiraProject,
    labels,
    components: jiraComponents ?? '',
    referenceUrl: window.location.href,
    description,
  };
};

export type JiraCreateButtonProps = {
  kpiId: string;
  dashboardId: string;
  tagProperties?: Omit<TagJiraArgs, 'jira'>;
  createKpiAnnotation?: boolean;
  kpi?: FlatKpi;
  jiraProject: string;
  jiraLabels: string;
  jiraComponents: string;
};

export const JiraCreateButton = ({
  kpiId,
  dashboardId,
  kpi,
  createKpiAnnotation = true,
  jiraProject,
  jiraLabels,
  jiraComponents,
  tagProperties,
}: JiraCreateButtonProps): JSX.Element => {
  const { openToast } = useToaster();
  const { mutateAsync: createJira } = useJiraCreate();
  const { mutateAsync: tagJira } = useJiraTag();
  const { mutateAsync: createAnnotation } = useDashboardAnnotationCreate(
    dashboardId
  );
  const {
    data: userProfile,
    isLoading: isUserProfileLoading,
  } = useUserProfile();
  const disabled = useMemo(
    () => kpi === undefined || (createKpiAnnotation && !userProfile),
    [createKpiAnnotation, kpi, userProfile]
  );
  const [jira, setJira] = useState<CreateJiraArgs>(
    getInitialJiraState(kpi, jiraProject, jiraComponents, jiraLabels, kpiId)
  );
  const onClick = useCallback(async (): Promise<void> => {
    var errorMessage = "";
    try {
      const newJira = await createJira(jira, {
        onError: (error: Error) =>
          {
            errorMessage = `Failed to create a new Jira: ${error}`
          }
      });
      if (tagProperties) {
        await tagJira(
          { ...tagProperties, jira: newJira.key },
          {
            onError: (error: Error) =>
              {
                errorMessage = `Failed to tag the Jira: ${error}`
              }
          }
        );
      }
      if (createAnnotation && userProfile) {
        try {
          await createAnnotation({
            type: 'kpi_annotation',
            notes: newJira.key,
            updater: userProfile.user_id,
            source: jira.source,
            kpi_id: kpiId,
          });
        } catch (error) {
          openToast({
            message: `Failed to add ${newJira.key} as an annotation`,
            type: 'error',
          });
        }
      }
      openToast({
        message: `Successfully created ${newJira.key}`,
        type: 'success',
      });
    } catch (error) {
      // we have a defined error, so we can show it to the user
      if(errorMessage){
        openToast({
          message: errorMessage,
          type: 'error'
        })
      } else {
      // we have an undefined error, so we show users a generic message
        openToast({
          message: 'Failed to create a new Jira',
          type: 'error'
        })
      }
    }
  }, [
    createJira,
    jira,
    tagProperties,
    createAnnotation,
    userProfile,
    openToast,
    tagJira,
    kpiId,
  ]);
  return (
    <CoreAsyncButton
      size="small"
      disabled={disabled}
      onClick={onClick}
      isLoading={createKpiAnnotation && isUserProfileLoading}
      confirm={{
        title: 'New Issue Details',
        children: (
          <Column spacing="medium" width={500}>
            <Textarea
              label="Issue Summary"
              value={jira.summary}
              onChange={(summary: string): void =>
                setJira((prev) => ({ ...prev, summary }))
              }
            />
            <Textarea
              label="Labels"
              placeholder="Split by comma"
              value={jira.labels}
              onChange={(labels: string): void =>
                setJira((prev) => ({ ...prev, labels }))
              }
            />
            <Textarea
              label="Components"
              placeholder="Split by comma"
              value={jira.components}
              onChange={(components: string): void =>
                setJira((prev) => ({ ...prev, components }))
              }
            />
          </Column>
        ),
      }}
    >
      <Icon tokens={addTokens} />
      Create Jira
    </CoreAsyncButton>
  );
};

/* eslint-disable no-param-reassign */
import { isNullOrWhitespace, keys } from '@amzn/dots-core-ui';
import { format, addDays, isValid, parse } from 'date-fns';
import { getUniqueId } from '@/features/Dashboards/helpers';
import {
  DashboardFilterDataRange,
  DashboardFilterTestrunRangeFilter,
  DashboardFilterTestrunRangeTestSourceFilter,
  DashboardRelativeTimeRange,
  DashboardExactTimeRange,
} from '@/types';
import {
  CustomTabParser,
  DataRangeParser,
  GoalGroupParser,
  RelativeTimeRangeLabel,
  TabOverrideParser,
  TestrunFilterParser,
  TimeMode,
  TranslatorFilterParser,
} from './types';

export const echoValue = (value: string): string => value;

export const relativeRanges: Record<
  RelativeTimeRangeLabel,
  DashboardRelativeTimeRange
> = {
  'Last day': { unit: 'days', number: 1 },
  'Last week': { unit: 'weeks', number: 1 },
  'Last 2 weeks': { unit: 'weeks', number: 2 },
  'Last 30 days': { unit: 'days', number: 30 },
};

export const defaultRelativeTimeRange: RelativeTimeRangeLabel = 'Last 2 weeks';

export const defaultExactTimeRange = [
  format(addDays(new Date(), -14), 'yyyy-MM-dd'),
  format(new Date(), 'yyyy-MM-dd'),
];

const getTimeMode = (range?: DashboardFilterDataRange): TimeMode =>
  range?.time?.range !== undefined &&
  Array.isArray(range.time.range) &&
  range.time.range.length === 2 &&
  range.time.range.every(
    (d) =>
      typeof d === 'string' &&
      !isNullOrWhitespace(d) &&
      isValid(parse(d, 'yyyy-MM-dd', new Date()))
  )
    ? 'exact'
    : 'relative';

export const getRelativeRangeLabel = (
  range?: DashboardFilterDataRange
): RelativeTimeRangeLabel => {
  if (
    range?.time?.range !== undefined &&
    !Array.isArray(range.time.range) &&
    'unit' in range.time.range &&
    'number' in range.time.range
  ) {
    if (range.time.range.number === 1 && range.time.range.unit === 'days') {
      return 'Last day';
    }
    if (range.time.range.number === 1 && range.time.range.unit === 'weeks') {
      return 'Last week';
    }
    if (range.time.range.number === 2 && range.time.range.unit === 'weeks') {
      return 'Last 2 weeks';
    }
    if (range.time.range.number === 30 && range.time.range.unit === 'days') {
      return 'Last 30 days';
    }
  }
  return 'Last 2 weeks';
};

const getExactRange = (
  range?: DashboardFilterDataRange
): DashboardExactTimeRange =>
  range?.time?.range !== undefined && Array.isArray(range.time.range)
    ? range.time.range
    : defaultExactTimeRange;

export const getTimeRange = (
  range?: DashboardFilterDataRange
): RelativeTimeRangeLabel | DashboardExactTimeRange =>
  getTimeMode(range) === 'exact'
    ? getExactRange(range)
    : getRelativeRangeLabel(range);

export const testrunFilterParser: TestrunFilterParser = {
  toFormModel: (testrunRange) => {
    if (
      testrunRange === undefined ||
      keys(testrunRange).every((testSource) =>
        keys(testrunRange[testSource]).every((filterType) =>
          keys(testrunRange[testSource][filterType]).every(
            (propertyName) =>
              testrunRange[testSource][filterType][propertyName]?.length === 0
          )
        )
      )
    ) {
      return [
        {
          id: getUniqueId(),
          testSource: 'KATS',
          propertyName: 'data.test_suite',
          filterType: 'include',
          value: [],
        },
      ];
    }
    return keys(testrunRange).flatMap((testSource) =>
      keys(testrunRange[testSource]).flatMap((filterType) =>
        keys(testrunRange[testSource][filterType]).map((propertyName) => ({
          id: getUniqueId(),
          testSource,
          propertyName,
          filterType,
          value: testrunRange[testSource][filterType][propertyName],
        }))
      )
    );
  },
  toConfigurationModel: (testrunRange) =>
    testrunRange.reduce(
      (result, { testSource, propertyName, filterType, value }) => {
        if (!(testSource in result)) {
          result[
            testSource
          ] = {} as DashboardFilterTestrunRangeTestSourceFilter;
        }
        if (!(filterType in result[testSource])) {
          result[testSource][filterType] = {};
        }
        result[testSource][filterType][propertyName] = value;
        return result;
      },
      {} as DashboardFilterTestrunRangeFilter
    ),
};

export const dataRangeParser: DataRangeParser = {
  toFormModel: (range) => ({
    allBuilds: range?.allBuilds ?? false,
    numberOfBuilds: range?.last_n_sth?.n.toString() ?? '10',
    timeRange: getTimeRange(range),
  }),
  toConfigurationModel: (range) => ({
    allBuilds: range.allBuilds,
    last_n_sth: {
      field: {
        KATS: 'data.build.version',
        Perf: 'data.metadata.build',
      },
      n: range.numberOfBuilds,
    },
    time: {
      field: {
        KATS: 'data.start_time',
        Perf: 'data.start_time',
      },
      range: Array.isArray(range.timeRange)
        ? range.timeRange
        : relativeRanges[range.timeRange],
    },
  }),
};

export const translatorFilterParser: TranslatorFilterParser = {
  toFormModel: (translators) =>
    translators?.map((t) => ({
      ...t,
      id: getUniqueId(),
    })) ?? [
      { id: 'd1', key: 'katsDefaultTrans', value: '0.0' },
      { id: 'd2', key: 'KatsTestcaseTranslator', value: '1.4' },
    ],
  toConfigurationModel: (translators) =>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    translators.map(({ id, ...translator }) => translator),
};

export const customTabParser: CustomTabParser = {
  toFormModel: (tabs) =>
    tabs?.map(({ field, values, auto }) => {
      const [source, ...propertyName] = field.split('.');
      return {
        source,
        propertyName: propertyName.join('.'),
        values,
        mode: auto ? 'auto' : 'selective',
        id: getUniqueId(),
      };
    }) ?? [],
  toConfigurationModel: (tabs) =>
    tabs.map((tab) => ({
      auto: tab.mode === 'auto',
      field: [tab.source, tab.propertyName].join('.'),
      values: tab.values,
    })),
};

export const tabOverrideParser: TabOverrideParser = {
  toFormModel: (overrides) =>
    overrides?.map((override) => ({
      ...override,
      id: getUniqueId(),
    })) ?? [],
  toConfigurationModel: (overrides) =>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    overrides.map(({ id, ...override }) => override),
};

export const goalGroupParser: GoalGroupParser = {
  toFormModel: (goals) =>
    goals?.map((goal) => ({
      ...goal,
      id: getUniqueId(),
    })) ?? [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toConfigurationModel: (goals) => goals.map(({ id, ...goal }) => goal),
};

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  CoreFilterableSelect,
  CoreFormFooter,
  CoreInput,
  CorePageLoader,
  CoreTextarea,
  isNullOrWhitespace,
  useForm,
} from '@amzn/dots-core-ui';
import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Select, { SelectOption } from '@amzn/meridian/select';
import Row from '@amzn/meridian/row';
import RadioButton from '@amzn/meridian/radio-button';
import { GroupSelect } from '@/components/GroupSelect';
import { GroupFilterSelect } from '@/components/GroupFilterSelect';
import { useAnnotationTemplates, useUserProfile } from '@/hooks';
import { Dashboard, DefaultAnnotationTemplateField } from '@/types';
import { DashboardFormSection } from '../DashboardFormSection';
import { DashboardFormRow } from '../DashboardFormRow';
import { DashboardTestrunFilterCollectionControl } from './DashboardTestrunFilterCollectionControl';
import {
  CustomTab,
  DataRangeFilter,
  GoalGroup,
  TabOverride,
  TestrunRangeFilter,
  TranslatorFilter,
  Visibility,
} from './types';
import {
  customTabParser,
  dataRangeParser,
  goalGroupParser,
  tabOverrideParser,
  testrunFilterParser,
  translatorFilterParser,
} from './helpers';
import { DashboardDataRangeControl } from './DashboardDataRangeControl';
import { DashboardTranslatorFilterCollectionControl } from './DashboardTranslatorFilterCollectionControl';
import { DashboardCustomTabCollectionControl } from './DashboardCustomTabCollectionControl';
import { DashboardTabOverrideCollectionControl } from './DashboardTabOverrideCollectionControl';
import { DashboardGoalGroupCollectionControl } from './DashboardGoalGroupCollectionControl';
import { DashboardImportedDashboardsControl } from './DashboardImportedDashboardsControl';
import {
  defaultDashboardLayouts,
  defaultDashboardWizards,
} from '../../constants';

const getVisibility = (viewers: string[], viewerGroups: string[]) =>
  viewers?.length > 0 || viewerGroups?.length > 0 ? 'private' : 'public';

export type DashboardFiltersFormProps = {
  dashboard?: Dashboard;
  onCancel: () => void;
  onSubmit: (dashboard: Partial<Dashboard>) => Promise<void> | void;
};

export const DashboardFiltersForm = ({
  onCancel,
  onSubmit,
  dashboard,
}: DashboardFiltersFormProps): JSX.Element => {
  const {
    data: userProfile,
    isLoading: isUserProfileLoading,
  } = useUserProfile();
  const {
    values,
    states,
    errors,
    handlers,
    hasErrors,
    handleSubmit,
    isSubmitting,
    submitState,
  } = useForm({
    name: {
      initialValue: dashboard?.name ?? '',
      validate: (value: string) =>
        isNullOrWhitespace(value) ? 'Name is required' :
        !(/^[a-zA-Z0-9.,\[\]\(\)_ -]*$/.test(value)) ? 'Name can only include A-Z,a-z,0-9,.,_,-,[],() characters' : '',
    },
    description: {
      initialValue: dashboard?.description ?? '',
    },
    jiraProject: {
      initialValue: dashboard?.jiraProject ?? '',
    },
    jiraComponents: {
      initialValue: dashboard?.jiraComponents ?? '',
    },
    jiraLabels: {
      initialValue: dashboard?.jiraLabels ?? '',
    },
    owners: {
      initialValue: dashboard
        ? dashboard.owners ?? []
        : userProfile
        ? [userProfile.user_id]
        : [],
    },
    ownerGroups: {
      initialValue: dashboard?.ownerGroups ?? [],
      validate: (value: string[]) =>
        value.length === 0 ? 'An owning group is required' : '',
    },
    viewers: {
      initialValue: dashboard?.viewers ?? [],
    },
    viewerGroups: {
      initialValue: dashboard?.viewerGroups ?? [],
    },
    testsources: {
      initialValue: dashboard?.testsources ?? ['KATS'],
      validate: (value: string[]) =>
        value.length === 0 ? 'A test source is required' : '',
    },
    testrunRange: {
      initialValue: testrunFilterParser.toFormModel(
        dashboard?.filters?.testrun_range
      ),
      validate: (value: TestrunRangeFilter[]) => {
        if (value.length === 0) {
          return 'At least one filter required';
        }
        if (
          value.some(
            (f) =>
              isNullOrWhitespace(f.propertyName) ||
              (Array.isArray(f.value) && f.value.length === 0) ||
              isNullOrWhitespace(f.value)
          )
        ) {
          return 'There are errors in the filters defined below';
        }
        return '';
      },
    },
    dataRange: {
      initialValue: dataRangeParser.toFormModel(dashboard?.filters?.data_range),
      validate: (value: DataRangeFilter) => {
        if (
          (!value.allBuilds && isNullOrWhitespace(value.numberOfBuilds)) ||
          (Array.isArray(value.timeRange) &&
            (value.timeRange.length !== 2 ||
              value.timeRange.some(isNullOrWhitespace))) ||
          isNullOrWhitespace(value.timeRange)
        ) {
          return 'There are errors in the data range';
        }
        return '';
      },
    },
    translators: {
      initialValue: translatorFilterParser.toFormModel(
        dashboard?.filters?.auto_translators
      ),
      validate: (value: TranslatorFilter[]) =>
        value.some(
          (t) => isNullOrWhitespace(t.key) || isNullOrWhitespace(t.value)
        )
          ? 'There are errors in the translator filters'
          : '',
    },
    groupFilters: {
      initialValue: dashboard?.filters?.group_filter ?? [],
    },
    groupTabs: {
      initialValue: dashboard?.filters?.group_tabs ?? [],
    },
    customTabs: {
      initialValue: customTabParser.toFormModel(
        dashboard?.filters?.custom_tabs
      ),
      validate: (value: CustomTab[]) =>
        value.some(
          (tab) =>
            isNullOrWhitespace(tab.propertyName) ||
            (tab.mode === 'selective' && tab.values.length === 0)
        )
          ? 'There are errors in the custom tabs'
          : '',
    },
    tabOverrides: {
      initialValue: tabOverrideParser.toFormModel(
        dashboard?.filters?.local_group_tabs
      ),
      validate: (value: TabOverride[]) =>
        value.some(
          (override) =>
            isNullOrWhitespace(override.groupName) ||
            isNullOrWhitespace(override.type) ||
            override.value.length === 0
        )
          ? 'There are errors in the local tab groups'
          : '',
    },
    goalGroups: {
      initialValue: goalGroupParser.toFormModel(
        dashboard?.filters?.goal_groups
      ),
      validate: (value: GoalGroup[]) =>
        value.some(
          (goal) =>
            isNullOrWhitespace(goal.kpi) || isNullOrWhitespace(goal.display)
        )
          ? 'There are errors in the goal groups'
          : '',
    },
    importedDashboards: {
      initialValue: dashboard?.filters?.imported_dashboards ?? [],
    },
    annotationTemplate: {
      initialValue: dashboard?.templateState,
    }
  });
  const [{ visibility }, setState] = useState({
    visibility: getVisibility(values.viewers, values.viewerGroups),
  });
  const setVisibility = (nextValue: Visibility) =>
    setState((prev) => ({ ...prev, visibility: nextValue }));

  useEffect(() => {
    if (!dashboard && userProfile) {
      handlers.owners([userProfile?.user_id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard, userProfile]);

  useEffect(() => {
    setVisibility(getVisibility(values.viewers, values.viewerGroups));
  }, [values.viewers, values.viewerGroups]);

  const { data: annotationTemplates, isLoading: isAnnotationTemplatesLoading } = useAnnotationTemplates();

  return (
    <form
      onSubmit={handleSubmit((vs) => {
        const nextDashboard: Partial<Dashboard> = {
          name: vs.name,
          description: vs.description,
          jiraProject: vs.jiraProject,
          jiraLabels: vs.jiraLabels,
          jiraComponents: vs.jiraComponents,
          testsources: vs.testsources,
          owners: vs.owners,
          ownerGroups: vs.ownerGroups,
          viewers: vs.viewers,
          viewerGroups: vs.viewerGroups,
          filters: {
            auto_translators: translatorFilterParser.toConfigurationModel(
              vs.translators
            ),
            custom_tabs: customTabParser.toConfigurationModel(vs.customTabs),
            data_range: dataRangeParser.toConfigurationModel(vs.dataRange),
            goal_groups: goalGroupParser.toConfigurationModel(vs.goalGroups),
            group_filter: vs.groupFilters,
            group_tabs: vs.groupTabs,
            import_filters: vs.importedDashboards,
            imported_dashboards: vs.importedDashboards,
            local_group_tabs: tabOverrideParser.toConfigurationModel(
              vs.tabOverrides
            ),
            testrun_range: testrunFilterParser.toConfigurationModel(
              vs.testrunRange
            ),
          },
          templateState: vs.annotationTemplate,
          template: annotationTemplates ? annotationTemplates[vs.annotationTemplate] : DefaultAnnotationTemplateField,
        };
        if (dashboard) {
          nextDashboard.id = dashboard.id;
          nextDashboard.layouts = dashboard.layouts;
          nextDashboard.wizards = dashboard.wizards;
        } else {
          nextDashboard.layouts = defaultDashboardLayouts;
          nextDashboard.wizards = defaultDashboardWizards;
        }
        return onSubmit(nextDashboard);
      })}
    >
      <Column spacing="500" maxWidth={1100} alignmentVertical="top">
        {submitState.status === 'error' && (
          <Alert type="error" title="Error!" size="large">
            {submitState.error?.message}
          </Alert>
        )}
        <DashboardFormSection title="Basic Info">
          <DashboardFormRow label="Name">
            <CoreInput
              value={values.name}
              onChange={handlers.name}
              errorMessage={errors.name}
              validationState={states.name}
            />
          </DashboardFormRow>
          <DashboardFormRow label="Description">
            <CoreTextarea
              value={values.description}
              onChange={handlers.description}
              errorMessage={errors.description}
              validationState={states.description}
              rows={3}
            />
          </DashboardFormRow>
          <DashboardFormRow label="Test Source">
            <Select
              value={values.testsources}
              onChange={handlers.testsources}
              disabled
            >
              <SelectOption label="KATS" value="KATS" />
            </Select>
          </DashboardFormRow>
        </DashboardFormSection>
        <DashboardFormSection title="Owners">
          <DashboardFormRow label="Users">
            <CoreFilterableSelect
              canAddItems
              items={dashboard?.owners ?? []}
              value={values.owners}
              onChange={handlers.owners}
              errorMessage={errors.owners}
              validationState={states.owners}
              disabled={isUserProfileLoading}
            />
          </DashboardFormRow>
          <DashboardFormRow label="Groups">
            <GroupSelect
              value={values.ownerGroups}
              onChange={handlers.ownerGroups}
              errorMessage={errors.ownerGroups}
              validationState={states.ownerGroups}
            />
          </DashboardFormRow>
        </DashboardFormSection>
        <DashboardFormSection title="Visibility">
          <DashboardFormRow label="Visibility">
            <Row alignmentVertical="center">
              <RadioButton
                checked={visibility === 'public'}
                onChange={(value) => {
                  setVisibility(value);
                  handlers.viewers([]);
                  handlers.viewerGroups([]);
                }}
                name="Restricted"
                value="public"
              >
                Public
              </RadioButton>
              <RadioButton
                checked={visibility === 'private'}
                onChange={setVisibility}
                name="Restricted"
                value="private"
              >
                Restricted
              </RadioButton>
            </Row>
          </DashboardFormRow>
          {visibility === 'private' && (
            <>
              <DashboardFormRow label="Users">
                <CoreFilterableSelect
                  canAddItems
                  items={dashboard?.viewers ?? []}
                  value={values.viewers}
                  onChange={handlers.viewers}
                  errorMessage={errors.viewers}
                  validationState={states.viewers}
                />
              </DashboardFormRow>
              <DashboardFormRow label="Groups">
                <GroupSelect
                  value={values.viewerGroups}
                  onChange={handlers.viewerGroups}
                  errorMessage={errors.viewerGroups}
                  validationState={states.viewerGroups}
                />
              </DashboardFormRow>
            </>
          )}
        </DashboardFormSection>
        { annotationTemplates !== undefined &&
          <DashboardFormSection title="Annotation Template">
            <DashboardFormRow label="Annotation Template">
              <Select
                value={values.annotationTemplate}
                onChange={handlers.annotationTemplate}
                placeholder="Select annotation template...">
                {annotationTemplates.map(( annotationField, index ) =>
                  <SelectOption value={index} label={annotationField.name} key={annotationField.name} />
                )
                }
              </Select>
            </DashboardFormRow>
          </DashboardFormSection>
        }
        { isAnnotationTemplatesLoading && <CorePageLoader /> }
        <DashboardFormSection title="Jira Integration">
          <DashboardFormRow label="Project">
            <CoreInput
              placeholder="A project key like DOTS or KATS..."
              value={values.jiraProject}
              onChange={handlers.jiraProject}
              errorMessage={errors.jiraProject}
              validationState={states.jiraProject}
            />
          </DashboardFormRow>
          <DashboardFormRow label="Components">
            <CoreInput
              placeholder="Comma separated components, used in every new issue"
              value={values.jiraComponents}
              onChange={handlers.jiraComponents}
              errorMessage={errors.jiraComponents}
              validationState={states.jiraComponents}
            />
          </DashboardFormRow>
          <DashboardFormRow label="Labels">
            <CoreInput
              placeholder="Comma separated labels, used in every new issue"
              value={values.jiraLabels}
              onChange={handlers.jiraLabels}
              errorMessage={errors.jiraLabels}
              validationState={states.jiraLabels}
            />
          </DashboardFormRow>
        </DashboardFormSection>
        <DashboardTestrunFilterCollectionControl
          testSource={values.testsources[0]}
          value={values.testrunRange}
          onChange={handlers.testrunRange}
          errorMessage={errors.testrunRange}
          validationState={states.testrunRange}
        />
        <DashboardDataRangeControl
          testSource={values.testsources[0]}
          value={values.dataRange}
          onChange={handlers.dataRange}
          errorMessage={errors.dataRange}
          validationState={states.dataRange}
        />
        <DashboardTranslatorFilterCollectionControl
          testSource={values.testsources[0]}
          value={values.translators}
          onChange={handlers.translators}
          errorMessage={errors.translators}
          validationState={states.translators}
        />
        <DashboardFormSection
          title="Group Filters"
          descriptionText="Filter data by preset Project Groups or Device Families"
        >
          <DashboardFormRow label="Groups">
            <GroupFilterSelect
              value={values.groupFilters}
              onChange={handlers.groupFilters}
              errorMessage={errors.groupFilters}
              validationState={states.groupFilters}
            />
          </DashboardFormRow>
        </DashboardFormSection>
        <DashboardFormSection
          title="Group Tabs"
          descriptionText="Define tabs to filter data by preset Project Groups or Device Families"
        >
          <DashboardFormRow label="Tabs">
            <GroupFilterSelect
              value={values.groupTabs}
              onChange={handlers.groupTabs}
              errorMessage={errors.groupTabs}
              validationState={states.groupTabs}
            />
          </DashboardFormRow>
        </DashboardFormSection>
        <DashboardCustomTabCollectionControl
          testSource={values.testsources[0]}
          value={values.customTabs}
          onChange={handlers.customTabs}
          errorMessage={errors.customTabs}
          validationState={states.customTabs}
        />
        <DashboardTabOverrideCollectionControl
          testSource={values.testsources[0]}
          value={values.tabOverrides}
          onChange={handlers.tabOverrides}
          errorMessage={errors.tabOverrides}
          validationState={states.tabOverrides}
        />
        <DashboardGoalGroupCollectionControl
          testSource={values.testsources[0]}
          value={values.goalGroups}
          onChange={handlers.goalGroups}
          errorMessage={errors.goalGroups}
          validationState={states.goalGroups}
        />
        <DashboardImportedDashboardsControl
          testSource={values.testsources[0]}
          value={values.importedDashboards}
          onChange={handlers.importedDashboards}
          errorMessage={errors.importedDashboards}
          validationState={states.importedDashboards}
        />
        <CoreFormFooter
          isConfirmDisabled={hasErrors || isSubmitting}
          isLoading={isSubmitting}
          onCancelClick={onCancel}
        />
      </Column>
    </form>
  );
};

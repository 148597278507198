import React from 'react';
import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Input from '@amzn/meridian/input';
import InputGroup from '@amzn/meridian/input-group';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import Text from '@amzn/meridian/text';
import AddTokens from '@amzn/meridian-tokens/base/icon/plus';
import TrashTokens from '@amzn/meridian-tokens/base/icon/trash';
import { CoreInput, isNullOrWhitespace, useForm } from '@amzn/dots-core-ui';
import { TestSourcePropertySelect } from '@/components';
import { useDashboard } from '@/features/Dashboards/contexts';
import { getUniqueId } from '@/features/Dashboards/helpers';
import { WithId } from '@/features/Dashboards/types';
import { KpiTableWizardConfiguration } from '@/types';
import { DashboardWizardBaseControl } from './DashboardWizardBaseControl';
import { DashboardWizardFormFieldLabel } from './DashboardWizardFormFieldLabel';
import {
  onWizardConfirmFactory,
  getWizardBaseFormConfiguration,
  kpiDimensionParser,
  userDefinedColumnParser,
  userDefinedVariableParser,
} from './helpers';
import {
  DashboardWizardFormProps,
  KpiDimension,
  UserDefinedColumn,
  UserDefinedVariable,
} from './types';

export const DashboardKpiTableWizardForm = ({
  wizard,
  onCancel,
  onSubmit,
}: DashboardWizardFormProps<KpiTableWizardConfiguration>): JSX.Element => {
  const { dashboard } = useDashboard();
  const { hasErrors, handlers, values, errors } = useForm({
    ...getWizardBaseFormConfiguration(wizard),
    display: { initialValue: wizard.display ?? 'Data item in columns' },
    dimensions: {
      initialValue: kpiDimensionParser.toFormModel(wizard.attrs),
      validate: (value: KpiDimension[]) => {
        if (value.length === 0) {
          return 'At least one attribute must be defined';
        }
        if (
          value.some(
            (v) => isNullOrWhitespace(v.name) || isNullOrWhitespace(v.data)
          )
        ) {
          return 'Name and Property must be defined for all columns';
        }
        return '';
      },
    },
    columns: {
      initialValue: userDefinedColumnParser.toFormModel(wizard.extras),
      validate: (value: UserDefinedColumn[]) => {
        if (
          value.some(
            (v) => isNullOrWhitespace(v.name) || isNullOrWhitespace(v.format)
          )
        ) {
          return 'Name and Format must be defined for all columns';
        }
        return '';
      },
    },
    variables: {
      initialValue: userDefinedVariableParser.toFormModel(wizard.data),
      validate: (value: UserDefinedVariable[]) => {
        if (
          value.some(
            (v) =>
              isNullOrWhitespace(v.name) || isNullOrWhitespace(v.expression)
          )
        ) {
          return 'Name and Expression must be defined for all variables';
        }
        return '';
      },
    },
  });
  const onChange = (
    targetId: string,
    field: 'dimensions' | 'columns' | 'variables',
    next:
      | Partial<UserDefinedColumn>
      | Partial<UserDefinedVariable>
      | Partial<KpiDimension>
  ) =>
    handlers[field](
      values[field].map((item: WithId<unknown>) => {
        if (item.id === targetId) {
          return { ...item, ...next };
        }
        return item;
      })
    );
  const onRemove = (
    targetId: string,
    field: 'dimensions' | 'columns' | 'variables'
  ) =>
    handlers[field](
      values[field].filter(({ id }: WithId<unknown>) => id !== targetId)
    );
  const onAdd = (field: 'dimensions' | 'columns' | 'variables') => () =>
    handlers[field](values[field].concat({ id: getUniqueId() }));
  const onConfirm = () =>
    onSubmit({
      ...onWizardConfirmFactory(wizard, values),
      type: wizard.type,
      attrs: kpiDimensionParser.toConfigurationModel(values.dimensions),
      data: userDefinedVariableParser.toConfigurationModel(values.variables),
      extras: userDefinedColumnParser.toConfigurationModel(values.columns),
      display: values.display,
    });
  return (
    <Column>
      <DashboardWizardBaseControl
        wizard={wizard}
        values={values}
        handlers={handlers}
      />
      <Select
        label="Table Display Type"
        value={values.display}
        onChange={handlers.display}
      >
        <SelectOption
          label="Data item in columns"
          value="Data item in columns"
        />
        <SelectOption label="Data item in rows" value="Data item in rows" />
      </Select>
      <DashboardWizardFormFieldLabel
        label="Table Attributes"
        hint={
          <Column spacing="100">
            <Text>
              KPI properties to group KPIs by. Each group is represented by a
              column/row.
            </Text>
          </Column>
        }
      >
        <Column>
          {errors.dimensions.length !== 0 && (
            <Alert type="error" size="small">
              {errors.dimensions}
            </Alert>
          )}
          {values.dimensions.map(({ id, name, data }: KpiDimension) => (
            <Column key={id}>
              <Row widths={['fill', 'fit']}>
                <Row widths="grid-6">
                  <CoreInput
                    label="Name"
                    value={name}
                    onChange={(value) =>
                      onChange(id, 'dimensions', { name: value })
                    }
                    errorMessage={
                      isNullOrWhitespace(name) ? 'Name is required' : ''
                    }
                  />
                  <TestSourcePropertySelect
                    label="KPI Property"
                    testSource={dashboard.testsources}
                    value={data}
                    onChange={(value: any) =>
                      onChange(id, 'dimensions', { data: value })
                    }
                    errorMessage={
                      isNullOrWhitespace(data) ? 'KPI Property is required' : ''
                    }
                  />
                </Row>
                <Button
                  type="secondary"
                  onClick={() => onRemove(id, 'dimensions')}
                  disabled={values.dimensions.length === 1}
                >
                  <Icon tokens={TrashTokens}>Remove this attribute</Icon>
                </Button>
              </Row>
            </Column>
          ))}
        </Column>
        <Button type="tertiary" onClick={onAdd('dimensions')}>
          <Icon tokens={AddTokens}>Add a new attribute</Icon>
        </Button>
      </DashboardWizardFormFieldLabel>
      <DashboardWizardFormFieldLabel
        label="Additional Table Columns"
        hint={
          <Column spacing="100">
            <Text>
              Optional, define the name and format for each column to add.
            </Text>
            <Text>
              Column formats can reference the name fields of the User Defined
              Variables below and define the template for each cell in that
              column.
            </Text>
          </Column>
        }
      >
        <Column>
          {errors.columns.length !== 0 && (
            <Alert type="error" size="small">
              {errors.columns}
            </Alert>
          )}
          {values.columns.map(({ id, name, format }: UserDefinedColumn) => (
            <Column key={id}>
              <Row widths={['fill', 'fit']}>
                <InputGroup
                  value={[name, format]}
                  onChange={([nextName, nextFormat]) =>
                    onChange(id, 'columns', {
                      name: nextName,
                      format: nextFormat,
                    })
                  }
                  direction="column"
                >
                  <Input label="Name" error={isNullOrWhitespace(name)} />
                  <Input label="Format" error={isNullOrWhitespace(format)} />
                </InputGroup>
                <Button
                  type="secondary"
                  onClick={() => onRemove(id, 'columns')}
                >
                  <Icon tokens={TrashTokens}>Remove this column</Icon>
                </Button>
              </Row>
              {(isNullOrWhitespace(name) || isNullOrWhitespace(format)) && (
                <Alert type="error" size="small">
                  Name and Format must be defined
                </Alert>
              )}
            </Column>
          ))}
        </Column>
        <Button type="tertiary" onClick={onAdd('columns')}>
          <Icon tokens={AddTokens}>Add a new column</Icon>
        </Button>
      </DashboardWizardFormFieldLabel>
      <DashboardWizardFormFieldLabel
        label="User Defined Variables"
        hint={
          <Column spacing="100">
            <Text>
              Define the name and expression for variables to be used in
              Additional Table Columns above.
            </Text>
          </Column>
        }
      >
        <Column>
          {errors.variables.length !== 0 && (
            <Alert type="error" size="small">
              {errors.variables}
            </Alert>
          )}
          {values.variables.map(
            ({ id, name, expression }: UserDefinedVariable) => (
              <Column key={id}>
                <Row widths={['fill', 'fit']}>
                  <InputGroup
                    value={[name, expression]}
                    onChange={([nextName, nextExpression]) =>
                      onChange(id, 'variables', {
                        name: nextName,
                        expression: nextExpression,
                      })
                    }
                    direction="column"
                  >
                    <Input label="Name" error={isNullOrWhitespace(name)} />
                    <Input
                      label="Expression"
                      error={isNullOrWhitespace(expression)}
                    />
                  </InputGroup>
                  <Button
                    type="secondary"
                    onClick={() => onRemove(id, 'variables')}
                  >
                    <Icon tokens={TrashTokens}>Remove this variable</Icon>
                  </Button>
                </Row>
                {(isNullOrWhitespace(name) ||
                  isNullOrWhitespace(expression)) && (
                  <Alert type="error" size="small">
                    Name and Expression must be defined
                  </Alert>
                )}
              </Column>
            )
          )}
        </Column>
        <Button type="tertiary" onClick={onAdd('variables')}>
          <Icon tokens={AddTokens}>Add a new variable</Icon>
        </Button>
      </DashboardWizardFormFieldLabel>
      <Row alignmentHorizontal="right" alignmentVertical="center" widths="fit">
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={hasErrors} onClick={onConfirm}>
          Confirm
        </Button>
      </Row>
    </Column>
  );
};

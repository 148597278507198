import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useHttp, HttpClient } from '@/client';
import { Jira } from '@/types';

export type LinkJiraArgs = {
  jira: string;
  kpiBucket: string;
  kpiBucketLink: string;
  buildVersion: string;
  buildProject: string;
  testsuiteName: string;
};

export const linkJira = async (
  http: HttpClient,
  args: LinkJiraArgs
): Promise<Jira> => {
  const { data, status } = await http.post('jira/link', args);
  if (status.code === 200) {
    return data;
  }
  throw new Error(data.message);
};

export const useJiraLink = (): UseMutationResult<Jira, Error, LinkJiraArgs> => {
  const { http } = useHttp();
  const queryClient = useQueryClient();
  return useMutation((args: LinkJiraArgs) => linkJira(http, args), {
    onSuccess: () => queryClient.invalidateQueries(['jiras']),
  });
};

/* eslint-disable no-param-reassign */
import React, { useCallback, useMemo, useState } from 'react';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import Pagination from '@amzn/meridian/pagination';
import Text from '@amzn/meridian/text';
import viewTokens from '@amzn/meridian-tokens/base/icon/view';
import Link from '@amzn/meridian/link';
import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import { TableRowProps } from '@amzn/meridian/table/table-row';
import Alert from '@amzn/meridian/alert';
import { KpiLogFile, PageError } from '@/components';
import {
  KpiFilterResult,
  mapBaseDataPageToFlatKpi,
  usePagination,
  useSortableData,
} from '@/hooks';

type GroupedKpis = Record<
  string,
  {
    testStartTime: string;
    testrun: string;
    build: string;
    device: string;
    kpi: string;
    value: string;
    source: string;
    testrunId: string;
    filePaths: string[];
  }
>;

const ExpandableRow = ({
  children,
  rowComponents,
}: TableRowProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const onClick = useCallback(() => setOpen(!open), [open]);
  return (
    <TableRow open={open} onClick={onClick} rowComponents={rowComponents}>
      {children}
    </TableRow>
  );
};

export type SelectedLog = {
  source: string;
  testrunId: string;
  logFilePath: string;
};

export type KpiBucketTestrunTableProps = {
  kpiBucket: string;
  kpiFilterResult: KpiFilterResult;
};

export const KpiBucketTestrunTable = ({
  kpiBucket,
  kpiFilterResult,
}: KpiBucketTestrunTableProps): JSX.Element => {
  const columns = [
    { label: 'Test Start Time', sortKey: 'testStartTime' },
    { label: 'Testrun', sortKey: 'testrun' },
    { label: 'Build', sortKey: 'build' },
    { label: 'Device', sortKey: 'device' },
    { label: 'KPI', sortKey: 'kpi' },
    { label: 'Value', sortKey: 'value' },
  ];
  const [selectedLog, setSelectedLog] = useState<SelectedLog | undefined>();
  const tableRows = useMemo(() => {
    const kpis = mapBaseDataPageToFlatKpi(kpiFilterResult.baseData);
    const testruns: GroupedKpis = kpis
      .filter((kpi) => kpi('kpi.bucket') === kpiBucket)
      .reduce((groups, kpi): GroupedKpis => {
        const currentTestrun = kpi('data.testrun_id');
        if (currentTestrun in groups) {
          groups[currentTestrun].value = (
            Number(groups[currentTestrun].value) + Number(kpi('kpi.value'))
          ).toString();
          groups[currentTestrun].filePaths = groups[
            currentTestrun
          ].filePaths.concat(kpi('kpi.where') as string);
        } else {
          groups[currentTestrun] = {
            testStartTime: kpi('data.start_time') as string,
            testrun: `https://tools.dots.lab126.a2z.com/scheduling/testruns/${kpi(
              'data.testrun_id'
            )}`,
            testrunId: kpi('data.testrun_id') as string,
            source: kpi('ingest.source') as string,
            build: `${kpi('data.build.project')} - ${kpi(
              'data.build.type'
            )} - ${kpi('data.build.version')} - ${kpi('data.build.variant')}`,
            device: `${kpi('data.device.type')}: ${kpi('data.device.dsn')}`,
            kpi: kpi('kpi.name') as string,
            value: kpi('kpi.value') as string,
            filePaths: [kpi('kpi.where') as string],
          };
        }
        return groups;
      }, {} as GroupedKpis);
    return Object.values(testruns);
  }, [kpiBucket, kpiFilterResult.baseData]);
  const { items: sortedTableRows, sortState, onSort } = useSortableData(
    tableRows,
    {
      sortColumn: 'testStartTime',
      sortDirection: 'descending',
    }
  );
  const { currentPage, numberOfPages, changePage, data: page } = usePagination(
    sortedTableRows
  );
  if (tableRows.length === 0) {
    return (
      <PageError message="No Kpis found, please review the current filters" />
    );
  }
  return (
    <>
      <Column>
        <Heading level={3}>Testrun Log Files</Heading>
        <Table
          headerRows={1}
          showDividers
          spacing="small"
          sortColumn={sortState.sortColumn}
          sortDirection={sortState.sortDirection}
          onSort={onSort}
          rowComponents={[ExpandableRow, TableRow]}
        >
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.sortKey} sortColumn={column.sortKey}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
          {page.map((row) => (
            <ExpandableRow key={row.testrun}>
              <TableCell>{row.testStartTime}</TableCell>
              <TableCell>
                <Link href={row.testrun} target="_blank">
                  {row.testrun}
                </Link>
              </TableCell>
              <TableCell>{row.build}</TableCell>
              <TableCell>{row.device}</TableCell>
              <TableCell>{row.kpi}</TableCell>
              <TableCell>{row.value}</TableCell>
              {row.filePaths.map((path) => (
                <TableRow key={path}>
                  <TableCell columnSpan={columns.length}>
                    <Row type="fill" spacingInset="200" spacing="400">
                      <Button
                        size="small"
                        disabled={
                          row.source === selectedLog?.source &&
                          row.testrunId === selectedLog?.testrunId &&
                          path === selectedLog?.logFilePath
                        }
                        onClick={(): void =>
                          setSelectedLog({
                            source: row.source,
                            testrunId: row.testrunId,
                            logFilePath: path,
                          })
                        }
                      >
                        <Icon tokens={viewTokens}>View Log</Icon>
                      </Button>
                      <Text type="b200" breakWord>
                        {path}
                      </Text>
                    </Row>
                  </TableCell>
                </TableRow>
              ))}
            </ExpandableRow>
          ))}
        </Table>
        <Row alignmentHorizontal="right">
          <Pagination
            numberOfPages={numberOfPages}
            currentPage={currentPage}
            onChange={changePage}
          />
        </Row>
      </Column>
      {!selectedLog && (
        <Alert>
          Select a log file path from one of the above testruns to see the
          log&apos;s contents
        </Alert>
      )}
      {selectedLog && (
        <KpiLogFile
          source={selectedLog.source}
          testrunId={selectedLog.testrunId}
          logFilePath={selectedLog.logFilePath}
        />
      )}
    </>
  );
};

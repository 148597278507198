/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { isNullOrWhitespace, ValidationProps } from '@amzn/dots-core-ui';
import { useHttp } from '../client/HttpContext';
import { CoreAsyncSelect, CoreAsyncSelectProps } from './CoreAsyncSelect';

const get = (item: string): string => item;

export type TestSourcePropertyValueSelectProps = {
  propertyName: string;
  testSource: string;
} & Pick<
  ValidationProps<CoreAsyncSelectProps<string>>,
  | 'createItem'
  | 'label'
  | 'value'
  | 'onChange'
  | 'errorMessage'
  | 'validationState'
>;

export const TestSourcePropertyValueSelect = ({
  propertyName,
  testSource,
  ...props
}: TestSourcePropertyValueSelectProps): JSX.Element => {
  const { http } = useHttp();
  const getValues = useCallback(
    async (query: string): Promise<string[]> => {
      if (isNullOrWhitespace(query)) {
        return [];
      }
      const data = await http.get(`testsource/${testSource}/${propertyName}`, {
        search: query,
        limit: 100,
      });
      if (data.status.code === 200) {
        return data.data.map(
          (prop: { label: string; value: string }) => prop.label
        );
      }
      return [];
    },
    [http, testSource, propertyName]
  );
  return (
    <CoreAsyncSelect
      {...props}
      placeholder="Filter values"
      getItems={getValues}
      getItemLabel={get}
      getItemValue={get}
    />
  );
};

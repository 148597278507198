import React, { useContext, useMemo } from 'react';
import { TokenCreator, useAuth } from '@amzn/dots-core-ui';
import { createHttpClient } from './http';
import { HttpClient } from './types';

export type HttpContext = {
  http: HttpClient;
};

export const HttpContext = React.createContext<HttpContext | undefined>(
  undefined
);

export type HttpProviderProps = {
  children: React.ReactNode;
  createToken?: TokenCreator;
};

export const HttpProvider = ({
  children,
  createToken,
}: HttpProviderProps): JSX.Element => {
  // createToken could come from one of two places: props or AuthProvider
  // use the props by default and optionally subscribe to the AuthProvider
  let defaultCreateToken = createToken;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    defaultCreateToken = useAuth().createToken;
  } catch (error) {
    // ignored, assume props are available
  }
  if (defaultCreateToken === undefined) {
    throw new Error('no TokenCreator provided');
  }
  const httpClient = createHttpClient(createToken ?? defaultCreateToken);
  const value = useMemo(() => ({ http: httpClient }), [httpClient]);

  return <HttpContext.Provider value={value}>{children}</HttpContext.Provider>;
};

export const useHttp = (): HttpContext => {
  const context = useContext(HttpContext);
  if (context === undefined) {
    throw new Error('useHttp must be used within an HttpProvider');
  }
  return context;
};

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useToaster } from '@amzn/dots-core-ui';
import { DashboardDetailUri, DashboardsUri } from '@/features/routes';
import { Dashboard } from '@/types';
import { CorePage, CorePageTitle } from '@/components';
import { DashboardFiltersForm } from './components';
import { useDashboardCreate } from './hooks';

export const DashboardCreatePage = (): JSX.Element => {
  const history = useHistory();
  const { openToast } = useToaster();
  const { mutateAsync } = useDashboardCreate();
  const onSubmit = useCallback(
    async (nextDashboard: Partial<Dashboard>) => {
      await mutateAsync(nextDashboard);
      openToast({
        title: 'Success!',
        message: `${nextDashboard?.name} was created successfully.`,
        type: 'success',
      });
      history.push(DashboardDetailUri(nextDashboard?.name ?? ''));
    },
    [history, mutateAsync, openToast]
  );
  return (
    <CorePage
      title="Add new"
      breadcrumbs={[{ href: DashboardsUri, title: 'Dashboards' }]}
      header={<CorePageTitle title="Add a New Dashboard" />}
    >
      <DashboardFiltersForm
        onCancel={() => history.goBack()}
        onSubmit={onSubmit}
      />
    </CorePage>
  );
};

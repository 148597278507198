import React from 'react';
import Heading from '@amzn/meridian/heading';
import Column from '@amzn/meridian/column';
import Box from '@amzn/meridian/box';
import { CorePage, CorePageTitle } from '@/components';
import { ContactUs, Documents, Glossary } from './components';

export const HelpCenterPage = (): JSX.Element => (
  <CorePage title="Help Center" header={<CorePageTitle title="Help Center" />}>
    <Box>
      <Column spacing="400">
        <Heading level={4}>Documents</Heading>
        <Documents />
        <Box />
        <Heading level={4}>Glossary</Heading>
        <Glossary />
        <Box />
        <Heading level={4}>Contact us</Heading>
        <ContactUs />
      </Column>
    </Box>
  </CorePage>
);

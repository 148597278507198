/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { isNullOrWhitespace, ValidationProps } from '@amzn/dots-core-ui';
import { useHttp } from '../client/HttpContext';
import { CoreAsyncSelect, CoreAsyncSelectProps } from './CoreAsyncSelect';

const get = (item: string): string => item;

export type GroupSelectProps = Pick<
  ValidationProps<CoreAsyncSelectProps<string>>,
  'value' | 'onChange' | 'errorMessage' | 'validationState'
>;

export const GroupSelect = (props: GroupSelectProps): JSX.Element => {
  const { http } = useHttp();
  const getGroups = useCallback(
    async (query: string): Promise<string[]> => {
      if (isNullOrWhitespace(query)) {
        return [];
      }
      const data = await http.get('groups', { query });
      if (data.status.code === 200) {
        return data.data;
      }
      return [];
    },
    [http]
  );
  return (
    <CoreAsyncSelect
      {...props}
      placeholder="Search for a group..."
      getItems={getGroups}
      getItemLabel={get}
      getItemValue={get}
    />
  );
};

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useToaster } from '@amzn/dots-core-ui';
import {
  useDashboardEmailReport,
  useDashboardEmailReportUpdate,
} from '@/hooks';
import {
  DashboardDetailUri,
  DashboardEmailReportsUri,
  DashboardsUri,
} from '@/features/routes';
import { DashboardEmailReportConfiguration } from '@/types';
import { CorePage, CorePageTitle } from '@/components';
import { DashboardEmailReportForm } from './components';
import { useDashboard, withDashboard } from './contexts';

export const DashboardEmailReportEditPage = withDashboard(
  (): JSX.Element => {
    const history = useHistory();
    const { openToast } = useToaster();
    const { dashboard } = useDashboard();
    const { data: report } = useDashboardEmailReport();
    const { mutateAsync } = useDashboardEmailReportUpdate();
    const onSubmit = useCallback(
      async (updatedReport: Partial<DashboardEmailReportConfiguration>) => {
        await mutateAsync(updatedReport);
        openToast({
          title: 'Success!',
          message: `${updatedReport?.title} has been updated.`,
          type: 'success',
        });
        history.push(DashboardEmailReportsUri(dashboard.name));
      },
      [history, mutateAsync, dashboard.name, openToast]
    );
    return (
      <CorePage
        title={`Edit ${report?.title}`}
        breadcrumbs={[
          { href: DashboardsUri, title: 'Dashboards' },
          { href: DashboardDetailUri(dashboard.name), title: dashboard.name },
          {
            href: DashboardEmailReportsUri(dashboard.name),
            title: 'Email Reports',
          },
        ]}
        header={<CorePageTitle title={`Edit {report?.title}`} />}
      >
        {report && (
          <DashboardEmailReportForm
            report={report}
            onCancel={() =>
              history.push(DashboardEmailReportsUri(dashboard.name))
            }
            onSubmit={onSubmit}
          />
        )}
      </CorePage>
    );
  }
);

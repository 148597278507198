import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Menu, { MenuItem } from '@amzn/meridian/menu';
import MetricsTokens from '@amzn/meridian-tokens/base/icon/metrics';
import MailTokens from '@amzn/meridian-tokens/base/icon/mail';
import { useDashboard } from '@/features/Dashboards/contexts';
import {
  DashboardEmailReportsUri,
  DashboardMonthlyReportsUri,
} from '@/features/routes';

const tooltip = 'View the monthly report';

export const DashboardReportButton = (): JSX.Element => {
  const history = useHistory();
  const buttonRef = useRef();
  const { dashboard } = useDashboard();
  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
  return (
    <>
      <Button
        size="small"
        type="tertiary"
        ref={buttonRef}
        label={tooltip}
        onClick={() => setIsEditMenuOpen(true)}
      >
        <Icon tokens={MetricsTokens}>tooltip</Icon>
        Report
      </Button>
      <Menu
        anchorNode={buttonRef.current}
        open={isEditMenuOpen}
        position="bottom"
        onClose={() => setIsEditMenuOpen(false)}
      >
        <MenuItem
          onClick={() => history.push(DashboardEmailReportsUri(dashboard.name))}
        >
          <Row spacing="small">
            <Icon tokens={MailTokens} />
            <span>Email Reports</span>
          </Row>
        </MenuItem>
        <MenuItem
          onClick={() =>
            history.push(DashboardMonthlyReportsUri(dashboard.name))
          }
        >
          <Row spacing="small">
            <Icon tokens={MetricsTokens} />
            <span>Monthly Reports</span>
          </Row>
        </MenuItem>
      </Menu>
    </>
  );
};

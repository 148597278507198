import React from 'react';
import Column from '@amzn/meridian/column';
import Alert from '@amzn/meridian/alert';
import AddTokens from '@amzn/meridian-tokens/base/icon/plus';
import TrashTokens from '@amzn/meridian-tokens/base/icon/trash';
import InputGroup from '@amzn/meridian/input-group';
import Input from '@amzn/meridian/input';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';
import Icon from '@amzn/meridian/icon';
import Text from '@amzn/meridian/text';
import { isNullOrWhitespace, useForm } from '@amzn/dots-core-ui';
import { getUniqueId } from '@/features/Dashboards/helpers';
import { BaseTableWizardConfiguration } from '@/types';
import { DashboardWizardBaseControl } from './DashboardWizardBaseControl';
import { DashboardWizardFormFieldLabel } from './DashboardWizardFormFieldLabel';
import {
  baseTableColumnWizardParser,
  onWizardConfirmFactory,
  getWizardBaseFormConfiguration,
} from './helpers';
import { BaseTableColumn, DashboardWizardFormProps } from './types';

export const DashboardBaseTableWizardForm = ({
  wizard,
  onCancel,
  onSubmit,
}: DashboardWizardFormProps<BaseTableWizardConfiguration>): JSX.Element => {
  const { hasErrors, handlers, values, errors } = useForm({
    ...getWizardBaseFormConfiguration(wizard),
    columns: {
      initialValue: baseTableColumnWizardParser.toFormModel(wizard.col),
      validate: (value: BaseTableColumn[]) => {
        if (value.length === 0) {
          return 'At least one column must be defined';
        }
        if (
          value.some(
            (v) => isNullOrWhitespace(v.name) || isNullOrWhitespace(v.format)
          )
        ) {
          return 'Name and Format must be defined for all columns';
        }
        return '';
      },
    },
  });
  const onChangeColumn = (columnId: string) => ([name, format]: string[]) =>
    handlers.columns(
      values.columns.map((column: BaseTableColumn) => {
        if (column.id === columnId) {
          return { ...column, name, format };
        }
        return column;
      })
    );
  const onRemoveColumn = (columnId: string) =>
    handlers.columns(
      values.columns.filter(({ id }: BaseTableColumn) => id !== columnId)
    );
  const onAddColumn = () =>
    handlers.columns(
      values.columns.concat({ id: getUniqueId(), name: '', format: '' })
    );
  const onConfirm = () =>
    onSubmit({
      ...onWizardConfirmFactory(wizard, values),
      type: wizard.type,
      col: baseTableColumnWizardParser.toConfigurationModel(values.columns),
    });
  return (
    <Column>
      <DashboardWizardBaseControl
        wizard={wizard}
        values={values}
        handlers={handlers}
      />
      <DashboardWizardFormFieldLabel
        label="Table Columns"
        hint={
          <Column spacing="100">
            <Text>
              Define the name and format of each column. Column formats can
              reference KPI properties (ex. data.result.pass or translator.name)
              and define the template for each cell in that column.
            </Text>
            <Text> Arithmetic expressions are not supported</Text>
          </Column>
        }
      >
        <Column>
          {errors.columns.length !== 0 && (
            <Alert type="error" size="small">
              {errors.columns}
            </Alert>
          )}
          {values.columns.map(({ id, name, format }: BaseTableColumn) => (
            <Column key={id}>
              <Row widths={['fill', 'fit']}>
                <InputGroup
                  value={[name, format]}
                  onChange={onChangeColumn(id)}
                  direction="column"
                >
                  <Input label="Name" error={isNullOrWhitespace(name)} />
                  <Input label="Format" error={isNullOrWhitespace(format)} />
                </InputGroup>
                <Button
                  type="secondary"
                  onClick={() => onRemoveColumn(id)}
                  disabled={values.columns.length === 1}
                >
                  <Icon tokens={TrashTokens}>Remove this column</Icon>
                </Button>
              </Row>
              {(isNullOrWhitespace(name) || isNullOrWhitespace(format)) && (
                <Alert type="error" size="small">
                  Name and Format must be defined
                </Alert>
              )}
            </Column>
          ))}
        </Column>
      </DashboardWizardFormFieldLabel>
      <Button type="tertiary" onClick={onAddColumn}>
        <Icon tokens={AddTokens}>Add a new column</Icon>
      </Button>
      <Row alignmentHorizontal="right" alignmentVertical="center" widths="fit">
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={hasErrors} onClick={onConfirm}>
          Confirm
        </Button>
      </Row>
    </Column>
  );
};

import React, { useMemo } from 'react';
import Badge from '@amzn/meridian/badge';
import { useDashboardWizardTableCellAnnotations } from '@/features/Dashboards/hooks';
import { AnnotationContext } from '@/features/Dashboards/types';

export type DashboardAnnotationCountBadgeProps = {
  context: AnnotationContext;
};

export const DashboardAnnotationCountBadge = ({
  context,
}: DashboardAnnotationCountBadgeProps): JSX.Element => {
  const { annotations } = useDashboardWizardTableCellAnnotations(context);
  const annotationCount = useMemo(
    () => annotations.filter((a) => !a.resolved).length,
    [annotations]
  );
  return annotationCount > 0 ? (
    <Badge value={annotationCount} type="theme" />
  ) : (
    <></>
  );
};

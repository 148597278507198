/* eslint-disable no-param-reassign */
import { keys } from '@amzn/dots-core-ui';
import { parseExpression } from '@/features/Dashboards/aggregation';
import {
  calculateExpressions,
  extractUniqueKpiValues,
} from '@/features/Dashboards/helpers';
import {
  AnnotationContext,
  WizardState,
  WizardTableCell,
  WizardTableData,
  WizardTableRow,
} from '@/features/Dashboards/types';
import { WizardSelection } from '@/features/Dashboards/contexts';
import { collator, randomColor } from '@/helpers';
import {
  BaseData,
  KpiProperty,
  PivotTableCellColorConfiguration,
  PivotTableWizardConfiguration,
} from '@/types';
import { colorRuleRelationships } from './constants';

export interface PivotTableCell extends WizardTableCell {
  annotationContext?: AnnotationContext;
  calculatedData?: Record<string, unknown>;
  selectionContext: WizardSelection;
}

export type PivotTableChartData = {
  xAxisDataKey: string;
  yAxisLabel?: string;
  yAxisUnit?: string;
  lines: { dataKey: string; color: string }[];
  data: Record<string, unknown>[];
};

const xAxisDataKey = 'xAxis';

const transformObjectToValueString = (obj: Record<string, unknown>): string =>
  Object.values(obj).join(' ');

const getCellColor = (
  rules: PivotTableCellColorConfiguration[],
  cellData: Record<string, unknown>
): string | undefined => {
  const numericCellData = keys(cellData).reduce((result, key) => {
    const value = cellData[key];
    if (!Number.isNaN(Number(value))) {
      result[key] = Number(value);
    }
    return result;
  }, {} as Record<string, number>);
  const matchedRules = rules.filter((rule) => {
    if (
      !(rule.data in numericCellData) ||
      !(rule.relationship in colorRuleRelationships)
    ) {
      return false;
    }
    const ruleThreshold = Number.isNaN(Number(rule.value))
      ? rule.value
      : Number(rule.value);
    const actualValue = numericCellData[rule.data];
    if (
      (rule.relationship === 'equal' && ruleThreshold === actualValue) ||
      (rule.relationship === 'greater than' && actualValue >= ruleThreshold) ||
      (rule.relationship === 'less than' && actualValue < ruleThreshold)
    ) {
      return true;
    }
    if (rule.relationship === 'between' && typeof ruleThreshold === 'string') {
      const values = ruleThreshold.split(',').map((item) => item.trim());
      if (values.length !== 2) {
        return false;
      }
      const leftEnd = Number.isNaN(Number(values[0]))
        ? values[0]
        : Number(values[0]);
      const rightEnd = Number.isNaN(Number(values[1]))
        ? values[1]
        : Number(values[1]);

      if (rightEnd > actualValue && leftEnd <= actualValue) {
        return true;
      }
    }
    if (rule.relationship === 'else') {
      return true;
    }
    return false;
  });
  if (matchedRules.length > 0) {
    return matchedRules[0].color;
  }
  return undefined;
};

/**
 * Create a selector function that produces a single page of data to be rendered
 * by a base table wizard
 */
export const createWizardData = ({
  configuration,
  data: { propertyIndexMap, baseData },
}: WizardState<PivotTableWizardConfiguration>): WizardTableData<
  PivotTableCell
> => {
  const {
    col: columnProperties,
    row: rowProperties,
    cell: {
      format: cellFormat,
      data: userDefinedVariables,
      color: cellColorRules,
    },
  } = configuration;
  const userDefinedExpressions = userDefinedVariables.map(
    ({ name, expression }) => ({
      variableName: name,
      expression: parseExpression(expression),
    })
  );
  const [baseDataHeader, ...kpis] = baseData;
  const uniqueColumnHeaders: Record<string, Record<KpiProperty, string>> = {};
  const uniqueRowHeaders: Record<string, Record<KpiProperty, string>> = {};
  const rowColumnIndexMap: Record<string, Record<string, number[]>> = {};
  kpis.forEach((kpi, i) => {
    const columnValues = columnProperties.reduce((values, { data: prop }) => {
      values[prop] = kpi[propertyIndexMap[prop]].toString();
      return values;
    }, {} as Record<KpiProperty, string>);
    const columnKey = Object.values(columnValues).join('|');
    uniqueColumnHeaders[columnKey] = columnValues;

    const rowValues = rowProperties.reduce((values, { data: prop }) => {
      values[prop] = kpi[propertyIndexMap[prop]].toString();
      return values;
    }, {} as Record<KpiProperty, string>);
    const rowKey = Object.values(rowValues).join('|');
    uniqueRowHeaders[rowKey] = rowValues;

    if (!(rowKey in rowColumnIndexMap)) {
      rowColumnIndexMap[rowKey] = { [columnKey]: [i] };
    } else if (!(columnKey in rowColumnIndexMap[rowKey])) {
      rowColumnIndexMap[rowKey][columnKey] = [i];
    } else {
      rowColumnIndexMap[rowKey][columnKey].push(i);
    }
  });
  const columnKeys = keys(uniqueColumnHeaders).sort((a, b) =>
    collator.compare(b, a)
  );
  const rowKeys = keys(uniqueRowHeaders);
  const rows = rowKeys.map(
    (rowKey): WizardTableRow<PivotTableCell> => {
      const locationBuilder: Partial<Record<KpiProperty, string>> = {};
      const rowHeaders = keys(uniqueRowHeaders[rowKey]).map(
        (prop): PivotTableCell => {
          const content = uniqueRowHeaders[rowKey][prop];
          locationBuilder[prop] = content;
          return {
            key: prop,
            content,
            annotationContext: {
              type: 'wizard_annotation',
              wizardId: configuration.id,
              location: JSON.stringify({ rowName: locationBuilder }),
              kpiIds: [],
              testrunIds: [],
            },
            selectionContext: [locationBuilder],
          };
        }
      );
      const dataCells = columnKeys.map(
        (columnKey): PivotTableCell => {
          const location = JSON.stringify({
            colName: uniqueColumnHeaders[columnKey],
            rowName: uniqueRowHeaders[rowKey],
          });
          if (
            !(rowKey in rowColumnIndexMap) ||
            !(columnKey in rowColumnIndexMap[rowKey]) ||
            rowColumnIndexMap[rowKey][columnKey].length === 0
          ) {
            return {
              key: columnKey,
              content: '',
              annotationContext: {
                type: 'wizard_annotation',
                wizardId: configuration.id,
                location,
                testrunIds: [],
                kpiIds: [],
              },
              selectionContext: [
                {
                  ...uniqueColumnHeaders[columnKey],
                  ...uniqueRowHeaders[rowKey],
                },
              ],
            };
          }
          const kpiIndices = rowColumnIndexMap[rowKey][columnKey];
          const cellBaseData: BaseData = [
            baseDataHeader,
            ...kpiIndices.map((index) => kpis[index]),
          ];
          const uniqueValues = extractUniqueKpiValues(
            ['unique_id', 'data.testrun_id'],
            {
              propertyIndexMap,
              baseData: cellBaseData,
            }
          );
          const { content, calculatedData } = calculateExpressions(
            cellFormat,
            userDefinedExpressions,
            cellBaseData
          );
          const annotationContext: AnnotationContext = {
            type: 'wizard_annotation',
            wizardId: configuration.id,
            location,
            testrunIds: uniqueValues['data.testrun_id'],
            kpiIds: uniqueValues.unique_id,
          };
          const backgroundColor = cellColorRules
            ? getCellColor(cellColorRules, calculatedData)
            : undefined;
          return {
            key: columnKey,
            calculatedData,
            content,
            backgroundColor,
            annotationContext,
            selectionContext: [
              {
                ...uniqueColumnHeaders[columnKey],
                ...uniqueRowHeaders[rowKey],
              },
            ],
          };
        }
      );
      return {
        key: rowHeaders.map((r) => r.content).join(','),
        cells: [...rowHeaders, ...dataCells],
      };
    }
  );
  const header: PivotTableCell[] = [
    ...rowProperties.map(({ name }) => ({
      key: name,
      content: name,
      selectionContext: [],
    })),
    ...columnKeys.map((key) => ({
      key,
      content: key,
      selectionContext: [{ ...uniqueColumnHeaders[key] }],
    })),
  ];
  return {
    header,
    rows,
  };
};

export const createPivotTableChartData = (
  configuration: PivotTableWizardConfiguration,
  pivotTableWizardData: WizardTableData<
    PivotTableCell,
    WizardTableRow<PivotTableCell>
  >
): PivotTableChartData | undefined => {
  if (!configuration.chart || !configuration?.chart.data) {
    return undefined;
  }
  const {
    col: columnConfiguration,
    row: rowConfiguration,
    chart: chartConfiguration,
  } = configuration;
  const headerColumns = rowConfiguration.length;
  const { header, rows } = pivotTableWizardData;
  const lines = [];
  const data = [];
  // I'm sorry people of the future, I wish I could leave you a more helpful
  // comment...
  // Hopefully you will have more patience to better understand the indexing
  // here. I am 100% sure there is an indexing error somewhere in this mess.
  // I copied, pasted and adapted for the latest pivot table data model when
  // porting to typescript... so this is good enough for now
  if (chartConfiguration?.xAxis === 'Table Row') {
    for (
      let columnIndex = headerColumns;
      columnIndex < header.length;
      columnIndex += 1
    ) {
      const headerCell = header[columnIndex];
      lines.push({
        dataKey: transformObjectToValueString(headerCell.selectionContext[0]),
        color: lines.length === 0 ? '#0AABC4' : randomColor(),
      });
    }
    for (let rowIndex = 0; rowIndex < rows.length; rowIndex += 1) {
      const headerCell = rows[rowIndex].cells[headerColumns - 1];
      const curPoint: Record<string, unknown> = {
        xAxis: rowConfiguration
          .map(({ data: prop }) =>
            headerCell.selectionContext.length
              ? headerCell.selectionContext[0][prop]
              : prop
          )
          .join(' '),
      };
      for (
        let columnIndex = headerColumns;
        columnIndex < rows[rowIndex].cells.length;
        columnIndex += 1
      ) {
        const cell = rows[rowIndex].cells[columnIndex];
        if (cell.calculatedData && chartConfiguration.data) {
          curPoint[lines[columnIndex - headerColumns].dataKey] =
            cell.calculatedData[chartConfiguration.data];
        }
      }
      data.push(curPoint);
    }
  } else {
    for (
      let columnIndex = headerColumns - 1;
      columnIndex < header.length;
      columnIndex += 1
    ) {
      const headerCell = header[columnIndex];
      const curPoint: Record<string, unknown> = {
        xAxis: columnConfiguration
          .map(({ data: prop }) =>
            headerCell.selectionContext.length
              ? headerCell.selectionContext[0][prop]
              : prop
          )
          .join(' '),
      };
      for (let rowIndex = 0; rowIndex < rows.length; rowIndex += 1) {
        const cell = rows[rowIndex].cells[columnIndex];
        if (columnIndex === headerColumns - 1) {
          lines.push({
            dataKey: rowConfiguration
              .map(({ data: prop }) =>
                cell.selectionContext.length
                  ? cell.selectionContext[0][prop]
                  : prop
              )
              .join(' '),
            color: lines.length === 0 ? '#0AABC4' : randomColor(),
          });
        } else if (cell.calculatedData && chartConfiguration.data) {
          curPoint[
            rowConfiguration
              .map(({ data: prop }) =>
                cell.selectionContext.length
                  ? cell.selectionContext[0][prop]
                  : prop
              )
              .join(' ')
          ] = cell.calculatedData[chartConfiguration.data];
        }
      }
      if (columnIndex > headerColumns - 1) {
        data.push(curPoint);
      }
    }
  }
  return {
    xAxisDataKey,
    yAxisLabel: chartConfiguration.yAxis?.label,
    yAxisUnit: chartConfiguration.yAxis?.unit,
    lines,
    data,
  };
};

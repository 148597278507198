/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { isNullOrWhitespace, ValidationProps } from '@amzn/dots-core-ui';
import { useHttp } from '../client/HttpContext';
import { CoreAsyncSelect, CoreAsyncSelectProps } from './CoreAsyncSelect';

const get = (item: string): string => item;

export type TestrunSelectProps = Pick<
  ValidationProps<CoreAsyncSelectProps<string>>,
  | 'value'
  | 'onChange'
  | 'errorMessage'
  | 'validationState'
  | 'disabled'
  | 'constraintText'
> & { source: string };

export const TestrunSelect = ({
  source,
  ...props
}: TestrunSelectProps): JSX.Element => {
  const { http } = useHttp();
  const getTestruns = useCallback(
    async (query: string): Promise<string[]> => {
      if (isNullOrWhitespace(query)) {
        return [];
      }
      const data = await http.get('testruns', {
        prefix: query,
        source,
      });
      if (data.status.code === 200) {
        return data.data.map(({ id }: { id: string }) => id);
      }
      return [];
    },
    [http, source]
  );
  return (
    <CoreAsyncSelect
      {...props}
      label="Testrun ID"
      placeholder="Search for a testrun..."
      getItems={getTestruns}
      getItemLabel={get}
      getItemValue={get}
    />
  );
};

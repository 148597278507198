import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { HttpClient, useHttp } from '@/client';
import { DashboardReportRequest } from '@/types';
import { isNullOrWhitespace } from '@amzn/dots-core-ui';

const createDashboardReportRequest = async (
  http: HttpClient,
  dashboardId: string,
  dashboardName: string,
  startTime: string,
  endTime: string,
  title: string
): Promise<DashboardReportRequest> => {
  const params = {
    dashboard_id: dashboardId,
    dashboard_name: dashboardName,
    start_time: startTime,
    end_time: endTime,
    title: isNullOrWhitespace(title)
      ? `Report for data between ${startTime} and ${endTime}`
      : title,
  };
  const { data, status } = await http.post(`report/request`, params);
  if (status.code === 200) {
    return data;
  }
  throw new Error(data.message);
};

export const useCreateDashboardReportRequest = (): UseMutationResult<
  DashboardReportRequest,
  Error,
  DashboardReportRequest
> => {
  const { http } = useHttp();
  const queryClient = useQueryClient();

  return useMutation(
    (reportRequest: DashboardReportRequest) =>
      createDashboardReportRequest(
        http,
        reportRequest.dashboard_id,
        reportRequest.dashboard_name,
        reportRequest.start_date,
        reportRequest.end_date,
        reportRequest.title
      ),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['dashboardReports']);
      },
    }
  );
};

import React from 'react';
import { useQuery } from 'react-query';
import Row from '@amzn/meridian/row';
import { useParams } from 'react-router-dom';
import { CoreTabGroupSwitch, CoreTabRoute } from '@amzn/dots-core-ui';
import { CorePage, CorePageTitle } from '@/components';
import { useHttp } from '@/client';
import { TranslatorsUri } from '@/features/routes';
import {
  TranslatorUploadButton,
  TranslatorCards,
  TranslatorExceptions,
  TranslatorTester,
} from './components';
import { translatorKeys } from './queries';
import { translatorClient } from './client';
import { Translator } from './types';

export const TranslatorDetailPage = (): JSX.Element => {
  const { http } = useHttp();
  const params = useParams<{ name: string }>();
  const { data: translators = [], isLoading, isError, error } = useQuery<
    Translator[],
    Error
  >({
    queryKey: translatorKeys.all(params),
    queryFn: () => translatorClient.getTranslators(http, params),
  });
  translators.sort((a, b) => b.iversion - a.iversion);
  const versions = translators.map(({ version }) => version);
  return (
    <CorePage
      title={params.name}
      breadcrumbs={[{ href: TranslatorsUri, title: 'Translators' }]}
      isLoading={isLoading}
      isError={isError}
      error={error}
      header={
        <Row widths="fill">
          <CorePageTitle title={params.name} />
          <Row alignmentHorizontal="right">
            <TranslatorUploadButton />
          </Row>
        </Row>
      }
    >
      <CoreTabGroupSwitch>
        <CoreTabRoute label="Versions" path="versions">
          <TranslatorCards translators={translators} />
        </CoreTabRoute>
        <CoreTabRoute label="Test" path="test">
          <TranslatorTester
            name={params.name}
            source="KATS"
            versions={versions}
          />
        </CoreTabRoute>
        <CoreTabRoute label="Exceptions" path="exceptions">
          <TranslatorExceptions
            name={params.name}
            source="KATS"
            versions={versions}
          />
        </CoreTabRoute>
      </CoreTabGroupSwitch>
    </CorePage>
  );
};

import React, { useMemo } from 'react';
import ControlGroup from '@amzn/meridian/control-group';
import Column from '@amzn/meridian/column';
import Alert from '@amzn/meridian/alert';
import RadioButton from '@amzn/meridian/radio-button';
import {
  CoreFilterableSelect,
  CoreFormFooter,
  isNullOrWhitespace,
  useForm,
} from '@amzn/dots-core-ui';
import { TestrunSelect } from '@/components';
import {
  translatorDestinations,
  TranslatorTestRequest,
} from '@/features/Translators/types';

export type TranslatorTestFormProps = {
  name: string;
  versions: string[];
  source: string;
  onSubmit: (request: TranslatorTestRequest) => Promise<void> | void;
};

export const TranslatorTestForm = ({
  name,
  versions,
  source,
  onSubmit,
}: TranslatorTestFormProps): JSX.Element => {
  const {
    values,
    errors,
    handlers,
    submitState,
    handleSubmit,
    hasErrors,
    isSubmitting,
  } = useForm({
    ids: {
      initialValue: [],
      validate: (value: string[]) =>
        value.length === 0 ? 'Select at least one Testrun' : '',
    },
    version: {
      initialValue: versions[0],
      validate: (value: string) =>
        isNullOrWhitespace(value) ? 'Version is required' : '',
    },
    destination: {
      initialValue: translatorDestinations[0],
    },
  });
  const isMaxTestrunCount = useMemo(() => values.ids.length === 10, [
    values.ids.length,
  ]);
  return (
    <form
      onSubmit={handleSubmit((vs) => {
        return onSubmit({ ...vs, name, source });
      })}
    >
      <Column
        spacing="500"
        spacingInset="300"
        maxWidth="100%"
        alignmentVertical="top"
        type="fill"
      >
        {submitState.status === 'error' && (
          <Alert type="error" title="Error!" size="large">
            {submitState.error?.message}
          </Alert>
        )}
        <CoreFilterableSelect
          label="Version"
          items={versions}
          value={values.version}
          onChange={handlers.version}
          errorMessage={errors.version}
        />
        <TestrunSelect
          source={source}
          value={values.ids}
          onChange={handlers.ids}
          errorMessage={errors.ids}
          disabled={isMaxTestrunCount}
          constraintText={
            isMaxTestrunCount
              ? '10 is the maximum number of Testruns that can be tested at one time'
              : ''
          }
        />
        <ControlGroup
          label="Destination"
          name="destination"
          onChange={handlers.destination}
          value={values.destination}
        >
          {translatorDestinations.map((destination) => (
            <RadioButton key={destination} value={destination}>
              {destination}
            </RadioButton>
          ))}
        </ControlGroup>
        <CoreFormFooter
          isConfirmDisabled={hasErrors || isSubmitting}
          isLoading={isSubmitting}
          onCancelClick={() => handlers.ids([])}
        />
      </Column>
    </form>
  );
};

import React from 'react';
import Column from '@amzn/meridian/column';
import { TableRow } from '@amzn/meridian/table';
import {
  useDashboardTableState,
  useDashboardWizardTableData,
} from '@/features/Dashboards/hooks';
import { PivotTableWizardConfiguration } from '@/types';
import { DashboardSelectableTableCell } from '../../DashboardSelectableTableCell';
import { DashboardTableActionBar } from '../DashboardTableActionBar';
import { DashboardWizardTable } from '../DashboardWizardTable';
import { DashboardPivotTableCellContent } from './DashboardPivotTableCellContent';
import { createWizardData } from './data';
import { DashboardPivotTableChart } from './DashboardPivotTableChart';

export type DashboardPivotTableWizardProps = {
  configuration: PivotTableWizardConfiguration;
};

export const DashboardPivotTableWizard = ({
  configuration,
}: DashboardPivotTableWizardProps): JSX.Element => {
  const [tableState, tableStateDispatch] = useDashboardTableState();
  const { pagination, sortColumn, sortDirection } = tableState;
  const wizardData = useDashboardWizardTableData(
    configuration,
    tableState,
    createWizardData
  );
  return (
    <Column>
      {(!configuration.chart || configuration.chart.show !== 'Chart Only') && (
        <DashboardWizardTable
          spacing="small"
          showDividers
          headerRows={1}
          sortColumn={sortColumn.toString()}
          sortDirection={sortDirection}
          onSort={(payload) =>
            tableStateDispatch({
              type: 'update-sort-state',
              payload: {
                sortColumn: Number(payload.sortColumn),
                sortDirection: payload.sortDirection,
              },
            })
          }
        >
          <DashboardTableActionBar
            dataLength={wizardData.rows.length}
            tableState={tableState}
            tableStateDispatch={tableStateDispatch}
            wizardData={wizardData}
          />
          {wizardData.header.length > 0 && (
            <TableRow>
              {wizardData.header.map((cell, index) => (
                <DashboardSelectableTableCell
                  key={cell.key}
                  backgroundColor={cell.backgroundColor}
                  selectionContext={cell.selectionContext}
                  sortColumn={index.toString()}
                  wizardId={configuration.id}
                >
                  <DashboardPivotTableCellContent cell={cell} />
                </DashboardSelectableTableCell>
              ))}
            </TableRow>
          )}
          {wizardData.rows
            .slice(pagination.startIndex, pagination.endIndex)
            .map((row) => (
              <TableRow key={row.key}>
                {row.cells.map((cell) => (
                  <DashboardSelectableTableCell
                    key={cell.key}
                    backgroundColor={cell.backgroundColor}
                    selectionContext={cell.selectionContext}
                    wizardId={configuration.id}
                  >
                    <DashboardPivotTableCellContent cell={cell} />
                  </DashboardSelectableTableCell>
                ))}
              </TableRow>
            ))}
        </DashboardWizardTable>
      )}
      {configuration.chart && configuration.chart.show !== 'Table Only' && (
        <DashboardPivotTableChart
          configuration={configuration}
          wizardData={wizardData}
        />
      )}
    </Column>
  );
};

import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Menu, { MenuItem } from '@amzn/meridian/menu';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import editTokens from '@amzn/meridian-tokens/base/icon/edit';
import gridTokens from '@amzn/meridian-tokens/base/icon/grid';
import filterTokens from '@amzn/meridian-tokens/base/icon/filter';
import {
  DashboardEditFiltersUri,
  DashboardEditLayoutsUri,
} from '@/features/routes';
import { DashboardButtonProps } from './types';

export const DashboardEditButton = ({
  name,
  type = 'tertiary',
}: Omit<DashboardButtonProps, 'id'>): JSX.Element => {
  const buttonRef = useRef();
  const history = useHistory();
  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
  return (
    <>
      <Button
        label="Open dashboard edit menu"
        size="small"
        type={type}
        ref={buttonRef}
        onClick={() => setIsEditMenuOpen(true)}
      >
        <Icon tokens={editTokens}>Open dashboard edit menu</Icon>
        {type !== 'icon' && <span>Edit</span>}
      </Button>
      <Menu
        anchorNode={buttonRef.current}
        open={isEditMenuOpen}
        position="bottom"
        onClose={() => setIsEditMenuOpen(false)}
      >
        <MenuItem
          aria-label="Edit dashboard filters"
          onClick={() => history.push(DashboardEditFiltersUri(name))}
        >
          <Row spacing="small">
            <Icon tokens={filterTokens}>Edit dashboard filters</Icon>
            <span>Filters</span>
          </Row>
        </MenuItem>
        <MenuItem
          aria-label="Edit dashboard layouts"
          onClick={() => history.push(DashboardEditLayoutsUri(name))}
        >
          <Row spacing="small">
            <Icon tokens={gridTokens}>Edit dashboard layouts</Icon>
            <span>Layouts</span>
          </Row>
        </MenuItem>
      </Menu>
    </>
  );
};

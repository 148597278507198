import React, { useMemo } from 'react';
import Column from '@amzn/meridian/column';
import Alert from '@amzn/meridian/alert';
import AddTokens from '@amzn/meridian-tokens/base/icon/plus';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import { CoreFormFooter, isNullOrWhitespace } from '@amzn/dots-core-ui';
import { Dashboard } from '@/types';
import { DashboardFormSection } from '../DashboardFormSection';
import { DashboardLayoutControl } from './DashboardLayoutControl';
import {
  DashboardLayoutsFormStateProvider,
  useDashboardLayoutsFormState,
} from './DashboardLayoutsFormStateContext';
import { DashboardLayoutSectionControl } from './DashboardLayoutSectionControl';
import { DashboardLayoutSectionSheet } from './DashboardLayoutSectionSheet';
import { DashboardLayoutSectionWizardSheet } from './DashboardLayoutSectionWizardSheet';

export type DashboardLayoutsFormProps = {
  dashboard?: Dashboard;
  onSubmit: (dashboard: Partial<Dashboard>) => Promise<void> | void;
  onCancel: () => void;
};

const DashboardLayoutsFormInner = ({
  onCancel,
}: Pick<DashboardLayoutsFormProps, 'onCancel'>): JSX.Element => {
  const {
    dispatch,
    handleSubmit,
    state: { editingLayoutIndex, layouts, submitState },
  } = useDashboardLayoutsFormState();
  const hasErrors = useMemo(
    () =>
      layouts.some(
        (layout) =>
          isNullOrWhitespace(layout.name) ||
          !layout.sections ||
          !layout.sections.length
      ),
    [layouts]
  );
  const isSubmitting = useMemo(() => submitState.status === 'submitting', [
    submitState.status,
  ]);
  const editingLayout =
    editingLayoutIndex !== undefined ? layouts[editingLayoutIndex] : undefined;
  const onAddSection = () => {
    if (editingLayout) {
      dispatch({
        type: 'section-add',
        payload: { layoutId: editingLayout.id },
      });
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Column spacing="500" maxWidth={960} alignmentVertical="top">
        {submitState.status === 'error' && (
          <Alert type="error" title="Error!" size="large">
            {submitState.error?.message}
          </Alert>
        )}
        <DashboardLayoutSectionSheet />
        <DashboardLayoutSectionWizardSheet />
        <DashboardFormSection title="Tabs">
          {layouts.map((layout, i) => (
            <DashboardLayoutControl
              key={layout.id}
              layoutIndex={i}
              layout={layout}
            />
          ))}
          <Button
            onClick={() => dispatch({ type: 'layout-add' })}
            type="tertiary"
          >
            <Icon tokens={AddTokens}>Add a new tab</Icon>
          </Button>
        </DashboardFormSection>
        <DashboardFormSection title="Tab Preview">
          {!editingLayout && (
            <Alert type="informational" size="large">
              Select a tab to preview the layout
            </Alert>
          )}
          {editingLayout && editingLayoutIndex !== undefined && (
            <>
              {editingLayout.sections.length === 0 && (
                <Alert type="warning" size="medium">
                  This tab does not have any sections...
                  <Button type="link" size="small" onClick={onAddSection}>
                    Add one!
                  </Button>
                </Alert>
              )}
              {editingLayout.sections.map((section, i) => (
                <DashboardLayoutSectionControl
                  key={section.id}
                  layoutIndex={editingLayoutIndex}
                  layoutId={editingLayout.id}
                  sectionIndex={i}
                  section={section}
                />
              ))}
              <Button onClick={onAddSection} type="tertiary">
                <Icon tokens={AddTokens}>Add a new section</Icon>
              </Button>
            </>
          )}
        </DashboardFormSection>
        <CoreFormFooter
          isConfirmDisabled={hasErrors || isSubmitting}
          isLoading={isSubmitting}
          onCancelClick={onCancel}
        />
      </Column>
    </form>
  );
};

export const DashboardLayoutsForm = ({
  dashboard,
  onSubmit,
  onCancel,
}: DashboardLayoutsFormProps): JSX.Element => (
  <DashboardLayoutsFormStateProvider dashboard={dashboard} onSubmit={onSubmit}>
    <DashboardLayoutsFormInner onCancel={onCancel} />
  </DashboardLayoutsFormStateProvider>
);

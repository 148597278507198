import React, { useEffect } from 'react';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import RadioButton from '@amzn/meridian/radio-button';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import closeTokens from '@amzn/meridian-tokens/base/icon/close';
import { useForm, isNullOrWhitespace } from '@amzn/dots-core-ui';
import {
  TestSourcePropertySelect,
  TestSourcePropertyValueSelect,
} from '@/components';
import { CustomTab } from './types';
import { echoValue } from './helpers';

export type DashboardCustomTabControlProps = {
  tab?: Partial<CustomTab>;
  testSource: string;
  onChange: (value: CustomTab) => void;
  onRemove: () => void;
};

export const DashboardCustomTabControl = ({
  tab,
  testSource,
  onChange,
  onRemove,
}: DashboardCustomTabControlProps): JSX.Element => {
  const { values, handlers, errors, states } = useForm({
    id: {
      initialValue: tab?.id,
    },
    source: {
      initialValue: tab?.source ?? testSource,
    },
    mode: {
      initialValue: tab?.mode ?? 'auto',
      validate: (value: string) =>
        isNullOrWhitespace(value) ? 'Tab mode is required' : '',
    },
    propertyName: {
      initialValue: tab?.propertyName ?? '',
      validate: (value: string) =>
        isNullOrWhitespace(value) ? 'Property name is required' : '',
    },
    values: {
      initialValue: tab?.values ?? [],
      validate: (value: string[]) =>
        value.length === 0 ? 'A property value is required' : '',
    },
  });
  useEffect(() => {
    onChange(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  return (
    <Row
      widths={['grid-11', 'grid-1']}
      alignmentVertical="center"
      type="outline"
      spacingInset="400"
    >
      <Column>
        <Row widths={['grid-8', 'grid-4']} alignmentVertical="center">
          <TestSourcePropertySelect
            label="Property Name"
            testSource={testSource}
            value={values.propertyName}
            onChange={handlers.propertyName}
            errorMessage={errors.propertyName}
            validationState={states.propertyName}
          />
          <Row alignmentVertical="center">
            <RadioButton
              checked={values.mode === 'auto'}
              onChange={handlers.mode}
              value="auto"
            >
              Auto
            </RadioButton>
            <RadioButton
              checked={values.mode === 'selective'}
              onChange={handlers.mode}
              value="selective"
            >
              Selective
            </RadioButton>
          </Row>
        </Row>
        {values.mode === 'selective' && (
          <TestSourcePropertyValueSelect
            label="Propety Value(s)"
            createItem={echoValue}
            testSource={testSource}
            propertyName={values.propertyName}
            value={values.values}
            onChange={handlers.values}
            errorMessage={errors.values}
            validationState={states.values}
          />
        )}
      </Column>
      <Button
        type="icon"
        size="small"
        onClick={onRemove}
        disabled={onRemove === undefined}
      >
        <Icon tokens={closeTokens}>delete</Icon>
      </Button>
    </Row>
  );
};

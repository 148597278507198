import React from 'react';
import Popover from '@amzn/meridian/popover';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import chatTokens from '@amzn/meridian-tokens/base/icon/chat';
import { useOnHover } from '@/hooks';
import { AnnotationContext } from '@/features/Dashboards/types';
import { useDashboardAnnotationViewContext } from './DashboardAnnotationViewContext';

export type DashboardAnnotationPopoverProps = {
  anchorNode: React.RefObject<HTMLElement>;
  context: AnnotationContext;
};

export const DashboardAnnotationPopover = ({
  anchorNode,
  context,
}: DashboardAnnotationPopoverProps): JSX.Element => {
  const { openView } = useDashboardAnnotationViewContext();
  const isAnchorHoverActive = useOnHover(anchorNode);
  return (
    <Popover
      anchorNode={anchorNode.current ?? undefined}
      open={isAnchorHoverActive}
      onClose={() => {
        // noop;
      }}
      position="left"
      spacingInset="none"
    >
      <Button type="icon" onClick={() => openView(context)}>
        <Icon tokens={chatTokens}>comment</Icon>
      </Button>
    </Popover>
  );
};

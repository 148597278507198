import React from 'react';
import { BrowserRouter, Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import '@amzn/meridian-tokens/base/font/amazon-ember.css';
import {
  CoreAuth,
  mountReactComponent,
  unmountReactComponent,
  RemoteLifecyleEventArgs,
  RemoteApplicationAdapter,
} from '@amzn/dots-core-ui';
import { HttpProvider } from '@/client';
import { App, AppNavigation, AppRouter } from '@/features';
import { environment } from './environments';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // react query by default likes to keep thing fresh, our requests can be
      // pretty large so lets keep the cache around. These settings could also
      // be set per query, but for now we will set globals.
      // do not trigger a refetch when the window re-focuses
      refetchOnWindowFocus: false,
      // mark data as stale every 5 minutes
      staleTime: 300000,
      // don't retry failed requests, it probably won't help
      retry: 0,
    },
  },
});

declare global {
  interface Window {
    Boomerang: RemoteApplicationAdapter;
  }
}

window.Boomerang = {
  mount: ({ containerId }: RemoteLifecyleEventArgs): void =>
    mountReactComponent(
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <CoreAuth userManagerSettings={environment.userManagerSettings}>
            <HttpProvider>
              <App />
            </HttpProvider>
          </CoreAuth>
        </BrowserRouter>
      </QueryClientProvider>,
      containerId
    ),
  mountRouter: ({
    containerId,
    history,
    createToken,
  }: RemoteLifecyleEventArgs): void =>
    mountReactComponent(
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <HttpProvider createToken={createToken}>
            <AppRouter isRootApp={false} />
          </HttpProvider>
        </Router>
      </QueryClientProvider>,
      containerId
    ),
  mountNavigation: ({
    containerId,
    history,
    isMenuOpen,
    createToken,
  }: RemoteLifecyleEventArgs): void =>
    mountReactComponent(
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <HttpProvider createToken={createToken}>
            <AppNavigation isMenuOpen={isMenuOpen} />
          </HttpProvider>
        </Router>
      </QueryClientProvider>,
      containerId
    ),
  unmountRouter: unmountReactComponent,
  unmountNavigation: unmountReactComponent,
};

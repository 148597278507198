/* eslint-disable @typescript-eslint/no-explicit-any */
import Bottleneck from 'bottleneck';
import { TokenCreator } from '@amzn/dots-core-ui';
import { dictToURI } from '@/helpers';
import { environment } from '@/environments';
import { HttpMethod, HttpResponse, HttpClient } from './types';

/**
 * HttpClient callers do not distinguish between the boomerang middleware API
 * and the boomerang Jira API so we will handle that here. Not sure why the APIs
 * are behind two different URLs...
 */
const getEndpoint = (resource: string) => {
  const apexResource = resource.split('/', 1)[0];
  if (apexResource === 'jira') {
    return environment.boomerangJiraApiUrl;
  }
  return environment.boomerangApiUrl;
};

// bottleneck restricts the number of concurrent requests at any given time
// https://github.com/SGrondin/bottleneck#docs
// maxConcurrent: How many jobs can be executing at the same time
// minTime (ms): How long to wait after launching a job before launching
// another one in milliseconds
const limiter = new Bottleneck({ maxConcurrent: 10, minTime: 100 });

const createHttpMethod = (
  createToken: TokenCreator,
  method: HttpMethod
) => async <T = any>(
  resource: string,
  params?: Record<string, any>,
  contentType = 'application/json'
): Promise<HttpResponse<T>> => {
  const options: any = {
    method,
    headers: {
      'Content-Type': contentType,
    },
  };
  if (createToken) {
    const token = await createToken();
    options.headers.Authorization = `Bearer ${token}`;
  }
  const endpoint = getEndpoint(resource);
  let url = `${endpoint}/${resource}`;
  if (method === 'GET' && params) {
    const encodedParams =
      typeof params === 'string' ? params : dictToURI(params);
    url = `${endpoint}/${resource}?${encodedParams}`;
  } else {
    const requestBody =
      params instanceof Object && contentType === 'application/json'
        ? JSON.stringify(params)
        : params;
    options.body = requestBody;
  }

  const result = await limiter.schedule(() => fetch(url, options));
  const status = { code: result.status, text: result.statusText };
  const data = await result.json();
  return { status, data };
};

export const createHttpClient = (createToken: TokenCreator): HttpClient => {
  return {
    get: createHttpMethod(createToken, 'GET'),
    post: createHttpMethod(createToken, 'POST'),
    put: createHttpMethod(createToken, 'PUT'),
    patch: createHttpMethod(createToken, 'PATCH'),
    delete: createHttpMethod(createToken, 'DELETE'),
  };
};

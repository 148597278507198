import React, { useCallback } from 'react';
import Theme from '@amzn/meridian/theme';
import Text from '@amzn/meridian/text';
import Icon from '@amzn/meridian/icon';
import trashIconTokens from '@amzn/meridian-tokens/base/icon/trash';
import orangeLightTokens from '@amzn/meridian-tokens/theme/orange-light';
import { CoreAsyncButton, useToaster } from '@amzn/dots-core-ui';
import { DashboardEmailReportConfiguration } from '@/types';
import { useDashboardEmailReportDelete } from '@/hooks';

export type DashboardEmailReportDeleteButtonProps = {
  report: DashboardEmailReportConfiguration;
};

export const DashboardEmailReportDeleteButton = ({
  report,
}: DashboardEmailReportDeleteButtonProps): JSX.Element => {
  const { openToast } = useToaster();
  const { mutateAsync } = useDashboardEmailReportDelete({
    reportConfiguration: report,
  });
  const onClick = useCallback(async () => {
    await mutateAsync();
    openToast({
      title: 'Success!',
      message: `${report.title} has been removed.`,
      type: 'success',
    });
  }, [mutateAsync, openToast, report.title]);
  return (
    <Theme tokens={orangeLightTokens}>
      <CoreAsyncButton
        type="tertiary"
        onClick={onClick}
        confirm={{
          title: `Remove ${report.title}`,
          children: (
            <Text>
              Are you sure you want to delete <strong>{report.title}</strong>?
            </Text>
          ),
        }}
      >
        <Icon tokens={trashIconTokens} />
      </CoreAsyncButton>
    </Theme>
  );
};

import React from 'react';
import Row from '@amzn/meridian/row';
import { CoreTable } from '@amzn/dots-core-ui';
import { useDashboardEmailReports } from '@/hooks';
import { DashboardDetailUri, DashboardsUri } from '@/features/routes';
import { CorePage, CorePageTitle } from '@/components';
import { DashboardEmailReportConfiguration } from '@/types';
import {
  DashboardEmailReportColumnDefinitions,
  DashboardEmailReportAddButton,
} from './components';
import { useDashboard, withDashboard } from './contexts';

export const DashboardEmailReportListPage = withDashboard(
  (): JSX.Element => {
    const { dashboard } = useDashboard();
    const {
      data: reports,
      isError,
      isLoading,
      error,
    } = useDashboardEmailReports();
    return (
      <CorePage
        title="Email Reports"
        isLoading={isLoading}
        isError={isError}
        error={error}
        breadcrumbs={[
          { href: DashboardsUri, title: 'Dashboards' },
          { href: DashboardDetailUri(dashboard.name), title: dashboard.name },
        ]}
        header={
          <Row widths="fill">
            <CorePageTitle title={`${dashboard.name} Email Reports`} />
            <Row alignmentHorizontal="right">
              <DashboardEmailReportAddButton />
            </Row>
          </Row>
        }
      >
        <CoreTable
          isLoading={isLoading}
          getItemId={(item: DashboardEmailReportConfiguration) =>
            item.report_id
          }
          items={reports}
          columnDefinitions={DashboardEmailReportColumnDefinitions}
        />
      </CorePage>
    );
  }
);

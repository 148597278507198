import { UseQueryResult } from 'react-query';
import { KpiFilterResult, useKpis, useDashboardQueryParameters } from '@/hooks';
import { useDashboard } from '../contexts';
import { useDashboardFilters } from './useDashboardFilters';

export const useDashboardKpis = (): UseQueryResult<KpiFilterResult, Error> => {
  const {
    params: {
      allBuilds,
      filters,
      filterType,
      filterValues,
      groupTab,
      timeRange,
      numberOfBuilds,
      importedDashboards,
    },
    createApiFilters,
  } = useDashboardQueryParameters();
  const { dashboard } = useDashboard();
  const { areFiltersProvided, areFiltersRequired } = useDashboardFilters();
  const filter = createApiFilters({
    dashboard: dashboard.id,
    allBuilds:
      (numberOfBuilds === undefined &&
        dashboard.filters.data_range.allBuilds) ||
      (allBuilds ?? false),
    filters,
    timeRange,
    filterType,
    filterValues,
    groupTab,
  });
  return useKpis({
    filter,
    importedDashboards: importedDashboards ?? dashboard.filters.import_filters,
    options: {
      enabled:
        dashboard !== undefined &&
        (!areFiltersRequired || (areFiltersRequired && areFiltersProvided)),
      getAllPages: true,
    },
  });
};

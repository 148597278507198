import React, { useMemo, useRef } from 'react';
import { css } from 'emotion';
import Row from '@amzn/meridian/row';
import {
  DashboardAnnotationCountBadge,
  DashboardAnnotationPopover,
} from '../DashboardAnnotations';
import { PivotTableCell } from './data';

export type DashboardPivotTableCellContentProps = {
  cell: PivotTableCell;
};

export const DashboardPivotTableCellContent = ({
  cell: { content, annotationContext, backgroundColor },
}: DashboardPivotTableCellContentProps): JSX.Element => {
  const ref = useRef(null);
  const contentStyle = useMemo(
    () => (backgroundColor ? css({ color: '#ffffff' }) : undefined),
    [backgroundColor]
  );
  return (
    <Row
      ref={ref}
      alignmentVertical="center"
      minWidth="max-content"
      spacingInset="none"
    >
      <span className={contentStyle}>{content}</span>
      {annotationContext && (
        <>
          <DashboardAnnotationCountBadge context={annotationContext} />
          <DashboardAnnotationPopover
            anchorNode={ref}
            context={annotationContext}
          />
        </>
      )}
    </Row>
  );
};

/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useMemo, useCallback } from 'react';
import { css } from 'emotion';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Loader from '@amzn/meridian/loader';
import Alert from '@amzn/meridian/alert';
import {
  colorGreen500,
  colorRed500,
  colorYellow500,
} from '@amzn/meridian-tokens/base/color';
import { compareValues } from '@/helpers';
import { MonthlyTestCount, DeviceTypeTestCount } from '@/types';
import { useGetDashboardReport } from '@/hooks';

type RateCellProps = {
  part: number;
  total: number;
  color: string;
};

const RateCell = ({ part, total, color }: RateCellProps): JSX.Element => {
  // avoid NaN when total is 0
  const percentageValue = useMemo(
    () =>
      total === 0
        ? '0.0%'
        : `${((Number(part) / Number(total)) * 100).toFixed(1)}%`,
    [part, total]
  );
  const secondaryText = useMemo(
    () => (total === 0 ? '(0/0)' : `(${part}/${total})`),
    [part, total]
  );
  return (
    <Row>
      <Text className={css({ color })}>{percentageValue}</Text>
      <Text color="secondary">{secondaryText}</Text>
    </Row>
  );
};

type ResultTableProps = {
  title: string;
  monthTotals: MonthlyTestCount[];
  columnLabels: string[];
  rowLabels: string[];
  tableData: any;
  cell: (cell: DeviceTypeTestCount) => JSX.Element | number;
  totalCell: (month: MonthlyTestCount) => JSX.Element | number;
};

const ResultTable = ({
  title,
  monthTotals,
  columnLabels,
  rowLabels,
  tableData,
  cell,
  totalCell,
}: ResultTableProps): JSX.Element => (
  <Column type="outline" spacingInset="medium">
    <Heading level={5}>{title}</Heading>
    <Table headerRows={1} showDividers spacing="small">
      <TableRow>
        <TableCell />
        {columnLabels.map((column) => (
          <TableCell key={column}>{column}</TableCell>
        ))}
      </TableRow>
      {rowLabels.map((row) => (
        <TableRow key={row}>
          <TableCell>{row}</TableCell>
          {columnLabels.map((column) => (
            <TableCell key={column}>{cell(tableData[row][column])}</TableCell>
          ))}
        </TableRow>
      ))}
      <TableRow backgroundColor="secondary">
        <TableCell>Total</TableCell>
        {monthTotals.map((month) => (
          <TableCell key={month.month}>{totalCell(month)}</TableCell>
        ))}
      </TableRow>
    </Table>
  </Column>
);

export type DashboardMonthlyReportProps = {
  reportRequestId: string;
};

export const DashboardMonthlyReport = ({
  reportRequestId,
}: DashboardMonthlyReportProps): JSX.Element => {
  const {
    data: report_data,
    isLoading,
    isError,
    error,
  } = useGetDashboardReport({
    reportRequestId,
  });

  const months = [
    '',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const getMonthKey = useCallback(
    (m: MonthlyTestCount) => `${months[Number(m.month)]} ${m.year}`,
    [months]
  );
  // our columns will be all of the 'month keys' found in the API response
  const columnLabels = useMemo(() => report_data?.map(getMonthKey), [
    getMonthKey,
    report_data,
  ]);

  const getTable = useCallback(
    (
      targetKey: 'device_types' | 'ingestion_sources',
      targetProperty: string
    ) => {
      if (report_data === undefined) {
        return { undefined };
      }
      const table = report_data.reduce((previous, current) => {
        (current[targetKey] as any)
          .filter((item: any) => item[targetProperty])
          .forEach((item: any) => {
            if (
              !Object.prototype.hasOwnProperty.call(
                previous,
                item[targetProperty]
              )
            ) {
              previous[item[targetProperty]] = {};
            }
            previous[item[targetProperty]][getMonthKey(current)] = item;
          });
        return previous;
      }, {} as any);
      const rowLabels = Object.keys(table);
      rowLabels.sort((x, y) => compareValues()(x, y));
      return { rowLabels, table };
    },
    [getMonthKey, report_data]
  );

  const { table: deviceTable, rowLabels: deviceRowLabels } = getTable(
    'device_types',
    'device_type_name'
  );
  const { table: sourceTable, rowLabels: sourceRowLabels } = getTable(
    'ingestion_sources',
    'ingestion_source_name'
  );

  if (isLoading) {
    return <Loader size="medium" />;
  }

  if (isError && error) {
    return (
      <Alert size="small" type="error">
        {error?.message}
      </Alert>
    );
  }

  if (
    report_data == null ||
    deviceTable == null ||
    deviceRowLabels == null ||
    sourceTable == null ||
    sourceRowLabels == null ||
    columnLabels == null
  ) {
    return (
      <Alert size="small" type="error">
        Failed to fetch rpeort details
      </Alert>
    );
  }

  if (report_data.length === 0) {
    return (
      <Alert size="large" type="informational">
        There is not enough data to show the report.
      </Alert>
    );
  }

  return (
    <div>
      <Column spacingInset="large" spacing="large" maxWidth={1200}>
        <>
          <ResultTable
            title="Testsuites By Device Type"
            monthTotals={report_data}
            columnLabels={columnLabels}
            rowLabels={deviceRowLabels}
            tableData={deviceTable}
            cell={(cell: DeviceTypeTestCount) => cell?.test_suite_count ?? 0}
            totalCell={(month: MonthlyTestCount) =>
              month?.test_suite_count ?? 0
            }
          />
          <ResultTable
            title="Test Throughput by Source"
            monthTotals={report_data}
            columnLabels={columnLabels}
            rowLabels={sourceRowLabels}
            tableData={sourceTable}
            cell={(cell: DeviceTypeTestCount) => cell?.test_count ?? 0}
            totalCell={(month: MonthlyTestCount) => month?.test_count ?? 0}
          />
          <ResultTable
            title="Test Throughput by Device Type"
            monthTotals={report_data}
            columnLabels={columnLabels}
            rowLabels={deviceRowLabels}
            tableData={deviceTable}
            cell={(cell: DeviceTypeTestCount) => cell?.test_count ?? 0}
            totalCell={(month: MonthlyTestCount) => month?.test_count ?? 0}
          />
          <ResultTable
            title="Pass Rate"
            monthTotals={report_data}
            columnLabels={columnLabels}
            rowLabels={deviceRowLabels}
            tableData={deviceTable}
            cell={(cell: DeviceTypeTestCount) => (
              <RateCell
                color={colorGreen500}
                part={cell?.pass_count ?? 0}
                total={cell?.test_count ?? 0}
              />
            )}
            totalCell={(month: MonthlyTestCount) => (
              <RateCell
                color={colorGreen500}
                part={month?.pass_count ?? 0}
                total={month?.test_count ?? 0}
              />
            )}
          />
          <ResultTable
            title="Fail Rate"
            monthTotals={report_data}
            columnLabels={columnLabels}
            rowLabels={deviceRowLabels}
            tableData={deviceTable}
            cell={(cell: DeviceTypeTestCount) => (
              <RateCell
                color={colorRed500}
                part={cell?.fail_count ?? 0}
                total={cell?.test_count ?? 0}
              />
            )}
            totalCell={(month: MonthlyTestCount) => (
              <RateCell
                color={colorRed500}
                part={month?.fail_count ?? 0}
                total={month?.test_count ?? 0}
              />
            )}
          />
          <ResultTable
            title="Error Rate"
            monthTotals={report_data}
            columnLabels={columnLabels}
            rowLabels={deviceRowLabels}
            tableData={deviceTable}
            cell={(cell: DeviceTypeTestCount) => (
              <RateCell
                color={colorYellow500}
                part={cell?.skip_count ?? 0}
                total={(cell?.skip_count ?? 0) + (cell?.test_count ?? 0)}
              />
            )}
            totalCell={(month: MonthlyTestCount) => (
              <RateCell
                color={colorYellow500}
                part={month?.skip_count ?? 0}
                total={(month?.skip_count ?? 0) + (month?.test_count ?? 0)}
              />
            )}
          />
        </>
      </Column>
    </div>
  );
};

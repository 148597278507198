import React from 'react';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import Divider from '@amzn/meridian/divider';
import Link from '@amzn/meridian/link';
import Text from '@amzn/meridian/text';

export type DashboardFormSectionProps = {
  title: string;
  descriptionText?: string;
  descriptionLink?: string;
  children: React.ReactNode;
};

export const DashboardFormSection = ({
  title,
  descriptionText,
  descriptionLink,
  children,
}: DashboardFormSectionProps): JSX.Element => (
  <Column spacing="400">
    <Column spacing="300">
      <Row alignmentVertical="bottom">
        <Heading level={5}>{title}</Heading>
        {descriptionLink && (
          <Link
            href={descriptionLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </Link>
        )}
      </Row>
      <Divider size="small" />
      {descriptionText && <Text>{descriptionText}</Text>}
    </Column>
    {children}
  </Column>
);

import React from 'react';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import { useDashboard } from '@/features/Dashboards/contexts';
import {
  DashboardFavoriteButton,
  DashboardEditButton,
  DashboardFiltersButton,
  DashboardCloneButton,
} from '../DashboardButtons';
import { DashboardReportButton } from './DashboardReportButton';

export const DashboardHeader = (): JSX.Element => {
  const { dashboard } = useDashboard();
  return (
    <Column spacing="400">
      <Row widths={['fit', 'fill', 'fit', 'fit', 'fit', 'fit']} spacing="small">
        <DashboardFavoriteButton id={dashboard.id} name={dashboard.name} />
        <Heading level={2}>{dashboard.name}</Heading>
        <DashboardEditButton name={dashboard.name} />
        <DashboardCloneButton id={dashboard.id} name={dashboard.name} />
        <DashboardReportButton />
        <DashboardFiltersButton />
      </Row>
      {dashboard.description && (
        <Row>
          <Text>{dashboard.description}</Text>
        </Row>
      )}
    </Column>
  );
};

import { RefObject, useEffect, useState } from 'react';

export const useOnHover = (
  ref: RefObject<Element> | undefined,
  enabled = true
): boolean => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    let onMouseOverTimeout: ReturnType<typeof setTimeout>;
    let onMouseOutTimeout: ReturnType<typeof setTimeout>;
    const onMouseOver = () => {
      onMouseOverTimeout = setTimeout(() => setValue(true), 1000);
    };
    const onMouseOut = () => {
      onMouseOutTimeout = setTimeout(() => setValue(false), 1000);
    };
    if (enabled && ref && ref.current) {
      ref.current.addEventListener('mouseover', onMouseOver);
      ref.current.addEventListener('mouseout', onMouseOut);
    }

    return () => {
      if (onMouseOutTimeout) {
        clearTimeout(onMouseOutTimeout);
      }
      if (onMouseOverTimeout) {
        clearTimeout(onMouseOverTimeout);
      }
      if (enabled && ref && ref.current) {
        ref.current.removeEventListener('mouseover', onMouseOver);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref.current.removeEventListener('mouseout', onMouseOut);
      }
    };
  }, [enabled, ref]);

  return value;
};

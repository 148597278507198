import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useHttp, HttpClient } from '@/client';
import { Dashboard } from '@/types';

export const createDashboard = async (
  http: HttpClient,
  dashboard: Partial<Dashboard>
): Promise<Dashboard> => {
  const { data, status } = await http.post('dashboard', dashboard);
  if (status.code === 200) {
    return data;
  }
  throw new Error(data.message);
};

export const useDashboardCreate = (): UseMutationResult<
  Partial<Dashboard>,
  Error,
  Partial<Dashboard>
> => {
  const { http } = useHttp();
  const queryClient = useQueryClient();
  return useMutation(
    (dashboard: Partial<Dashboard>) => createDashboard(http, dashboard),
    {
      onSuccess: (dashboard) => {
        queryClient.setQueryData(
          ['dashboard', { dashboardId: dashboard.name }],
          dashboard
        );
      },
    }
  );
};

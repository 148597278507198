import { DashboardWizardBase } from '@/types';
import { useDashboardLayoutsFormState } from './DashboardLayoutsFormStateContext';
import { wizardTypeLabels } from './helpers';

export type WizardParentOption = {
  label: string;
  value: string;
  disabled: boolean;
};

export const useParentWizardOptions = (
  wizard: DashboardWizardBase
): WizardParentOption[] => {
  const {
    state: { wizards },
  } = useDashboardLayoutsFormState();
  return wizards
    .sort((a, b) => a.position - b.position)
    .filter(
      (w) =>
        w.id !== wizard.id &&
        w.tabIndex === wizard.tabIndex &&
        w.sectionIndex === wizard.sectionIndex
    )
    .map((w) => ({
      label: `${w.id}: ${wizardTypeLabels[w.type]}`,
      value: w.id,
      disabled: w.parent === wizard.id,
    }))
    .concat({ label: 'No Parent', value: '', disabled: false });
};

import React, { useContext, useMemo, useState } from 'react';
import { AnnotationContext } from '@/features/Dashboards/types';

export type DashboardAnnotationViewContext = {
  isViewOpen: boolean;
  openView: (context: AnnotationContext) => void;
  closeView: () => void;
  context: AnnotationContext;
};

const defaultAnnotationContext: AnnotationContext = {
  type: 'wizard_annotation',
  kpiIds: [],
  testrunIds: [],
};

export const DashboardAnnotationViewContext = React.createContext<
  DashboardAnnotationViewContext | undefined
>(undefined);

export type DashboardAnnotationViewProviderProps = {
  children: React.ReactNode;
};

export const DashboardAnnotationViewProvider = ({
  children,
}: DashboardAnnotationViewProviderProps): JSX.Element => {
  const [state, setState] = useState<{
    isViewOpen: boolean;
    context: AnnotationContext;
  }>({
    isViewOpen: false,
    context: defaultAnnotationContext,
  });
  const value = useMemo<DashboardAnnotationViewContext>(
    () => ({
      ...state,
      openView: (context: AnnotationContext) =>
        setState({ isViewOpen: true, context }),
      closeView: () =>
        setState({ isViewOpen: false, context: defaultAnnotationContext }),
    }),
    [state]
  );
  return (
    <DashboardAnnotationViewContext.Provider value={value}>
      {children}
    </DashboardAnnotationViewContext.Provider>
  );
};

export const useDashboardAnnotationViewContext = (): DashboardAnnotationViewContext => {
  const context = useContext(DashboardAnnotationViewContext);
  if (context === undefined) {
    throw new Error(
      'useAnnotationView must be used within an AnnotationViewProvider'
    );
  }
  return context;
};

import React from 'react';
import Column from '@amzn/meridian/column';
import {
  DashboardFilters,
  DashboardHeader,
  DashboardWizardLayout,
  DashboardTabs,
  DashboardAnnotationViewProvider,
} from './components';
import { withDashboard } from './contexts';

export const DashboardPage = withDashboard(
  (): JSX.Element => {
    return (
      <Column spacing="300" spacingInset="400">
        <DashboardHeader />
        <DashboardFilters />
        <DashboardTabs />
        <DashboardAnnotationViewProvider>
          <DashboardWizardLayout />
        </DashboardAnnotationViewProvider>
      </Column>
    );
  }
);

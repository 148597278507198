import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Tab, { TabGroup } from '@amzn/meridian/tab';
import Row from '@amzn/meridian/row';
import { useDashboardQueryParameters } from '@/hooks';
import { useDashboard } from '@/features/Dashboards/contexts';

export const DashboardTabs = (): JSX.Element => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { params, serializeUiQueryParameters } = useDashboardQueryParameters();
  const {
    dashboard: { layouts },
  } = useDashboard();
  const layoutNames = useMemo(() => layouts.map((layout) => layout.name), [
    layouts,
  ]);
  useEffect(() => {
    if (!params.tab || !layoutNames.includes(params.tab)) {
      history.replace({
        pathname,
        search: `?${serializeUiQueryParameters({
          ...params,
          tab: layoutNames[0],
        })}`,
      });
    }
  }, [history, layoutNames, params, pathname, serializeUiQueryParameters]);
  return (
    <Row>
      <TabGroup
        value={params.tab ?? ''}
        onChange={(tab) =>
          history.push({
            pathname,
            search: `?${serializeUiQueryParameters({
              ...params,
              tab,
            })}`,
          })
        }
        fill="none"
        type="default"
      >
        {layoutNames.map((name) => (
          <Tab key={name} value={name}>
            {name}
          </Tab>
        ))}
      </TabGroup>
    </Row>
  );
};

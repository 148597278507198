import React, { useState } from 'react';
import { useQuery } from 'react-query';
import Row from '@amzn/meridian/row';
import Column from '@amzn/meridian/column';
import Table, {
  TableActionBar,
  TableCell,
  TableRow,
} from '@amzn/meridian/table';
import SearchField from '@amzn/meridian/search-field';
import { useFilter } from '@amzn/dots-core-ui';
import { CorePage, CorePageTitle } from '@/components';
import { useHttp } from '@/client';
import {
  TranslatorUploadButton,
  TranslatorColumnDefinitions,
} from './components';
import { translatorClient } from './client';
import { translatorKeys } from './queries';
import { Translator, TranslatorFilters } from './types';

export const TranslatorsListPage = (): JSX.Element => {
  const { http } = useHttp();
  const translatorFilter: TranslatorFilters = { latest: true };
  const [searchQuery, setSearchQuery] = useState('');
  const { data: translators = [], isLoading, isError, error } = useQuery<
    Translator[],
    Error
  >({
    queryKey: translatorKeys.all(translatorFilter),
    queryFn: () => translatorClient.getTranslators(http, translatorFilter),
  });
  const filteredData = useFilter(translators, searchQuery, (item) => item.name);
  filteredData.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <CorePage
      title="Translators"
      isLoading={isLoading}
      isError={isError}
      error={error}
      header={
        <Row widths="fill">
          <CorePageTitle title="Translators" />
          <Row alignmentHorizontal="right">
            <TranslatorUploadButton />
          </Row>
        </Row>
      }
    >
      <Column width="100%" alignmentHorizontal="right" spacing="100">
        <Table
          spacing="small"
          showDividers
          headerRows={1}
          fixHeaderRows
          fixHeaderRowsFullWidth
        >
          <TableActionBar widths="fit">
            <SearchField
              value={searchQuery}
              placeholder="Search for..."
              inaccessibleAutoFocus
              searchButton={false}
              onChange={setSearchQuery}
              onSubmit={setSearchQuery}
            />
          </TableActionBar>
          <TableRow>
            {TranslatorColumnDefinitions.map(({ id, header }) => (
              <TableCell key={id}>{header}</TableCell>
            ))}
          </TableRow>
          {filteredData.map((translator) => (
            <TableRow key={translator.name}>
              {TranslatorColumnDefinitions.map(({ id, item }, index) => (
                <TableCell key={id}>{item(translator, index)}</TableCell>
              ))}
            </TableRow>
          ))}
        </Table>
      </Column>
    </CorePage>
  );
};

import React, { useCallback, useState } from 'react';
import Icon from '@amzn/meridian/icon';
import syncTokens from '@amzn/meridian-tokens/base/icon/sync';
import { CoreAsyncButton, useToaster } from '@amzn/dots-core-ui';
import { LinkJiraArgs, useJiraLink } from '@/hooks';
import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';

export type JiraLinkButtonProps = {
  linkProperties: Omit<LinkJiraArgs, 'jira'>;
};

export const JiraLinkButton = ({
  linkProperties,
}: JiraLinkButtonProps): JSX.Element => {
  const { openToast } = useToaster();
  const [jira, setJira] = useState('');
  const { mutateAsync } = useJiraLink();
  const onClick = useCallback(async () => {
    await mutateAsync(
      { ...linkProperties, jira },
      {
        onError: (error: Error) =>
          openToast({
            message: `Failed to link Jira ${jira} to ${linkProperties.kpiBucket}: ${error}`,
            type: 'error',
          }),
      }
    );
    openToast({
      message: `Successfully linked Jira ${jira} to ${linkProperties.kpiBucket}!`,
      type: 'success',
    });
  }, [jira, linkProperties, mutateAsync, openToast]);
  return (
    <CoreAsyncButton
      type="tertiary"
      size="small"
      onClick={onClick}
      confirm={{
        title: 'Link an Existing Jira',
        children: (
          <Column>
            <Input
              label="Jira ID"
              value={jira}
              onChange={(value): void => setJira(value)}
            />
          </Column>
        ),
      }}
    >
      <Icon tokens={syncTokens} />
      Link Jira
    </CoreAsyncButton>
  );
};

/* eslint-disable camelcase */
export const translatorDestinations = ['LOCAL', 'PIPELINE'] as const;
export const translatorModes = ['public', 'private'] as const;
export const translatorTypes = ['ST', 'CT'] as const;

export type TranslatorDestination = typeof translatorDestinations[number];
export type TranslatorMode = typeof translatorModes[number];
export type TranslatorType = typeof translatorTypes[number];

export type TranslatorId = {
  name: string;
  version: string;
};

export type Translator = TranslatorId & {
  all_versions?: string[];
  date: string;
  description: string;
  enable: boolean;
  iversion: number;
  mode: TranslatorMode;
  owners: string[];
  source: string;
  type: TranslatorType;
  url: string;
};

export type TranslatorFileDescrptor = {
  name?: string;
  md5?: string;
  version?: string;
  creator?: string;
};

export type TranslatorUploadResult = {
  result: TranslatorFileDescrptor;
  errors: string[];
};

export type TranslatorFilters = {
  latest?: boolean;
  name?: string;
  version?: string;
};

export type TranslatorExceptionFilter = {
  id: string;
  source: string;
  name: string;
  version: string;
};

export type TranslatorException = {
  data_id: string;
  data_source: string;
  date: string;
  exception: string;
  filename: string;
  iversion: number;
  name: string;
  version: string;
};

export type TranslatorTestRequest = {
  name: string;
  version: string;
  source: string;
  ids: string[];
  destination: TranslatorDestination;
};

export type TranslatorTestResponse = {
  queryCode: string;
  testrunId: string;
  status: 'rejected' | 'pending';
}[];

export type TranslatorGetTestResultRequest = {
  name: string;
  version: string;
  source: string;
  query_codes: string[];
};

export type TranslatorGetTestResultResponse = Record<string, string>;

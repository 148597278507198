import React from 'react';
import { useParams } from 'react-router-dom';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import { CorePage, CorePageTitle } from '@/components';
import { DashboardMonthlyReport } from './components';
import { useDashboard, withDashboard } from './contexts';
import {
  DashboardDetailUri,
  DashboardMonthlyReportsUri,
  DashboardsUri,
} from '../routes';

export const DashboardMonthlyReportPage = withDashboard(
  (): JSX.Element => {
    const { dashboard } = useDashboard();
    const { reportRequestId } = useParams<{
      reportRequestId: string;
    }>();
    return (
      <CorePage
        title={reportRequestId}
        breadcrumbs={[
          { href: DashboardsUri, title: 'Dashboards' },
          { href: DashboardDetailUri(dashboard.name), title: dashboard.name },
          {
            href: DashboardMonthlyReportsUri(dashboard.name),
            title: 'Monthly Reports',
          },
        ]}
        header={
          <Row widths="fill">
            <CorePageTitle title={`${dashboard.name}: Monthly Report`} />
            <Row alignmentHorizontal="right" alignmentVertical="bottom">
              <Heading level={6} color="secondary">
                (last 4 months)
              </Heading>
            </Row>
          </Row>
        }
      >
        <DashboardMonthlyReport reportRequestId={reportRequestId} />
      </CorePage>
    );
  }
);

import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import { DashboardSelect } from '@/components';
import { useDashboardQueryParameters } from '@/hooks';

export const KpiBucketDashboardPicker = (): JSX.Element => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { params, serializeUiQueryParameters } = useDashboardQueryParameters();
  return (
    <Column spacing="200" maxWidth={400}>
      <DashboardSelect
        value={params.dashboard ?? ''}
        onChange={(dashboardIds: string | string[]) => {
          const nextDashboardId = Array.isArray(dashboardIds)
            ? dashboardIds[0]
            : dashboardIds;
          history.push(
            `${url}?${serializeUiQueryParameters({
              ...params,
              dashboard: nextDashboardId,
            })}`
          );
        }}
      />
      <Alert type="warning" size="small">
        Please choose a Dashboard to create/link/tag Jiras
      </Alert>
    </Column>
  );
};

/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import Column from '@amzn/meridian/column';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';
import { getUniqueId } from '@/features/Dashboards/helpers';
import { DashboardFormSection } from '../DashboardFormSection';
import { CustomTab, DashboardFiltersFormControlProps } from './types';
import { DashboardCustomTabControl } from './DashboardCustomTabControl';

export const DashboardCustomTabCollectionControl = ({
  testSource,
  value,
  onChange,
}: DashboardFiltersFormControlProps<Partial<CustomTab>[]>): JSX.Element => {
  const onAddClick = useCallback(
    () => onChange(value.concat({ id: getUniqueId() })),
    [onChange, value]
  );
  const onUpdate = useCallback(
    (index: number) => (tab: CustomTab) => {
      const next = [...value];
      next[index] = tab;
      onChange(next);
    },
    [onChange, value]
  );
  const onRemove = useCallback(
    (index: number) => () => onChange(value.filter((_, i) => i !== index)),
    [onChange, value]
  );
  return (
    <DashboardFormSection
      title="Custom Tabs"
      descriptionText="Define custom tabs to filter data based on a Kpi property"
      descriptionLink="https://quip-amazon.com/eZruAhb4Ztqm/Boomerang-Pivot-v2-User-Guide#FPT9CAMlYzA"
    >
      <Column spacing="500">
        {value.map((tab, i) => (
          <DashboardCustomTabControl
            key={tab.id}
            testSource={testSource}
            tab={tab}
            onChange={onUpdate(i)}
            onRemove={onRemove(i)}
          />
        ))}
      </Column>
      <Button type="primary" onClick={onAddClick}>
        <Icon tokens={plusTokens}>Add a new custom tab</Icon>
      </Button>
    </DashboardFormSection>
  );
};

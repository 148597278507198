import React, { useRef } from 'react';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import { WizardTableCell } from '@/features/Dashboards/types';
import { BaseTableRowContext, getAnnotationContext } from './data';
import { DashboardAnnotationPopover } from '../DashboardAnnotations';

export type DashboardBaseTableCellContentProps = {
  cell: WizardTableCell;
  rowContext: BaseTableRowContext;
};

export const DashboardBaseTableCellContent = ({
  cell: { content, href },
  rowContext,
}: DashboardBaseTableCellContentProps): JSX.Element => {
  const ref = useRef(null);
  const annotationContext = getAnnotationContext(rowContext);
  return (
    <>
      <Row ref={ref} minWidth="max-content">
        {href ? (
          <Link href={href} target="_blank" type="secondary">
            {content}
          </Link>
        ) : (
          content
        )}
      </Row>
      <DashboardAnnotationPopover
        anchorNode={ref}
        context={annotationContext}
      />
    </>
  );
};

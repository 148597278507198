/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useHistory } from 'react-router-dom';
import Tooltip from '@amzn/meridian/tooltip';
import Button from '@amzn/meridian/button';
import { ButtonProps } from '@amzn/meridian/button/button';
import Icon from '@amzn/meridian/icon';
import pencilIconTokens from '@amzn/meridian-tokens/base/icon/pencil';
import { TranslatorEditUri } from '@/features/routes';
import { TranslatorId } from '@/features/Translators/types';

export type TranslatorEditButtonProps = TranslatorId &
  Omit<ButtonProps, 'onClick' | 'children'>;

export const TranslatorEditButton = ({
  name,
  version,
  type = 'tertiary',
  ...buttonProps
}: TranslatorEditButtonProps): JSX.Element => {
  const history = useHistory();
  return (
    <Tooltip title={`edit ${name} v${version}`} position="bottom">
      <Button
        {...buttonProps}
        type={type}
        onClick={() => history.push(TranslatorEditUri(name, version))}
      >
        <Icon tokens={pencilIconTokens} />
      </Button>
    </Tooltip>
  );
};

import React from 'react';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Tooltip from '@amzn/meridian/tooltip';
import ExportSmallTokens from '@amzn/meridian-tokens/base/icon/export-small';
import { WizardTableCell, WizardTableData, WizardTableRow } from '../../types';

export const DashboardExportButton = (wizardData: WizardTableData): JSX.Element => {
  const exportToCSV = () => {
    let csvContent = 'data:text/csv;charset=utf-8'

    //add the headers to csv file
    wizardData.header.forEach((heading: WizardTableCell) => {
      csvContent += ',' + heading.content
    });
    csvContent += '\r\n';

    //iterate through rows and add rows to csv
    wizardData.rows.forEach((row: WizardTableRow) => {
      row.cells.forEach((cell: WizardTableCell) => {
        csvContent += cell.content + ',';
      });
      csvContent.slice(0, -1);
      csvContent += '\r\n';
    });
    
    //export to csv
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "Boomerang_table_export.csv");
    document.body.appendChild(link);
    link.click()
  };
  return (
    <Tooltip position="bottom" title="Export table to CSV">
      <Button label="Export" onClick={() => exportToCSV()}>
        <Icon tokens={ExportSmallTokens}>Export table to CSV</Icon>
        Export
      </Button>
    </Tooltip>
  );
};
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { css } from 'emotion';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Alert from '@amzn/meridian/alert';
import {
  CoreFilterableSelect,
  CorePageLoader,
  isNullOrWhitespace,
  useForm,
} from '@amzn/dots-core-ui';
import { useHttp } from '@/client';
import { TestrunSelect, ControlledExpander } from '@/components';
import {
  TranslatorException,
  TranslatorExceptionFilter,
} from '@/features/Translators/types';
import { translatorClient } from '@/features/Translators/client';
import { translatorKeys } from '@/features/Translators/queries';

const prewrapStyle = css({
  whiteSpace: 'pre-wrap',
});

export type TranslatorExceptionsProps = {
  name: string;
  versions: string[];
  source: string;
};

export const TranslatorExceptions = ({
  name,
  versions,
  source,
}: TranslatorExceptionsProps): JSX.Element => {
  const { http } = useHttp();
  const queryClient = useQueryClient();
  const [exceptions, setExceptions] = useState<TranslatorException[]>([]);
  const { values, handlers, handleSubmit, hasErrors, isSubmitting } = useForm({
    id: {
      initialValue: '',
      validate: (value: string) =>
        isNullOrWhitespace(value) ? 'Select a testrun' : '',
    },
    name: {
      initialValue: name,
    },
    source: { initialValue: source },
    version: {
      initialValue: versions[0],
      validate: (value: string) =>
        isNullOrWhitespace(value) ? 'Translator Version is required' : '',
    },
  });
  const { mutateAsync, error, isLoading, isSuccess, isError } = useMutation<
    TranslatorException[],
    Error,
    TranslatorExceptionFilter
  >(
    (filters: TranslatorExceptionFilter) =>
      translatorClient.getTranslatorExceptions(http, filters),
    {
      onSuccess: (result, filters) => {
        queryClient.setQueryData(translatorKeys.allExceptions(filters), result);
      },
    }
  );
  return (
    <Column>
      <form
        onSubmit={handleSubmit(async (vs) => {
          await mutateAsync(vs, {
            onSuccess: (results) => {
              setExceptions(results);
            },
          });
        })}
      >
        <Row type="fill" spacingInset="300">
          <TestrunSelect
            source="KATS"
            value={values.id}
            onChange={handlers.id}
          />
          <CoreFilterableSelect
            label="Translator Version"
            items={versions}
            value={values.version}
            onChange={handlers.version}
          />
          <Button
            size="large"
            submit
            disabled={hasErrors || isSubmitting || isLoading}
          >
            Search
          </Button>
        </Row>
      </form>
      {isLoading && <CorePageLoader />}
      {isError && (
        <Alert type="error" title="Error!" size="large">
          {error?.message}
        </Alert>
      )}
      {isSuccess && (
        <>
          <Text>{exceptions.length} exception(s) found</Text>
          {exceptions.map((exception) => (
            <ControlledExpander
              key={exception.date}
              title={exception.data_id}
              type="box"
            >
              <Text className={prewrapStyle}>
                Exception: {exception.exception}
              </Text>
            </ControlledExpander>
          ))}
        </>
      )}
    </Column>
  );
};

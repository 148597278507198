/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { isNullOrWhitespace, ValidationProps } from '@amzn/dots-core-ui';
import { useHttp } from '../client/HttpContext';
import { CoreAsyncSelect, CoreAsyncSelectProps } from './CoreAsyncSelect';

const get = (item: string): string => item;

export type GroupFilterSelectProps = Pick<
  ValidationProps<CoreAsyncSelectProps<string>>,
  'value' | 'onChange' | 'errorMessage' | 'validationState'
>;

export const GroupFilterSelect = (
  props: GroupFilterSelectProps
): JSX.Element => {
  const { http } = useHttp();
  const getValues = useCallback(
    async (query: string): Promise<string[]> => {
      if (isNullOrWhitespace(query)) {
        return [];
      }
      const data = await http.get('global/tab_groups', {
        search: query,
        limit: 100,
      });
      if (data.status.code === 200) {
        return data.data.map(
          (prop: { label: string; value: string }) => prop.label
        );
      }
      return [];
    },
    [http]
  );
  return (
    <CoreAsyncSelect
      {...props}
      placeholder="Search for a Device Family or Product Group..."
      getItems={getValues}
      getItemLabel={get}
      getItemValue={get}
    />
  );
};

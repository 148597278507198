import { useMemo, useState } from 'react';

export type SortState = {
  sortColumn: string;
  sortDirection: 'ascending' | 'descending';
};

export const useSortableData = <T extends Record<string, any>>(
  items: T[],
  defaultSortState: SortState
): {
  items: T[];
  sortState: SortState;
  onSort: (nextState: SortState) => void;
} => {
  const [state, setState] = useState(defaultSortState);

  const sortedItems = useMemo(() => {
    const sortableItems = [...items];
    if (state !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[state.sortColumn];
        const bValue = b[state.sortColumn];
        const isComplexType =
          Array.isArray(aValue) || typeof aValue === 'object';
        const transform = isComplexType
          ? (value: any): string => JSON.stringify(value)
          : (value: any): string => value.toString();
        const comparison = transform(aValue).localeCompare(
          transform(bValue),
          undefined,
          {
            numeric: true,
          }
        );
        return state.sortDirection === 'ascending'
          ? comparison
          : comparison * -1;
      });
    }
    return sortableItems;
  }, [items, state]);

  return { items: sortedItems, sortState: state, onSort: setState };
};

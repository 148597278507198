import React from 'react';
import Row from '@amzn/meridian/row';
import { KpiLogFile } from '@/components';
import { useDashboardWizardData } from '@/features/Dashboards/hooks';
import { KpiLogWizardConfiguration } from '@/types';
import { createWizardData } from './data';

export type DashboardKpiLogWizardProps = {
  configuration: KpiLogWizardConfiguration;
};

export const DashboardKpiLogWizard = ({
  configuration,
}: DashboardKpiLogWizardProps): JSX.Element => {
  const wizardData = useDashboardWizardData(configuration, createWizardData);
  if (!wizardData || wizardData?.logFiles.length > 3) {
    return <>Please select at most 3 KPIs for checking logs.</>;
  }
  return (
    <Row alignmentVertical="top">
      {wizardData.logFiles.map((file) => (
        <KpiLogFile
          key={`${file.testrunId}-${file.logFilePath}`}
          source={file.source}
          testrunId={file.testrunId}
          logFilePath={file.logFilePath}
        />
      ))}
    </Row>
  );
};

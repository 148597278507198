import React, { useState } from 'react';
import AppLayout from '@amzn/meridian/app-layout';
import { AppMasthead } from './AppMasthead';
import { AppNavigation } from './AppNavigation';
import { AppRouter } from './AppRouter';

export const App = (): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  return (
    <AppLayout
      headerComponent={AppMasthead}
      sidebarComponent={AppNavigation}
      backgroundColor="alternatePrimary"
    >
      <AppMasthead onMenuButtonClick={() => setIsMenuOpen((prev) => !prev)} />
      <AppNavigation isMenuOpen={isMenuOpen} />
      <AppRouter isRootApp />
    </AppLayout>
  );
};

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useHttp, HttpClient } from '@/client';
import { DashboardEmailReportConfiguration } from '@/types';

export type DeleteDashboardEmailConfigurationArgs = {
  reportConfiguration: DashboardEmailReportConfiguration;
};

export const deleteDashboardEmailReportConfiguration = async (
  http: HttpClient,
  reportConfiguration: DashboardEmailReportConfiguration
): Promise<void> => {
  const { data, status } = await http.delete(
    `dashboard/email_report/${reportConfiguration.report_id}`,
    reportConfiguration
  );
  if (status.code === 200) {
    return data;
  }
  throw new Error(data.message);
};

export const useDashboardEmailReportDelete = ({
  reportConfiguration,
}: DeleteDashboardEmailConfigurationArgs): UseMutationResult<
  void,
  Error,
  void
> => {
  const { http } = useHttp();
  const queryClient = useQueryClient();
  const { dashboardName: rawName } = useParams<{
    dashboardName: string;
  }>();
  const dashboardName = useMemo(() => decodeURIComponent(rawName), [rawName]);
  return useMutation(
    () => deleteDashboardEmailReportConfiguration(http, reportConfiguration),
    {
      onSettled: () => {
        queryClient.invalidateQueries([
          'dashboardEmailReports',
          { dashboardName },
        ]);
      },
    }
  );
};

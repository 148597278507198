import { useQuery, UseQueryResult } from 'react-query';
import { isNullOrWhitespace } from '@amzn/dots-core-ui';
import { useHttp, HttpClient } from '@/client';
import { Dashboard } from '@/types';

export type DashboardQueryArgs = {
  dashboardId?: string;
};

const getDashboard = async (
  http: HttpClient,
  { dashboardId }: DashboardQueryArgs
): Promise<Dashboard> => {
  const { data, status } = await http.get(`dashboard?name=${dashboardId}`);
  if (status.code === 200) {
    const dashboard: Dashboard = data;
    if (dashboard?.filters?.custom_tabs?.some((tab) => tab.auto)) {
      const tabResponse = await http.get('kpi/filter', {
        dashboard_name: dashboardId,
        get_tabs: true,
      });
      if (tabResponse.status.code === 200) {
        dashboard.filters.custom_tabs = [
          ...dashboard.filters.custom_tabs.map((tab) => {
            if (
              Object.prototype.hasOwnProperty.call(
                tabResponse.data.tabs,
                tab.field
              )
            ) {
              return { ...tab, values: tabResponse.data.tabs[tab.field] };
            }
            return tab;
          }),
        ];
      }
    }
    return dashboard;
  }
  throw new Error(data.message);
};

export const useDashboard = (
  args: DashboardQueryArgs
): UseQueryResult<Dashboard, Error> => {
  const { http } = useHttp();
  return useQuery({
    queryFn: () => getDashboard(http, args),
    queryKey: ['dashboard', args],
    enabled: !isNullOrWhitespace(args.dashboardId),
  });
};

import React, { useEffect } from 'react';
import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import {
  CoreFormFooter,
  isNullOrWhitespace,
  isJiraString,
  useForm,
  CoreInput,
  FormConfiguration,
  CoreTextarea,
  CoreDatePicker,
  CoreFilterableSelect
} from '@amzn/dots-core-ui';
import { Annotation, AnnotationTemplateContent, AnnotationTemplateField } from '@/types';
import Row from '@amzn/meridian/row';

export type DashboardAnnotationFormProps = {
  annotation?: Partial<Annotation>;
  onCancel: () => void;
  onSubmit: (report: Partial<Annotation>) => Promise<void> | void;
  template: AnnotationTemplateField;
};

export const DashboardAnnotationForm = ({
  annotation,
  onCancel,
  onSubmit,
  template,
}: DashboardAnnotationFormProps): JSX.Element => {
  const {
    handlers,
    values,
    errors,
    states,
    hasErrors,
    submitState,
    isSubmitting,
    handleSubmit,
  } = useForm(
    template.attributes.reduce((result, field) => {
      result[field.name] = { 
        initialValue: annotation?.template?.content[field.name]?.value ?? '',
        validate: (value: string) => 
          isNullOrWhitespace(value) && field.is_required ?
            `A ${field.name} is required` : field.type === 'jira' && !isJiraString(value) ?
              'A proper JIRA value is required' : ''
      }
      return result;
    }, {} as FormConfiguration<string>)
  );
  useEffect(() => {
    template.attributes.map((field) => (
      handlers[field.name](annotation?.template?.content[field.name]?.value ?? '')
    ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotation]);
  return (
    <form
      onSubmit={handleSubmit((vs) => {
        if (annotation) {
          if (annotation.template) {
            return onSubmit({
              ...annotation,
              template: { 
                ...annotation?.template,
                content: {
                  ...annotation.template.content,
                  ...template.attributes.reduce((result, field) => {
                    result[field.name] = {
                      value: vs[field.name],
                      type: field.type
                    }
                    return result;
                  }, {} as AnnotationTemplateContent)
                }
              }
            });
          } else {
            return onSubmit({
              ...annotation,
              template: {
                templateId: template.template_id,
                version: template.version,
                content: {
                  ...template.attributes.reduce((result, field) => {
                    result[field.name] = {
                      value: vs[field.name],
                      type: field.type
                    }
                    return result;
                  }, {} as AnnotationTemplateContent)
                }
              }
            });
          }
        }
      })}
    >
      <Column spacing="300" maxWidth="100%" width={650}>
        {submitState.status === 'error' && (
          <Alert type="error" title="Error!" size="small">
            {submitState.error?.message}
          </Alert>
        )}
        {template.attributes.map((templateField) => (
          <Row key={templateField.name} >
            {templateField.type === 'string' &&
              <CoreTextarea
                value={values[templateField.name]}
                onChange={handlers[templateField.name]}
                errorMessage={errors[templateField.name]}
                validationState={states[templateField.name]}
                rows={7}
                resize="none"
                placeholder={templateField.name}
              />}
            {templateField.type === 'jira' &&
              <CoreInput
                value={values[templateField.name]}
                onChange={handlers[templateField.name]}
                errorMessage={errors[templateField.name]}
                validationState={states[templateField.name]}
                placeholder={templateField.name}
              />
            }
            {templateField.type === 'date' &&
              <CoreDatePicker
                value={values[templateField.name]}
                onChange={handlers[templateField.name]}
                errorMessage={errors[templateField.name]}
                validationState={states[templateField.name]}
                placeholder={templateField.name}
              />
            }
            {templateField.type === 'choice' &&
              <CoreFilterableSelect
                canAddItems={false}
                items={templateField.choices ?? []}
                value={values[templateField.name]}
                onChange={handlers[templateField.name]}
                validationState={states[templateField.name]}
                errorMessage={errors[templateField.name]}
                placeholder={templateField.name}/>
            }
          </Row>
        ))}
        <CoreFormFooter
          buttonSize="small"
          isConfirmDisabled={hasErrors || isSubmitting}
          isLoading={isSubmitting}
          onCancelClick={() => {
            onCancel();
          }}
        />
      </Column>
    </form>
  );
};

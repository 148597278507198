/* eslint-disable camelcase */
import { useCallback, useMemo } from 'react';
import { useDashboardQueryParameters } from '@/hooks';
import { useDashboard } from '../contexts';
import { getDashboardFilters } from '../helpers';
import { DashboardUiFilter } from '../types';

export const useDashboardFilters = (): {
  areFiltersProvided: boolean;
  areFiltersRequired: boolean;
  filters: DashboardUiFilter[];
  isFilterActive: (filter: DashboardUiFilter) => boolean;
  isValidFilterAvailable: boolean;
} => {
  const {
    params: { filterType, filterValues, groupTab },
  } = useDashboardQueryParameters();
  const {
    dashboard: {
      filters: { group_tabs, custom_tabs },
    },
  } = useDashboard();
  const filters = useMemo(
    () => getDashboardFilters(group_tabs ?? [], custom_tabs ?? []),
    [group_tabs, custom_tabs]
  );
  const areFiltersRequired = useMemo(() => filters.length > 0, [
    filters.length,
  ]);
  const areFiltersProvided = useMemo(
    () =>
      groupTab !== undefined ||
      (filterType !== undefined && filterValues !== undefined),
    [groupTab, filterType, filterValues]
  );
  const isFilterActive = useCallback(
    (filter: DashboardUiFilter): boolean => {
      if (
        'field' in filter &&
        filterType &&
        filterValues &&
        filter.field === filterType
      ) {
        return filter.value === filterValues;
      }
      return groupTab !== undefined && groupTab === filter.value;
    },
    [filterType, filterValues, groupTab]
  );
  const isValidFilterAvailable = useMemo(
    () =>
      (groupTab !== undefined && group_tabs?.includes(groupTab)) ||
      (filterType !== undefined &&
        filterValues !== undefined &&
        custom_tabs?.filter(
          (tab) => tab.field === filterType && tab.values.includes(filterValues)
        ).length !== -1),
    [custom_tabs, filterType, filterValues, groupTab, group_tabs]
  );
  return {
    areFiltersProvided,
    areFiltersRequired,
    filters,
    isFilterActive,
    isValidFilterAvailable,
  };
};

/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import Column from '@amzn/meridian/column';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';
import { getUniqueId } from '@/features/Dashboards/helpers';
import { DashboardFormSection } from '../DashboardFormSection';
import { DashboardFiltersFormControlProps, GoalGroup } from './types';
import { DashboardGoalGroupControl } from './DashboardGoalGroupControl';

export const DashboardGoalGroupCollectionControl = ({
  testSource,
  value,
  onChange,
}: DashboardFiltersFormControlProps<Partial<GoalGroup>[]>): JSX.Element => {
  const onAddClick = useCallback(
    () => onChange(value.concat({ id: getUniqueId() })),
    [onChange, value]
  );
  const onUpdate = useCallback(
    (index: number) => (tab: GoalGroup) => {
      const next = [...value];
      next[index] = tab;
      onChange(next);
    },
    [onChange, value]
  );
  const onRemove = useCallback(
    (index: number) => () => onChange(value.filter((_, i) => i !== index)),
    [onChange, value]
  );
  return (
    <DashboardFormSection
      title="Goal Groups"
      descriptionText="Goal Groups define conditional formatting for Kpi Wizards. Goals that are within upper and lower bounds (inclusive) will be highlighted green. Those not within bounds will be highlighted red. Specifying a Device Type limits the goal group scope to Device Type"
    >
      <Column spacing="400">
        {value.map((goalGroup, i) => (
          <DashboardGoalGroupControl
            key={goalGroup.id}
            testSource={testSource}
            goalGroup={goalGroup}
            onChange={onUpdate(i)}
            onRemove={onRemove(i)}
          />
        ))}
      </Column>
      <Button type="primary" onClick={onAddClick}>
        <Icon tokens={plusTokens}>Add a new goal group</Icon>
      </Button>
    </DashboardFormSection>
  );
};

import { useMemo } from 'react';
import { BaseData } from '@/types';
import { calculate, parseExpression } from '../aggregation';
import { createGoalGroupKey, groupByProperties, groupKpis } from '../helpers';
import { GlobalCalculatedData } from '../types';
import { useDashboardKpis } from './useDashboardKpis';

const totalDurationPerHour = parseExpression(
  'divide(sum(max(group(input, data.testrun_id, data.test_runtime))),3600)'
);

export const useDashboardGlobalData = (): GlobalCalculatedData => {
  const { data } = useDashboardKpis();
  const globalData = useMemo(() => {
    if (!data) {
      return {};
    }
    const { baseData, propertyIndexMap } = data;
    const [kpiHeader] = baseData;
    const groupedKpis = groupKpis(
      propertyIndexMap,
      baseData,
      groupByProperties
    );
    const globalGroupedPropertiesData: Record<string, number> = {};
    groupedKpis.forEach(({ groupedPropertyValues, baseDataIndices }) => {
      const currentBaseData: BaseData = [
        kpiHeader,
        ...baseDataIndices.map((index) => baseData[index]),
      ];
      const groupByPropertiesKey = createGoalGroupKey(groupedPropertyValues);
      totalDurationPerHour.reset();
      try {
        calculate(currentBaseData, {}, totalDurationPerHour);
        globalGroupedPropertiesData[groupByPropertiesKey] =
          totalDurationPerHour.value;
      } catch (error) {
        globalGroupedPropertiesData[groupByPropertiesKey] = 0;
      }
    });
    return globalGroupedPropertiesData;
  }, [data]);
  return {
    _globalGroupPropertyData: globalData,
  };
};

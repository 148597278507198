import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useHttp, HttpClient } from '@/client';

export type GetKpiArgs = {
  source: string;
  kpiId: string;
};

export type Kpi = Record<string, any>;

const getKpi = async (
  http: HttpClient,
  { source, kpiId }: GetKpiArgs
): Promise<Kpi> => {
  const { data, status } = await http.get(`kpi/${source}/${kpiId}`);
  if (status.code === 200) {
    return data;
  }
  throw new Error(data.message);
};

export const createKpiQueryOptions = (
  http: HttpClient,
  args: GetKpiArgs
): UseQueryOptions<Kpi, Error, Kpi, (string | GetKpiArgs)[]> => ({
  queryFn: () => getKpi(http, args),
  queryKey: ['kpi', args],
});

export const useKpi = (args: GetKpiArgs): UseQueryResult<Kpi, Error> => {
  const { http } = useHttp();
  return useQuery(createKpiQueryOptions(http, args));
};

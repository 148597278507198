import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Tooltip from '@amzn/meridian/tooltip';
import plusIconTokens from '@amzn/meridian-tokens/base/icon/plus';
import { DashboardAddUri } from '@/features/routes';

export const DashboardAddButton = (): JSX.Element => {
  const history = useHistory();
  return (
    <Tooltip position="bottom" title="Add a new dashboard">
      <Button label="Add new" onClick={() => history.push(DashboardAddUri)}>
        <Icon tokens={plusIconTokens}>Add a new dashboard</Icon>
        Add New
      </Button>
    </Tooltip>
  );
};

import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { useHttp, HttpClient } from '@/client';
import { Dashboard } from '@/types';
import { useDashboard } from '@/features/Dashboards/contexts';

export const updateDashboard = async (
  http: HttpClient,
  dashboard: Partial<Dashboard>
): Promise<Dashboard> => {
  const { data, status } = await http.patch('dashboard', dashboard);
  if (status.code === 200) {
    return data;
  }
  throw new Error(data.message);
};

export const useDashboardUpdate = (): UseMutationResult<
  Partial<Dashboard>,
  Error,
  Partial<Dashboard>
> => {
  const { http } = useHttp();
  const queryClient = useQueryClient();
  const { dashboard } = useDashboard();
  return useMutation(
    (next: Partial<Dashboard>) => updateDashboard(http, next),
    {
      onSettled: () => {
        return Promise.all([
          queryClient.invalidateQueries([
            'dashboard',
            { dashboardId: dashboard.name },
          ]),
          queryClient.invalidateQueries(['all-dashboards']),
          queryClient.invalidateQueries(['user']),
          queryClient.invalidateQueries(['kpi']),
        ]);
      },
    }
  );
};

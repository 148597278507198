import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Tooltip from '@amzn/meridian/tooltip';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import ViewTokens from '@amzn/meridian-tokens/base/icon/view';
import closeTokens from '@amzn/meridian-tokens/base/icon/close';
import Sheet from '@amzn/meridian/sheet';
import { isNullOrWhitespace, useForm } from '@amzn/dots-core-ui';
import { useDashboardQueryParameters, Filter } from '@/hooks';
import { useDashboard } from '@/features/Dashboards/contexts';
import { getUniqueId } from '@/features/Dashboards/helpers';
import {
  DashboardDataRangeControl,
  DashboardImportedDashboardsControl,
  DashboardTestrunFilterCollectionControl,
  DataRangeFilter,
  dataRangeParser,
  getTimeRange,
  TestrunRangeFilter,
} from '../DashboardFiltersForm';

const tooltip = 'Filter dashboard data';

const toTestrunFilterFormModel = (filters?: Filter[]) =>
  filters?.map(({ key, operator, value }) => {
    const [testSource, ...propertyNameParts] = key.split('.');
    return {
      id: getUniqueId(),
      testSource,
      propertyName: propertyNameParts.join('.'),
      filterType: operator,
      value,
    } as TestrunRangeFilter;
  }) ?? [];

const toTestrunFilterQueryStringModel = (
  filters: TestrunRangeFilter[]
): Filter[] =>
  filters.map(({ testSource, filterType, value, propertyName }) => ({
    key: [testSource, propertyName].join('.'),
    operator: filterType,
    // eslint-disable-next-line no-nested-ternary
    value: Array.isArray(value) ? value : value ? [value] : [],
  }));

export type DashboardViewerFiltersProps = {
  isVisible: boolean;
  onClose: () => void;
};

export const DashboardViewerFilters = ({
  isVisible,
  onClose,
}: DashboardViewerFiltersProps): JSX.Element => {
  const { params, serializeUiQueryParameters } = useDashboardQueryParameters();
  const history = useHistory();
  const { dashboard } = useDashboard();
  const { pathname } = useLocation();
  const { values, handlers, errors, states, hasErrors } = useForm({
    testrunRange: {
      initialValue: toTestrunFilterFormModel(params.filters),
      validate: (value: TestrunRangeFilter[]) => {
        if (
          value.some(
            (f) =>
              isNullOrWhitespace(f.propertyName) ||
              (Array.isArray(f.value) && f.value.length === 0) ||
              isNullOrWhitespace(f.value)
          )
        ) {
          return 'There are errors in the filters defined below';
        }
        return '';
      },
    },
    dataRange: {
      initialValue: {
        allBuilds:
          ((params.allBuilds ?? false) ||
            (params.numberOfBuilds === undefined &&
              dashboard?.filters?.data_range.allBuilds)) ??
          false,
        numberOfBuilds:
          params.numberOfBuilds ??
          dashboard?.filters?.data_range?.last_n_sth.n ??
          10,
        timeRange: params.timeRange
          ? getTimeRange({
              time: { range: params.timeRange },
              last_n_sth: { n: '' },
            })
          : getTimeRange(dashboard.filters?.data_range),
      },
      validate: (value: DataRangeFilter) => {
        if (
          (!value.allBuilds && isNullOrWhitespace(value.numberOfBuilds)) ||
          (Array.isArray(value.timeRange) &&
            (value.timeRange.length !== 2 ||
              value.timeRange.some(isNullOrWhitespace))) ||
          isNullOrWhitespace(value.timeRange)
        ) {
          return 'There are errors in the data range';
        }
        return '';
      },
    },
    importedDashboards: {
      initialValue:
        params.importedDashboards ??
        dashboard?.filters?.imported_dashboards ??
        [],
    },
  });

  const applyFilter = () => {
    // we can use parts of dataRange if the user only change the
    // date range or the last_n_builds, default to the dashboard
    // configuration if the user did not change it
    const transformedStateTimeRange = dataRangeParser.toConfigurationModel(
      values.dataRange
    );
    // cheap comparison here, we don't care how the arrays/objects are different
    // just that they are, comparing string representation works in this case
    const timeRange =
      transformedStateTimeRange.time.range !==
      dashboard.filters.data_range.time.range
        ? transformedStateTimeRange.time.range
        : undefined;
    const numberOfBuilds =
      values.dataRange.numberOfBuilds !==
        dashboard.filters?.data_range?.last_n_sth?.n ||
      (dashboard.filters?.data_range?.allBuilds && !values.dataRange.allBuilds)
        ? values.dataRange.numberOfBuilds
        : undefined;
    const allBuilds =
      !dashboard.filters?.data_range?.allBuilds && values.dataRange.allBuilds
        ? true
        : undefined;
    // if the user did not update the dashboard collection, send an
    // empty array to signal no change
    // cheap/lazy string comparison here again...
    const importedDashboards =
      values.importedDashboards.toString() !==
      dashboard.filters?.imported_dashboards.toString()
        ? values.importedDashboards
        : [];
    const filters = toTestrunFilterQueryStringModel(values.testrunRange);
    history.push({
      pathname,
      search: `?${serializeUiQueryParameters({
        ...params,
        filters,
        timeRange,
        numberOfBuilds,
        allBuilds,
        importedDashboards,
      })}`,
    });
    onClose();
  };

  return (
    <Sheet type="overlay" side="right" open={isVisible} onClose={onClose}>
      <Column spacing="500" maxWidth={1100} alignmentVertical="top">
        <Row alignmentHorizontal="right">
          <Button onClick={onClose} type="icon">
            <Icon tokens={closeTokens}>Close sheet</Icon>
          </Button>
        </Row>
        <DashboardTestrunFilterCollectionControl
          canBeEmpty
          testSource={dashboard.testsources[0]}
          value={values.testrunRange}
          onChange={handlers.testrunRange}
          errorMessage={errors.testrunRange}
          validationState={states.testrunRange}
        />
        <DashboardDataRangeControl
          testSource={dashboard.testsources[0]}
          value={values.dataRange}
          onChange={handlers.dataRange}
          errorMessage={errors.dataRange}
          validationState={states.dataRange}
        />
        <DashboardImportedDashboardsControl
          testSource={dashboard.testsources[0]}
          value={values.importedDashboards}
          onChange={handlers.importedDashboards}
          errorMessage={errors.importedDashboards}
          validationState={states.importedDashboards}
        />
        <Row alignmentHorizontal="right">
          <Button type="primary" onClick={applyFilter} disabled={hasErrors}>
            Apply
          </Button>
          <Button type="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Row>
      </Column>
    </Sheet>
  );
};

export const DashboardFiltersButton = (): JSX.Element => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  return (
    <>
      <Tooltip position="bottom" title={tooltip}>
        <Button
          size="small"
          type="tertiary"
          label={tooltip}
          onClick={() => setIsMenuVisible(true)}
        >
          <Icon tokens={ViewTokens}>{tooltip}</Icon>
          Filter Data
        </Button>
      </Tooltip>
      {isMenuVisible && (
        <DashboardViewerFilters
          isVisible={isMenuVisible}
          onClose={() => setIsMenuVisible(false)}
        />
      )}
    </>
  );
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { css } from 'emotion';
import Table, { TableProps } from '@amzn/meridian/table/table';

export const horizontalScrollableTable = css({
  overflowX: 'auto',
  maxWidth: '100%',
});

export type DashboardWizardTableProps = {
  children: React.ReactNode;
} & TableProps;

export const DashboardWizardTable = ({
  children,
  ...tableProps
}: DashboardWizardTableProps): JSX.Element => (
  <div className={horizontalScrollableTable}>
    <Table {...tableProps}>{children}</Table>
  </div>
);

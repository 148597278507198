import React from 'react';
import Row from '@amzn/meridian/row';
import { ColumnDefinition, CoreRouterLink } from '@amzn/dots-core-ui';
import { DashboardListItem } from '@/hooks';
import {
  DashboardCloneButton,
  DashboardDeleteButton,
  DashboardEditButton,
  DashboardFavoriteButton,
} from './DashboardButtons';

export const DashboardColumnDefinitions: ColumnDefinition<
  DashboardListItem
>[] = [
  {
    id: 'name',
    header: 'Name',
    item: ({ name }: DashboardListItem): JSX.Element => (
      <CoreRouterLink href={`dashboard/${name}`}>{name}</CoreRouterLink>
    ),
  },
  {
    id: 'description',
    header: 'Description',
    item: (dashboard: DashboardListItem): string => dashboard.description,
  },
  {
    id: 'actions',
    header: 'Actions',
    item: ({ id, name }: DashboardListItem): JSX.Element => (
      <Row>
        <DashboardEditButton name={name} type="icon" />
        <DashboardFavoriteButton id={id} name={name} />
        <DashboardCloneButton id={id} name={name} type="icon" />
        <DashboardDeleteButton id={id} name={name} type="icon" />
      </Row>
    ),
  },
];

import React from 'react';
import { CoreInput, isNullOrWhitespace, useForm } from '@amzn/dots-core-ui';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import AddTokens from '@amzn/meridian-tokens/base/icon/plus';
import TrashTokens from '@amzn/meridian-tokens/base/icon/trash';
import { getUniqueId } from '@/features/Dashboards/helpers';
import { PivotTabWizardConfiguration } from '@/types';
import {
  TestSourcePropertyValueSelect,
  TestSourcePropertySelect,
} from '@/components';
import { useDashboard } from '@/features/Dashboards/contexts';
import { DashboardWizardBaseControl } from './DashboardWizardBaseControl';
import { DashboardWizardFormFieldLabel } from './DashboardWizardFormFieldLabel';
import {
  customTabParser,
  echoValue,
  onWizardConfirmFactory,
  getWizardBaseFormConfiguration,
  validateCustomTabName,
} from './helpers';
import { CustomTab, DashboardWizardFormProps } from './types';

export const DashboardPivotTabWizardForm = ({
  wizard,
  onCancel,
  onSubmit,
}: DashboardWizardFormProps<PivotTabWizardConfiguration>): JSX.Element => {
  const { dashboard } = useDashboard();
  const { hasErrors, handlers, values, errors } = useForm({
    ...getWizardBaseFormConfiguration(wizard),
    properties: {
      initialValue: wizard.data ?? [],
      validate: (value: unknown[]) =>
        value.length === 0 ? 'At least one KPI Property is required' : '',
    },
    customTabs: {
      initialValue: customTabParser.toFormModel(wizard.custom),
      validate: (value: CustomTab[]) =>
        value.some(
          (v) =>
            isNullOrWhitespace(v.key) ||
            isNullOrWhitespace(v.name) ||
            isNullOrWhitespace(v.source) ||
            isNullOrWhitespace(v.value)
        )
          ? 'All fields must be defined for all columns'
          : '',
    },
  });
  const onChangeCustomTabs = (tabId: string, next: Partial<CustomTab>) =>
    handlers.customTabs(
      values.customTabs.map((tab: CustomTab) => {
        if (tab.id === tabId) {
          return { ...tab, ...next };
        }
        return tab;
      })
    );
  const onRemoveCustomTabs = (tabId: string) =>
    handlers.customTabs(
      values.customTabs.filter(({ id }: CustomTab) => id !== tabId)
    );
  const onAddCustomTab = () =>
    handlers.customTabs(
      values.customTabs.concat({
        id: getUniqueId(),
        source: dashboard.testsources,
        key: '',
        name: '',
        value: [],
      })
    );
  const onConfirm = () =>
    onSubmit({
      ...onWizardConfirmFactory(wizard, values),
      type: wizard.type,
      custom: customTabParser.toConfigurationModel(values.customTabs),
      data: values.properties,
    });
  return (
    <Column>
      <DashboardWizardBaseControl
        wizard={wizard}
        values={values}
        handlers={handlers}
      />
      <DashboardWizardFormFieldLabel
        label="Data Fields"
        hint={
          <Column spacing="100">
            <Text>
              A filter tab will be created for each unique value of each KPI
              property selected.
            </Text>
            <Text>The unique values are used as the tab&apos;s label. </Text>
          </Column>
        }
      >
        <TestSourcePropertySelect
          label="KPI Properties"
          testSource={dashboard.testsources}
          value={values.properties}
          onChange={handlers.properties}
          errorMessage={errors.properties}
        />
      </DashboardWizardFormFieldLabel>
      <DashboardWizardFormFieldLabel
        label="Custom Fields"
        hint={
          <Column spacing="100">
            <Text>
              Define a custom filter tab by selecting specific KPI values for
              based on a specific KPI property.
            </Text>
            <Text>
              The Name field are used as the custom tab&apos;s label.{' '}
            </Text>
          </Column>
        }
      >
        {values.customTabs.map(
          ({ id, key, name, source, value: keyValues }: CustomTab) => (
            <Row key={id} widths={['fill', 'fit']}>
              <Column>
                <CoreInput
                  label="Name"
                  value={name}
                  onChange={(value) => onChangeCustomTabs(id, { name: value })}
                  errorMessage={validateCustomTabName(name, values.customTabs)}
                />
                <TestSourcePropertySelect
                  label="KPI Property"
                  testSource={dashboard.testsources}
                  value={key}
                  onChange={(value: any) =>
                    onChangeCustomTabs(id, { key: value })
                  }
                  errorMessage={
                    isNullOrWhitespace(key) ? 'KPI Property is required' : ''
                  }
                />
                <TestSourcePropertyValueSelect
                  label="Split Value(s)"
                  createItem={echoValue}
                  testSource={source}
                  propertyName={name}
                  value={keyValues}
                  onChange={(value: any) => onChangeCustomTabs(id, { value })}
                  errorMessage={
                    keyValues.length === 0
                      ? 'At least one Split Value is required'
                      : ''
                  }
                />
              </Column>
              <Button type="secondary" onClick={() => onRemoveCustomTabs(id)}>
                <Icon tokens={TrashTokens}>Remove this custom tab</Icon>
              </Button>
            </Row>
          )
        )}
        <Button type="tertiary" onClick={onAddCustomTab}>
          <Icon tokens={AddTokens}>Add a new custom tab</Icon>
        </Button>
      </DashboardWizardFormFieldLabel>
      <Row alignmentHorizontal="right" alignmentVertical="center" widths="fit">
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={hasErrors} onClick={onConfirm}>
          Confirm
        </Button>
      </Row>
    </Column>
  );
};

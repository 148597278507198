import React from 'react';
import ReactMarkdown from 'react-markdown';
import Text from '@amzn/meridian/text';
import Link from '@amzn/meridian/link';
import { JiraStatus } from '@/components/JiraStatus';

const MarkdownLink = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => {
  if (href.match(/\.(jira)$/)) {
    return <JiraStatus jiraID={href.split('.')[0]} />;
  }
  if (href.match(/\.(jpe?g|png|gif)$/)) {
    return <img src={href} alt={href} />;
  }
  return <Link href={href}>{children}</Link>;
};
const MarkdownParagraph = ({ children }: { children: React.ReactNode }) => {
  return <Text breakWord>{children}</Text>;
};
export const MarkdownContent = ({ text }: { text: string }): JSX.Element => {
  return (
    <ReactMarkdown
      source={text}
      renderers={{ link: MarkdownLink, paragraph: MarkdownParagraph }}
    />
  );
};

import React from 'react';
import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback } from '@/components';
import { DashboardWizardConfiguration } from '@/types';
import { DashboardBaseTableWizard } from './DashboardBaseTableWizard';
import { DashboardJiraTableWizard } from './DashboardJiraTableWizard';
import { DashboardKpiBaseTableWizard } from './DashboardKpiBaseTableWizard';
import { DashboardKpiLogWizard } from './DashboardKpiLogWizard';
import { DashboardKpiTableWizard } from './DashboardKpiTableWizard';
import { DashboardPivotTabWizard } from './DashboardPivotTabWizard';
import { DashboardPivotTableWizard } from './DashboardPivotTableWizard';
import { DashboardLineChartWizard } from './DashboardLineChartWizard';

export const getTestId = (
  configuration: DashboardWizardConfiguration
): string => `${configuration.type}-${configuration.id}`;

export type DashboardWizardProps = {
  configuration: DashboardWizardConfiguration;
};

const renderWizard = (configuration: DashboardWizardConfiguration) => {
  switch (configuration.type) {
    case 'basetable':
      return <DashboardBaseTableWizard configuration={configuration} />;
    case 'jiratable':
      return <DashboardJiraTableWizard configuration={configuration} />;
    case 'kpibasetable':
      return <DashboardKpiBaseTableWizard configuration={configuration} />;
    case 'kpilog':
      return <DashboardKpiLogWizard configuration={configuration} />;
    case 'kpitable':
      return <DashboardKpiTableWizard configuration={configuration} />;
    case 'linechart':
      return <DashboardLineChartWizard configuration={configuration} />;
    case 'pivottab':
      return <DashboardPivotTabWizard configuration={configuration} />;
    case 'pivottable':
      return <DashboardPivotTableWizard configuration={configuration} />;
    default:
      return (
        <Alert type="warning" size="medium">
          Wizard type not found
        </Alert>
      );
  }
};

export const DashboardWizard = ({
  configuration,
}: DashboardWizardProps): JSX.Element => {
  return (
    <Column data-testid={getTestId(configuration)}>
      {configuration.title && (
        <Heading level={2}>{configuration.title}</Heading>
      )}
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => (
          <ErrorBoundaryFallback
            error={error}
            resetErrorBoundary={resetErrorBoundary}
            message="An error occured rendering a wizard"
          />
        )}
      >
        {renderWizard(configuration)}
      </ErrorBoundary>
    </Column>
  );
};

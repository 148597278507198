import querystring from 'query-string';

export const jiraIdRegularExpression = /[A-Z]{3,}-[0-9]+/g;
export const jiraLinkRegularExpression = /(https?:\/\/(.+?\.)?labcollab\.net(\/[A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=]*)?)?[A-Z]{3,}-[0-9]+/g;

// use a single instance of Intl.Collator to speed up sorting comparisons
export const collator = new Intl.Collator(undefined, { numeric: true });

/**
 * Create a comparator function for sorting an array of values
 */
export const compareValues = (
  sortDirection: 'ascending' | 'descending' = 'ascending'
) => (a: any, b: any): number => {
  const comparisonResult = collator.compare(a.toString(), b.toString());
  return sortDirection === 'ascending'
    ? comparisonResult
    : comparisonResult * -1;
};

/**
 * Convert parameters in object to URL compatible string
 * @param {Object} payload
 * @returns {string} A URL compatible string: "param1=value1&param2=value2"
 */
export const dictToURI = (payload: Record<string, unknown>): string =>
  querystring.stringify(payload, {
    arrayFormat: 'comma',
    skipEmptyString: true,
    skipNull: true,
  });

/**
 * Turn URL query string into an object
 * @param {string} queryString
 * @returns {querystring.ParsedQuery<string | number | boolean>}
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const URIToDict = (queryString: string) =>
  querystring.parse(queryString, {
    arrayFormat: 'comma',
    parseBooleans: true,
    parseNumbers: true,
  });

/**
 * Replace Jira ID to markdown link
 * e.g. PIV-1234 -> [PIV-1234](PIV-1234.jira)
 */
export const replaceJiraID = (text: string): string => {
  return text
    .replace(jiraLinkRegularExpression, (m) => {
      const match = m.match(jiraIdRegularExpression);
      return match ? match[0] : m;
    })
    .replace(jiraIdRegularExpression, (m) => `[${m}](${m}.jira)`);
};

/**
 * Extract Jira ID from plain text
 * e.g. "Jira Integration PIV-1234 TASTE-123" => ["PIV-1234", "TASTE-123"]
 */
export const extractJiraID = (text: string): string[] => {
  const result = text
    .replace(jiraLinkRegularExpression, (m) => {
      const match = m.match(jiraIdRegularExpression);
      return match ? match[0] : m;
    })
    .match(jiraIdRegularExpression);

  return result ?? [];
};

/**
 * Get a darkened color from hex color
 */
export const shadeColor = (hexColorCode: string, percent: number): string => {
  let R = parseInt(hexColorCode.substring(1, 3), 16);
  let G = parseInt(hexColorCode.substring(3, 5), 16);
  let B = parseInt(hexColorCode.substring(5, 7), 16);

  R = Math.round((R * (100 + percent)) / 100);
  G = Math.round((G * (100 + percent)) / 100);
  B = Math.round((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR =
    R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

  return `#${RR}${GG}${BB}`;
};

/**
 * Transform HSV to RGB hex
 * @param {number} h
 * @param {number} s
 * @param {number} v
 */
const HSVtoRGB = (h: number, s: number, v: number) => {
  const i = Math.floor(h * 6);
  const f = h * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);
  let r = v;
  let g = t;
  let b = p;
  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
      r = v;
      g = p;
      b = q;
      break;
    default:
      break;
  }

  const RGB = {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  };

  return `#${[RGB.r, RGB.g, RGB.b]
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    })
    .join('')}`;
};

/**
 * Generate random hex color
 */
export const randomColor = (): string => {
  return HSVtoRGB(Math.random(), 0.9, 0.6);
};

import { useMemo } from 'react';
import { collator } from '@/helpers';
import { Annotation } from '@/types';
import { useDashboard } from '../contexts';
import { AnnotationContext } from '../types';
import { useDashboardAnnotations } from './useDashboardAnnotations';

const filterAnnotationsByContext = (
  dashboardId: string,
  context?: AnnotationContext,
  annotations?: Annotation[]
) =>
  annotations !== undefined && context !== undefined
    ? annotations.filter(
        (annotation) =>
          // include testrun annotations always
          ((annotation.type === 'testrun_annotation' || context.testrunIds) &&
            annotation.testrun_id &&
            context.testrunIds.includes(annotation.testrun_id)) ||
          // include wizard annotations if the wizard annotation
          // properties match or if the annotation was used in this cell
          // (i.e. if the annotation ID is in the dataIDs collection)
          (context.type === 'wizard_annotation' &&
            ((annotation.dashboard_id === dashboardId &&
              annotation.wizard_id === context.wizardId &&
              annotation.location === context.location) ||
              (annotation.kpi_id &&
                context.kpiIds.includes(annotation.kpi_id)))) ||
          // include kpi annotations if the kpi was used in this cell
          ((context.type === 'kpi_annotation' || context.kpiIds) &&
            annotation.kpi_id &&
            context.kpiIds.includes(annotation.kpi_id))
      )
    : [];

export const useDashboardWizardTableCellAnnotations = (
  context?: AnnotationContext
): { annotations: Annotation[] } => {
  const { dashboard } = useDashboard();
  const { data: allAnnotations } = useDashboardAnnotations();
  const annotations = useMemo(
    () =>
      filterAnnotationsByContext(
        dashboard.id,
        context,
        allAnnotations
      ).sort((a, b) => collator.compare(b.update_time, a.update_time)),
    [allAnnotations, context, dashboard.id]
  );
  return { annotations };
};

import { keys } from '@amzn/dots-core-ui';
import { serializeUiQueryParameters } from '@/hooks';
import { parseExpression } from '@/features/Dashboards/aggregation';
import { findMatchingGoal } from '@/features/Dashboards/goals';
import {
  calculateExpressions,
  extractUniqueKpiValues,
  groupKpis,
} from '@/features/Dashboards/helpers';
import {
  WizardTableData,
  WizardState,
  WizardTableCell,
  WizardTableRow,
} from '@/features/Dashboards/types';
import { WizardSelection } from '@/features/Dashboards/contexts';
import {
  BaseData,
  BaseDataPropertyIndexMap,
  Dashboard,
  KpiBaseTableWizardConfiguration,
  KpiProperty,
} from '@/types';
import { KpiBucketDetailUri } from '@/features/routes';

export interface KpiBaseTableCell extends WizardTableCell {
  selectionContext: WizardSelection;
}

export interface KpiBaseTableRow extends WizardTableRow<KpiBaseTableCell> {
  kpiBucket: string;
  kpiIds: string[];
  testrunIds: string[];
}

type ColumnDefinitionCompileParameters = {
  index: number;
  dashboard: Dashboard;
  groupBaseData: BaseData;
  groupedPropertyValues: Record<KpiProperty, string>;
  goal: string;
  resultColor?: string;
  result: string;
};

type ColumnDefinition = {
  compile: (params: ColumnDefinitionCompileParameters) => KpiBaseTableCell;
  name: string;
};

/**
 * Properties used to represent Kpi Base Table rows
 */
const groupByProperties: KpiProperty[] = [
  'kpi.bucket',
  'data.device.type',
  'data.build.project',
  'data.build.version',
  'data.build.variant',
  'data.test_suite',
];

/**
 * Create closures that can create a column cell based on a KPI group
 */
const getColumnDefinitions = (
  configuration: KpiBaseTableWizardConfiguration,
  propertyIndexMap: BaseDataPropertyIndexMap
): ColumnDefinition[] => {
  const {
    extras: userDefinedColumns,
    data: userDefinedVariables,
  } = configuration;
  // we should avoid parsing these expressions more than once, so we will close
  // over the parsed expressions here
  const userDefinedExpressions = userDefinedVariables.map(
    ({ name, expression }) => ({
      variableName: name,
      expression: parseExpression(expression),
    })
  );
  return [
    {
      name: '#',
      compile: ({ index, groupedPropertyValues }) => ({
        key: '#',
        content: `${index + 1}`,
        selectionContext: [groupedPropertyValues],
      }),
    },
    {
      name: 'SUMMARY',
      compile: ({ groupBaseData, groupedPropertyValues, dashboard }) => {
        const kpiBucket = groupedPropertyValues['kpi.bucket'];
        const kpiSummary = groupBaseData[1][
          propertyIndexMap['kpi.kpi_summary']
        ].toString();
        const filters = serializeUiQueryParameters({
          filters: keys(groupedPropertyValues)
            .filter((key) => key !== 'kpi.bucket')
            .map((prop) => ({
              key: `kats.${prop}`,
              operator: 'include',
              value: [groupedPropertyValues[prop]],
            })),
          timeRange: dashboard.filters.data_range.time.range
        });
        return {
          key: 'SUMMARY',
          content: kpiSummary,
          href: `${KpiBucketDetailUri(kpiBucket)}?dashboard=${
            dashboard.id
          }&${filters}`,
          selectionContext: [groupedPropertyValues],
        };
      },
    },
    ...userDefinedColumns.map(
      ({ name, format }): ColumnDefinition => ({
        name,
        compile: ({ groupBaseData, groupedPropertyValues }) => {
          const { content } = calculateExpressions(
            format,
            userDefinedExpressions,
            groupBaseData
          );
          return {
            key: name,
            content,
            selectionContext: [groupedPropertyValues],
          };
        },
      })
    ),
    {
      name: 'GOAL',
      compile: ({ goal, groupedPropertyValues }) => ({
        key: 'GOAL',
        content: goal,
        selectionContext: [groupedPropertyValues],
      }),
    },
    {
      name: 'RESULT',
      compile: ({ resultColor, result, groupedPropertyValues }) => ({
        key: 'RESULT',
        content: result,
        backgroundColor: resultColor,
        selectionContext: [groupedPropertyValues],
      }),
    },
  ];
};

/**
 * Create a selector function that produces a single page of data to be rendered
 * by a base table wizard
 */
export const createWizardData = ({
  configuration,
  data: { baseData, propertyIndexMap },
  dashboard,
  globalCalculatedData,
}: WizardState<KpiBaseTableWizardConfiguration>): WizardTableData<
  KpiBaseTableCell,
  KpiBaseTableRow
> => {
  const [baseDataHeader] = baseData;
  const kpiGroups = groupKpis(propertyIndexMap, baseData, groupByProperties);
  const columnDefinitions = getColumnDefinitions(
    configuration,
    propertyIndexMap
  );
  const header = columnDefinitions.map(
    ({ name }): KpiBaseTableCell => ({
      key: name,
      content: name,
      selectionContext: [],
    })
  );
  const rows = kpiGroups.map(
    (
      { baseDataIndices, groupKey, groupedPropertyValues },
      index
    ): KpiBaseTableRow => {
      // lets get a basedata structure that represents the current group
      const groupBaseData: BaseData = [
        baseDataHeader,
        ...baseDataIndices.map((i) => baseData[i]),
      ];
      // the JIRA cells require testrun and KPI Ids
      // const { kpiIds, testrunIds } = getIds(groupBaseData, propertyIndexMap);
      // the result and goal columns rely on computing a goal against the
      // KPI group current
      const { goal, value, color } = findMatchingGoal(
        dashboard.filters?.goal_groups ?? [],
        propertyIndexMap,
        groupBaseData,
        // Reducing by 1 because first row is the header
        { ...globalCalculatedData, _kpiCount: groupBaseData.length - 1 }
      );
      const cells = columnDefinitions.map(({ compile }) =>
        compile({
          dashboard,
          index,
          groupedPropertyValues,
          groupBaseData,
          goal,
          resultColor: color,
          result: value,
        })
      );
      const uniqueValues = extractUniqueKpiValues(
        ['unique_id', 'data.testrun_id'],
        { propertyIndexMap, baseData: groupBaseData }
      );
      return {
        key: groupKey,
        kpiBucket: groupedPropertyValues['kpi.bucket'],
        kpiIds: uniqueValues.unique_id,
        testrunIds: uniqueValues['data.testrun_id'],
        cells,
      };
    }
  );
  return {
    header,
    rows,
  };
};

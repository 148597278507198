import { useHttp, HttpClient } from '@/client';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { Jira } from '@/types';

export type CreateJiraArgs = {
  where: string;
  testrunId: string;
  source: string;
  summary: string;
  kpiBucket: string;
  jiraProject: string;
  labels: string;
  components: string;
  referenceUrl: string;
  description: string;
};

const createJira = async (
  http: HttpClient,
  args: CreateJiraArgs
): Promise<Jira> => {
  const { data, status } = await http.put('jira', args);
  if (status.code === 200) {
    return data;
  }
  throw new Error(data.message);
};

export const useJiraCreate = (): UseMutationResult<
  Jira,
  Error,
  CreateJiraArgs
> => {
  const { http } = useHttp();
  const queryClient = useQueryClient();
  return useMutation((args: CreateJiraArgs) => createJira(http, args), {
    onSuccess: () => queryClient.invalidateQueries('jira'),
  });
};

import React from 'react';
import Masthead, {
  MastheadTitle,
  MastheadMenuButton,
} from '@amzn/meridian/masthead';
import Loader from '@amzn/meridian/loader';
import Text from '@amzn/meridian/text';
import Heading from '@amzn/meridian/heading';
import { useUserProfile } from '@/hooks';
import { RootUri } from '@/features/routes';

const UserProfile = () => {
  const { data: userProfile, isLoading } = useUserProfile();
  if (isLoading || !userProfile) {
    return (
      <>
        <Loader size="medium" />
        <Text>Loading...</Text>
      </>
    );
  }
  return <Text>Welcome! {userProfile.user_id}</Text>;
};

export type AppMastheadProps = { onMenuButtonClick: () => void };

export const AppMasthead = ({
  onMenuButtonClick,
}: AppMastheadProps): JSX.Element => (
  <Masthead>
    <MastheadMenuButton onClick={onMenuButtonClick} />
    <MastheadTitle href={RootUri}>
      <Heading level={1}>Boomerang</Heading>
    </MastheadTitle>
    <UserProfile />
  </Masthead>
);

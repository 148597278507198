import { useQuery, UseQueryResult } from 'react-query';
import { Annotation, Dashboard, DefaultAnnotationTemplateId } from '@/types';
import { useHttp, HttpClient } from '@/client';
import {
  useDashboardQueryParameters,
  DashboardQueryParameterResult,
  DashboardQueryParameters,
} from '@/hooks';
import { useDashboard } from '../contexts';
import { extractUniqueKpiValues } from '../helpers';
import { useDashboardKpis } from './useDashboardKpis';

export type DashboardAnnotationsQueryKey = [
  string,
  {
    dashboardId: string;
    filters: Omit<DashboardQueryParameters, 'tab'>;
  }
];

const getAnnotations = async (
  http: HttpClient,
  filters: DashboardQueryParameterResult['params'],
  dashboard: Dashboard,
  annotationIds: string[],
  testrunIds: string[]
): Promise<Annotation[]> => {
  let dashboardFilters = {};
  if (filters.groupTab) {
    dashboardFilters = { groupTab: filters.groupTab };
  }
  if (filters.filterType && filters.filterValues) {
    dashboardFilters = {
      filterType: filters.filterType,
      filterValues: filters.filterValues,
    };
  }
  const annotationResponse = await http.post('annotation/filter', {
    dashboard_ids: [dashboard.id],
    dashboard_filters: JSON.stringify(dashboardFilters),
    annotation_ids: annotationIds,
    testrun_ids: testrunIds,
  });
  if (
    annotationResponse.status.code !== 200 ||
    !Array.isArray(annotationResponse.data)
  ) {
    return [];
  }
  // dashboard annotations use additions to specify if the annotation was made
  // on a specific tab, lets filter by those properties now
  const additionFilters: Record<string, string | undefined> = {
    groupTab: filters?.groupTab,
    filterType: filters?.filterType,
    filterValues: filters?.filterValues,
  };
  return annotationResponse.data.filter((annotation) => {
    if ('dashboard_additions' in annotation) {
      try {
        const additions = JSON.parse(annotation.dashboard_additions);
        // if a filter is defined by the annotation's dashboard addition, the
        // value should match the available filter's value
        return Object.keys(additionFilters)
          .filter((prop) => prop in additions)
          .every((prop) => additions[prop] === additionFilters[prop]);
      } catch (error) {
        return false;
      }
    } else {
      return true;
    }
  }).map(({notes, ...annotation}) => ( notes !== "" && annotation.template === undefined ? {
    ...annotation,
    template: {
      templateId: DefaultAnnotationTemplateId,
      version: '1',
      content: {
        Notes: {
          type: 'string',
          value: notes
        }
      }
    }
  } : annotation))
};

export const useDashboardAnnotations = (): UseQueryResult<
  Annotation[],
  Error
> => {
  const { http } = useHttp();
  const { dashboard } = useDashboard();
  const { params } = useDashboardQueryParameters();
  const { data } = useDashboardKpis();
  const kpiValues = extractUniqueKpiValues(
    ['annotations', 'data.testrun_id'],
    data
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { tab, ...keyParams } = params;
  return useQuery({
    queryKey: [
      'annotations',
      { dashboardId: dashboard.id, filters: keyParams },
    ],
    queryFn: () =>
      getAnnotations(
        http,
        params,
        dashboard,
        kpiValues.annotations,
        kpiValues['data.testrun_id']
      ),
    enabled: data !== undefined,
  });
};

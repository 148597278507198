
/* eslint-disable camelcase */
export type DashboardEmailReportConfigurationStatus =
  | 'ENABLED'
  | 'DISABLED'
  | 'ARCHIVED';

export type DashboardEmailReportConfigurationType = 'TESTRUN_SUMMARY';

export interface DashboardEmailReportConfiguration {
  dashboard_id: string;
  report_id: string;
  title: string;
  time_to_send: number;
  recipients: string[];
  status: DashboardEmailReportConfigurationStatus;
  report_type: DashboardEmailReportConfigurationType;
  report_params: {
    data_range: {
      time_range_unit: 'minutes' | 'hours' | 'days' | 'weeks';
      time_range_value: number;
    };
  };
  created_by?: string;
  created_on?: number;
  modified_by?: string;
  modified_on?: number;
}
export type SortDirection = 'ascending' | 'descending';

export type DashboardWizardSelection = { row: number; col: number };

export type UserDefinedVariableConfiguration = {
  name: string;
  expression: string;
};

export type KpiDimensionConfiguration = {
  name: string;
  data: KpiProperty;
};

export type UserDefinedColumnConfiguration = {
  name: string;
  format: string;
};

export type DashboardWizardBase = {
  id: string;
  tabIndex: number;
  sectionIndex: number;
  position: number;
  title?: string;
  kpi?: { key: KpiProperty; value: string };
  parent?: string;
};

export type PivotTabWizardConfigurationCustomTabConfiguration = {
  key: KpiProperty;
  name: string;
  source: string;
  value: string[];
};

export type PivotTabWizardConfiguration = DashboardWizardBase & {
  type: 'pivottab';
  data: KpiProperty[];
  custom?: PivotTabWizardConfigurationCustomTabConfiguration[];
};

export type BaseTableWizardConfigurationColumnConfiguration = {
  name: string;
  format: string | string[];
  href?: KpiProperty;
};

export type BaseTableWizardConfiguration = DashboardWizardBase & {
  type: 'basetable';
  col: BaseTableWizardConfigurationColumnConfiguration[];
};

export type KpiLogWizardConfiguration = DashboardWizardBase & {
  type: 'kpilog';
};

export type JiraTableWizardConfiguration = DashboardWizardBase & {
  type: 'jiratable';
  show: boolean;
  col: JiraTableWizardConfigurationColumnConfiguration[];
  jql?: string;
};

export type KpiBaseTableWizardConfiguration = DashboardWizardBase & {
  type: 'kpibasetable';
  extras: UserDefinedColumnConfiguration[];
  data: UserDefinedVariableConfiguration[];
};

export type KpiTableWizardConfiguration = DashboardWizardBase & {
  type: 'kpitable';
  display: 'Data item in rows' | 'Data item in columns';
  attrs: KpiDimensionConfiguration[];
  extras: UserDefinedColumnConfiguration[];
  data: UserDefinedVariableConfiguration[];
};

export type PivotTableCellColorConfiguration = {
  data: string;
  relationship: 'equal' | 'greater than' | 'less than' | 'between' | 'else';
  value: string;
  color: string;
};

export type PivotTableCellConfiguration = {
  color_type: 'text' | 'grid';
  color?: PivotTableCellColorConfiguration[];
  format: string;
  data: UserDefinedVariableConfiguration[];
};

export type PivotTableChartType = 'Line Chart' | 'Bar Chart';

export type PivotTableXAxisType = 'Table Row' | 'Table Column';

export type PivotTableChartMode =
  | 'Table Only'
  | 'Chart Only'
  | 'Table and Chart';

export type PivotTableChartConfiguration = {
  show: PivotTableChartMode;
  type?: PivotTableChartType;
  data?: string;
  yAxis?: {
    label?: string;
    unit?: string;
  };
  xAxis?: PivotTableXAxisType;
};

export type PivotTableWizardConfiguration = DashboardWizardBase & {
  type: 'pivottable';
  row: KpiDimensionConfiguration[];
  col: KpiDimensionConfiguration[];
  cell: PivotTableCellConfiguration;
  chart?: PivotTableChartConfiguration;
};

export type LineChartWizardConfiguration = DashboardWizardBase & {
  type: 'linechart';
  group_key?: KpiProperty;
  xAxis: {
    data: KpiProperty;
  };
  yAxis: {
    unit: string;
    label: string;
  };
  line: {
    value: string;
    split_key?: KpiProperty;
  };
};

export type DashboardWizardConfiguration =
  | BaseTableWizardConfiguration
  | JiraTableWizardConfiguration
  | KpiBaseTableWizardConfiguration
  | KpiLogWizardConfiguration
  | KpiTableWizardConfiguration
  | PivotTabWizardConfiguration
  | PivotTableWizardConfiguration
  | LineChartWizardConfiguration;

export type DashboardWizardKpiFilter = Record<KpiProperty, string>;

export type DashboardLayoutSection = {
  widths: string[];
  heights: string[];
};
export type DashboardLayout = {
  name: string;
  layout: DashboardLayoutSection[];
};

export type DashboardFilterAutoTranstlator = { key: string; value: string };

export type DashboardFilterCustomTab = {
  auto?: boolean | undefined;
  field: string;
  values: string[];
};

export type DashboardExactTimeRange = string[];

export type DashboardRelativeTimeRange = {
  unit: 'days' | 'weeks';
  number: number;
};

export type DashboardFilterTimeRange =
  | DashboardExactTimeRange
  | DashboardRelativeTimeRange;

export type DashboardFilterDataRange = {
  allBuilds?: boolean;
  last_n_sth: {
    n: string;
  };
  time: {
    range: DashboardFilterTimeRange;
  };
};

export type DashboardFilterTestrunRangeTermFilter = Partial<
  Record<KpiProperty, string | string[]>
>;

export type DashboardFilterGoalGroup = {
  display: string;
  deviceType?: string[];
  kpi?: string;
  upper?: string;
  lower?: string;
};

export type DashboardFilterLocalTabGroup = {
  enabled: boolean;
  groupName: string;
  type: string;
  value: string[];
};

export type TestrunRangeFilterType =
  | 'include'
  | 'exclude'
  | 'include_fuzzy'
  | 'exclude_fuzzy';

export type DashboardFilterTestrunRangeTestSourceFilter = Record<
  TestrunRangeFilterType,
  DashboardFilterTestrunRangeTermFilter
>;

export type DashboardFilterTestrunRangeFilter = {
  KATS: DashboardFilterTestrunRangeTestSourceFilter;
};

export type DashboardFilter = {
  auto_translators: DashboardFilterAutoTranstlator[];
  custom_tabs?: DashboardFilterCustomTab[];
  data_range: DashboardFilterDataRange;
  goal_groups?: DashboardFilterGoalGroup[];
  group_filter?: string[];
  group_tabs?: string[];
  import_filters: string[];
  imported_dashboards: string[];
  local_group_tabs?: DashboardFilterLocalTabGroup[];
  testrun_range: DashboardFilterTestrunRangeFilter;
};

export type AnnotationTemplateFieldType = 'string' | 'date' | 'jira' | 'choice';

export type AnnotationTemplateContentField = {
  type: AnnotationTemplateFieldType;
  value: string;
};

export type AnnotationTemplateFieldAttributes = {
  name: string;
  type: AnnotationTemplateFieldType;
  is_required: boolean;
  choices?: string[];
}

export type AnnotationTemplateContent = Record<string, AnnotationTemplateContentField>;

export type AnnotationTemplate = {
  templateId: string;
  version: String;
  content: AnnotationTemplateContent;
};

export type AnnotationTemplateField = {
  template_id: string;
  version: string;
  name: string;
  created_by: string;
  created_on: string;
  modified_by?: string;
  modified_on?: string;
  attributes: AnnotationTemplateFieldAttributes[];
}

export const DefaultAnnotationTemplateId:string = '9fe67090-4d44-45e7-bcae-d83b0f513687';

export const DefaultAnnotationTemplateField:AnnotationTemplateField = {
  template_id: DefaultAnnotationTemplateId,
	version: '1',
	name: 'DefaultAnnotationTemplate',
	created_by: 'Boomerang',
	created_on: '1970-01-01',
	attributes: [
	  {
	    name: 'Notes',
	    type: 'string',
	    is_required: true
	  }
  ]
};

export type Dashboard = {
  id: string;
  name: string;
  description: string;
  jiraProject: string;
  jiraLabels: string;
  jiraComponents: string;
  testsources: string;
  owners: string[];
  ownerGroups: string[];
  viewers: string[];
  viewerGroups: string[];
  filters: DashboardFilter;
  layouts: DashboardLayout[];
  wizards: DashboardWizardConfiguration[];
  template?: AnnotationTemplateField;
  templateState?: string;
};

export type KpiProperty =
  | 'kpi.name'
  | 'kpi.value'
  | 'kpi.kpi_summary'
  | 'kpi.unit'
  | 'kpi.bucket'
  | 'kpi.where'
  | 'id'
  | 'data.status'
  | 'data.tms_tags'
  | 'data.testrun_id'
  | 'data.testrun_url'
  | 'data.test_runtime'
  | 'data.test_suite'
  | 'data.result.pass'
  | 'data.result.fail'
  | 'data.result.skip'
  | 'data.total_runtime'
  | 'data.device.type'
  | 'data.device.dsn'
  | 'data.device.hw_config'
  | 'data.device.pool'
  | 'data.start_time'
  | 'data.build.version'
  | 'data.build.variant'
  | 'data.build.project'
  | 'data.build.type'
  | 'data.build.time'
  | 'ingest.source'
  | 'ingest.type'
  | 'ingest.start_time'
  | 'ingest.duration'
  | 'translator.name'
  | 'translator.version'
  | 'unique_id'
  | 'dashboard_name'
  | 'annotations'
  | 'total'
  | 'pass_rate'
  | 'data.task_uptake_latency'
  | 'data.app_build.version'
  | 'data.app_build.variant'
  | 'data.app_build.project'
  | 'data.app_build.type'
  | 'data.app_build.time';

export type KpiPropertyValue = string | number;

export type KpiFilter = Record<string, string | string[]>;

export type FlatKpi = (property: KpiProperty) => KpiPropertyValue;

export type BaseData = [KpiProperty[], ...KpiPropertyValue[][]];

export type BaseDataPropertyIndexMap = {
  [key in KpiProperty]: number;
};

export type FilterKpiResponse = {
  num_page: number;
  page: number;
  data: BaseData;
};

export type DashboardExtractTableDataArgs<
  TDashboardWizard extends DashboardWizardBase
> = {
  wizardConfiguration: TDashboardWizard;
  goalGroups: any[];
  propertyIndexMap: BaseDataPropertyIndexMap;
  baseData: BaseData;
  globalCalculatedData: Record<string, any>;
};

export type TestrunFile = {
  content: string;
  url: string;
};

export type AnnotationType =
  | 'wizard_annotation'
  | 'kpi_annotation'
  | 'testrun_annotation';

export type Annotation = {
  annotation_id: string;
  // type was introduced late so not all annotations will have it
  type?: AnnotationType;
  template?: AnnotationTemplate;
  resolved: boolean;
  updater: string;
  notes?: string;
  update_time: string;
  // used by kpi and testrun annotations
  source?: string;
  // kpi annotation only
  kpi_id?: string;
  // testrun annotation only
  testrun_id?: string;
  // wizard annotation only
  dashboard_id?: string;
  dashboard_additions?: string;
  wizard_id?: string;
  location?: string;
};

export type WizardExtractDataArgs = {
  wizardConfiguration: any;
  goalGroups: any;
  propertyIndexMap: { [key in KpiProperty]: number };
  baseData: string[][];
};

export type JiraIcon = {
  name: string;
  iconUrl: string;
};

export type Jira = {
  key: string;
  summary: string;
  priority: JiraIcon;
  status: JiraIcon;
  creator: string;
  created: string;
  assignee: string;
  updated: string;
  labels: string[];
};

export type JiraField =
  | 'key'
  | 'created'
  | 'updated'
  | 'priority'
  | 'status'
  | 'assignee'
  | 'summary';

export type JiraTableWizardConfigurationColumnConfiguration = {
  name: string;
  data: JiraField;
};

export type JiraTableData = {
  columns: JiraTableWizardConfigurationColumnConfiguration[];
  kpiBuckets: string[];
};

export type UserProfile = {
  user_id: string;
  dashboards: string[];
  recent_visits: { path: string; times: number }[];
  save_for_later: string[];
  setting: { landing_page: string };
};

export type GetReportRequestsResponse = {
  report_requests: DashboardReportRequest[];
  last_evaluated_key: string;
};

export type DashboardReportRequest = {
  report_request_id: string;
  report_request_status: string;
  title: string;
  created_on: number;
  report_location: string;
  submitted_by: string;
  dashboard_id: string;
  dashboard_name: string;
  start_date: string;
  end_date: string;
};

export type DeviceTypeTestCount = {
  device_type_name: string;
  fail_count: number;
  pass_count: number;
  skip_count: number;
  test_count: number;
  test_suite_count: number;
};

export type IngestionSource = {
  ingestion_source_name: string;
  test_count: number;
};

export type MonthlyTestCount = {
  device_types: DeviceTypeTestCount[];
  ingestion_sources: IngestionSource[];
  fail_count: number;
  month: string;
  year: string;
  pass_count: number;
  skip_count: number;
  test_count: number;
  test_suite_count: number;
};
